<template>
  <section class="section">
    <div class="fixedCta">
      <div class="field has-addons">
        <p class="control">
          <button
            class="button"
            :class="{ selected: current == 'my' }"
            style="border-radius: 15px 0 0 15px"
            @click="current = 'my'"
          >
            <span class="icon is-small">
              <i class="fas fa-layer-group"></i>
            </span>
            <span>My Groups</span>
          </button>
        </p>
        <p class="control">
          <button
            class="button"
            :class="{ selected: current == 'explore' }"
            style="border-radius: 0 15px 15px 0"
            @click="current = 'explore'"
          >
            <span class="icon is-small">
              <i class="fas fa-search"></i>
            </span>
            <span>Explore</span>
          </button>
        </p>
      </div>
    </div>
    <div v-if="current == 'my'">
      <!-- <h1 class="title">Groups</h1> -->
      <div
        v-for="group in groups"
        :key="group.id"
        class="columns"
        :class="{ 'is-hidden': !joined.includes(group.id) }"
      >
        <div class="column">
          <div
            class="groupcard"
            @click="openGroupCard(group)"
            :style="{ background: group.theme }"
          >
            <div class="columns is-mobile">
              <div class="column">
                <h1 class="title">
                  {{ group.name }}
                </h1>
              </div>
              <div v-if="group.unread" class="column is-3">
                <div v-if="group.unread > 99" class="unread">99+</div>
                <div v-else class="unread">
                  {{ group.unread }}
                </div>
              </div>
            </div>
            <p class="members">{{ group.participants }} Members</p>
            <div
              class="parent"
              v-if="group.featuredProfiles"
              :class="{ morepadding: group.description }"
            >
              <!-- <img
              v-for="(picture, index) in group.featuredProfiles"
              :key="'picture' + index"
              class="image"
              :src="picture"
              :style="'left: ' + index * 30 + 'px'"
            /> -->
              <v-lazy-image
                :src-placeholder="require('@/assets/img/user.png')"
                v-for="(picture, index) in group.featuredProfiles"
                :key="'picture' + index"
                class="image"
                :src="picture"
                :style="'left: ' + index * 30 + 'px'"
              />
            </div>
            <div class="content">
              <p v-if="group.description">
                {{ group.description }}
              </p>
              <a
                v-if="!joined.includes(group.id) && !banned.includes(group.id)"
                class="button is-small is-rounded is-fullwidth"
                style="border: none"
                @click="openJoinModal(group)"
              >
                Join this group
              </a>
              <a
                v-if="banned.includes(group.id)"
                class="button is-small is-rounded is-fullwidth"
                style="border: none"
                disabled
              >
                You're Banned
              </a>
              <div
                v-if="joined.includes(group.id)"
                class="message"
                @click="$router.push('/chat/' + group.id)"
              >
                <span v-if="group.chat.type == 'text'">
                  <b>{{ group.chat.name }}</b> : {{ group.chat.text }}
                </span>
                <span v-if="group.chat.type == 'image'">
                  <b>{{ group.chat.name }}</b> : sent an image
                </span>
                <span v-if="group.chat.type == 'file'">
                  <b>{{ group.chat.name }}</b> : sent a file
                </span>
                <span v-if="group.chat.type == 'new'">
                  <b>{{ group.chat.name }}</b> : joined this group
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- <h1 class="title">Explore</h1> -->
      <div v-for="group in groups" :key="group.id" class="columns">
        <div class="column">
          <div
            class="groupcard"
            @click="openGroupCard(group)"
            :style="{ background: group.theme }"
          >
            <div class="columns is-mobile">
              <div class="column">
                <h1 class="title">
                  {{ group.name }}
                </h1>
              </div>
              <div v-if="group.unread" class="column is-3">
                <div v-if="group.unread > 99" class="unread">99+</div>
                <div v-else class="unread">
                  {{ group.unread }}
                </div>
              </div>
            </div>
            <p class="members">{{ group.participants }} Members</p>
            <div class="parent" v-if="group.featuredProfiles">
              <!-- <img
              v-for="(picture, index) in group.featuredProfiles"
              :key="'picture' + index"
              class="image"
              :src="picture"
              :style="'left: ' + index * 30 + 'px'"
            /> -->
              <v-lazy-image
                :src-placeholder="require('@/assets/img/user.png')"
                v-for="(picture, index) in group.featuredProfiles"
                :key="'picture' + index"
                class="image"
                :src="picture"
                :style="'left: ' + index * 30 + 'px'"
              />
            </div>
            <div class="content">
              <p v-if="group.description">
                {{ group.description }}
              </p>
              <a
                v-if="!joined.includes(group.id) && !banned.includes(group.id)"
                class="button is-small is-rounded is-fullwidth"
                style="border: none"
                @click="openJoinModal(group)"
              >
                Join this group
              </a>
              <a
                v-if="banned.includes(group.id)"
                class="button is-small is-rounded is-fullwidth"
                style="border: none"
                disabled
              >
                You're Banned
              </a>
              <div
                v-if="joined.includes(group.id)"
                class="message"
                @click="$router.push('/chat/' + group.id)"
              >
                <span v-if="group.chat.type == 'text'">
                  <b>{{ group.chat.name }}</b> : {{ group.chat.text }}
                </span>
                <span v-if="group.chat.type == 'image'">
                  <b>{{ group.chat.name }}</b> : sent an image
                </span>
                <span v-if="group.chat.type == 'file'">
                  <b>{{ group.chat.name }}</b> : sent a file
                </span>
                <span v-if="group.chat.type == 'new'">
                  <b>{{ group.chat.name }}</b> : joined this group
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="groupDialog" class="groupJoin">
      <div class="card">
        <div class="card-content">
          <div class="columns is-mobile">
            <div class="column">
              <h1 class="title">
                {{ group.name }}
              </h1>
            </div>
            <div
              class="column is-3 has-text-right"
              @click="groupDialog = false"
            >
              <span class="icon" style="font-size: 1.5rem">
                <i class="fas fa-times-circle" aria-hidden="true"></i>
              </span>
            </div>
          </div>
          <p class="description">
            {{ group.description }}
          </p>
          <hr />
          <h2 class="subtitle">Do's</h2>
          <ul>
            <li v-for="allowed in group.allowed" :key="allowed">
              {{ allowed }}
            </li>
          </ul>
          <hr />
          <h2 class="subtitle">Dont's</h2>
          <ul>
            <li v-for="notAllowed in group.notAllowed" :key="notAllowed">
              {{ notAllowed }}
            </li>
          </ul>
          <div class="groupJoinBtn">
            <a
              class="button is-fullwidth"
              :class="{ 'is-loading': groupBtnLoading }"
              @click="joinGroup(group)"
            >
              Join this group
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { auth } from "@/main.js";
import { db } from "@/main.js";
import firebase from "firebase/app";
// import { Share } from "@capacitor/share";
// import { Webengage } from "@ionic-native/webengage";
import { mapGetters } from "vuex";
import Imgproxy from "imgproxy";
import VLazyImage from "v-lazy-image";
import { FCM } from "@capacitor-community/fcm";
export default {
  name: "chat",
  components: {
    VLazyImage,
  },
  data() {
    return {
      groups: [],
      mygroups: [],
      joined: [],
      banned: [],
      group: {},
      groupDialog: false,
      groupBtnLoading: false,
      proxy: new Imgproxy({
        key: "fb6333a954da296899362eca74b278dd",
        salt: "7436536ea6bfeea3559b21121f4844c2",
        baseUrl: "https://witblox-imgproxy.herokuapp.com",
        encode: true,
      }),
      current: "my",
    };
  },
  methods: {
    openJoinModal(group) {
      this.group = group;
      this.groupDialog = true;
      console.log(this.group);
      //   this.groupBtnLoading = false;
    },
    openGroupCard(group) {
      if (this.joined.includes(group.id)) {
        this.$router.push("/chat/" + group.id);
      }
    },
    joinGroup(group) {
      this.groupBtnLoading = true;
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("groups")
        .doc(group.id)
        .set(
          {
            added: new Date().getTime(),
            lastchecked: new Date().getTime(),
          },
          { merge: true }
        )
        .then(() => {
          var picture = "";
          if (auth.currentUser.photoURL)
            picture = this.proxy
              .builder()
              .resize("auto", 300, 150, 0)
              .generateUrl(auth.currentUser.photoURL);
          console.log(picture);
          db.collection("groups")
            .doc(group.id)
            .collection("participants")
            .doc(auth.currentUser.uid)
            .set(
              {
                added: new Date().getTime(),
                fname: this.profile.fname,
                lname: this.profile.lname,
                picture: picture,
                role: "participant",
              },
              { merge: true }
            )
            .then(() => {
              db.collection("groups")
                .doc(group.id)
                .update({
                  participants: firebase.firestore.FieldValue.increment(1),
                })
                .then(() => {
                  db.collection("groups")
                    .doc(group.id)
                    .collection("chats")
                    .add({
                      name: this.profile.fname + " " + this.profile.lname,
                      uid: auth.currentUser.uid,
                      timestamp: new Date().getTime(),
                      type: "new",
                      text: "joined this group.",
                      media: "",
                      picture: picture,
                    })
                    .then(() => {
                      FCM.subscribeTo({ topic: `group-${group.id}` }).then(
                        (res) => {
                          console.log(
                            `Subscribed to group-${group.id}`,
                            JSON.stringify(res)
                          );
                        }
                      );
                      this.joined.push(group.id);
                      this.groupDialog = false;
                      this.groupBtnLoading = false;
                      this.$router.push("/chat/" + group.id);
                    });
                });
            });
        })
        .catch((err) => {
          this.groupDialog = false;
          this.groupBtnLoading = false;
          console.log("Error:", err);
        });
    },
  },
  beforeMount() {
    db.collection("groups")
      .where("isPrivate", "==", false)
      .orderBy("updated", "desc")
      .get()
      .then((groups) => {
        groups.forEach((group) => {
          var groupData = group.data();
          groupData.id = group.id;
          groupData.unread = 0;
          db.collection("groups")
            .doc(group.id)
            .collection("chats")
            .orderBy("timestamp", "desc")
            .limit(1)
            .get()
            .then((chat) => {
              //   console.log(chat.docs[0].id, chat.docs[0].data());
              groupData.chat = chat.docs[0].data();
              groupData.chat.id = chat.docs[0].id;
              db.collection("groups")
                .doc(group.id)
                .collection("participants")
                .orderBy("added", "desc")
                .limit(5)
                .get()
                .then((members) => {
                  members.forEach((member) => {
                    var picture = "";
                    if (member.data().picture)
                      picture = this.proxy
                        .builder()
                        .resize("auto", 150, 150, 0)
                        .generateUrl(member.data().picture);
                    // console.log(member.data().picture, picture);
                    groupData.featuredProfiles.push(picture);
                  });
                  this.groups.push(groupData);
                });
            });
        });
        db.collection("users")
          .doc(auth.currentUser.uid)
          .collection("groups")
          .get()
          .then((groups) => {
            if (groups.empty) this.current = "explore";
            else this.current = "my";
            groups.forEach((group) => {
              if (group.data().banned) this.banned.push(group.id);
              else this.joined.push(group.id);
            });
          });
        db.collection("users")
          .doc(auth.currentUser.uid)
          .collection("groups")
          .get()
          .then((groups) => {
            groups.forEach((group) => {
              db.collection("groups")
                .doc(group.id)
                .collection("chats")
                .where("timestamp", ">", group.data().lastchecked)
                .limit(100)
                .get()
                .then((unread) => {
                  //   console.log(this.groups);
                  this.groups.forEach((grp) => {
                    // console.log(unread.size, grp);
                    if (grp.id == group.id) grp.unread = unread.size;
                  });
                });
            });
          });
      });
  },
  mounted() {},
  computed: {
    ...mapGetters({
      profile: "profile",
    }),
  },
};
</script>
<style scoped>
.groupcard {
  background: #000;
  border-radius: 12.5px;
  padding: 20px;
  position: relative;
  font-family: "Poppins", "sans-serif";
}

.groupcard h1.title {
  color: #fff;
  font-size: 1.7rem;
  font-family: "Poppins", "sans-serif";
}

.groupcard p.members {
  padding-bottom: 10px;
  font-family: "Poppins", "sans-serif";
}

.parent {
  position: relative;
  top: 0;
  left: 0;
  height: 150px;
}

.parent.morepadding {
  height: 190px;
}

.parent img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  border: 2px solid #fff;
}

.image1 {
  position: relative;
  left: 0;
}

.image2 {
  left: 30px;
}
.image3 {
  left: 60px;
}
.image4 {
  left: 90px;
}
.image5 {
  left: 120px;
}
.groupcard .content {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(255, 255, 255, 0.7);
  width: 100%;
  padding: 20px;
  border-radius: 0 0 12.5px 12.5px;
  font-family: "Poppins", "sans-serif";
}
p.members {
  color: #ebebeb;
}
.groupJoin {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgb(255, 255, 255, 0.75);
}

.groupJoin .card {
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  border-radius: 20px;
  padding-bottom: 100px;
}

.groupJoin .card-content {
  height: 100%;
  width: 100%;
  overflow: scroll;
  position: relative;
  padding-bottom: 100px;
}
.groupJoin .groupJoinBtn {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px 40px 110px 40px;
  width: 100%;
  background: rgba(236, 236, 236, 0.602);
}

.groupJoin .groupJoinBtn a.button {
  background: #fff !important;
}

/*.groupJoin .card {
  left: 50%;
  position: absolute;
  transform: translate(-50%, 50%);
  min-width: 300px;
}
 */
/* 
.groupJoin h1.title {
  margin-bottom: 5px;
}
.groupJoin p.description {
  padding-bottom: 20px;
} */
.v-lazy-image {
  filter: blur(0px) !important;
}
section.section {
  padding-bottom: 170px;
}
.unread {
  background: red;
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 1.1rem;
  border-radius: 20px;
  padding: 3px;
}

.content .message {
  padding: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixedCta {
  /* position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1111; */
  width: 100%;
  padding-bottom: 30px;
}

.fixedCta .field.has-addons {
  justify-content: center;
}
.fixedCta .field.has-addons button.button {
  border: 2px solid #000;
  font-family: "Poppins", "sans-serif";
  font-weight: 700;
  background: #fff;
  color: #000;
  -webkit-box-shadow: 0 24px 38px 3px rgb(0 0 0 / 14%),
    0 9px 46px 8px rgb(0 0 0 / 12%), 0 11px 15px -7px rgb(0 0 0 / 20%);
  box-shadow: 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%),
    0 11px 15px -7px rgb(0 0 0 / 20%);
}

.fixedCta .field.has-addons button.button.selected {
  background: #000;
  color: #fff;
}

ul {
  list-style-position: outside;
  list-style-type: circle;
  padding-left: 20px;
}

ul li {
  padding-bottom: 5px;
}
</style>