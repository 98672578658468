<template>
  <section class="main_section">
    <div class="summary box">
      <h1 class="is-size-5">Chadgpt Recharge</h1>
    </div>

    <div class="box">
      <h1 class="is-size-6 has-text-weight-semibold">Recharge Plan</h1>

      <div class="plans_wrapper">
        <div
          class="plan"
          :class="{ active: plan === 'normal' }"
          @click="handleActivePlanChange('normal')"
        >
          <input type="radio" id="normal" value="normal" v-model="plan" />
          <p class="billing_text">Topup Once</p>
          <div class="plan_input">
            <b style="margin-right: 5px">₹</b>
            <input type="number" placeholder="Enter amount" v-model="amount" />
          </div>

          <p class="plan_bottom">For {{ parseInt(amount / 2) }} Questions</p>
        </div>
        <div
          class="plan"
          :class="{ active: plan === 'unlimited' }"
          @click="handleActivePlanChange('unlimited')"
        >
          <input type="radio" id="unlimited" value="unlimited" v-model="plan" />
          <p class="billing_text has-text-centered">Topup Monthly</p>
          <div class="plan_input">
            <div class="box">
              <b>₹</b>
              <b>250</b>
            </div>
          </div>

          <p class="plan_bottom">For Unlimited Questions</p>
        </div>
      </div>
    </div>

    <div class="cta_btn" v-if="plan === 'unlimited'">
      <button
        class="button"
        :class="{ 'is-loading': isPaymentLoading }"
        :disabled="isPaymentLoading"
        @click="handleSubscription"
      >
        Pay Now
      </button>
    </div>
    <div class="cta_btn" v-else>
      <button
        class="button"
        :class="{ 'is-loading': isPaymentLoading }"
        :disabled="isPaymentLoading"
        @click="handlePayment"
      >
        Pay Now
      </button>
    </div>
  </section>
</template>

<script>
import { auth, db } from "@/main.js";
import firebase from "firebase/app";
import { createWebengageEvent } from "../helper";

export default {
  name: "ChadgptPayment",
  data() {
    return {
      script: "https://checkout.razorpay.com/v1/checkout.js",
      amount: 10,
      user: null,
      plan: "normal",
      isPaymentLoading: false,
    };
  },
  methods: {
    handleActivePlanChange(plan) {
      this.plan = plan;
    },
    async handlePayment() {
      if (!this.amount || parseInt(this.amount) < 1)
        return alert("Please enter valid amount");

      this.isPaymentLoading = true;
      createWebengageEvent({
        uid: auth.currentUser.uid,
        eventName: "Chadgpt: Recharge Attempted",
        eventData: {
          amount: parseInt(this.amount),
        },
      });

      const t = await fetch(
        "https://us-central1-witblox-5ae68.cloudfunctions.net/chadgptRecharge",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: parseInt(this.amount),
            currency: "INR",
          }),
        }
      );
      const data = await t.json();

      const isLoaded = await this.loadRazorPay();

      if (!isLoaded) return alert("Razorpay SDK failed to load.");

      const options = {
        key: "rzp_live_LxqoqZ6JH2rIT4",
        amount: data.data.amount,
        currency: data.data.currency,
        name: "WitBlox", //your business name
        description: "Chadgpt Recharge",
        image: "https://witblox.com/assets/compressed/witblox.png",
        order_id: data.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: function (response) {
          this.isPaymentLoading = false;
          db.collection("users")
            .doc(auth.currentUser.uid)
            .update({
              chadgpt_credits: firebase.firestore.FieldValue.increment(
                parseInt(parseInt(data.data.amount) / 100 / 2)
              ),
            });

          db.collection("chadgpt-recharge")
            .add({
              amount: parseInt(data.data.amount) / 100,
              razorpay_details: {
                razorpay_order_id: data.data.id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              },
              uid: auth.currentUser.uid,
              created: new Date().getTime(),
            })
            .then(() => {
              console.log("Payment successful");
              createWebengageEvent({
                uid: auth.currentUser.uid,
                eventName: "Chadgpt: Recharge Successful",
                eventData: {
                  amount: parseInt(this.amount),
                },
              });
              window.location.href = "/chadgpt";
            })
            .catch((err) => {
              console.log(err);
              alert("Payment failed");
            });
        },
        prefill: {
          name: this.user.fname + " " + this.user.lname,
          email: this.user.email,
          contact: this.user.phone,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);

      rzp1.on("payment.failed", function (err) {
        this.isPaymentLoading = false;
        console.log("Payment failed", err);
      });

      rzp1.open();

      this.isPaymentLoading = false;
    },
    async loadRazorPay() {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = this.script;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    },
    async handleSubscription() {
      this.isPaymentLoading = true;
      createWebengageEvent({
        uid: auth.currentUser.uid,
        eventName: "Chadgpt: Subcription Attempted",
        eventData: {
          amount: parseInt(this.amount),
        },
      });

      const t = await fetch(
        "https://us-central1-witblox-5ae68.cloudfunctions.net/createChadgptSubscription",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: parseInt(this.amount),
            currency: "INR",
          }),
        }
      );
      const data = await t.json();

      const isLoaded = await this.loadRazorPay();

      if (!isLoaded) return alert("Razorpay SDK failed to load.");

      const options = {
        key: "rzp_live_LxqoqZ6JH2rIT4",
        subscription_id: data.data.id,
        name: "WitBlox",
        description: "Chadgpt Subscription Plan",
        image: "https://witblox.com/assets/compressed/witblox.png",
        handler: function (response) {
          this.isPaymentLoading = false;
          db.collection("users")
            .doc(auth.currentUser.uid)
            .update({
              chadgpt_credits: firebase.firestore.FieldValue.increment(
                parseInt(1000)
              ),
            });

          db.collection("chadgpt-recharge")
            .add({
              amount: 250,
              subscription: true,
              razorpay_details: {
                razorpay_order_id: data.data.id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              },
              uid: auth.currentUser.uid,
              created: new Date().getTime(),
            })
            .then(() => {
              console.log("Payment successful");
              createWebengageEvent({
                uid: auth.currentUser.uid,
                eventName: "Chadgpt: Subscription Successful",
                eventData: {
                  amount: parseInt(this.amount),
                },
              });
              window.location.href = "/chadgpt";
            })
            .catch((err) => {
              console.log(err);
              alert("Payment failed");
            });
        },
        prefill: {
          name: this.user.fname + " " + this.user.lname,
          email: this.user.email,
          contact: this.user.phone,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new window.Razorpay(options);

      rzp1.on("payment.failed", function (err) {
        this.isPaymentLoading = false;
        console.log("Payment failed", err);
      });

      rzp1.open();

      this.isPaymentLoading = false;
    },
  },
  beforeMount() {
    const plan = this.$route.params.plan;
    if (!plan) this.plan = "normal";
    else this.plan = plan;
    db.collection("users")
      .doc(auth.currentUser.uid)
      .get()
      .then((doc) => {
        this.user = { ...doc.data(), id: doc.id };
      });
  },
};
</script>

<style scoped>
iframe {
  position: relative !important;
  display: block !important;
}

.main_section {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin: 20px 0;
}

.box {
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summary h1 {
  font-weight: 500;
}

.plans_wrapper {
  display: flex;
  justify-content: center;
}

.plan {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  border: 2px solid #ccc;
  border-radius: 3px;
}

.active {
  border: 2px solid #3399cc;
}

.plan_input input {
  width: 4rem;
  padding: 0.7rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  text-align: center;
  font-size: 1.4rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: none;
  font-weight: 500;
}

.billing_text {
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.plan_bottom {
  opacity: 0.7;
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
  margin: 0.6rem 0;
}

.cta_btn button {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 3px;
  background-color: #3399cc;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>