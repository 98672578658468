<template>
  <div class="hero transactions">
    <div class="hero-body">
      <div class="columns is-mobile">
        <div class="column">
          <h1 class="title has-text-centered">
            <i class="fas fa-wallet"></i>
            Wallet
          </h1>
          <div class="card depth">
            <div class="columns is-mobile has-text-centered tvalues">
              <div class="column">
                <p class="title">{{ coins }}</p>
                <p class="subtitle">WitCoins</p>
              </div>
              <div class="column is-2">
                <p class="title">=</p>
              </div>
              <div class="column">
                <p class="title">&#8377;{{ cash }}</p>
                <p class="subtitle">Cash</p>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="notification">
                  You have earned {{ totalcoins }} WitCoins till now. Above
                  amount reflects only remaining WitCoins.
                </div>
              </div>
            </div>
            <hr />
            <div
              class="columns is-mobile transaction plus"
              :class="{ minus: transaction.minus }"
              v-for="transaction in orderedTransactions"
              :key="transaction.id"
            >
              <div class="column is-3 has-text-centered">
                <i
                  v-if="transaction.type == 'signup'"
                  class="fas fa-user-plus"
                ></i>
                <i
                  v-if="transaction.type == 'referral'"
                  class="fas fa-star"
                ></i>
                <i v-if="transaction.type == 'rating'" class="fas fa-star"></i>
                <i v-if="transaction.type == 'upload'" class="fas fa-atom"></i>
                <i
                  v-if="transaction.type == 'shop'"
                  class="fas fa-shopping-bag"
                ></i>
                <i
                  v-if="transaction.type == 'quiz'"
                  class="fas fa-question-circle"
                ></i>
              </div>
              <div class="column">
                <p class="reason">{{ transaction.title }}</p>
                <p class="date">{{ transaction.timing }}</p>
              </div>
              <div class="column is-4 amount">
                <p>{{ transaction.coins }}</p>
                <p>{{ transaction.cash }}&#8377;</p>
              </div>
            </div>
            <!-- <div class="columns">
              <div class="column has-text-centered">
                <a class="button">Load More</a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { auth } from "@/main.js";
import { functions } from "@/main.js";
import { db } from "@/main.js";
import _ from "lodash";
export default {
  name: "shoptransactions",
  data() {
    return {
      coins: 0,
      cash: 0,
      totalcoins: 0,
      transactions: [],
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    /*
    accoutingCheck() {
      //do a quick accounting fix
      var users = [
        "EygYraWN9hSth5sYXXcWuC9necp2",
        "AZ55ai06zIWsCLiajk040oEKk4X2",
        "LwM63NuKDkQFhI6c3N3AvPQhtz02",
        "WT6I0rpHXtfZncn3ZoP1wUnwyNL2",
        "cnphiT6HbeXZLKYPBXzYavZGmbF2",
        "AOqPeiFhdwfa20bxj7pufLIeFFE2",
        "4OyL08iuLyMw70sz3uo8Tl2XRoI2",
        "EF6y1H08zzg9uvVoBxXBsK8TaQs2",
        "pNO8x5Wdc3ebSUHQ7qd42AKPSDc2",
        "bG995RGvKZWvpmFKmu20VgsGPrf1",
        "ultYqpsIhQMZLODiE50BuH1xd8U2",
        "98RULZrJ3wOX1xHzmogbAUkkaTd2",
        "XT4AiqSeH8NwS8PqHwllIeOeT2p1",
        "Fr4pomJTktho51Xthc9ojepfqFD2",
        "xR2PUahZTFNrPPFNjed5S4Qx0oV2",
        "2fAB8eZRWZgYH0k2ULWVM5of7of1",
        "NPWlSTh7aQSzLKP4bBeszISwoEx2",
        "4pNIDbDODBdynNZgUisldWlKndE3",
        "oOMuHyiFrlUwfB2JhAZZAvZ7v2K3",
        "fyYqs4WgAMdkRzCevzoRBU9fHPX2",
        "bsEtFNMQ2UWxprYNpSGgOL9lTNs2",
        "5UFqDJa7KvNd54bsQe9M7c4V5ut1",
        "EDDnx28FuTZ0A9Ccg02fxeoTHcY2",
        "Pn3mHfGwvTQV45ulugqApcwuKE42",
        "Iv9ZMIdJi3Uc77OhQRweXVRkjjk1",
        "WlXP9C2qxKYUodfvAQIjomEWIgU2",
        "HA9gFV8ICdTSkdg7RNAMyeAnQhC2",
        "JRKKycWV5GRr90XFhM7vhvLC1VJ3",
        "EH12xaqmNyVDr6xvTCN0PS7ND6D2",
        "vBRhAcW6UWh9SsXKse2v8VyR2a03",
        "pVLtX1vqe9gIE9cl689MdQA7smy2",
        "bStfKEGyR7fJCq7J7awSQdIPVpI2",
        "iDQgkePyKfSxjk6aalGHYArJtcX2",
        "72E9KYJSAbQZ5gL0fPCd6Iodg003",
        "5s4JQCyTYFNavaFODqIfHDLyrrl2",
        "z8zYU2DBM1aSuj1Ps3p82M5Ee253",
        "2X2jE4VZ5YgcdNThrzpuFvJOLBr2",
        "lwTlBnYaHcRXubnzMgj06Nuh7q12",
        "l7aqPKzziuY36Fy9gmRgRK0iHH12",
        "7lsVv9GpDgYB9V95a48y72vlOFG3",
        "D45s1nh6rsfftGUnRZRNt7VDFAG2",
        "0DnkhPOezLc5LHCMkXvZk1WqJ3D3",
        "POsST76UuMYXS17JG5DW2Kv9LOF3",
        "uOqZj8mUumUJ94q992iTmFQFY8T2",
        "6UwAmao2uzWbleEigScVsqB0osl1",
        "B2uT6o8PlhPc20G8zehaAb2DZ2i1",
        "fW9VDRvZZfbXiUT2Ue39lgWhi1h2",
        "mlLcxhcinZP0Z8zbqc19fzIhzmW2",
        "uajb7saeG1Vq2nkoVGBir88sWMB2",
        "SoFp3Bz3mVXC2VbTaFXb4tTAqZx2",
        "YsMvjhEZh4aYIfCwGPFAMchnFWm2",
        "x5L4ZXhTiScgWwsLpkIxYanzZsW2",
        "DyLK23neqpckDeU4qyz1PJRK5PG3",
        "jiEZBwSOT0OgUpEmi2drV24Zjem2",
        "W0Tjm3S14bSJHkiIHtNX4z7Bqeu2",
        "4goLwFjyKcYa5n3ReV7o42b5oKg2",
        "eohFsmHHZhW2Pml4s5dhF2Z69rj2",
        "w6YGaJTOQ8YeRuZbre6wR4Ozrhz2"
      ];
      users.forEach((user) => {
        db.collection("users")
          .doc(user)
          .collection("coins")
          .get()
          .then((coins) => {
            var totalCoins = 0;
            coins.forEach((coin) => {
              totalCoins = totalCoins + coin.data().value;
            });
            console.log("user: ", user, " : ", totalCoins);
          });
      });
    },
    */
  },
  computed: {
    orderedTransactions: function () {
      return _.orderBy(this.transactions, ["index"], ["desc"]);
    },
  },
  beforeMount() {
    auth.onAuthStateChanged((user) => {
      //fetch usable coins
      db.collection("users")
        .doc(user.uid)
        .collection("coins")
        .get()
        .then((snap) => {
          var coinsCollection = [];
          for (var i = 0; i < snap.size; i++) {
            coinsCollection.push(snap.docs[i].data().value);
          }
          function arraySum(arr) {
            return arr.reduce(function (a, b) {
              return a + b;
            }, 0);
          }
          this.coins = this.numberWithCommas(
            arraySum(coinsCollection).toFixed(0)
          );
          this.cash = this.numberWithCommas(
            (arraySum(coinsCollection) / 10).toFixed(1)
          );
          snap.forEach((coin) => {
            var coins = this.numberWithCommas(coin.data().value.toFixed(0));
            var cash = this.numberWithCommas(
              (coin.data().value / 10).toFixed(1)
            );
            var title = "";
            if (coin.data().from == "rating")
              title = "Rating received on your post";
            else if (coin.data().from == "upload")
              title = "Coins rewarded for uploading project";
            else if (coin.data().from == "shop")
              title = "Paid for order on Shop";
            else if (coin.data().from == "signup")
              title = "Coins rewarded by WitBlox";
            else if (coin.data().from == "referral")
              title = "Coins rewarded by friend";
            else if (coin.data().from == "quiz")
              title = "Coins rewarded for quiz answers";
            else title = "Transaction";
            if (coin.data().from) {
              this.transactions.push({
                id: coin.id,
                title: title,
                timing: "",
                coins: (coin.data().value < 0 ? "" : "+") + coins,
                cash: (coin.data().value < 0 ? "" : "+") + cash,
                type: coin.data().from,
                minus: coin.data().value < 0,
                index: coin.data().created,
              });
            }
          });
        });
      var profileStats = functions.httpsCallable("profileStats");
      profileStats({ uid: user.uid }).then((result) => {
        this.totalcoins = this.numberWithCommas(Number(result.data.coins));
      });
    });
  },
  mounted() {
    setTimeout(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }, 1000);
  },
};
</script>
<style scoped>
.tvalues .title {
  font-size: 1.3rem;
}
.tvalues .subtitle {
  font-size: 0.9rem;
}
.transactions .card {
  padding: 25px;
  margin-bottom: 50px;
}
.columns.is-mobile.transaction {
  border-radius: 10px;
  margin-bottom: 18px;
}
.transaction p {
  font-weight: 600;
  font-size: 1.1rem;
}
.transaction p.reason {
  font-size: 0.8rem;
}
.transaction p.date {
  font-size: 0.85rem;
  font-weight: 500;
}
.transaction.plus {
  background: #f4f8fbab;
}
.transaction.minus {
  background: #ff000017;
}
.transactions .button {
  border: 4px solid;
  font-weight: 600;
}
.transaction i {
  font-size: 2rem;
  padding-top: 10px;
}
.transactions .notification {
  font-size: 0.85rem;
  padding: 1rem;
}
</style>