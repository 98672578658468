<template>
  <section class="prizes section">
    <div class="columns is-mobile">
      <div class="column">
        <h1 class="title">Build Projects &amp; Win Prizes</h1>
      </div>
      <div class="column is-4 has-text-right">
        <a
          class="button is-small is-rounded"
          @click="$router.push('/transactions')"
        >
          <img
            src="@/assets/img/coin.svg"
            style="width: 15px; margin-right: 5px"
          />
          <span id="coins">{{ coins }}</span>
        </a>
      </div>
    </div>
    <div v-if="timer" class="columns">
      <div class="column">
        <article class="message is-primary">
          <div class="message-body">
            {{ timer }}
          </div>
        </article>
      </div>
    </div>
    <!-- <h1 class="title">Prizes Zone</h1> -->
    <div class="columns is-hidden">
      <!-- <div class="column">
        <div class="card prize">
          <div class="card-content">
            <div class="columns is-mobile">
              <div class="column is-3">
                <img
                  src="https://png.pngitem.com/pimgs/s/117-1172906_fidji-dinner-in-bernardaud-square-white-plate-png.png"
                  alt=""
                />
              </div>
              <div class="column">
                <h3 class="subtitle">Samsung Tab</h3>
                <h4 class="price">10,00,000 WitCoins</h4>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="buttons">
                  <a class="button">Claim</a>
                  <a class="button">Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="column">
        <video-player
          v-if="vid1.loaded"
          class="vjs-custom-skin"
          ref="videoPlayer"
          :options="vid1"
          :playsinline="true"
        ></video-player>
      </div>
    </div>
    <div class="columns is-hidden">
      <div class="column">
        <div
          class="reward"
          v-for="prize in prizes"
          :key="prize.id"
          @click="openPopup(prize)"
        >
          <img :src="prize.image" alt="..." />
          <a v-if="prize.stock > 0" class="button is-small"
            >{{ prize.stock }} Left</a
          >
          <a v-else class="button is-small disabled" disabled>All Claimed</a>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column prizecollection">
        <div
          class="prizeitem"
          v-for="prize in prizes"
          :key="prize.id"
          @click="openConfirmationPopup(prize)"
        >
          <a class="pcard">
            <div class="prize-container">
              <img v-if="prize.newImage" :src="prize.newImage" alt="..." />
              <img v-else :src="prize.image" alt="..." />
            </div>

            <button class="button is-large is-fullwidth has-text-left">
              <div class="lock-wrapper" v-if="coinsRaw < prize.value">
                <!-- <i class="fas fa-lock"></i> -->
                <img src="@/assets/img/lock.png" alt="..." />
              </div>
              <span class="icon">
                <i class="fas fa-coins"></i>
              </span>
              <span>
                <span>
                  <span v-if="coinsRaw < prize.value">Unlock</span>
                  <span v-else>Claim</span>
                  with
                </span>
                <br />
                <b>{{ numberWithCommas(prize.value) }}</b> WitCoins
              </span>
            </button>
          </a>
        </div>
      </div>
    </div>

    <!-- Confirmation Popup -->
    <div class="popup" v-if="confirmationPopup">
      <div class="columns is-mobile">
        <div class="column">
          <h1 class="title">Are you sure?</h1>
        </div>

        <div class="column is-3 has-text-right">
          <i
            @click="confirmationPopup = false"
            class="far fa-times-circle"
            style="font-size: 1.6rem"
          ></i>
        </div>
      </div>

      <div>
        <img
          src="https://media.tenor.com/PLK7px-Lji8AAAAC/side-eye.gif"
          alt="..."
        />

        <p style="margin-bottom: 1rem" class="is-size-5">
          <b> Have you earned WitCoins using fair means? </b>
          <br />
          If not we will reject the claim.
        </p>
      </div>

      <div class="columns" style="display: flex">
        <div class="column" style="width: 100%" @click="openPopup(activePrize)">
          <a style="width: 100%" class="button unclaimed">Yes</a>
        </div>
        <div
          class="column"
          style="width: 100%"
          @click="confirmationPopup = false"
        >
          <a class="button unclaimed" style="width: 100%">No</a>
        </div>
      </div>
    </div>

    <!-- popup -->
    <div class="popup" v-if="!hide">
      <div class="columns is-mobile">
        <div class="column">
          <h1 class="title">{{ popup.name }}</h1>
          <h3 class="coins">
            <img src="@/assets/img/coin.svg" width="15px" />
            {{ numberWithCommas(popup.value) }} WitCoins
          </h3>
        </div>
        <div class="column is-3 has-text-right">
          <i
            @click="hide = true"
            class="far fa-times-circle"
            style="font-size: 1.6rem"
          ></i>
        </div>
      </div>
      <hr />
      <p class="subtitle">
        {{ popup.desc }}
      </p>
      <h4 v-if="popup.value > coinsRaw" class="message">
        You will need
        <b>{{ numberWithCommas(popup.value - coinsRaw) }} WitCoins</b>
        more to claim this.
      </h4>
      <hr />
      <img v-if="popup.featuredimg" :src="popup.featuredimg" alt="" />
      <hr v-if="popup.featuredimg" />
      <div v-if="popup.stock > 0">
        <div v-if="!popup.claimed && popup.value < coinsRaw" class="columns">
          <div class="column">
            <div class="field">
              <div class="control">
                <input
                  v-model="details.name"
                  class="input"
                  type="text"
                  placeholder="Full Name"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <div class="control">
                <input
                  v-model="details.phone"
                  class="input"
                  type="number"
                  placeholder="Phone Number"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <div class="control">
                <input
                  v-model="details.pin"
                  class="input"
                  type="number"
                  placeholder="Pincode"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field">
              <div class="control">
                <input
                  v-model="details.address"
                  class="input"
                  type="text"
                  placeholder="Full Address"
                />
              </div>
            </div>
          </div>
        </div>
        <article v-if="details.error" class="message is-danger">
          <div class="message-body">
            {{ details.error }}
          </div>
        </article>
        <a v-if="popup.claimed" class="button is-fullwidth disabled" disabled
          >CLAIMED</a
        >
        <a
          v-if="!popup.claimed && popup.value < coinsRaw"
          class="button is-fullwidth unclaimed"
          :class="{ 'is-loading': btnLoading }"
          @click="claim()"
          >CLAIM</a
        >
        <a
          v-if="popup.value > coinsRaw"
          class="button is-fullwidth unclaimed disabled"
          disabled
          >NEED MORE COINS</a
        >
      </div>
      <div v-else>
        <a class="button is-fullwidth unclaimed disabled" disabled
          >ALL CLAIMED</a
        >
      </div>
    </div>
    <!-- /popup -->
  </section>
</template>
<script>
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { functions } from "@/main.js";
import "@/assets/css/video-js.css";
import { videoPlayer } from "vue-video-player";
// import { Webengage } from "@ionic-native/webengage";
import { createWebengageEvent } from "../helper";
export default {
  name: "prizes",
  components: {
    videoPlayer,
  },
  data() {
    return {
      prizes: [],
      coins: 0,
      coinsRaw: 0,
      activePrize: {},
      timer: "",
      popup: {},
      hide: true,
      on: false,
      btnLoading: false,
      confirmationPopup: false,
      details: {
        name: "",
        address: "",
        phone: "",
        pin: "",
        error: "",
      },
      claims: [],
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: "https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/banners%2Fprizes%20zone%20video.mp4?alt=media&token=3fb848fe-40ae-44fc-98aa-4b08d567813c",
          },
        ],
        poster:
          "https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/banners%2F1.jpg?alt=media&token=c8003e56-7a6a-4519-8f4a-e611c7755d4f",
        loaded: true,
      },
    };
  },
  methods: {
    format(value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
      }
      return newValue;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setTimer(countDownDate, start) {
      console.log(countDownDate, start);
      const that = this;
      function timerFunc() {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if (start) {
          that.timer =
            "Starts in " +
            days +
            "d " +
            hours +
            "h " +
            minutes +
            "m " +
            seconds +
            "s ";
        } else
          that.timer =
            "Ends in " +
            days +
            "d " +
            hours +
            "h " +
            minutes +
            "m " +
            seconds +
            "s ";
        if (distance < 0) {
          clearInterval(x);
          that.timer = "now";
        }
      }
      timerFunc();
      var x = setInterval(function () {
        timerFunc();
      }, 60000);
    },
    openConfirmationPopup(prize) {
      if (prize.value > this.coinsRaw) return;

      this.activePrize = prize;
      this.confirmationPopup = !this.confirmationPopup;
    },
    openPopup(obj) {
      if (obj.value > this.coinsRaw) return;
      this.confirmationPopup = false;

      obj.claimed = false;
      this.popup = obj;
      this.hide = false;
      if (this.claims.includes(obj.id)) obj.claimed = true;
    },
    claim() {
      this.btnLoading = true;
      if (this.details.name) {
        if (this.details.address) {
          if (this.details.phone) {
            if (this.details.pin) {
              this.details.error = "";
              var claimPrize = functions.httpsCallable("claimPrize");
              claimPrize({ item: this.popup.id, details: this.details }).then(
                (response) => {
                  if (response.data.claimed) {
                    this.btnLoading = false;
                    this.popup.claimed = true;
                  }
                }
              );
            } else {
              this.details.error = "Please fill your PIN Code.";
              this.btnLoading = false;
            }
          } else {
            this.details.error = "Please fill your phone.";
            this.btnLoading = false;
          }
        } else {
          this.details.error = "Please fill your address.";
          this.btnLoading = false;
        }
      } else {
        this.details.error = "Please fill your name.";
        this.btnLoading = false;
      }
    },
  },
  beforeMount() {
    db.collection("config")
      .doc("prizes")
      .collection("items")
      .orderBy("order")
      .get()
      .then((prizes) => {
        prizes.forEach((prize) => {
          var prizeObj = prize.data();
          prizeObj.id = prize.id;
          this.prizes.push(prizeObj);
        });
      });
    db.collection("config")
      .doc("prizes")
      .get()
      .then((config) => {
        if (config.data().start > new Date().getTime())
          this.setTimer(config.data().start, true);
        else if (config.data().end > new Date().getTime())
          this.setTimer(config.data().end, false);
        else this.ended = true;
      });
    db.collection("config")
      .doc("prizes")
      .collection("claims")
      .where("uid", "==", auth.currentUser.uid)
      .get()
      .then((snap) => {
        snap.forEach((claim) => {
          this.claims.push(claim.data().item);
        });
      });
    db.collection("users")
      .doc(auth.currentUser.uid)
      .collection("coins")
      .get()
      .then((snap) => {
        var coinsCollection = [];
        for (var i = 0; i < snap.size; i++) {
          coinsCollection.push(snap.docs[i].data().value);
        }
        function arraySum(arr) {
          return arr.reduce(function (a, b) {
            return a + b;
          }, 0);
        }
        this.coins = this.numberWithCommas(arraySum(coinsCollection));
        this.coinsRaw = arraySum(coinsCollection);
      });
  },
  mounted() {
    createWebengageEvent({
      uid: auth.currentUser.uid,
      eventName: "Prizes Zone Viewed",
      eventData: {},
    });
  },
};
</script>
<style scoped>
section.prizes {
  /* height: 100% !important; */
  padding: 2rem 1.5rem 5rem 1.5rem;
}

.prize {
  border-radius: 15px;
}

.prizes h3.subtitle {
  margin-bottom: 0.5rem;
}

.prizes h4.price {
  font-family: Poppins;
  font-weight: 600;
  font-size: 1.1rem;
}

.prizes .message {
  font-family: Poppins;
  font-weight: 600;
}

.prizes .message b {
  font-weight: 700;
}

.prizes a.button {
  margin-left: 3px;
  margin-bottom: 3px;
  border: 2px solid;
  color: #5743e1;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.prizes a.button span {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.reward {
  position: relative;
  padding-bottom: 0px;
  width: 50%;
  display: inline-block;
}

.reward img {
  border-radius: 10px;
  width: 97%;
  margin: auto;
  display: block;
  padding-bottom: 5px;
}

@media only screen and (max-width: 600px) {
  .reward {
    width: 100%;
    display: inline-block;
  }

  .reward img {
    border-radius: 10px;
    width: 97%;
    margin: auto;
    display: block;
    padding-bottom: 5px;
  }
}

.reward a.button {
  position: absolute;
  bottom: 12.5px;
  right: 20px;
  border: none;
}

.popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 25px 25px 100px 25px;
  border-radius: 20px 20px 0 0;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
  max-height: 75%;
  overflow: scroll;
}

.popup a.button.unclaimed {
  color: #fff;
  background: #5743e1;
  border-color: #5743e1;
}

.popup h3.coins {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}

.popup p.subtitle {
  font-weight: 500;
  font-size: 0.9rem;
}

.popup .message {
  padding: 10px 15px;
  font-size: 1rem;
}

.popup h1.title {
  margin-bottom: 0.5rem;
}

.video-player {
  height: 200px !important;
}

@media only screen and (min-width: 600px) {
  .video-player {
    height: 400px !important;
  }
}

.parent {
  position: relative;
  top: 0;
  left: 0;
  height: auto !important;
}

.parent.morepadding {
  /* height: 190px; */
}

.parent img {
  border-radius: 50%;
  width: 40px !important;
  height: 40px;
  position: absolute;
  top: 0;
  border: 2px solid #fff;
}

.prize-container {
  position: relative;
  height: 100%;
  /* background: #000; */
}

.lock-wrapper {
  position: absolute;
  top: 0rem;
  right: -2rem;
  /* background: #5743e1; */
  padding: 2px 4px;
  border-radius: 10px;
}

.lock-wrapper img {
  height: 60px;
  transform: rotate(-20deg);
  animation: shakeLock 0.5s infinite;
}

@keyframes shakeLock {
  0% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}
</style>