<template>
  <div class="mainscreen" :style="color">
    <div class="back_btn" v-if="screen !== 'screen_1'">
      <i class="fa fa-arrow-left" @click="handleBack" />
      <i
        v-if="screen === 'screen_4'"
        class="fa fa-home"
        @click="handleHomeRoute"
      />
    </div>

    <template v-if="screen === 'screen_1'">
      <div class="modal_container">
        <h1>Pick your <span style="font-weight: bold">Interests</span></h1>
        <p>You can select upto 3 interests</p>

        <div class="categories">
          <div
            class="category"
            v-for="category in categories.slice(0, categories.length)"
            :key="category.id"
            @click="handleSelect(category)"
          >
            <div class="category_image">
              <img :src="category.icon" alt="..." />
            </div>
            <div class="category_name">
              <p>{{ category.name }}</p>
            </div>

            <div class="overlay" v-if="category.selected">
              <img src="../assets/icons/icon _checkmark.svg" alt="..." />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="screen === 'screen_2'">
      <div class="modal_container sc2_modal">
        <h1>
          Do you have a <span style="font-weight: bold">WitBlox Kit?</span>
        </h1>
      </div>
    </template>

    <template v-if="screen === 'screen_3'">
      <div class="modal_container">
        <h1>Which <span style="font-weight: bold">Kit</span> do you have?</h1>

        <!-- <input type="search" placeholder="Search here..." /> -->

        <div class="kits_wrapper">
          <div
            class="kit_card"
            v-for="kit in kits"
            :key="kit.id"
            @click="handleKitSelect(kit.id)"
          >
            <img :src="kit.imgs[0]" alt="..." />
            <p>{{ kit.name }}</p>
            <div
              class="selector"
              :class="{ selected: kit.id === selectedKit }"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-if="screen === 'screen_4'">
      <div class="modal_container">
        <h1>Try these <span style="font-weight: bold">Projects</span></h1>

        <div class="kits_wrapper">
          <a
            class="kit_card"
            v-for="project in projects"
            :key="project.id"
            style="justify-content: flex-start; color: black"
            @click="handleProjectClick(project.id)"
          >
            <img :src="project.image" alt="..." />
            <p>{{ project.name }}</p>
          </a>
        </div>
      </div>
    </template>

    <div class="btn_wrapper">
      <button
        v-if="screen === 'screen_1'"
        class="button next_btn"
        @click="handleScreen1Next"
      >
        Next
      </button>

      <button
        v-if="screen === 'screen_2'"
        class="button next_btn"
        style="background: #fff; border: 4px solid #5041e1; color: #5041e1"
        @click="handleNoKit"
      >
        No
      </button>
      <button
        v-if="screen === 'screen_2'"
        class="button next_btn"
        @click="handleScreen2Next"
      >
        Yes
      </button>

      <button
        v-if="screen === 'screen_3'"
        class="button next_btn"
        @click="handleScreen3Next"
      >
        Next
      </button>

      <button
        v-if="screen === 'screen_4'"
        class="button next_btn"
        @click="handleClassBook"
      >
        Book your free class
      </button>
    </div>
  </div>
</template>

<script>
import { Browser } from "@capacitor/browser";
import { db, auth } from "../main";
import { createWebengageEvent } from "../helper";

export default {
  name: "onboarding",
  data() {
    return {
      color: "",
      screen: "screen_1",
      categories: [],
      interests: [],
      kits: [],
      selectedKit: "",
      selectedKitCategories: [],
      default_kits: [
        {
          id: "FUPqjSYHqVhWcwvapbzN",
          types: ["pmm", "pmm", "pda", "pl", "pba"],
        },
        {
          id: "h9HCLShOLxzTT9yF8Tuj",
          types: ["pmm", "pmm", "pda", "pl", "pba", "pwa"],
        },
        {
          id: "pxwgWxv64aHyce9tpV41",
          types: ["pmm", "pmm", "pda", "pl", "pba", "pia"],
        },
        {
          id: "cfF4w4Un6O1N14DgPSZx",
          types: ["pmm", "pmm", "pda", "pl", "pba", "tx-rx"],
        },
      ],
      projects: [],
    };
  },
  methods: {
    addQueryToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path + "?screen=" + encodeURIComponent(params)
      );
    },
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
    },
    handleSelect(category) {
      if (this.interests.length >= 3 && !category.selected) {
        alert("You can select only 3 interests");
        return;
      }
      category.selected = !category.selected;
      if (category.selected) {
        this.interests.push(category.id);
      } else {
        this.interests = this.interests.filter(
          (interest) => interest !== category.id
        );
      }
    },
    async handleScreen1Next() {
      // if (this.interests.length === 0) {
      //   alert("Please select atleast one interest");
      //   return;
      // }

      await db.collection("users").doc(auth.currentUser.uid).update({
        newuser_interests: this.interests,
      });

      this.addQueryToLocation("screen_2");
      this.screen = "screen_2";
    },
    async handleScreen2Next() {
      await db.collection("users").doc(auth.currentUser.uid).update({
        haveWitbloxKit: true,
      });

      createWebengageEvent({
        uid: auth.currentUser.uid,
        eventName: "have_witblox_kit",
        eventData: {
          have_witblox_kit: true,
        },
      });

      this.addQueryToLocation("screen_3");
      this.screen = "screen_3";
    },
    async handleScreen3Next() {
      if (!this.selectedKit) {
        alert("Please select a kit");
        return;
      }

      await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .update({
          kits_purchased: [this.selectedKit],
        });

      await this.fetchProjects();

      this.addQueryToLocation("screen_4");
      this.screen = "screen_4";
    },
    async handleNoKit() {
      await createWebengageEvent({
        uid: auth.currentUser.uid,
        eventName: "have_witblox_kit",
        eventData: {
          have_witblox_kit: false,
        },
      });
      this.$router.push("/");
    },
    handleKitSelect(kitId) {
      this.selectedKit = kitId;
      this.selectedKitCategories = this.default_kits.find(
        (kit) => kit.id === kitId
      ).types;
    },
    handleBack() {
      if (this.screen === "screen_2") {
        this.addQueryToLocation("screen_1");
        this.screen = "screen_1";
      } else if (this.screen === "screen_3") {
        this.addQueryToLocation("screen_2");
        this.screen = "screen_2";
      } else if (this.screen === "screen_4") {
        this.addQueryToLocation("screen_3");
        this.screen = "screen_3";
      }
    },
    async fetchProjects() {
      this.projects = [];

      if (this.selectedKitCategories.length === 0) {
        this.selectedKitCategories = [
          "pmm",
          "pmm",
          "pda",
          "pl",
          "pba",
          "pwa",
          "pia",
          "tx-rx",
        ];
      }
      const categories = await db.collection("config").doc("categories").get();
      const recommendedProjects = categories.data().recommended_projects;

      recommendedProjects.forEach((project) => {
        if (this.selectedKitCategories.includes(project.category_id)) {
          this.projects.push(project);
        }
      });

      this.projects.sort((a, b) => {
        return a.level - b.level;
      });
    },
    handleProjectClick(id) {
      this.$router.push(`/project/${id}`);
    },
    handleClassBook() {
      Browser.open({
        url: "https://topmate.io/witblox/204077",
      });
    },
    handleHomeRoute() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.getColor();

    db.collection("category")
      .get()
      .then((categories) => {
        categories.forEach((category) => {
          var categoryObj = category.data();
          categoryObj.id = category.id;
          categoryObj.selected = false;
          this.categories.push(categoryObj);
        });
      });

    const kitData = this.default_kits.map((kit) => {
      return db
        .collection("shop-products")
        .doc(kit.id)
        .get()
        .then((kit) => {
          return { ...kit.data(), id: kit.id };
        });
    });

    Promise.all(kitData).then((data) => {
      this.kits = data;
    });

    if (this.screen === "screen_4") {
      this.fetchProjects();
    }
  },
  beforeMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const screen = urlParams.get("screen");
    const screens = ["screen_1", "screen_2", "screen_3", "screen_4"];
    if (!screens.includes(screen)) {
      this.screen = "screen_1";
    } else {
      this.screen = screen;
    }
  },
};
</script>

<style scoped>
.mainscreen {
  height: 80vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  font-family: Poppins, sans-serif;
}

.modal_container {
  width: 100%;
  max-height: 60vh;
  max-width: 700px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.sc2_modal {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modal_container h1 {
  font-size: 2rem;
  color: #5041e1;
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  height: 70%;
  overflow: auto;
}

.category {
  flex: 40%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #c1bfdb;
  border-radius: 10px;
  border: 1px solid #c1bfdb;
  padding: 0.5rem 0 0 0;
  position: relative;
}

.category_image {
  flex: 1;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.category_name {
  background: white;
  border-radius: 0 0 10px 10px;
  width: 100%;
  text-align: center;
}

.overlay {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.next_btn {
  background: #5041e1;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  width: 70%;
}

.btn_wrapper {
  position: absolute;
  bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  gap: 1rem;
}

.modal_container input[type="search"] {
  width: 100%;
  min-height: 3rem;
  border-radius: 10px;
  border: 1px solid #c1bfdb;
  padding: 0 1rem;
  box-sizing: border-box;
  margin: 1rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  outline: none;
}

.kits_wrapper {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-height: 60vh;
  overflow: auto;
}

.kit_card {
  width: 100%;
  padding: 0.2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  position: relative;
  border: 1px solid #c1bfdb;
  gap: 1rem;
}

.kit_card img {
  height: 70px;
  width: 70px;
  object-fit: contain;
}

.kit_card p {
  font-size: 1rem;
}

.selector {
  min-height: 1rem;
  min-width: 1rem;
  border-radius: 50%;
  border: 1px solid #5041e1;
}

.selected {
  background: #5041e1;
}

.back_btn {
  position: absolute;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  inset: 0;
  display: flex;
  justify-content: space-between;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  outline: none;
}
</style>