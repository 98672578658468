<template>
  <section class="quiz">
    <!-- <iframe class="webview" src="https://wit-quiz.vercel.app/"></iframe> -->
    <!-- <div class="logo">
      <img src="./logo/" alt="" />
    </div> -->
    <div
      class="fixedTop"
      v-if="q1.active || q2.active || q3.active || q4.active || q5.active"
    >
      <img src="@/assets/img/home/boy-thinking.gif" />
    </div>
    <div class="startb">
      <div class="txt">
        <p>Answer today's quiz and win WitCoins</p>
      </div>
      <div class="start_btn">
        <button @click="infobox = true">Start Quiz</button>
      </div>
      <div class="info_box activeInfo" v-if="infobox">
        <div class="info-title"><span>Some Rules of this Quiz</span></div>
        <div class="info-list">
          <div class="info">
            1. You will have only <span>30 seconds</span> per each question.
          </div>
          <div class="info">
            2. Once you select your answer, it can't be undone.
          </div>
          <div class="info">
            3. You can't select any option once time goes off.
          </div>
          <div class="info">
            4. You can't exit from the Quiz while you're playing.
          </div>
          <div class="info">
            5. You'll get points on the basis of your correct answers.
          </div>
        </div>
        <div class="buttons">
          <button class="quit" @click="shareDialog">
            <span class="icon">
              <i class="fas fa-share-alt"></i>
            </span>
            <span>Share</span>
          </button>
          <button class="quit" @click="infobox = false">Exit Quiz</button>
          <button class="restart" @click="start">Continue</button>
        </div>
      </div>
      <div class="quiz_box activeQuiz" v-if="q1.active">
        <header>
          <div class="title">Quiz Game</div>
          <div class="timer">
            <div v-if="q1.timer > 0" class="time_left_txt">Time Left</div>
            <div v-else class="time_left_txt">Time Off</div>
            <div class="timer_sec">{{ q1.timer }}</div>
          </div>
          <div class="time_line"></div>
        </header>
        <section>
          <div class="que_text">
            <span>{{ q1.text }}</span>
          </div>
          <div v-if="q1.image" class="que_image">
            <img :src="q1.image" alt="" />
          </div>
          <div class="option_list">
            <div
              v-for="(option, index) in q1.options"
              :key="index + option"
              class="option"
              :class="{
                correct: q1.answer == option && q1.attempted,
                disabled: q1.timer < 1,
                disabled: q1.attempted,
                incorrect: q1.answer != q1.selected && q1.selected == option,
              }"
              @click="
                q1.selected = option;
                q1.attempted = true;
              "
            >
              <span>
                {{ option }}
              </span>
              <div v-if="q1.answer == option && q1.attempted" class="icon tick">
                <i class="fas fa-check"></i>
              </div>
              <div
                v-if="q1.answer != q1.selected && q1.selected == option"
                class="icon cross"
              >
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div class="total_que">
            <span>1 of 5 Questions</span>
          </div>
          <button class="next_btn show" v-if="q1.attempted" @click="next(0)">
            Next Question
          </button>
        </footer>
      </div>
      <div class="quiz_box activeQuiz" v-if="q2.active">
        <header>
          <div class="title">Quiz Game</div>
          <div class="timer">
            <div v-if="q2.timer > 0" class="time_left_txt">Time Left</div>
            <div v-else class="time_left_txt">Time Off</div>
            <div class="timer_sec">{{ q2.timer }}</div>
          </div>
          <div class="time_line"></div>
        </header>
        <section>
          <div class="que_text">
            <span>{{ q2.text }}</span>
          </div>
          <div class="option_list">
            <div
              v-for="(option, index) in q2.options"
              :key="index + option"
              class="option"
              :class="{
                correct: q2.answer == option && q2.attempted,
                disabled: q2.timer < 1,
                disabled: q2.attempted,
                incorrect: q2.answer != q2.selected && q2.selected == option,
              }"
              @click="
                q2.selected = option;
                q2.attempted = true;
              "
            >
              <span>
                {{ option }}
              </span>
              <div v-if="q2.answer == option && q2.attempted" class="icon tick">
                <i class="fas fa-check"></i>
              </div>
              <div
                v-if="q2.answer != q2.selected && q2.selected == option"
                class="icon cross"
              >
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div class="total_que">
            <span>2 of 5 Questions</span>
          </div>
          <button class="next_btn show" v-if="q2.attempted" @click="next(1)">
            Next Question
          </button>
        </footer>
      </div>
      <div class="quiz_box activeQuiz" v-if="q3.active">
        <header>
          <div class="title">Quiz Game</div>
          <div class="timer">
            <div v-if="q3.timer > 0" class="time_left_txt">Time Left</div>
            <div v-else class="time_left_txt">Time Off</div>
            <div class="timer_sec">{{ q3.timer }}</div>
          </div>
          <div class="time_line"></div>
        </header>
        <section>
          <div class="que_text">
            <span>{{ q3.text }}</span>
          </div>
          <div class="option_list">
            <div
              v-for="(option, index) in q3.options"
              :key="index + option"
              class="option"
              :class="{
                correct: q3.answer == option && q3.attempted,
                disabled: q3.timer < 1,
                disabled: q3.attempted,
                incorrect: q3.answer != q3.selected && q3.selected == option,
              }"
              @click="
                q3.selected = option;
                q3.attempted = true;
              "
            >
              <span>
                {{ option }}
              </span>
              <div v-if="q3.answer == option && q3.attempted" class="icon tick">
                <i class="fas fa-check"></i>
              </div>
              <div
                v-if="q3.answer != q3.selected && q3.selected == option"
                class="icon cross"
              >
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div class="total_que">
            <span>3 of 5 Questions</span>
          </div>
          <button class="next_btn show" v-if="q3.attempted" @click="next(2)">
            Next Question
          </button>
        </footer>
      </div>
      <div class="quiz_box activeQuiz" v-if="q4.active">
        <header>
          <div class="title">Quiz Game</div>
          <div class="timer">
            <div v-if="q4.timer > 0" class="time_left_txt">Time Left</div>
            <div v-else class="time_left_txt">Time Off</div>
            <div class="timer_sec">{{ q4.timer }}</div>
          </div>
          <div class="time_line"></div>
        </header>
        <section>
          <div class="que_text">
            <span>{{ q4.text }}</span>
          </div>
          <div class="option_list">
            <div
              v-for="(option, index) in q4.options"
              :key="index + option"
              class="option"
              :class="{
                correct: q4.answer == option && q4.attempted,
                disabled: q4.timer < 1,
                disabled: q4.attempted,
                incorrect: q4.answer != q4.selected && q4.selected == option,
              }"
              @click="
                q4.selected = option;
                q4.attempted = true;
              "
            >
              <span>
                {{ option }}
              </span>
              <div v-if="q4.answer == option && q4.attempted" class="icon tick">
                <i class="fas fa-check"></i>
              </div>
              <div
                v-if="q4.answer != q4.selected && q4.selected == option"
                class="icon cross"
              >
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div class="total_que">
            <span>4 of 5 Questions</span>
          </div>
          <button class="next_btn show" v-if="q4.attempted" @click="next(3)">
            Next Question
          </button>
        </footer>
      </div>
      <div class="quiz_box activeQuiz" v-if="q5.active">
        <header>
          <div class="title">Quiz Game</div>
          <div class="timer">
            <div v-if="q5.timer > 0" class="time_left_txt">Time Left</div>
            <div v-else class="time_left_txt">Time Off</div>
            <div class="timer_sec">{{ q5.timer }}</div>
          </div>
          <div class="time_line"></div>
        </header>
        <section>
          <div class="que_text">
            <span>{{ q5.text }}</span>
          </div>
          <div class="option_list">
            <div
              v-for="(option, index) in q5.options"
              :key="index + option"
              class="option"
              :class="{
                correct: q5.answer == option && q5.attempted,
                disabled: q5.timer < 1,
                disabled: q5.attempted,
                incorrect: q5.answer != q5.selected && q5.selected == option,
              }"
              @click="
                q5.selected = option;
                q5.attempted = true;
              "
            >
              <span>
                {{ option }}
              </span>
              <div v-if="q5.answer == option && q5.attempted" class="icon tick">
                <i class="fas fa-check"></i>
              </div>
              <div
                v-if="q5.answer != q5.selected && q5.selected == option"
                class="icon cross"
              >
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div class="total_que">
            <span>5 of 5 Questions</span>
          </div>
          <button class="next_btn show" v-if="q5.attempted" @click="next(4)">
            Next Question
          </button>
        </footer>
      </div>
      <div v-if="resultbox" class="result_box activeResult">
        <div class="icon">
          <i class="clogo">
            <img src="@/assets/img/home/cong.png" alt="" />
          </i>
        </div>
        <div class="complete_text">Congratulations!</div>
        <p class="score_text has-text-centered">
          You got <b>{{ score }} out of 5</b>.
          <br />
          You have won
          <span class="witcoins"
            ><b>{{ score * 10 }}</b> WitCoins</span
          >.
          <br />
          We have credited them to your wallet.
        </p>
      </div>
    </div>
  </section>
</template>
<script>
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { Dialog } from "@capacitor/dialog";
// import { functions } from "@/main.js";
// import { Webengage } from "@ionic-native/webengage";
import { Share } from "@capacitor/share";
import { createWebengageEvent } from '../helper';
export default {
  name: "quiz",
  data() {
    return {
      infobox: false,
      questions: ["q1", "q2", "q3", "q4", "q5"],
      q1: {
        active: false,
        text: "Entomology is the science that studies?",
        answer: "Insects",
        selected: "",
        options: [
          "Insects",
          "Behavior of human beings",
          "The origin and history of technical and scientific terms",
          "The formation of rocks",
        ],
        attempted: false,
        timer: 0,
      },
      q2: {
        active: false,
        text: "Galileo was an Italian astronomer who",
        answer: "All of the above",
        selected: "",
        options: [
          "developed the telescope",
          "discovered four satellites of Jupiter",
          "discovered that the movement of pendulum produces a regular time measurement",
          "All of the above",
        ],
        attempted: false,
        timer: 0,
      },
      q3: {
        active: false,
        text: "Friction can be reduced by changing from",
        answer: "sliding to rolling",
        selected: "",
        options: [
          "potential energy to kinetic energyr",
          "dynamic to static",
          "sliding to rolling",
          "rolling to sliding",
        ],
        attempted: false,
        timer: 0,
      },
      q4: {
        active: false,
        text: "Who invented the BALLPOINT PEN?",
        answer: "Biro Brothers",
        selected: "",
        options: [
          "Biro Brothers",
          "Waterman Brothers",
          "Ball Brothers",
          "Wright Brothers",
        ],
        attempted: false,
        timer: 0,
      },
      q5: {
        active: false,
        text: "Euclid was a",
        answer: "All of the above",
        selected: "",
        options: [
          "Greek mathematician",
          "Contributor to the use of deductive principles of logic as the basis of geometry",
          "Propounded the geometrical theorems",
          "All of the above",
        ],
        attempted: false,
        timer: 0,
      },
      resultbox: false,
      score: 0,
      quizName: "",
    };
  },
  methods: {
    format(value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
      }
      return newValue;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    start() {
      console.log("started");
      this.q1.active = true;
      this.q1.timer = 30;
      var x = setInterval(() => {
        this.q1.timer = this.q1.timer - 1;
        if (this.q1.timer == 0) {
          clearInterval(x);
          setTimeout(() => {
            if (this.q1.timer == 0) this.q1.attempted = true;
          }, 1000);
        }
      }, 1000);
    },
    next(num) {
      if (num == 4) {
        this.q5.active = false;
        this.resultbox = true;
        this.infobox = false;
        this.questions.forEach((question) => {
          //   console.log(this[question].selected, this[question].answer);
          if (this[question].selected == this[question].answer)
            this.score = this.score + 1;
        });
        this.creditCoins();
      } else {
        this[this.questions[num]].active = false;
        this[this.questions[num + 1]].active = true;
        this[this.questions[num + 1]].timer = 30;
        var x = setInterval(() => {
          this[this.questions[num + 1]].timer =
            this[this.questions[num + 1]].timer - 1;
          if (this[this.questions[num + 1]].timer == 0) {
            clearInterval(x);
            setTimeout(() => {
              if (this[this.questions[num + 1]].timer == 0)
                this[this.questions[num + 1]].attempted = true;
            }, 1000);
          }
        }, 1000);
      }
    },
    creditCoins() {
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("coins")
        .add({
          created: new Date().getTime(),
          from: "quiz",
          value: this.score * 10,
        })
        .then(() => {
          db.collection("quizzes")
            .doc(this.$route.params.id)
            .collection("logs")
            .doc(auth.currentUser.uid)
            .set(
              {
                updated: new Date().getTime(),
                value: this.score * 10,
                completed: true,
              },
              { merge: true }
            )
            .then(() => {
              db.collection("users").doc(auth.currentUser.uid).collection("notifications").add({
                created: new Date().getTime(),
                action: "",
                text: `You have earned ${this.score * 10} coins for answering the quiz`,
                img: "https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/profile%2Fd4ae4c4ced1234134c5d?alt=media&token=c1f8ce0d-cb76-48eb-8898-a1bc6c070864",
                link: "/transactions",
                user: auth.currentUser.uid,
              });
              createWebengageEvent({
                uid: auth.currentUser.uid,
                eventName: "Quiz Answered",
                eventData: {
                  witcoins: this.score * 10,
                  score: this.score,
                  name: this.quizName,
                }
              })
            });
          setTimeout(() => {
            this.$router.back();
          }, 6000);
        });
    },
    async alreadyFilled() {
      let confirmRet = await Dialog.confirm({
        title: "Info",
        message: "You have already taken this quiz.",
      });
      console.log(confirmRet.value);
      this.$router.back();
    },
    async shareDialog() {
      await Share.share({
        title: "WitBlox Quiz",
        text: "Hey, I am challenging you to take this fun quiz from WitBlox App! Can you beat my score to win amazing prizes?",
        url: "https://app.witblox.com/quiz/" + this.$route.params.id,
        dialogTitle: "Share with friends",
      });
    },
  },
  beforeMount() {
    db.collection("quizzes")
      .doc(this.$route.params.id)
      .get()
      .then((doc) => {
        console.log(doc.data());
        this.q1.answer = doc.data().q1.answer;
        this.q1.options = doc.data().q1.options;
        this.q1.text = doc.data().q1.text;
        this.q1.image = doc.data().q1.image;
        //
        this.q2.answer = doc.data().q2.answer;
        this.q2.options = doc.data().q2.options;
        this.q2.text = doc.data().q2.text;
        this.q2.image = doc.data().q2.image;
        //
        this.q3.answer = doc.data().q3.answer;
        this.q3.options = doc.data().q3.options;
        this.q3.text = doc.data().q3.text;
        this.q3.image = doc.data().q3.image;
        //
        this.q4.answer = doc.data().q4.answer;
        this.q4.options = doc.data().q4.options;
        this.q4.text = doc.data().q4.text;
        this.q4.image = doc.data().q4.image;
        //
        this.q5.answer = doc.data().q5.answer;
        this.q5.options = doc.data().q5.options;
        this.q5.text = doc.data().q5.text;
        this.q5.image = doc.data().q5.image;
        //
        this.quizName = doc.data().name;
      });
    db.collection("quizzes")
      .doc(this.$route.params.id)
      .collection("logs")
      .doc(auth.currentUser.uid)
      .get()
      .then((log) => {
        if (log.exists) {
          if (log.data().completed) {
            //send back
            this.alreadyFilled();
          } else {
            db.collection("quizzes")
              .doc(this.$route.params.id)
              .collection("logs")
              .doc(auth.currentUser.uid)
              .set(
                {
                  added: new Date().getTime(),
                  value: 0,
                  completed: false,
                },
                { merge: true }
              );
          }
        }
      });
  },
  mounted() {},
};
</script>
<style scoped>
.webview {
  display: block !important;
  height: 100vh;
  width: 390px;
}

.quiz {
  /* padding: 0px; */
  height: 100%;
  background: #3e048a;
}
/* 
body {
  background: #3e048a;
}

::selection {
  color: #fff;
  background: #3e048a;
} */

.start_btn,
.info_box,
.quiz_box,
.result_box,
.startb,
.txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 
                0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.txt p {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.startb {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 250px;
  width: 250px;
}

.info_box.activeInfo,
.quiz_box.activeQuiz,
.result_box.activeResult {
  opacity: 1;
  z-index: 5;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
}

.start_btn button {
  font-size: 25px;
  font-weight: 500;
  color: #007bff;
  padding: 15px 30px;
  outline: none;
  border: none;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  /* display: flex; */
  width: 200px;
  margin-bottom: 0rem;
  text-align: center;
}

.info_box {
  width: 540px;
  background: #fff;
  border-radius: 5px;
  transform: translate(-50%, -50%) scale(0.9);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}

.info_box .info-title {
  height: 60px;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  display: flex;
  align-items: center;
  padding: 0 30px;
  border-radius: 5px 5px 0 0;
  font-size: 20px;
  font-weight: 600;
}

.info_box .info-list {
  padding: 15px 30px;
}

.info_box .info-list .info {
  margin: 5px 0;
  font-size: 17px;
}

.info_box .info-list .info span {
  font-weight: 600;
  color: #007bff;
}
.info_box .buttons {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
  border-top: 1px solid lightgrey;
}

.info_box .buttons button {
  margin: 0 5px;
  height: 40px;
  width: 100px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 5px;
  border: 1px solid #007bff;
  transition: all 0.3s ease;
}

.quiz_box {
  width: 550px;
  background: #fff;
  border-radius: 5px;
  transform: translate(-50%, -50%) scale(0.9);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}

.quiz_box header {
  position: relative;
  z-index: 2;
  height: 70px;
  padding: 0 30px;
  background: #fff;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.1);
}

.quiz_box header .title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
}

.quiz_box header .timer {
  color: #004085;
  background: #cce5ff;
  border: 1px solid #b8daff;
  height: 45px;
  padding: 0 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 145px;
}

.quiz_box header .timer .time_left_txt {
  font-weight: 400;
  font-size: 17px;
  user-select: none;
}

.quiz_box header .timer .timer_sec {
  font-size: 18px;
  font-weight: 500;
  height: 30px;
  width: 45px;
  color: #fff;
  border-radius: 5px;
  line-height: 30px;
  text-align: center;
  background: #343a40;
  border: 1px solid #343a40;
  user-select: none;
}

.quiz_box header .time_line {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 3px;
  background: #007bff;
}

section {
  padding: 25px 30px 20px 30px;
  background: #fff;
}

section .que_text {
  font-size: 25px;
  font-weight: 600;
}

section .option_list {
  padding: 20px 0px;
  display: block;
}

section .option_list .option {
  background: aliceblue;
  border: 1px solid #84c5fe;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 17px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section .option_list .option:last-child {
  margin-bottom: 0px;
}

section .option_list .option:hover {
  color: #004085;
  background: #cce5ff;
  border: 1px solid #b8daff;
}

section .option_list .option.correct {
  color: #155724;
  background: #d4edda;
  border: 1px solid #c3e6cb;
}

section .option_list .option.incorrect {
  color: #721c24;
  background: #f8d7da;
  border: 1px solid #f5c6cb;
}

section .option_list .option.disabled {
  pointer-events: none;
}

section .option_list .option .icon {
  height: 26px;
  width: 26px;
  border: 2px solid transparent;
  border-radius: 50%;
  text-align: center;
  font-size: 13px;
  pointer-events: none;
  transition: all 0.3s ease;
  line-height: 24px;
}
.option_list .option .icon.tick {
  color: #23903c;
  border-color: #23903c;
  background: #d4edda;
}

.option_list .option .icon.cross {
  color: #a42834;
  background: #f8d7da;
  border-color: #a42834;
}

footer {
  height: 60px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid lightgrey;
}

footer .total_que span {
  display: flex;
  user-select: none;
}

footer .total_que span p {
  font-weight: 500;
  padding: 0 5px;
}

footer .total_que span p:first-child {
  padding-left: 0px;
}

footer button {
  height: 40px;
  padding: 0 13px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 5px;
  background: #007bff;
  border: 1px solid #007bff;
  line-height: 10px;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.95);
  transition: all 0.3s ease;
}

footer button:hover {
  background: #0263ca;
}

footer button.show {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
}

.result_box {
  background: #fff;
  border-radius: 5px;
  display: flex;
  padding: 65px 30px !important;
  width: 450px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: translate(-50%, -50%) scale(0.9);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  height: auto;
}
.txtt p {
  margin-right: -1.5rem;
  font-weight: 500;
  text-align: center;
}

.result_box .icon {
  font-size: 100px;
  color: #007bff;
  margin-bottom: 10px;
}

.result_box .complete_text {
  font-size: 25px;
  font-weight: 700;
  padding-bottom: 20px;
}

.result_box .score_text {
  /* display: flex;
  margin: 10px 0; */
  font-size: 20px;
  font-weight: 500;
}

.result_box .score_text span p,
.score_text2 {
  padding: 0 4px;
  font-weight: 600;
}
.result_box .score_text2 {
  text-align: center;
}
.result_box .buttons {
  display: flex;
  margin: 20px 0;
}

.result_box .buttons button {
  margin: 0 10px;
  height: 45px;
  padding: 0 20px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 5px;
  border: 1px solid #007bff;
  transition: all 0.3s ease;
}

.buttons button.restart {
  color: #fff;
  background: #007bff;
}

.buttons button.restart:hover {
  background: #0263ca;
}

.buttons button.quit {
  color: #007bff;
  background: #fff;
}

.buttons button.quit:hover {
  color: #fff;
  background: #007bff;
}
.startb .txt {
  font-size: 16px;
  color: rgb(196, 192, 192);
  font-family: "Poppins", sans-serif;
  display: contents;
  text-align: center;
  width: 380px;
  margin-top: -4rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.startb img {
  width: 80px;
  height: 80px;
  position: absolute;
  top: -16%;
  left: 34%;
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}
.result_box .icon .clogo img {
  height: 120px;
  width: 120px;
  margin-bottom: -1rem;
}

/* for costomize For mobile max-width 570  */

@media /*(min-width: 320px) and*/ (max-width: 570px) {
  .info_box {
    width: 350px;
    height: 440px;
    top: 55%;
  }
  .quiz_box {
    width: 350px;
  }
  .result_box {
    width: 350px;
    padding: 27px 1px;
  }
}
.witcoins {
  /* color: #FFD700; */
}
.fixedTop {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.fixedTop img {
  width: 90px;
  border-radius: 50%;
}
.que_image img {
  position: inherit !important;
  width: 100%;
  margin: 4px 0;
}
</style>