<template>
  <main v-bind:class="{ 'hide-overflow': overflow, ios: ios }">
    <!-- <keep-alive> -->
    <router-view :class="{ blur: blur, blur: rewardBoxState, blur: popupBannerActive }"></router-view>
    <!-- </keep-alive> -->
    <div v-if="navbar" class="bottom-nav-container">
      <div class="bottom-nav columns is-mobile">
        <a class="column active">
          <img v-if="screen === 'home'" src="~@/assets/icons/home.png" style="padding: 10px 12px" />
          <img style="padding: 10px 12px" v-else src="~@/assets/icons/home-outlined.png" @click="router('')" />
        </a>
        <a @click="router('social')" class="column notifholder">
          <img v-if="screen === 'social'" src="~@/assets/icons/video.png" style="padding: 10px 12px" />
          <img v-else src="~@/assets/icons/video-outlined.png" style="padding: 10px 12px" />
          <span v-if="socialcount" class="notifcount">{{ socialcount }}</span>
        </a>
        <a @click="action()" class="column">
          <img v-if="screen === 'upload'" :src="middleBtn" style="padding: 10px 12px" />
          <img v-else :src="middleBtnAlt" style="padding: 10px 12px" />
        </a>
        <a @click="router('activity')" class="column notifholder">
          <img v-if="screen === 'activity'" src="~@/assets/icons/bell.png" style="padding: 10px 12px" />
          <img v-else src="~@/assets/icons/bell-outlined.png" style="padding: 10px 12px" />
          <span v-if="newnotif" class="notifcount">{{ notifcount }}</span>
        </a>
        <a @click="router('profile')" class="column">
          <img v-if="screen === 'profile'" src="~@/assets/icons/user.png" style="padding: 10px 12px" />
          <img v-else src="~@/assets/icons/user-outlined.png" style="padding: 10px 12px" />
        </a>
      </div>
    </div>
    <div class="nointernet" v-if="nointernet">
      <div class="card">
        <div class="card-content">
          <img src="./assets/img/computer.png" alt="" />
          <p class="notification">
            <b>WitBlox</b> requires an internet connection to work.
          </p>
        </div>
      </div>
    </div>
    <div class="updateAvailable" v-if="updateAvailable">
      <div class="card">
        <div class="card-content">
          <p class="notification">
            New features now available in the latest version of <b>WitBlox</b>.
          </p>
          <div class="buttons has-addons">
            <a class="button" target="blank" :href="ua_link">Update</a>
            <a class="button" @click="
              updateAvailable = false;
            blur = false;
            ">Close</a>
          </div>
        </div>
      </div>
    </div>
    <div class="rewardpopup reward" v-if="rewardBoxState">
      <i class="far fa-times-circle" style="font-size: 1.6rem" @click="closeReward()"></i>
      <div class="card">
        <div class="card-content has-text-centered">
          <h1 class="title">Yay!</h1>
          <img :src="rewardBox.img" alt="" />
          <h3 class="subtitle">
            {{ rewardBox.rewardObj.text }}
          </h3>
        </div>
        <div class="card-footer">
          <a v-if="rewardBox.rewardObj.claimed" :class="{ 'is-loading': btnLoading }" class="button is-fullwidth disabled"
            disabled>CLAIMED</a>
          <a v-else :class="{ 'is-loading': btnLoading }" class="button is-fullwidth" @click="claim(rewardBox)">CLAIM</a>
        </div>
      </div>
    </div>
    <div class="popupBanner" v-if="popupBannerActive" @click="popupBannerActive = false">
      <img :src="popupBanner.image" @click="popupAction()" />
    </div>
  </main>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { db } from "@/main.js";
import { functions } from "@/main.js";
import { firestore } from "@/main.js";
// const { PushNotifications } = Plugins;
// const { Browser } = Plugins;
// const { App } = Plugins;
// const { Device } = Plugins;
// const { Network } = Plugins;
import { Network } from "@capacitor/network";
import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
// import { Capacitor } from "@capacitor/core";
// import { StatusBar } from "@capacitor/status-bar";
// import { CapacitorFirebaseDynamicLinks } from "@clipboardhealth/capacitor-firebase-dynamic-links";
import { PushNotifications } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
// const { Modals } = Plugins;
// const fcm = new FCM();
import { mapGetters } from "vuex";
// import {
//   Webengage,
//   WebengageUser,
//   WebengagePush,
//   WebengageNotification,
// } from "@ionic-native/webengage";
// import { AndroidFcmPlugin } from "cordova-plugin-android-fcm@1.0.1";
import { Browser } from "@capacitor/browser";
import { createWebengageUser } from "./helper";
export default {
  name: "App",
  data() {
    return {
      ios: false,
      navbar: true,
      screen: "home",
      middleBtn: require("@/assets/icons/shopping-cart.png"),
      middleBtnAlt: require("@/assets/icons/shopping-cart-outlined.png"),
      overflow: false,
      newnotif: false,
      notifcount: 0,
      socialcount: 0,
      ua_link: "",
      updateAvailable: false,
      nointernet: false,
      blur: false,
      reward: false,
      btnLoading: false,
      popupBanner: {},
      popupBannerActive: false,
    };
  },
  methods: {
    async popupAction() {
      db.collection("banners")
        .doc(this.popupBanner.id)
        .collection("views")
        .doc(auth.currentUser.uid)
        .update({
          updated: new Date().getTime(),
          clicked: true,
        });
      if (this.popupBanner.external) {
        //open route in browser
        await Browser.open({ url: this.popupBanner.route });
      } else this.$router.push(this.popupBanner.route);
    },
    async getStatus() {
      let status = await Network.getStatus();
      this.nointernet = !status.connected;
      this.blur = !status.connected;
    },
    async pushInit(user) {
      // android only method
      PushNotifications.register().then(() =>
        console.log("registered for push")
      );
      PushNotifications.addListener("registration", (token) => {
        // alert("Push registration success, token: " + token.value);
        console.log("Push registration success, token: " + token.value);

        createWebengageUser({
          uid: auth.currentUser.uid,
          firstName: user.fname,
          lastName: user.lname,
          email: user.email,
          phone: user.phone,
          attrs: {
            deviceToken: token.value,
          },
        });

        db.collection("users").doc(auth.currentUser.uid).update({
          devicetoken: token.value,
        });
        // window.androidfcm.updateToken();
        db.collection("users")
          .doc(auth.currentUser.uid)
          .collection("devices")
          .where("token", "==", token.value)
          .get()
          .then((snap) => {
            this.getDeviceInfo().then((device) => {
              if (snap.empty) {
                db.collection("users")
                  .doc(auth.currentUser.uid)
                  .collection("devices")
                  .add({
                    created: new Date().getTime(),
                    token: token.value,
                    isVirtual: device.isVirtual,
                    manufacturer: device.manufacturer,
                    model: device.model,
                    operatingSystem: device.operatingSystem,
                    osVersion: device.osVersion,
                    platform: device.platform,
                  })
                  .then((doc) => {
                    console.log("new device added", doc.id);
                  })
                  .catch((err) => {
                    console.error(err.message);
                  });
              } else {
                console.log("device already exists");
              }
            });
          });
      });
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification) => {
          console.log(notification);
          console.log(
            "notification recieved",
            JSON.stringify({ notification })
          );
        }
      );
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification) => {
          if (notification.notification.data.route) {
            this.$router.push(notification.notification.data.route);
          }
          console.log(
            "notification action performed",
            JSON.stringify({ notification })
          );
        }
      );
    },
    router(name) {
      this.$router.push("/" + name);
      if (name == "") name = "home";
      this.screen = name;
    },
    update() {
      this.blur = false;
      /*
      setTimeout(() => {
        this.blur = false;
      }, 1200);
      */
      //
      var current = this.$router.history.current.name;
      this.screen = current;
      //
      if (current == "login" || current == "onboarding") {
        window.screen.orientation.lock("portrait");
        this.navbar = false;
      } else if (
        current == "project" ||
        current == "challenge" ||
        current == "event" ||
        current == "trailer" ||
        current == "theme" ||
        current == "festtheme" ||
        current == "competition" ||
        current == "chat" ||
        current == "chadgpt" ||
        current == "classdetails"
      ) {
        this.navbar = false;
        window.screen.orientation.unlock();
        this.overflow = false;
      } else if (current == "social") {
        window.screen.orientation.lock("portrait");
        this.socialcount = 0;
        this.navbar = true;
        this.middleBtn = require("@/assets/icons/plus.png");
        this.middleBtnAlt = require("@/assets/icons/plus-outlined.png");
        this.overflow = true;
      } else if (current == "upload") {
        window.screen.orientation.lock("portrait");
        this.navbar = true;
        this.middleBtn = require("@/assets/icons/plus.png");
        this.middleBtnAlt = require("@/assets/icons/plus-outlined.png");
        this.overflow = false;
      } else if (current == "intro") {
        window.screen.orientation.lock("portrait");
        this.navbar = false;
      } else if (current == "shophome") {
        window.screen.orientation.lock("portrait");
        this.navbar = true;
        this.middleBtn = require("@/assets/icons/shopping-cart.png");
        this.middleBtnAlt = require("@/assets/icons/shopping-cart.png");
        this.overflow = false;
      } else {
        window.screen.orientation.lock("portrait");
        this.navbar = true;
        this.middleBtn = require("@/assets/icons/shopping-cart.png");
        this.middleBtnAlt = require("@/assets/icons/shopping-cart-outlined.png");
        this.overflow = false;
      }
    },
    action() {
      var current = this.$router.history.current.name;
      if (current == "social" || current == "upload")
        this.router("upload/generic/newpost/20");
      else this.router("shop");
    },
    async getDeviceInfo() {
      const device = await Device.getInfo();
      return device;
    },
    // webEngageInit() {
    //   if (auth.currentUser.uid) {
    //     if (Capacitor.getPlatform() != "ios") {
    //       // Webengage.engage();
    //       StatusBar.show();
    //       console.log("Device Info:", Device.getInfo());
    //     }
    //     // console.log(Capacitor.getPlatform());
    //     Webengage.engage();
    //     WebengageUser.login(auth.currentUser.uid);
    //     window.androidfcm.updateToken();
    //     WebengagePush.onClick((deeplink, customData) => {
    //       console.log("push clicked", deeplink, customData);
    //       this.$router.push(deeplink);
    //     });
    //     WebengageNotification.onClick((inAppData, actionId) => {
    //       console.log("in-app clicked", inAppData, actionId);
    //       var deeplink = inAppData.actions.actionLink;
    //       this.$router.push(deeplink);
    //     });
    //     db.collection("users")
    //       .doc(auth.currentUser.uid)
    //       .get()
    //       .then((profile) => {
    //         var userProfile = profile.data();
    //         WebengageUser.setAttribute(
    //           "created",
    //           new Date(userProfile.created)
    //         );
    //         const date = new Date(userProfile.dob);
    //         const dob =
    //           date.getFullYear() +
    //           "-" +
    //           (date.getMonth() + 1) +
    //           "-" +
    //           date.getDate();
    //         WebengageUser.setAttribute("we_birth_date", dob);
    //         WebengageUser.setAttribute("we_email", userProfile.email);
    //         WebengageUser.setAttribute("we_first_name", userProfile.fname);
    //         WebengageUser.setAttribute("we_last_name", userProfile.lname);
    //         WebengageUser.setAttribute("we_phone", userProfile.phone);
    //         WebengageUser.setAttribute("ranking", userProfile.ranking);
    //         WebengageUser.setAttribute("we_company", userProfile.school);
    //         WebengageUser.setAttribute("totalcoins", userProfile.totalcoins);
    //         WebengageUser.setAttribute("verified", userProfile.verified);
    //       });
    //   } else WebengageUser.logout();
    // },
    openReward() {
      this.reward = true;
      this.blur = true;
    },
    closeReward() {
      var object = { open: false };
      this.$store.commit("openRewardBox", object);
    },
    claim(obj) {
      //   console.log(obj);
      this.btnLoading = true;
      var claimReward = functions.httpsCallable("claimReward");
      claimReward({ object: obj }).then((response) => {
        console.log(obj, response);
        this.btnLoading = false;
        obj.rewardObj.claimed = true;
        setTimeout(() => {
          this.closeReward();
        }, 2000);
      });
    },
  },
  beforeMount() {
    //back button listener
    App.addListener("backButton", (data) => {
      console.log("backButtonListener: ", data);
      if (this.screen == "home") App.exitApp();
      else this.$router.back();
    });
    //deeplink listener
    App.addListener("appUrlOpen", (data) => {
      const slug = data.url.split(".com").pop();
      console.log("APP URL OPEN: ", slug);
      auth.onAuthStateChanged((user) => {
        if (user) this.$router.push(slug);
        else {
          if (slug) this.$store.state.homeopened = true;
        }
      });
    });
    //dynamic links listener
    // CapacitorFirebaseDynamicLinks.addListener("deepLinkOpen", (data) => {
    //   console.log("Dynamic:", JSON.stringify(data));
    //   this.$router.push(data.url);
    // });
    //screen orientation
    window.screen.orientation.lock("portrait");
    //network change
    Network.addListener("networkStatusChange", (status) => {
      this.nointernet = !status.connected;
      this.blur = !status.connected;
    });
    this.getStatus();
    //update status
    App.getInfo().then((app) => {
      Device.getInfo().then((device) => {
        if (device.platform == "ios") this.ios = true;
        console.log("Device: " + JSON.stringify(device));
        console.log("App: " + JSON.stringify(app));
        db.collection("config")
          .doc("homepage")
          .get()
          .then((config) => {
            if (device.platform == "android") {
              if (Number(app.build) < config.data().androidAppVersion) {
                setTimeout(() => {
                  this.updateAvailable = true;
                  this.blur = true;
                  this.ua_link = config.data().androidAppUpdateLink;
                }, 10000);
              }
            }
          })
          .catch((err) => {
            console.log("App Update Popup error: " + err);
          });
      });
    });
    this.update();
    //fetch data
    auth.onAuthStateChanged((user) => {
      if (!user) return;
      //update last used
      db.collection("users")
        .doc(user.uid)
        .update({
          location: {
            updated: new Date().getTime(),
          },
        });
      //notif handler
      db.collection("users")
        .doc(user.uid)
        .collection("notifications")
        .where("seen", "==", false)
        .onSnapshot((ref) => {
          this.newnotif = !ref.empty;
          if (ref.size < 100) this.notifcount = ref.size;
          else this.notifcount = 99;
        });
      //fetch not-viewed social post count
      db.collection("social")
        .orderBy("created", "desc")
        .limit(60)
        .get()
        .then((snap) => {
          this.socialcount = 0;
          snap.forEach((doc) => {
            db.collection("social")
              .doc(doc.id)
              .collection("views")
              .where("uid", "==", user.uid)
              .get()
              .then((post) => {
                if (!post.empty) this.socialcount = this.socialcount + 1;
              });
          });
        });
    });
  },
  mounted() {
    if (auth?.currentUser?.uid) {
      db.collection("banners")
        .orderBy("created", "desc")
        .limit(5)
        .get()
        .then((banners) => {
          for (let index = 0; index < banners.docs.length; index++) {
            const banner = banners.docs[index];
            if (banner.data().active) {
              if (banner.data().end > new Date().getTime()) {
                db.collection("banners")
                  .doc(banner.id)
                  .collection("views")
                  .doc(auth.currentUser.uid)
                  .get()
                  .then((view) => {
                    if (view.exists) console.log(banner.id);
                    else {
                      this.popupBanner = banner.data();
                      this.popupBanner.id = banner.id;
                      db.collection("banners")
                        .doc(banner.id)
                        .update({
                          views: firestore.FieldValue.increment(1),
                        })
                        .then(() => {
                          db.collection("banners")
                            .doc(banner.id)
                            .collection("views")
                            .doc(auth.currentUser.uid)
                            .set({
                              updated: new Date().getTime(),
                              clicked: false,
                            })
                            .then(() => {
                              setTimeout(() => {
                                this.popupBannerActive = true;
                                index = banners.docs.length;
                              }, 10000);
                            });
                        });
                    }
                  });
              }
            }
          }
        });
    }
    // this.webEngageInit();
    auth?.onAuthStateChanged((user) => {
      if (!user) return;

      this.pushInit(user);
      FCM.subscribeTo({ topic: "all" }).then((res) => {
        console.log("Subscribed to all", JSON.stringify(res));
      });
    });
    this.blur = true;
    setTimeout(() => {
      this.blur = false;
    }, 400);
    // if (this.$store.state.deeplink) {
    //   console.log(
    //     "Redirecting to the following : ",
    //     this.$store.state.deeplink
    //   );
    //   this.router(this.$store.state.deeplink);
    // } else console.log("no deeplinks");
  },
  watch: {
    $route() {
      this.update();
    },
  },
  computed: {
    ...mapGetters({
      rewardBox: "rewardBox",
      rewardBoxState: "rewardBoxState",
    }),
  },
};
</script>
<style>
section {
  transition: filter 1s;
}

.nointernet,
.updateAvailable,
.rewardpopup {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 100px 40px;
  background: rgb(255, 255, 255, 0.5);
}

.nointernet .card,
.updateAvailable .card,
.rewardpopup .card {
  border-radius: 25px;
  -webkit-box-shadow: 0 24px 38px 3px rgb(0 0 0 / 14%),
    0 9px 46px 8px rgb(0 0 0 / 12%), 0 11px 15px -7px rgb(0 0 0 / 20%);
  box-shadow: 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%),
    0 11px 15px -7px rgb(0 0 0 / 20%);
  max-width: 300px;
  margin: auto;
}

.updateAvailable .button {
  width: 50%;
  background: #000000;
  color: #ffff;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border-radius: 10px;
  padding: 25px 0;
}

.blur {
  filter: blur(4px);
  overflow: hidden;
}

.padding {
  padding: 13px 13px 0px 13px !important;
}

.notifholder {
  position: relative;
}

.notifcount {
  background: #f44721;
  color: #ffff;
  font-family: "Poppins", sans-serif;
  position: absolute;
  font-weight: 600;
  font-size: 0.88rem;
  top: 4px;
  right: 8px;
  padding: 2px 10px;
  border-radius: 25px;
}

/* ios-only-css */
.ios .bottom-nav {
  /* padding-bottom: 30px !important; */
  padding-bottom: env(safe-area-inset-bottom);
}

.ios .homeprofile {
  /* padding: 60px 20px 0 20px !important; */
}

/* */
</style>
<style scoped>
.rewards a.button {
  margin-left: 3px;
  margin-bottom: 3px;
  border: 2px solid;
  color: #5743e1;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.reward .card {
  border-radius: 15px;
}

.reward img {
  width: 120px;
  height: 120px;
  padding: 5px;
  background: #5843e149;
  border-radius: 50%;
}

.reward h1.title {
  margin-bottom: 1.75rem;
}

.reward h3.subtitle {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 5px;
}

.reward .card-footer {
  background: #5843e16b;
  padding: 6px 7px 4px 5px;
}

.reward .card-footer {
  border-radius: 0 0 15px 15px;
}

.reward .card-footer a.button {
  border-radius: 4px 4px 15px 15px;
  background: #5743e1;
  border-color: #5743e1;
  color: #fff;
  font-weight: 800;
  font-size: 1.2rem;
}

.rewardpopup {
  text-align: right;
}

.rewardpopup i {
  font-size: 2.5rem !important;
  margin-bottom: 25px;
  color: #fff;
  background: #000;
  border-radius: 50%;
}

/* desktop-styling */
</style>