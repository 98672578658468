<template>
  <section class="videosection">
    <a @click="$router.back()" class="closeBtns">
      <span class="icon">
        <i class="far fa-times-circle"></i>
      </span>
    </a>
    <div class="video-container" v-if="screen === 0">
      <video-player v-if="vid1.loaded" class="vjs-custom-skin" ref="videoPlayer" :options="vid1" :playsinline="true"
        @ended="onPlayerEnded($event)"></video-player>
      <div class="videoBtns">
        <a class="button is-small" id="skipBtn" @click="screen = 1">
          <span>Build It</span>
          <span class="icon">
            <i class="fas fa-chevron-right"></i>
          </span>
        </a>
      </div>
      <div v-if="popupOpen" class="cartoon2 is-hidden-desktop">
        <div class="columns">
          <div class="column">
            <div class="scard">
              <h1 class="title">
                Would you like to complete
                <b>"{{ name }}"</b>?
              </h1>
              <div class="columns">
                <div class="column has-text-centered">
                  <img src="@/assets/img/coin.svg" alt />
                  <h3 class="subtitle">
                    Build &amp; Win
                    {{ coins }} coins.
                  </h3>
                </div>
              </div>
              <button class="btn btn-theme" @click="screen = 1">Yes</button>
              <a class="btn btn-theme" @click="router('/')">No</a>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <img src="@/assets/img/cartoon.png" class="cartoonImg" />
          </div>
        </div>
      </div>
      <div class="cartoon2 is-hidden-touch">
        <div class="columns">
          <div class="column">
            <div class="scard">
              <h1 class="title">
                Would you like to complete
                <b>"{{ name }}"</b>?
              </h1>
              <div class="columns">
                <div class="column has-text-centered">
                  <img src="@/assets/img/coin.svg" alt />
                  <h3 class="subtitle">
                    Build &amp; Win
                    {{ coins }} coins.
                  </h3>
                </div>
              </div>
              <button class="btn btn-theme" @click="screen = 1">Yes</button>
              <a class="btn btn-theme" @click="router('/')">No</a>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <img src="@/assets/img/cartoon.png" class="cartoonImg" />
          </div>
        </div>
      </div>
    </div>
    <div class="video-container" v-else-if="screen === 1">
      <video-player v-if="vid3.loaded" class="vjs-custom-skin vid2 desktop-video" ref="videoPlayer" :options="vid3"
        :playsinline="true"></video-player>
      <div class="hero desktop-video-info">
        <div class="hero-body">
          <h1 class="title">
            {{ name }}
            <span @click="share" class="shareBtn">
              <i class="fas fa-share-alt-square"></i>
            </span>
          </h1>
          <div class="separator">MORE INFO</div>
          <h3 class="title" style="font-size: 1.7rem">Parts Needed:</h3>
          <div class="kitInfo">
            <div class="columns" v-for="(component, index) in components" :key="index">
              <div class="column plist">
                <div class="columns is-mobile">
                  <div class="column is-3">
                    <img :src="component.image" />
                  </div>
                  <div class="column">
                    <h3 class="subtitle">
                      {{ component.name }}
                      <br />
                      <span style="color: gray; font-weight: 400;">
                        Qty: {{ component.qty }}
                      </span>
                    </h3>
                  </div>
                  <div class="column is-2">
                    <!-- <a @click="showInApp(component.video)">
                      <i class="fas fa-info-circle"></i>
                    </a> -->
                    <!-- <a @click="showInApp(component.link)">
                    <i class="fas fa-shopping-bag"></i>
                  </a> -->
                    <!-- <a
                      v-if="component.pid"
                      @click="router('/product/' + component.pid)"
                    >
                      <i class="fas fa-shopping-bag"></i>
                    </a> -->
                    <!-- <a v-if="component.pid" @click="router('/product/' + component.pid)">
                                            <i class="fas fa-plus"></i>
                                        </a> -->
                    <a v-if="component.pid" @click="addToCart(component)">
                      <i v-if="!component.added" class="fas fa-cart-plus"></i>
                    </a>
                    <a v-if="component.added" @click="removeFromCart(component)">
                      <i class="fas fa-check"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>

             <!-- alternate-components -->
             <div v-if="altComponents.length > 0">
              <div class="separator">OR</div>
              <div
                class="columns"
                v-for="(component, index) in altComponents"
                :key="component.index"
              >
                <div class="column plist">
                  <div class="columns is-mobile">
                    <div class="column is-3">
                      <img :src="component.image" />
                    </div>
                    <div class="column">
                      <h3 class="subtitle">
                        #{{ index + 1 }}
                        <br />
                        {{ component.name }}
                      </h3>
                    </div>
                    <div class="column is-2">
                      <!-- <a @click="showInApp(component.video)">
                        <i class="fas fa-info-circle"></i>
                      </a>
                      <a
                        v-if="component.pid"
                        @click="router('/product/' + component.pid)"
                      >
                        <i class="fas fa-shopping-bag"></i>
                      </a> -->
                      <!-- <a v-if="component.pid" @click="router('/product/' + component.pid)">
                                                <i class="fas fa-plus"></i>
                                            </a> -->
                      <a v-if="component.pid" @click="addToCart(component)">
                        <i v-if="!component.added" class="fas fa-cart-plus"></i>
                      </a>
                      <a
                        v-if="component.added"
                        @click="removeFromCart(component)"
                      >
                        <i class="fas fa-check"></i>
                      </a>
                    </div>
                  </div>
                </div>
                </div>
             </div>


            <div class="separator" v-if="similarProjects.length > 0">
              Similar Projects
            </div>
            <div class="" v-if="similarProjects.length > 0">
              <div class="scrollingwrapper">
                <a :href="'/project/' + project.id" class="scrolling-card" :key="project.index"
                  v-for="project in similarProjects">
                  <v-lazy-image :src-placeholder="require('@/assets/img/home-lazy.png')" :src="project.image" @error="
                    post.thumbnail = require('@/assets/img/home-lazy.png')
                    " />
                </a>
              </div>
            </div>

            <!-- code -->
            <div v-if="codeExist" class="filecontainer">
              <div class="separator">Code &amp; Other Files</div>
              <div class="files">
                <div v-for="file in files" :key="file.id" class="file">
                  <a @click="showInApp(file.url)">
                    {{ file.name }}
                  </a>
                </div>
              </div>
              <!-- <div v-if="codeExist" class="emailcode">
                <div class="notification" v-if="sendCodeErr">
                  {{ sendCodeErr }}
                </div>
                <input
                  class="input"
                  type="text"
                  placeholder="E-mail"
                  v-model="useremail"
                />
                <a
                  @click="emailCode"
                  class="button is-fullwidth"
                  v-bind:class="{ 'is-loading': sendCodeBtn }"
                  >Send Code</a
                >
              </div> -->
            </div>
            <!-- /code -->
            <!-- share -->
            <img @click="$router.push('/mentors')" style="border-radius: 10px; border: 3px solid"
              src="@/assets/img/doubtclass.gif" alt="..." />
            <div>
              <div class="columns">
                <div class="column" style="padding: 50px 25px">
                  <a @click="share" class="button is-fullwidth">Share It</a>
                </div>
              </div>
            </div>
          </div>
          <div class="trailerVidBtn">
            <div class="columns">
              <div class="column" style="padding-top: 0px">
                <a v-if="cart.length > 0" class="button btn btn-theme" @click="$router.push('/cart')" target="blank">Go to
                  cart</a>
                <!-- <a v-else class="button btn btn-theme" @click="
                                                    if (pid) {
                  router('/product/' + pid);
                } else {
                  router('/shop/');
                }
                  " target="blank">Order Parts</a> -->
              </div>
            </div>
            <div class="columns">
              <div class="column" style="padding-top: 0px">
                <button v-if="completed" class="button btn btn-theme" @click="
                    router('/upload/story/' + $route.params.id + '/' + coins)
                    ">
                  Upload Video
                </button>
                <button v-else class="button btn btn-theme" @click="markComplete()"
                  v-bind:class="{ 'is-loading': btnLoading }">
                  Completed?
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a @click="screen = 0" class="screen2Btn">
        <span class="icon">
          <i class="fas fa-chevron-circle-left"></i>
        </span>
      </a>
    </div>
    <div class="video-container" v-else-if="screen === 2">
      <video-player v-if="vid3.loaded" class="vjs-custom-skin vid3 desktop-video" ref="videoPlayer" :options="vid3"
        :playsinline="true"></video-player>
      <div class="hero desktop-video-info">
        <div class="hero-body" style="padding: 3rem 1.5rem 0 1.5rem">
          <h1 class="title">Follow steps in the video</h1>
          <div class="columns">
            <div class="column">
              <div class="scard dark">
                <h3 class="subtitle">
                  Have you completed
                  <b>{{ name }}</b>?
                </h3>
                <a class="button" @click="markComplete()" v-bind:class="{ 'is-loading': btnLoading }">Mark it Complete</a>
              </div>
            </div>
            <div class="column" style="text-align: right; padding: 0.75rem 0.75rem 0rem 0.75rem">
              <img src="@/assets/img/cartoon.png" style="width: 120px; margin-right: 80px" />
            </div>
          </div>
        </div>
      </div>
      <a @click="screen = 1" class="screen3Btn">
        <span class="icon">
          <i class="fas fa-chevron-circle-left"></i>
        </span>
      </a>
    </div>
    <div style="padding: 20px" v-else-if="screen === 3">
      <img src="@/assets/img/award.png" class="congrats" />
      <h1 class="title has-text-centered" style="padding-bottom: 15px">
        Congratulations!
      </h1>

      <img src="https://i.pinimg.com/originals/ee/1f/17/ee1f179fe400a4dbfec0b70ac58478c1.gif" alt="..." />

      <h3 class="subtitle has-text-centered">
        By building this project, you have made us proud.
      </h3>

      <h3 class="subtitle has-text-centered">
        Upload your project's video to win
        <b style="color: #000000">{{ coins }} coins.</b>
      </h3>
      <a @click="router('/upload/story/' + $route.params.id + '/' + coins)" class="btn btn-theme">Upload Video</a>
      <a @click="screen = 2" class="screen3Btn">
        <span class="icon">
          <i class="fas fa-chevron-circle-left"></i>
        </span>
      </a>

      <!-- <hr /> -->
    </div>
    <transition name="fade">
      <div v-if="animation" class="addedtocart">
        <div class="container">
          <h3 class="subtitle">
            <b>{{ animation }}</b> added to cart
          </h3>
        </div>
      </div>
    </transition>
  </section>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { functions } from "@/main.js";
import { db } from "@/main.js";
import { Browser } from "@capacitor/browser";
import "@/assets/css/video-js.css";
import { videoPlayer } from "vue-video-player";
// import { Webengage } from "@ionic-native/webengage";
import { Share } from "@capacitor/share";
import { firestore } from "@/main.js";
import { createWebengageEvent } from "../helper";
import VLazyImage from "v-lazy-image";

export default {
  name: "project",
  components: {
    videoPlayer,
    VLazyImage,
  },
  data() {
    return {
      screen: 0,
      name: "",
      orderLink: "",
      coins: "",
      popupOpen: false,
      components: [],
      altComponents: [],
      altComponentsExist: false,
      codeExist: true,
      files: [],
      useremail: "",
      sendCodeErr: "",
      sendCodeBtn: false,
      btnLoading: false,
      pid: "",
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster:
          "https://surmon-china.github.io/vue-quill-editor/static/images/surmon-1.jpg",
        loaded: false,
      },
      vid2: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster:
          "https://surmon-china.github.io/vue-quill-editor/static/images/surmon-1.jpg",
        loaded: false,
      },
      vid3: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster:
          "https://surmon-china.github.io/vue-quill-editor/static/images/surmon-1.jpg",
        loaded: false,
      },
      recommendations: [],
      completed: false,
      cart: [],
      animation: "",
      similarProjects: [],
    };
  },
  beforeMount() {
    if (this.$route.params.page) this.screen = Number(this.$route.params.page);
    db.collection("projects")
      .doc(this.$route.params.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log(doc.data());
          createWebengageEvent({
            uid: auth.currentUser.uid,
            eventName: "Project Viewed",
            eventData: {
              added: new Date().getTime(),
              readableDate: new Date().toLocaleString(),
              name: doc.data().name,
            },
          });
          this.pid = doc.data().pid;
          this.recommendations = doc.data().recommendations;
          this.vid1.sources.push({ type: "video/mp4", src: doc.data().story });
          this.vid2.sources.push({
            type: "video/mp4",
            src: doc.data().trailer,
          });
          this.vid3.sources.push({
            type: "video/mp4",
            src: doc.data().procedure,
          });
          this.vid1.poster =
            "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
            encodeURIComponent(doc.data().story) +
            "&size=400&mode=orig";
          this.vid2.poster =
            "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
            encodeURIComponent(doc.data().trailer) +
            "&size=400&mode=orig";
          this.vid3.poster =
            "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
            encodeURIComponent(doc.data().procedure) +
            "&size=400&mode=orig";
          this.name = doc.data().name;
          this.coins = doc.data().coins;
          if (this.$store.state.profile.premium)
            this.coins = doc.data().coins * 2;
          this.orderLink = doc.data().orderLink;
          doc.data().components_v2.forEach((component) => {
            db.collection("tools")
              .doc(component.id)
              .get()
              .then((doc) => {
                this.components.push({
                  name: doc.data().name,
                  link: doc.data().orderLink,
                  video: doc.data().video,
                  image: doc.data().img,
                  pid: doc.data().pid,
                  stock: doc.data().stock,
                  qty: component.qty
                });
              });
          });
          if (doc.data().altComponents[0] == "none") {
            this.altComponentsExist = false;
          } else {
            // console.log(doc.data().altComponents[0]);
            this.altComponentsExist = true;
            doc.data().altComponents_v2.forEach((component) => {
              db.collection("tools")
                .doc(component.id)
                .get()
                .then((doc) => {
                  this.altComponents.push({
                    name: doc.data().name,
                    link: doc.data().orderLink,
                    video: doc.data().video,
                    image: doc.data().img,
                    pid: doc.data().pid,
                    qty: component.qty
                  });
                });
            });
          }
          db.collection("projects")
            .doc(doc.id)
            .collection("files")
            .get()
            .then((files) => {
              if (files.empty) {
                this.codeExist = false;
              } else {
                files.forEach((file) => {
                  this.files.push({
                    name: file.data().name,
                    url: file.data().url,
                    id: file.id,
                  });
                });
              }
            });
          auth.onAuthStateChanged((user) => {
            this.displayName = user.displayName;
            this.useremail = user.email;
            db.collection("users")
              .doc(user.uid)
              .collection("tracking")
              .where("id", "==", this.$route.params.id)
              .get()
              .then((snap) => {
                if (snap.empty) {
                  db.collection("users")
                    .doc(user.uid)
                    .collection("tracking")
                    .add({
                      id: this.$route.params.id,
                      created: new Date().getTime(),
                      type: "project",
                    });
                }
              });
          });
          setTimeout(() => {
            this.vid1.loaded = true;
            this.vid2.loaded = true;
            this.vid3.loaded = true;
          }, 100);
        } else {
          this.router("/");
        }
      });
    this.fetchSimilarProjects();
  },
  mounted() {
    db.collection("users")
      .doc(auth.currentUser.uid)
      .collection("projects")
      .where("pid", "==", this.$route.params.id)
      .get()
      .then((project) => {
        this.completed = !project.empty;
      });
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    async share() {
      console.log("https://project.witblox.com/" + this.$route.params.id);
      await Share.share({
        title: "Check this project on WitBlox",
        text: "Check out this amazing project from WitBlox.",
        url: "https://project.witblox.com/" + this.$route.params.id,
        dialogTitle: "Share with friends",
      });
    },
    async showInApp(link) {
      await Browser.open({ url: link });
    },
    onPlayerEnded() {
      this.popupOpen = true;
    },
    addToCart(p) {
      console.log(p);

      this.cart.push(p.pid);
      this.animation = p.name;
      p.added = true;
      db.collection("shop-products")
        .doc(p.pid)
        .get()
        .then((product) => {
          if (product.exists) {
            db.collection("users")
              .doc(auth.currentUser.uid)
              .collection("cart")
              .where("product", "==", p.pid)
              .limit(1)
              .get()
              .then((snap) => {
                if (snap.empty) {
                  //not added in cart
                  db.collection("users")
                    .doc(auth.currentUser.uid)
                    .collection("cart")
                    .add({
                      quantity: p.qty,
                      product: p.pid,
                      added: new Date().getTime(),
                    })
                    .then(() => {
                      this.productsAdded = true;
                    });
                } else {
                  //already added in cart
                  db.collection("users")
                    .doc(auth.currentUser.uid)
                    .collection("cart")
                    .doc(snap.docs[0].id)
                    .set(
                      {
                        quantity: firestore.FieldValue.increment(1),
                        product: p.pid,
                        added: new Date().getTime(),
                      },
                      { merge: true }
                    )
                    .then(() => {
                      this.productsAdded = true;
                    });
                }
              });
          } else console.log("product doesn't exists", p.pid);
          setTimeout(() => {
            this.animation = "";
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.animation = "";
        });
    },
    removeFromCart(p) {
      this.cart.splice(this.cart.indexOf(p.pid), 1);
      p.added = false;
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("cart")
        .where("product", "==", p.pid)
        .limit(1)
        .get()
        .then((snap) => {
          if (snap.empty) {
            //not added in cart
            console.log("not added in cart");
          } else {
            //already added in cart
            if (snap.docs[0].data().quantity == 1) {
              db.collection("users")
                .doc(auth.currentUser.uid)
                .collection("cart")
                .doc(snap.docs[0].id)
                .delete()
                .then(() => {
                  this.productsAdded = true;
                });
              return;
            }

            db.collection("users")
              .doc(auth.currentUser.uid)
              .collection("cart")
              .doc(snap.docs[0].id)
              .set(
                {
                  quantity: firestore.FieldValue.increment(-1),
                  product: p.pid,
                  added: new Date().getTime(),
                },
                { merge: true }
              )
              .then(() => {
                this.productsAdded = true;
              });
          }
        });
    },
    markComplete() {
      this.btnLoading = true;
      var markComplete = functions.httpsCallable("markComplete");
      markComplete({ pid: this.$route.params.id, type: "story" }).then(() => {
        createWebengageEvent({
          uid: auth.currentUser.uid,
          eventName: "Project Completed",
          eventData: {
            added: new Date().getTime(),
            readableDate: new Date().toLocaleString(),
            name: this.name,
            recommendations: this.recommendations,
          },
        });
        this.btnLoading = false;
        this.screen = 3;
      });
    },
    async fetchSimilarProjects() {
      const categoriesConfig = await db
        .collection("config")
        .doc("categories")
        .get();

      const allProjects = categoriesConfig.data().recommended_projects;

      const activeProject = allProjects.find(
        (project) => project.id === this.$route.params.id
      );

      let similarProjects = allProjects.filter(
        (project) => project?.category_id === activeProject?.category_id
      );
      this.similarProjects = similarProjects;
    },
    emailCode() {
      this.sendCodeBtn = true;
      if (this.validateEmail(this.useremail)) {
        auth.onAuthStateChanged((user) => {
          var emailCode = functions.httpsCallable("emailCode");
          emailCode({
            pid: this.$route.params.id,
            uid: user.uid,
            email: this.useremail,
          }).then(() => {
            this.sendCodeBtn = false;
            this.sendCodeErr =
              "We've e-mailed you all required code & other files.";
          });
        });
      } else {
        this.sendCodeBtn = false;
        this.sendCodeErr = "Please enter a valid e-mail address.";
      }
    },
    validateEmail(email) {
      console.log(email);
      //eslint-disable-next-line
      //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      //   return re.test(String(email).toLowerCase());
    },
  },
};
</script>
<style>
.title span.shareBtn {
  padding-left: 10px;
}

.videoBtns {
  text-align: center;
}

.videoBtns .button {
  font-size: 1.2rem;
  background: #000;
  border-radius: 10px;
}

.videosection,
.video-container,
.video-player,
.vjs_video_3-dimensions,
.video-js {
  width: 100%;
  height: 100% !important;
}

.plist {
  max-width: 500px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 600px) {
  .video-player.vid2 {
    height: 450px !important;
  }

  .trailerVidBtn .columns {
    max-width: 700px;
    margin: auto;
  }

  .plist {
    max-width: 500px;
    margin-bottom: 10px;
  }

  .cartoon2 {
    width: 27%;
  }
}

@media only screen and (min-width: 900px) {

  .video-container .desktop-video,
  .video-container .desktop-video-info {
    display: inline;
  }

  .video-container .desktop-video {
    width: 70%;
    height: 100% !important;
    float: left;
  }

  .video-container .desktop-video-info {
    width: 30%;
    height: 100% !important;
    float: right;
    overflow: scroll;
    position: relative;
  }

  .trailerVidBtn {
    width: 30%;
    right: 0;
    left: auto;
  }
}

.addedtocart {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255, 0.8);
  display: grid;
  place-items: center;
  text-align: center;
  padding: 10%;
}

.addedtocart .subtitle {
  font-size: 1.4rem;
  font-weight: 700;
}

.addedtocart b {
  font-weight: 900;
}
</style>