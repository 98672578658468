<template>
  <div class="hero">
    <div v-if="!checking" class="hero-body">
      <div v-if="screen == 0">
        <div class="columns">
          <div class="column has-text-centered">
            <h1 class="title is-5">Make your child a superhero</h1>
          </div>
        </div>
        <!-- <div class="columns">
          <div class="column">
            <div class="card" style="border-radius: 20px">
              <div class="card-content">
                <div class="columns is-mobile">
                  <div
                    class="column is-2 has-text-centered"
                    style="margin: auto"
                  >
                    <i
                      class="fas fa-location-arrow"
                      style="font-size: 2rem"
                    ></i>
                    <br />
                  </div>
                  <div class="column">
                    <h6 class="subtitle" style="margin-bottom: 5px">
                      we'll deliver to:
                    </h6>
                    <p>{{ address.full }}, {{ address.city }}</p>
                    <a class="change" @click="selectedAddress = ''">
                      Change?
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="columns is-mobile">
          <div class="column">
            <img
              src="https://emojipedia-us.s3.amazonaws.com/source/skype/289/woman-superhero_1f9b8-200d-2640-fe0f.png"
              alt=""
              style="transform: rotateY(180deg)"
            />
          </div>
          <!-- <div class="column is-2" style="padding-top:40px">
                <img src="https://witblox.com/assets/img/witblox.png" alt="" class="witblox">
            </div> -->
          <div class="column">
            <img
              src="https://emojipedia-us.s3.amazonaws.com/source/skype/289/man-superhero_1f9b8-200d-2642-fe0f.png"
              alt=""
            />
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-centered">
            <h1 class="title is-3">Select your plan</h1>
          </div>
        </div>
        <div class="columns plans">
          <div class="column">
            <a
              class="plan blue"
              @click="selected = plans[0]"
              :class="{ selected: selected == plans[0] }"
            >
              <div class="columns is-mobile">
                <div class="column">
                  <h1 class="title is-3"><span>1</span> Box</h1>
                  <p>per month</p>
                  <h2 class="title is-5">Rs 1,299 per month</h2>
                </div>
                <div class="column is-3">
                  <div
                    class="circle"
                    :class="{ selected: selected == plans[0] }"
                  ></div>
                  <div style="padding-top: 30px">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/subscription%2Fa1_delivery%2Fbox1.png?alt=media&token=3035008f-f615-4104-baff-dcf32a2a9a52"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
            <a v-if="!plan1" class="button is-fullwidth" @click="plan1 = !plan1"
              >More</a
            >
            <div v-if="plan1" class="morecontent">
              <ul>
                <li v-for="bullet in plan1info" :key="bullet">{{ bullet }}</li>
              </ul>
            </div>
            <a v-if="plan1" class="button is-fullwidth" @click="plan1 = !plan1"
              >Less</a
            >
          </div>
        </div>
        <div class="columns plans">
          <div class="column">
            <a
              class="plan yellow"
              @click="selected = plans[1]"
              :class="{ selected: selected == plans[1] }"
            >
              <div class="columns is-mobile">
                <div class="column">
                  <h1 class="title is-3"><span>2</span> Boxes</h1>
                  <p>per month</p>
                  <h2 class="title is-5">Rs 2,197 per month</h2>
                </div>
                <div class="column is-3">
                  <div
                    class="circle"
                    :class="{ selected: selected == plans[1] }"
                  ></div>
                  <div style="padding-top: 30px">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/subscription%2Fa1_delivery%2Fbox2.png?alt=media&token=a4c51654-e22a-423d-99b0-1c70f7d1327d"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
            <a v-if="!plan2" class="button is-fullwidth" @click="plan2 = !plan2"
              >More</a
            >
            <div v-if="plan2" class="morecontent">
              <ul>
                <li v-for="bullet in plan2info" :key="bullet">{{ bullet }}</li>
              </ul>
            </div>
            <a v-if="plan2" class="button is-fullwidth" @click="plan2 = !plan2"
              >Less</a
            >
          </div>
        </div>
        <div class="columns plans">
          <div class="column">
            <a
              class="plan red"
              @click="selected = plans[2]"
              :class="{ selected: selected == plans[2] }"
            >
              <div class="columns is-mobile">
                <div class="column">
                  <h1 class="title is-3"><span>3</span> Boxes</h1>
                  <p>per month</p>
                  <h2 class="title is-5">Rs 2,697 per month</h2>
                </div>
                <div class="column is-3">
                  <div
                    class="circle"
                    :class="{ selected: selected == plans[2] }"
                  ></div>
                  <div style="padding-top: 30px">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/subscription%2Fa1_delivery%2Fbox3.png?alt=media&token=98583ec2-54c3-4ef0-a92b-3b6637c31431"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
            <a v-if="!plan3" class="button is-fullwidth" @click="plan3 = !plan3"
              >More</a
            >
            <div v-if="plan3" class="morecontent">
              <ul>
                <li v-for="bullet in plan3info" :key="bullet">{{ bullet }}</li>
              </ul>
            </div>
            <a v-if="plan3" class="button is-fullwidth" @click="plan3 = !plan3"
              >Less</a
            >
          </div>
        </div>
        <div class="columns plans">
          <div class="column">
            <a
              class="plan green"
              @click="selected = plans[3]"
              :class="{ selected: selected == plans[3] }"
            >
              <div class="columns is-mobile">
                <div class="column">
                  <h1 class="title is-3"><span>4</span> Boxes</h1>
                  <p>per month</p>
                  <h2 class="title is-5">Rs 3,197 per month</h2>
                </div>
                <div class="column is-3">
                  <div
                    class="circle"
                    :class="{ selected: selected == plans[3] }"
                  ></div>
                  <div style="padding-top: 30px">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/subscription%2Fa1_delivery%2Fbox4.png?alt=media&token=28c063ad-b6a3-4414-88d0-c25ff4911e6f"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </a>
            <a v-if="!plan4" class="button is-fullwidth" @click="plan4 = !plan4"
              >More</a
            >
            <div v-if="plan4" class="morecontent">
              <ul>
                <li v-for="bullet in plan4info" :key="bullet">{{ bullet }}</li>
              </ul>
            </div>
            <a v-if="plan4" class="button is-fullwidth" @click="plan4 = !plan4"
              >Less</a
            >
          </div>
        </div>
        <!-- cta -->
        <div class="scta">
          <button
            class="btn btn-theme"
            @click="screen = 1"
            :class="{ 'is-loading': btnLoading }"
          >
            Next
          </button>
        </div>
        <!-- /cta -->
      </div>
      <div v-if="screen == 1">
        <div class="columns">
          <div class="column">
            <h1 class="title is-1 has-text-centered">Shipping Address</h1>
            <a
              class="button is-fullwidth addressBtn"
              @click="addressForm.open = true"
            >
              ADD ADDRESS
            </a>
            <div
              class="addressbox card"
              v-for="a in addresses"
              :key="a.id"
              :class="{ active: address.key == a.id, 'is-hidden': a.deleted }"
            >
              <div class="card-content">
                <p>
                  {{ a.full }}
                  <br /><br />
                  <b>Name:</b> {{ a.name }}
                  <br />
                  <b>City:</b> {{ a.city }}, {{ a.state }}
                  <br />
                  <b>PIN:</b> {{ a.pincode }}
                  <br />
                  <b>Phone:</b> {{ a.phone }}
                </p>
                <div class="buttons">
                  <a class="button" @click="selectAddress(a)">
                    <span v-if="address.key == a.id">Selected</span>
                    <span v-else>Select</span>
                  </a>
                  <a class="button is-danger" @click="deleteAddress(a)">
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="scta">
              <button
                class="btn btn-theme"
                @click="screen = 2"
                :class="{ 'is-loading': btnLoading }"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="screen == 2">
        <div class="columns">
          <div class="column">
            <h3 class="title is-3 has-text-centered">Review</h3>
            <div class="card">
              <div class="card-content">
                <div class="columns">
                  <div class="column">
                    <h6 class="title is-5">Selected Plan</h6>
                    <p>
                      {{ plansinfo[plans.indexOf(selected)].name }} -
                      {{ plansinfo[plans.indexOf(selected)].price }} -
                      {{ plansinfo[plans.indexOf(selected)].quantity }}
                    </p>
                    <a class="link" @click="screen = 0"> Change </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-content">
                <div class="columns">
                  <div class="column">
                    <h6 class="title is-5">Selected Address</h6>
                    <p>
                      <b>Name:</b> {{ address.name }}
                      <br />
                      <b>Full:</b> {{ address.full }}
                      <br />
                      <b>City:</b>
                      {{ address.city }}, {{ address.state }}
                      <br />
                      <b>PIN:</b>
                      {{ address.pincode }}
                      <br />
                      <b>Phone:</b> {{ address.phone }}
                    </p>
                    <a class="link" @click="screen = 1"> Change </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- cta -->
            <div class="scta">
              <button
                class="btn btn-theme"
                @click="subscribe"
                :class="{ 'is-loading': btnLoading }"
              >
                Proceed to Payment
              </button>
            </div>
            <!-- /cta -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="checking" class="hero-body">
      <div class="columns has-text-centered">
        <div class="column">
          <h1 class="title is-1">Verifying</h1>
          <progress class="progress is-small is-primary" max="100">
            15%
          </progress>
          <p>
            We're verifying your payment status. Please wait 2-3 minutes. We
            will send you a notification shortly.
          </p>
        </div>
      </div>
    </div>
    <div v-if="addressForm.open" class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="field">
          <div class="control">
            <h4 class="subtitle has-text-centered" style="padding-bottom: 20px">
              Add Address
            </h4>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <div class="notification is-danger" v-if="addressForm.error">
              {{ addressForm.error }}
            </div>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model="addressForm.name"
              class="input"
              type="text"
              placeholder="Full Name"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model="addressForm.full"
              class="input"
              type="text"
              placeholder="Full Address"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model="addressForm.city"
              class="input"
              type="text"
              placeholder="City"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model="addressForm.state"
              class="input"
              type="text"
              placeholder="State"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model.number="addressForm.pincode"
              class="input"
              type="number"
              placeholder="6-digit PIN Code"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model.number="addressForm.phone"
              class="input"
              type="number"
              placeholder="10-digit Phone Number"
            />
          </div>
        </div>
        <div class="field">
          <a class="button" @click="addAddress()">Add</a>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="addressForm.open = false"
      ></button>
    </div>
  </div>
</template>
<script>
import { auth } from "@/main.js";
// import { functions } from "@/main.js";
import { db } from "@/main.js";
import { functions } from "@/main.js";
import { Browser } from "@capacitor/browser";
export default {
  name: "subscription_plans",
  data() {
    return {
      plans: [
        "plan_JUI7qpqOO0DrFN",
        "plan_JUI9QGtaRVZaVg",
        "plan_JUIACnpUFaCUfz",
        "plan_JUIBQ64NrpVCs2",
      ],
      plansinfo: [
        {
          name: "Blue Plan",
          price: "Rs 1,299 per month",
          quantity: "1 Box per month",
        },
        {
          name: "Yellow Plan",
          price: "Rs 2,197 per month",
          quantity: "2 Boxes per month",
        },
        {
          name: "Red Plan",
          price: "Rs 2,697 per month",
          quantity: "3 Boxes per month",
        },
        {
          name: "Green Plan",
          price: "Rs 3,197 per month",
          quantity: "4 Boxes per month",
        },
      ],
      selected: "plan_JUI7qpqOO0DrFN",
      btnLoading: false,
      checking: false,
      addressForm: {
        open: false,
        name: "",
        full: "",
        city: "",
        pincode: "",
        phone: "",
        error: "",
        state: "",
      },
      addresses: [],
      selectedAddress: "",
      selectedPlan: {},
      address: {},
      screen: 0,
      plan1: false,
      plan2: false,
      plan3: false,
      plan4: false,
      plan1info: [],
      plan2info: [],
      plan3info: [],
      plan4info: [],
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    subscribe() {
      this.btnLoading = true;
      this.checking = false;
      var createSubscription = functions.httpsCallable("createSubscription");
      createSubscription({
        plan: this.selected,
        address: this.selectedAddress,
      }).then(async (result) => {
        console.log(result);
        await Browser.open({ url: result.data.short_url });
        this.btnLoading = false;
        this.checking = true;
        db.collection("users")
          .doc(auth.currentUser.uid)
          .onSnapshot((doc) => {
            if (doc.data().premium) this.$router.push("/subscribed");
          });
      });
    },
    addAddress() {
      if (
        this.addressForm.name !== "" &&
        this.addressForm.full !== "" &&
        this.addressForm.city !== "" &&
        this.addressForm.pincode !== "" &&
        this.addressForm.state !== "" &&
        this.addressForm.phone !== ""
      ) {
        if (String(this.addressForm.pincode).length == 6) {
          if (String(this.addressForm.phone).length == 10) {
            auth.onAuthStateChanged((user) => {
              db.collection("users")
                .doc(user.uid)
                .collection("address")
                .add({
                  name: this.addressForm.name,
                  full: this.addressForm.full,
                  city: this.addressForm.city,
                  pincode: this.addressForm.pincode,
                  phone: this.addressForm.phone,
                  added: new Date().getTime(),
                  state: this.addressForm.state,
                })
                .then((doc) => {
                  this.addressForm.open = false;
                  this.addresses.push({
                    id: doc.id,
                    name: this.addressForm.name,
                    full: this.addressForm.full,
                    city: this.addressForm.city,
                    pincode: this.addressForm.pincode,
                    phone: this.addressForm.phone,
                    state: this.addressForm.state,
                    added: 0,
                    deleted: false,
                  });
                  this.selectedAddress = doc.id;
                });
            });
          } else {
            this.addressForm.error = "Please enter 10-digit Phone Number.";
          }
        } else {
          this.addressForm.error = "Please enter 6-digit PIN Code.";
        }
      } else {
        this.addressForm.error = "Please fill all fields.";
      }
    },
    selectAddress(a) {
      this.address.key = a.id;
      this.address.full = a.full;
      this.address.name = a.name;
      this.address.city = a.city;
      this.address.pincode = a.pincode;
      this.address.phone = a.phone;
      this.address.state = a.state;
      setTimeout(() => {
        this.selectedAddress = a.id;
      }, 100);
    },
    deleteAddress(a) {
      a.deleted = true;
      if (this.address.key == a.id) {
        this.address = {
          key: "",
          full: "",
          name: "",
          city: "",
          phone: "",
          pincode: 0,
          state: "",
        };
      }
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("address")
        .doc(a.id)
        .delete()
        .then(() => {
          console.log("deleted");
        });
    },
  },
  beforeMount() {
    db.collection("users")
      .doc(auth.currentUser.uid)
      .collection("address")
      .get()
      .then((addresses) => {
        addresses.forEach((address) => {
          var addressObj = address.data();
          addressObj.id = address.id;
          addressObj.deleted = false;
          this.addresses.push(addressObj);
          var a = addressObj;
          this.address.key = a.id;
          this.address.full = a.full;
          this.address.name = a.name;
          this.address.city = a.city;
          this.address.pincode = a.pincode;
          this.address.phone = a.phone;
          this.address.state = a.state;
          this.selectedAddress = address.id;
        });
      });
    db.collection("config")
      .doc("subscription")
      .get()
      .then((doc) => {
        this.plan1info = doc.data().plan1info;
        this.plan2info = doc.data().plan2info;
        this.plan3info = doc.data().plan3info;
        this.plan4info = doc.data().plan4info;
      });
  },
  mounted() {},
};
</script>
<style scoped>
a.plan {
  display: block;
  padding: 40px;
  border-radius: 20px 20px 0 0;
  /* border: 6px solid #fff; */
}

a.plan.selected {
  /* border: 6px solid #000 !important; */
}
a.plan .title {
  color: #fff !important;
}

a.plan .title.is-5 {
  margin-bottom: 10px;
}
.hero {
  padding-bottom: 100px;
}

.plan .circle.selected {
  background: #fdba0d;
  border: 8px solid #000;
}
.plan .circle {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 8px solid #fff;
  background: #fff;
  margin: auto;
}
.addressbox {
  border-radius: 10px;
  border: 3px solid;
}

.addressbox {
  margin-bottom: 10px;
}
.addressbox.active {
  background: rgb(188, 253, 188);
}
.addressbox .card-content p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.addressbox .buttons {
  padding-top: 15px;
}
.addressbox .button {
  border: 3px solid;
  font-weight: 700;
}
.button.is-fullwidth.addressBtn {
  margin-bottom: 20px;
  color: #000000;
  border: 3px solid;
  border-radius: 10px;
}
.modal .modal-background {
  background: rgba(10, 10, 10, 0.86);
}
.modal .modal-content {
  padding: 20px;
  background: #ffffff;
}

.change {
  display: inline-block;
  margin-top: 10px;
  color: #000;
  border-bottom: 3px solid #000;
}

.blue {
  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.yellow {
  background: #ffe000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #799f0c,
    #ffe000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #799f0c,
    #ffe000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.red {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.green {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0f9b0f,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #0f9b0f,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
a.link {
  color: #000;
  display: inline-block;
  padding-top: 25px;
  border-bottom: 3px solid;
}

.card {
  box-shadow: 0 8px 17px 2px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%),
    0 5px 5px -3px rgb(0 0 0 / 20%);
}

.plans h1.title.is-3 {
  margin-bottom: 2px;
}

.plans p {
  color: #fff;
  padding-bottom: 15px;
}

a.button.is-fullwidth {
  margin-bottom: 20px;
  color: #000000;
  border: 3px solid;
  border-radius: 0 0 10px 10px;
}

.morecontent {
  background: #d8d8d8;
  padding: 20px;
}

ul {
  list-style-type: circle;
  padding-left: 30px;
  font-size: 1rem;
  font-weight: 600;
}
.plans h1.title.is-3 span {
  font-size: 4rem;
}
</style>