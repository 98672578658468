<template>
  <div class="homescreen" :style="color">
    <!-- profile-header -->
    <div class="homeprofile">
      <div class="homeheader columns is-mobile">
        <div class="column is-3">
          <a @click="router('/profile')">
            <div
              v-bind:style="{ 'background-image': image }"
              v-if="profile.picture === ''"
              class="homeprofileimg"
            ></div>
            <div :style="profile.picture" v-else class="homeprofileimg"></div>
          </a>
        </div>
        <div class="column is-7">
          <h6 class="home title" :style="textColor">
            Hi
            <span>
              <span
                v-if="profile.fname === ''"
                class="preload"
                style="width: 100px"
              ></span>
              <span v-else>{{ profile.fname }}</span>
            </span>
            <img src="../assets/img/hi.png" width="28px" />
          </h6>
        </div>
        <div class="column is-2">
          <a @click="router('/search')" class="searchIcon">
            <i class="fas fa-search"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- /profile-header -->
    <!-- for-new-users -->
    <div class="section newuser" v-if="newuser">
      <div class="columns">
        <div class="column">
          <div class="card">
            <div v-if="userstage == 5" class="card-content">
              <h1 class="title">Do you have a WitBlox Kit?</h1>
              <div class="tags are-medium">
                <span
                  class="tag"
                  :class="{ selected: haveKit }"
                  @click="haveKit = true"
                >
                  Yes
                </span>
                <span
                  class="tag"
                  :class="{ selected: !haveKit }"
                  @click="haveKit = false"
                >
                  No
                </span>
              </div>
              <a class="button" @click="newuserflow(5)">
                <span>Next</span>
                <span class="icon">
                  <i class="fas fa-angle-right"></i>
                </span>
              </a>
            </div>
            <div v-if="userstage == 0" class="card-content">
              <h1 class="title">Pick your interests</h1>
              <div class="tags are-medium">
                <span
                  v-for="card in categories"
                  :key="'i' + card.id"
                  class="tag"
                  :class="{ selected: card.selected }"
                  @click="card.selected = !card.selected"
                >
                  {{ card.name }}
                </span>
              </div>
              <a class="button" @click="newuserflow(0)">
                <span>Next</span>
                <span class="icon">
                  <i class="fas fa-angle-right"></i>
                </span>
              </a>
            </div>
            <div v-if="userstage == 1" class="card-content">
              <h1 class="title">
                Upload your experiments and earn
                <span class="underline">WitCoins</span>
              </h1>
              <div class="columns">
                <div class="column">
                  <p class="home subtitle">
                    <span class="trendingtitle">
                      <i class="fas fa-bolt"></i>
                      Trending Projects
                    </span>
                  </p>
                </div>
                <div class="column userstories">
                  <div
                    class="storycontainer"
                    v-for="post in $store.state.trending"
                    :key="post.id"
                    @click="router('social/' + post.id)"
                  >
                    <div :style="post.thumbnail" class="userphoto"></div>
                    <p>
                      {{ post.author }}
                    </p>
                  </div>
                </div>
              </div>
              <a class="button" @click="newuserflow(1)">
                <span>Next</span>
                <span class="icon">
                  <i class="fas fa-angle-right"></i>
                </span>
              </a>
            </div>
            <div v-if="userstage == 3" class="card-content">
              <h2 class="title" v-if="newuserpost.hours > 8">
                <b>Congrats!</b> Your project was trending in just
                <span class="underline">8 hours</span>
              </h2>
              <h2 class="title" v-else>
                <b>Congrats!</b> Your project is trending in just
                <span class="underline">{{ newuserpost.hours }} hours</span>
              </h2>
              <div class="columns is-mobile">
                <div class="column">
                  <div class="columns is-mobile">
                    <div class="column">
                      <h1 class="title">{{ newuserpost.views }}</h1>
                      <h3 class="subtitle">Views</h3>
                    </div>
                    <div class="column">
                      <h1 class="title">{{ newuserpost.likes }}</h1>
                      <h3 class="subtitle">Likes</h3>
                    </div>
                  </div>
                  <div class="columns is-mobile">
                    <div class="column">
                      <h1 class="title">{{ newuserpost.witcoins }}</h1>
                      <h3 class="subtitle">WitCoins Earned</h3>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <img
                    @click="$router.push('/social/' + newuserpost.id)"
                    :src="newuserpost.thumbnail"
                  />
                </div>
              </div>
              <a class="button" @click="newuserflow(3)">
                <span>Explore More</span>
                <span class="icon">
                  <i class="fas fa-angle-down"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /for-new-users -->

    <div class="section newuser">
      <div class="columns">
        <div class="column">
          <div class="card">
            <div class="card-content">
              <h1 class="title">Get started with</h1>
              <div class="scrollingwrapper">
                <a
                  @click="router('/category/' + card.id)"
                  class="scrolling-card"
                  :key="card.id"
                  v-for="card in $store.state.categories"
                >
                  <v-lazy-image
                    :src-placeholder="require('@/assets/img/home-lazy.png')"
                    :src="card.img"
                    style="width: 100px; height: auto; border: 3px solid #000"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      

    <div class="section">
      <!-- mobile-carousel -->
      <flickity
        ref="flickity"
        :options="flickityOptions"
        style="padding-bottom: 30px"
        class="mobile is-hidden-desktop"
      >
        <!-- banner-1 -->
        <div class="grand-challenge" style="width: 95%">
          <div
            class="card"
            @click="router($store.state.homePageBanner[0].route)"
          >
            <a class="gc-next">
              <i class="fas fa-chevron-circle-right"></i>
            </a>
            <div
              class="card-content"
              v-bind:style="{
                'background-color': $store.state.selectedColor.c1,
                'background-image': $store.state.homePageBanner[0].banner,
              }"
            >
              <div class="featured-banner" style="margin: 0px !important">
                {{ $store.state.homePageBanner[0].tag }}
              </div>
            </div>
          </div>
        </div>
        <!-- banner-2 -->
        <div class="grand-challenge" style="width: 95%">
          <div
            class="card"
            @click="router($store.state.homePageBanner[1].route)"
          >
            <a class="gc-next">
              <i class="fas fa-chevron-circle-right"></i>
            </a>
            <div
              class="card-content"
              v-bind:style="{
                'background-color': $store.state.selectedColor.c1,
                'background-image': $store.state.homePageBanner[1].banner,
              }"
            >
              <div class="featured-banner" style="margin: 0px !important">
                {{ $store.state.homePageBanner[1].tag }}
              </div>
            </div>
          </div>
        </div>
        <!-- banner-3 -->
        <div class="grand-challenge" style="width: 95%">
          <div
            class="card"
            @click="router($store.state.homePageBanner[2].route)"
          >
            <a class="gc-next">
              <i class="fas fa-chevron-circle-right"></i>
            </a>
            <div
              class="card-content"
              v-bind:style="{
                'background-color': $store.state.selectedColor.c1,
                'background-image': $store.state.homePageBanner[2].banner,
              }"
            >
              <div class="featured-banner" style="margin: 0px !important">
                {{ $store.state.homePageBanner[2].tag }}
              </div>
            </div>
          </div>
        </div>
      </flickity>
      <!-- /mobile-carousel -->
      <!-- desktop-carousel -->
      <div class="columns is-hidden-touch">
        <div class="column">
          <video muted autoplay playsinline loop class="homepageFeaturedVideo">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fheader.mp4?alt=media&token=80c14b83-2e9d-4a40-a635-e00f26dbca6b"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <!-- /desktop-carousel -->
      <!-- subscription-banner -->
      <div class="columns is-hidden">
        <div class="column">
          <img
            @click="$router.push('/subscription')"
            v-if="!profile.premium"
            style="border-radius: 10px"
            src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/subscription%2Fa1_delivery%2Fa.gif?alt=media&token=f27ccd16-d476-4abd-a186-3867b37362a2"
            alt=""
          />
          <img
            @click="$router.push('/subscribed')"
            v-else
            style="border-radius: 10px"
            src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/subscription%2FGo%20to%20WitBlox%20Prime%20-%20Subscription%20App%20Home%20Banner.gif?alt=media&token=9c81981b-2512-4dcd-bcf7-cb55c39cc76b"
            alt=""
          />
        </div>
      </div>
      <div class="columns">
        <div class="column" style="display: flex; justify-content: center">
          <div v-if="isEligibleForFreeClass()">
            <img
              @click="handleFreeClassOpen"
              style="border-radius: 10px"
              src="@/assets/img/free_class_banner.png"
              alt="..."
            />

            <p v-if="freeClass.opened" style="font-size: 1.1rem">
              Expires in:
              <b>{{ getDateDiff() }} days</b>
            </p>
          </div>

          <img
            v-else
            @click="$router.push('/mentors')"
            style="border-radius: 10px; border: 3px solid"
            src="@/assets/img/doubtclass.gif"
            alt="..."
          />
        </div>
      </div>
      <!-- /subscription-banner -->
      <!-- watch -->
      <section>
        <div class="columns" v-if="$store.state.trending.length > 2">
          <div class="column has-text-centered">
            <a class="home subtitle" @click="$router.push('/social')">
              <span
                class="trendingtitle"
                style="background: #5041e1; color: #fff"
              >
                <i class="fas fa-play"></i>
                Watch Trending Projects
              </span>
            </a>
          </div>
        </div>
        <div class="columns" v-if="$store.state.trending.length > 2">
          <div class="column userstories us1">
            <a
              class="storycontainer"
              v-for="post in $store.state.trending"
              :key="post.id"
            >
              <p class="p1">
                <b>{{ post.author }}</b>
              </p>
              <div
                :style="post.thumbnail"
                class="userphoto"
                @click="router('social/' + post.id)"
              ></div>
              <p>
                <i class="fas fa-eye"></i> {{ post.views }} &bull;
                {{ post.likes }} <i class="fas fa-heart"></i>
              </p>
            </a>
          </div>
        </div>
      </section>
      <!-- /watch -->

      <!-- Challenges -->
      <div class="columns services" style="margin-top: 2rem">
        <div class="column">
          <div class="card">
            <div class="card-content">
              <div class="column has-text-centered">
                <a class="home subtitle" @click="$router.push('/challenges')">
                  <span
                    class="trendingtitle"
                    style="background: #5041e1; color: #fff"
                  >
                    <i class="fas fa-dumbbell"></i>
                    Participate in Challenges
                  </span>
                </a>
              </div>
              <p>Ready to test your skills? Participate in our challenges.</p>
              <div class="scrollingwrapper">
                <a
                  @click="router('/challenge/' + challenge.id)"
                  class="scrolling-card"
                  :key="challenge.id"
                  v-for="challenge in $store.state.challenges"
                >
                  <v-lazy-image
                    :src-placeholder="require('@/assets/img/home-lazy.png')"
                    :src="challenge.featuredImg"
                    style="height: 150px !important"
                  />
                </a>
              </div>
              <button
                class="button is-fullwidth viewAllChallengesBtn"
                @click="$router.push('/challenges')"
              >
                <span>View All Challenges</span>
                <span class="icon">
                  <i class="fas fa-angle-right"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- /Challenges -->
      <hr />
      <!-- build -->
      <section class="underlined">
        <div class="columns">
          <div class="column has-text-centered">
            <h2 class="title has-text-centered">
              <i class="fas fa-crosshairs"></i>
              Build Your Own Project
            </h2>
          </div>
        </div>
        <div v-if="expanded" class="columns">
          <div class="column">
            <div
              class="newcategoryholder"
              v-for="card in $store.state.categories"
              :key="card.id"
            >
              <a class="newcategory" @click="router('category/' + card.id)">
                <h3 class="title">{{ card.name }}</h3>
                <p>{{ card.desc }}</p>
                <img :src="card.icon" alt="" />
                <span class="tag is-danger is-light" v-if="card.tag">{{
                  card.tag
                }}</span>
              </a>
            </div>
          </div>
        </div>
        <div v-else class="columns">
          <div class="column">
            <div
              class="newcategoryholder"
              v-for="card in $store.state.categories.slice(0, 4)"
              :key="card.id"
            >
              <a class="newcategory" @click="router('category/' + card.id)">
                <h3 class="title">{{ card.name }}</h3>
                <p>{{ card.desc }}</p>
                <img :src="card.icon" alt="" />
                <span
                  style="margin-top: 15px"
                  class="tag is-link is-light"
                  v-if="card.tag"
                  >{{ card.tag }}</span
                >
              </a>
            </div>
            <div class="newcategoryholderBtn has-text-centered">
              <a @click="expanded = true" class="btn"
                >Explore Other Categories &crarr;</a
              >
            </div>
          </div>
        </div>
        <div class="columns is-hidden">
          <div class="column">
            <div
              v-for="card in $store.state.categories"
              :key="card.id"
              class="categorycard"
            >
              <div @click="router('category/' + card.id)" class="card">
                <v-lazy-image
                  :src-placeholder="require('@/assets/img/category-card.png')"
                  :src="card.img"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="columns services">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <div @click="$router.push('/classes')">
                  <h3 class="title">
                    <img
                      src="https://witblox.com/assets/img/witblox.png"
                      alt=""
                    />
                    <a
                      class="has-text-centered"
                      style="background-color: #1a1a40"
                      >Classes</a
                    >
                  </h3>
                  <p>
                    Need help building your project? Take a class from an
                    expert.
                  </p>
                </div>
                <div class="scrollingwrapper">
                  <a
                    @click="router('/class/' + cls.id)"
                    class="scrolling-card"
                    :key="cls.id"
                    v-for="cls in $store.state.classes"
                  >
                    <v-lazy-image
                      :src-placeholder="require('@/assets/img/home-lazy.png')"
                      :src="cls.homeBanner"
                      style="height: 150px !important"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /build -->
      <hr />
      <!-- win -->
      <section class="underlined">
        <div class="columns">
          <div class="column has-text-centered" style="padding-bottom: 15px">
            <!-- <p class="home subtitle" style="margin-bottom: 0px">
              <span class="trendingtitle" style="margin-bottom: 0px">
                <i class="fas fa-medal"></i>
                Earn &amp; Win Prizes
              </span>
            </p> -->
            <!-- <h2 class="home subtitle trendingtitle" style="">
              <i class="fas fa-medal"></i>
              Earn &amp; Win Prizes
            </h2> -->
            <h2 class="title has-text-centered">
              <i class="fas fa-medal"></i>
              Earn &amp; Win Prizes
            </h2>
          </div>
        </div>
        <div class="scrollingwrapper prizecollection scrolling">
          <div
            class="prizeitem"
            @click="$router.push('/prizes')"
            v-for="prize in prizes"
            :key="prize.id"
          >
            <a class="pcard">
              <img :src="prize.newImage" alt="" />
              <button class="button is-large is-fullwidth has-text-left">
                <span class="icon">
                  <i class="fas fa-coins"></i>
                </span>
                <span>
                  <span style="font-size: 1rem"> Claim with </span>
                  <br />
                  <b>{{ numberWithCommas(prize.value) }}</b> WitCoins
                </span>
              </button>
            </a>
          </div>
        </div>
        <div class="columns services">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <h3 class="title">
                  <img
                    src="https://witblox.com/assets/img/witblox.png"
                    alt=""
                  />
                  <a class="has-text-centered" style="background-color: #7a0bc0"
                    >Quizzes</a
                  >
                </h3>
                <p>Solve the quizzes and earn WitCoins.</p>
                <div class="scrollingwrapper">
                  <a
                    @click="router('/quiz/' + quiz.id)"
                    class="scrolling-card"
                    :key="quiz.id"
                    v-for="quiz in $store.state.quizzes"
                  >
                    <v-lazy-image
                      :src-placeholder="require('@/assets/img/home-lazy.png')"
                      :src="quiz.image"
                      style="height: 150px !important"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns services">
          <div class="column">
            <div class="card">
              <div class="card-content">
                <h3 class="title">
                  <img
                    src="https://witblox.com/assets/img/witblox.png"
                    alt=""
                  />
                  <a class="has-text-centered" style="background-color: #fa58b6"
                    >Events</a
                  >
                </h3>
                <p>
                  Get latest updates, live sessions, and workshop info here.
                </p>
                <div class="scrollingwrapper">
                  <a
                    @click="$router.push('/event/' + event.id)"
                    class="scrolling-card"
                    :key="event.id"
                    v-for="event in $store.state.featuredEvents"
                  >
                    <v-lazy-image
                      :src-placeholder="require('@/assets/img/home-lazy.png')"
                      :src="event.featuredImg"
                      style="height: 150px !important"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- win -->
      <hr />
      <!-- categories -->
      <!-- <div class="categories">
        <div ref="categoryContainer"></div>
        <div
          v-for="card in $store.state.categories"
          :key="card.id"
          class="categorycard"
        >
          <div @click="router('category/' + card.id)" class="card">
            <v-lazy-image
              :src-placeholder="require('@/assets/img/category-card.png')"
              :src="card.img"
            />
          </div>
        </div>
      </div>
      <hr />
      <img
        class="homebanner1 is-hidden-tablet"
        src="@/assets/img/home/2.jpg"
        @click="router('/classes')"
      />
      <hr class="is-hidden-tablet" />
      <div class="columns" style="margin: 0 1px">
        <div class="column">
          <p class="home subtitle has-text-centered">
            <span class="trendingtitle">
              <i class="fas fa-atom"></i>
              Newly Added Projects
            </span>
          </p>
          <div class="scrollingwrapper">
            <a
              @click="router('/project/' + project.id)"
              class="scrolling-card"
              :key="project.id"
              v-for="project in $store.state.projects"
            >
              <v-lazy-image
                :src-placeholder="require('@/assets/img/home-lazy.png')"
                :src="project.img"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        class="columns"
        style="margin: 0 1px"
        v-if="shopPromotion.length > 1"
      >
        <div class="column">
          <p class="home subtitle has-text-centered">
            <span class="trendingtitle">
              <i class="fas fa-bahai"></i>
              New Bloxes
            </span>
          </p>
          <div class="scrollingwrapper">
            <a
              @click="router(card.route)"
              class="scrolling-card"
              :key="card.id"
              v-for="card in shopPromotion"
            >
              <v-lazy-image
                :src-placeholder="require('@/assets/img/home-lazy.png')"
                :src="card.banner"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="columns" style="margin: 0 1px">
        <div class="column">
          <p
            class="home subtitle has-text-centered"
            @click="$router.push('/classes')"
          >
            <span class="trendingtitle">
              <i class="fas fa-bahai"></i>
              Join WitBlox Classes
              <span class="is-hidden-touch">& Learn From Experts</span>
            </span>
          </p>
          <div class="scrollingwrapper">
            <a
              @click="router('/class/' + cls.id)"
              class="scrolling-card"
              :key="cls.id"
              v-for="cls in $store.state.classes"
            >
              <v-lazy-image
                :src-placeholder="require('@/assets/img/home-lazy.png')"
                :src="cls.homeBanner"
                style="height: 150px !important"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="columns" style="margin: 0 1px">
        <div class="column">
          <p class="home subtitle has-text-centered">
            <span class="trendingtitle">
              <i class="fas fa-bahai"></i>
              <span v-if="$store.state.eventsName">{{
                $store.state.eventsName
              }}</span>
              <span v-else>Upcoming Events</span>
            </span>
          </p>
          <div class="scrollingwrapper">
            <a
              @click="router('/event/' + event.id)"
              class="scrolling-card"
              :key="event.id"
              v-for="event in $store.state.events"
            >
              <v-lazy-image
                :src-placeholder="require('@/assets/img/home-lazy.png')"
                :src="event.img"
              />
            </a>
          </div>
        </div>
      </div>
      <hr /> -->
      <div class="columns">
        <a class="column">
          <img
            class="homebanner1"
            src="@/assets/img/home/prizes-zone.jpg"
            @click="router('/prizes')"
            style="margin-bottom: 15px"
          />
        </a>
        <a class="column">
          <img
            class="homebanner1"
            src="@/assets/img/home/invite-friends.jpg"
            @click="router('/referral')"
            style="margin-bottom: 15px"
          />
        </a>
        <a class="column">
          <img
            class="homebanner1"
            src="@/assets/img/home/warranty.jpg"
            @click="openWarranty()"
          />
        </a>
      </div>
      <div class="columns">
        <div class="column" style="padding-top: 50px"></div>
      </div>
    </div>
    <!-- cta -->
    <!-- // v-if="!newuser" -->
    <div class="uploadcta">
      <a
        style="background: #25d366"
        class="button is-right is-medium is-rounded is-hidden-touch"
        href="https://wa.me/919987213420?text=Hi,%20I%20am%20interested%20in%20witblox."
        target="blank"
      >
        <span class="icon">
          <svg
            style="color: #fff"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
            <path
              d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
            />
          </svg>
        </span>
      </a>
      <a
        class="button is-right is-medium is-rounded is-hidden-desktop"
        @click="router('upload/generic/newpost/20')"
      >
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </a>
      <a class="button two is-left is-medium is-rounded" href="https://chat.whatsapp.com/GAnvZAg2oMNJChbtQ11fQG" target="blank">
        <span class="icon">
          <i class="fas fa-users"></i>
        </span>
        <!-- <span class="reddot"></span> -->
        <span style="font-size:0.85rem">Join</span>
      </a>
    </div>
    <!-- cta -->
    <!-- fixed-top-message -->
    <a
      href="/product/FUPqjSYHqVhWcwvapbzN"
      class="hellobar is-hidden-touch"
      :style="{ 'background-color': textColor }"
    >
      <p :style="{ color: $store.state.selectedColor.c1 }">
        Are you a <b>parent?</b> Know more about WitBlox Kits &rarr;
      </p>
    </a>
    <!-- /fixed-top-message -->
  </div>
</template>
<script>
import Flickity from "vue-flickity";
//
import { mapGetters } from "vuex";
//
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { firestore } from "@/main.js";
import VLazyImage from "v-lazy-image";
import { Share } from "@capacitor/share";
import { SplashScreen } from "@capacitor/splash-screen";
//
import { Browser } from "@capacitor/browser";
// import { AdMob, InterstitialAdPluginEvents } from "@capacitor-community/admob";

//
export default {
  name: "home",
  metaInfo: {
    title: "No.1 Robotics Learning Community",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "WitBlox is a STEM learning platform that helps children learn about design, hardware, electronics, and programming. Witblox is a great way for children to learn about STEM concepts in a fun and engaging way.",
      },
    ],
  },
  components: {
    VLazyImage,
    Flickity,
  },
  data() {
    return {
      landing: false,
      image: "url(" + require("@/assets/img/user.png") + ")",
      message: "What would you like to build today?",
      uid: "",
      statLoader: false,
      stats: {},
      screen: "posts",
      color: "",
      textColor: "",
      platformNotif: {},
      shopPromotion: [],
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
      },
      flickityOptions2: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: true,
        wrapAround: true,
        autoPlay: true,
      },
      aha_moment_projects: [],
      aha_moment_projects_for_kit: [],
      votingData: [],
      //for new users
      newuser: false,
      newuserpost: {},
      categories: [],
      userstage: 0,
      prizes: [],
      haveKit: true,
      freeClass: {
        opened: false,
        openedAt: 0,
        expiresAt: 0,
      },
      expanded: false,
    };
  },
  methods: {
    async openWarranty() {
      await Browser.open({ url: "https://witblox.com/warranty" });
    },
    updateLanding() {
      console.log("works");
      this.profile.homeOpened = 5;
    },
    router(name) {
      if (name[0] == "/") this.$router.push(name);
      else this.$router.push("/" + name);
    },
    async shareDialog() {
      await Share.share({
        title: "Check my profile on WitBlox",
        text: "Hi, look at the projects on my personal website:",
        url: "https://witblox.com/profile/" + auth.currentUser.uid,
        dialogTitle: "Share with friends",
      });
    },
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
    trackNotif(id, route, type) {
      if (type != "userbased") {
        this.router(route);
        auth.onAuthStateChanged((user) => {
          db.collection("users")
            .doc(user.uid)
            .collection("tracking")
            .where("id", "==", id)
            .get()
            .then((snap1) => {
              if (snap1.empty) {
                db.collection("users")
                  .doc(user.uid)
                  .collection("tracking")
                  .add({
                    created: new Date().getTime(),
                    id: id,
                    type: "platformNotification",
                  });
              }
            });
        });
      }
    },
    techfestVote(obj) {
      obj.voted = true;
      this.votingData.forEach((data) => {
        if (obj.id != data.id) data.voted = false;
      });
      db.collection("config")
        .doc("techfest2")
        .collection("votes")
        .doc(auth.currentUser.uid)
        .set(
          {
            id: obj.id,
            updated: new Date().getTime(),
          },
          { merge: true }
        );
    },
    newuserflow(num) {
      if (num == 5) {
        //stage 0
        this.userstage = 1;
        this.$store.state.profile.newuser_stage = 1;
        db.collection("users").doc(auth.currentUser.uid).update({
          haveKit: this.haveKit,
        });
      } else if (num == 0) {
        //stage 1
        this.userstage = 5;
        this.$store.state.profile.newuser_stage = 5;
        this.categories.forEach((category) => {
          if (category.selected) {
            this.$store.state.profile.newuser_interests.push(category.name);
            db.collection("users")
              .doc(auth.currentUser.uid)
              .update({
                newuser_interests: firestore.FieldValue.arrayUnion(
                  category.name
                ),
                newuser_stage: 1,
              });
          }
        });
      } else if (num == 1) {
        //stage 2
        this.userstage = 2;
        this.newuser = false;
        this.$store.state.profile.newuser_stage = 2;
        db.collection("users").doc(auth.currentUser.uid).update({
          newuser_stage: 2,
          newuser_active: false,
        });
      } else if (num == 3) {
        //stage 4
        this.$refs["categoryContainer"].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        this.newuser = false;
        this.userstage = 4;
        this.$store.state.profile.newuser_stage = 4;
        this.$store.state.profile.newuser_active = false;
        db.collection("users").doc(auth.currentUser.uid).update({
          newuser_stage: 4,
          newuser_active: false,
        });
      }
    },
    fetchAhaProjects() {
      db.collection("config")
        .doc("homepage")
        .get()
        .then((config) => {
          console.log(config.data());
          this.aha_moment_projects = config.data().aha_moment;
          this.aha_moment_projects_for_kit =
            config.data().aha_moment_projects_for_kit;
          db.collection("config").doc("homepage").update({
            aha_moment_projects_for_kit: config.data().aha_moment,
          });
        });
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    handleFreeClassOpen() {
      Browser.open({ url: "https://topmate.io/witblox/204077" });
      if (this.freeClass.opened) return;

      const expireAfter = 30; // days
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + expireAfter);

      db.collection("users")
        .doc(auth.currentUser.uid)
        .update({
          freeClass: {
            opened: true,
            openedAt: new Date().getTime(),
            expiresAt: expiryDate.getTime(),
          },
        });
    },
    getDateDiff() {
      if (!this.freeClass) return -1;
      if (!this.freeClass.opened) return -1;

      const t2 = this.freeClass.expiresAt;
      const finalDiff = parseInt(
        (t2 - new Date().getTime()) / (24 * 3600 * 1000)
      );

      return finalDiff;
    },
    isEligibleForFreeClass() {
      let isEligible = false;

      if (this.haveKit) {
        isEligible = true;
      } else {
        isEligible = false;
      }

      if (this.getDateDiff() > 0) {
        isEligible = true;
      } else {
        isEligible = false;
      }

      return isEligible;
    },
    // async initAdMob() {
    //   const { status } = await AdMob.trackingAuthorizationStatus();

    //   if (status === "notDetermined") {
    //     /**
    //      * If you want to explain TrackingAuthorization before showing the iOS dialog,
    //      * you can show the modal here.
    //      **/
    //   }

    //   await AdMob.initialize({
    //     requestTrackingAuthorization: true,
    //     testingDevices: ["13808048-6bfe-4fa6-b605-245764836284"],
    //     initializeForTesting: true,
    //   });

    //   AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info) => {
    //     console.log("interstitial ad loaded", JSON.stringify(info));
    //   });

    //   AdMob.addListener(InterstitialAdPluginEvents.FailedToLoad, (info) => {
    //     console.log("interstitial ad failed to load", JSON.stringify(info));
    //   });

    //   AdMob.addListener(InterstitialAdPluginEvents.Showed, (info) => {
    //     console.log("interstitial ad showed", JSON.stringify(info));
    //   });

    //   AdMob.addListener(InterstitialAdPluginEvents.FailedToShow, (info) => {
    //     console.log("interstitial FailedToShow", JSON.stringify(info));
    //   });

    //   await AdMob.prepareInterstitial({
    //     adId: "ca-app-pub-4823763639393449/5669099119",
    //     isTesting: true,
    //     npa: true,
    //   });

    //   await AdMob.showInterstitial();
    // },
  },
  beforeMount() {
    this.$store.commit("getProfile");
    this.fetchAhaProjects();
    // auth.onAuthStateChanged((currentUser) => {
    //   if (currentUser) {
    //     this.uid = currentUser.uid;
    //     db.collection("users")
    //       .doc(currentUser.uid)
    //       .get()
    //       .then((user) => {
    //         console.log(user.data());
    //         console.log(user.id, ":", user.data().homeOpened, ":", 3);
    //         if (user.data().homeOpened) {
    //           if (user.data().homeOpened > 3) this.landing = false;
    //           else this.landing = true;
    //         } else this.landing = true;
    //       });
    //   }
    // });
    this.getColor();
    db.collection("config")
      .doc("bloxes")
      .collection("all")
      .orderBy("created", "desc")
      .get()
      .then((bloxes) => {
        bloxes.forEach((blox) => {
          var bloxObj = blox.data();
          bloxObj.id = blox.id;
          bloxObj.route = "/trailer/" + blox.id;
          this.shopPromotion.push(bloxObj);
        });
      });
    db.collection("config")
      .doc("prizes")
      .collection("items")
      .orderBy("order")
      .get()
      .then((prizes) => {
        prizes.forEach((prize) => {
          var prizeObj = prize.data();
          prizeObj.id = prize.id;
          this.prizes.push(prizeObj);
        });
      });

    db.collection("users")
      .doc(auth.currentUser.uid)
      .get()
      .then((doc) => {
        if (doc.data().haveKit === undefined) {
          db.collection("users")
            .doc(auth.currentUser.uid)
            .update({
              haveKit: true,
              freeClass: {
                opened: false,
                openedAt: 0,
                expiresAt: 0,
              },
            });
          this.haveKit = true;
        } else {
          this.freeClass = doc.data().freeClass;
          this.haveKit = doc.data().haveKit;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    db.collection("config")
      .doc("homepage")
      .collection("notifications")
      .orderBy("created", "desc")
      .limit(1)
      .get()
      .then((snap) => {
        db.collection("users")
          .doc(auth.currentUser.uid)
          .collection("tracking")
          .where("id", "==", snap.docs[0].id)
          .get()
          .then((snap1) => {
            if (snap1.empty) {
              //not seen platform notification
              this.platformNotif = snap.docs[0].data();
              this.platformNotif.id = snap.docs[0].id;
            } else {
              db.collection("users")
                .doc(auth.currentUser.uid)
                .collection("notifications")
                .where("seen", "==", false)
                .orderBy("created", "desc")
                .limit(1)
                .get()
                .then((snap2) => {
                  if (snap2.empty) {
                    //all userbased notification seen
                    this.platformNotif = snap.docs[0].data();
                    this.platformNotif.id = snap.docs[0].id;
                  } else {
                    //latest unseen userbased notification
                    this.platformNotif = {
                      text: snap2.docs[0].data().text,
                      route: snap2.docs[0].data().link,
                      img:
                        "background: url(" +
                        snap2.docs[0].data().img +
                        "),linear-gradient(to right, #8e2de2, #4a00e0)",

                      type: "userbased",
                    };
                    this.platformNotif.id = snap.docs[0].id;
                  }
                });
            }
          });
      });
  },
  mounted() {
    SplashScreen.hide();
    // this.initAdMob();
    // if(!this.$store.state.homeopened) console.log(this.$store.state.premium);
    if (
      this.$store.state.homeopened == false ||
      this.$store.state.homeopened == undefined
    )
      this.$store.state.homeopened = true;
    if (this.$route.params.new == "new") {
      this.fetchAhaProjects();
      if (this.$store.state.profile.newuser_active) {
        this.userstage = this.$store.state.profile.newuser_stage;
        db.collection("users")
          .doc(auth.currentUser.uid)
          .update({
            newuser_cardseen: firestore.FieldValue.increment(1),
          });
      } else {
        db.collection("users")
          .doc(auth.currentUser.uid)
          .update({
            newuser_active: true,
            newuser_cardseen: 1,
            newuser_stage: 0,
            newuser_firstupload: false,
            newuser_firstuploadid: "",
            newuser_interests: [],
          })
          .then(() => {
            this.$store.state.profile.newuser_active = true;
            this.$store.state.profile.newuser_cardseen = 1;
            this.$store.state.profile.newuser_stage = 0;
            this.$store.state.profile.newuser_firstupload = false;
            this.$store.state.profile.newuser_firstuploadid = "";
            this.$store.state.profile.newuser_interests = [];
          });
      }
      db.collection("category")
        .get()
        .then((categories) => {
          categories.forEach((category) => {
            var categoryObj = category.data();
            categoryObj.id = category.id;
            categoryObj.selected = false;
            this.categories.push(categoryObj);
          });
        });
      db.collection("config").doc();
      if (this.$store.state.profile.newuser_stage == 3) {
        db.collecton("social")
          .doc(this.$store.state.profile.newuser_firstuploadid)
          .get()
          .then((post) => {
            console.log(
              "new user post:",
              post,
              this.$store.state.profile.newuser_firstuploadid
            );
            if (post.exists) {
              console.log(post.data());
            }
          });
      }
      this.newuser = true;
    } else {
      var i = 0;
      var x = setInterval(() => {
        i++;
        if (this.$store.state.profile.newuser_active) {
          clearInterval(x);
          this.fetchAhaProjects();
          this.userstage = this.$store.state.profile.newuser_stage;
          if (this.userstage == 3) {
            db.collection("social")
              .doc(this.$store.state.profile.newuser_firstuploadid)
              .get()
              .then((post) => {
                var hours =
                  Math.abs(new Date() - new Date(post.data().created)) / 36e5;
                if (hours > 5) {
                  var postData = post.data();
                  postData.id = post.id;
                  postData.likes = 0;
                  postData.witcoins = 20;
                  postData.hours = Math.round(hours);
                  this.newuserpost = postData;
                  this.newuser = true;
                  db.collection("social")
                    .doc(this.$store.state.profile.newuser_firstuploadid)
                    .collection("likes")
                    .get()
                    .then((likes) => {
                      this.newuserpost.likes = likes.size;
                    });
                  db.collection("social")
                    .doc(this.$store.state.profile.newuser_firstuploadid)
                    .collection("rating")
                    .get()
                    .then((ratings) => {
                      ratings.forEach((rating) => {
                        this.newuserpost.witcoins =
                          this.newuserpost.witcoins + rating.data().score;
                      });
                    });
                }
              });
          } else if (this.userstage == 0) {
            db.collection("category")
              .get()
              .then((categories) => {
                categories.forEach((category) => {
                  var categoryObj = category.data();
                  categoryObj.id = category.id;
                  categoryObj.selected = false;
                  this.categories.push(categoryObj);
                });
                this.newuser = true;
              });
          } else this.newuser = true;
          db.collection("users")
            .doc(auth.currentUser.uid)
            .update({
              newuser_cardseen: firestore.FieldValue.increment(1),
            });
        } else {
          if (i > 30) clearInterval(x);
        }
      }, 100);
    }
    db.collection("users")
      .doc(auth.currentUser.uid)
      .update({
        homeOpened: firestore.FieldValue.increment(1),
      });
    /*
        db.collection("config")
          .doc("techfest2")
          .collection("votes")
          .doc(auth.currentUser.uid)
          .get()
          .then((vote) => {
            db.collection("config")
              .doc("techfest2")
              .get()
              .then((doc) => {
                if (doc.data().avotesenabled) this.votingData = doc.data().avotes;
                if (vote.exists) {
                  this.votingData.forEach((data) => {
                    if (vote.data().id == data.id) data.voted = true;
                    else data.voted = false;
                  });
                }
              });
          });
          */
  },
  computed: {
    ...mapGetters({
      profile: "profile",
    }),
  },
  /*
    beforeRouteEnter(to, from, next) {
      next(() => {
      });
    },
    */
};
</script>
<style scoped>
.scrolling-card img {
  height: 200px;
  border-radius: 10px;
}

.homenotif .column {
  display: flex;
  align-items: center;
  justify-content: left;
}

.userimg {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  border: 3px solid #ffff;
  display: inline-block;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/profile%2Fd4ae4c4ced1234134c5d?alt=media&token=c1f8ce0d-cb76-48eb-8898-a1bc6c070864);
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.promocard1 {
  margin: 0 10px;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 14%) 0px 24px 38px 3px,
    rgb(0 0 0 / 12%) 0px 9px 46px 8px, rgb(0 0 0 / 20%) 0px 11px 15px -7px;
}

.promocard1 img {
  border-radius: 20px;
}

.grand-challenge {
  padding: 0 4px;
  background: #0000;
}

.grand-challenge .card {
  box-shadow: none;
}

.voting.scrollingwrapper .scrolling-card {
  display: grid;
}

.voting .scrolling-card a.btn {
  margin: 10px 0;
  background: #5041e1;
  color: #fff;
  font-weight: 500;
}

.voting .scrolling-card a.btn.voted {
  margin: 10px 0;
  background: #7e70ff;
  color: #fff;
  font-weight: 500;
}

.uploadcta {
  position: fixed;
  bottom: 0;
  margin-bottom: 80px;
  width: 100%;
  /* text-align: right; */
  z-index: 11111;
  /* padding: 0 35px; */
}

.uploadcta .button {
  box-shadow: rgb(0 0 0 / 14%) 0px 24px 38px 3px,
    rgb(0 0 0 / 12%) 0px 9px 46px 8px, rgb(0 0 0 / 20%) 0px 11px 15px -7px;
  background: #5041e1;
  color: #fff;
  /* border: 3px solid; */
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.button.two {
  background: #25D366;
}

.uploadcta .button.is-left {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 20px 20px 0;
}

.uploadcta .button.is-right {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 20px 0 0 20px;
}

.reddot {
  background: red;
  color: red;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
}

@media only screen and (min-width: 600px) {
  .categories {
    max-width: 75%;
    margin: auto;
  }
}

section.underlined {
  /* border: 3.5px solid #aaaaaa; */
  border-radius: 20px;
  padding: 10px 30px 40px 30px;
  /* background: rgb(255, 255, 255, 0.8); */
  box-shadow: rgb(50 50 93 / 25%) 0px 5px 50px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px -2px 6px 0px inset;
}

section.underlined h2.title {
  font-size: 1.5rem;
  padding-top: 25px;
  padding-bottom: 0px;
  color: #000;
}

.card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.homepageFeaturedVideo {
  width: 100%;
  height: 350px;
  border-radius: 15px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background: #fff;
}

.hellobar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.85);
  text-align: center;
  padding: 3px 20%;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  display: block;
}

.hellobar p {
  color: #fff;
}

.mobile .grand-challenge .card-content {
  background-size: cover !important;
}

.newcategory .tag {
  margin-top: 8px;
}

.newcategoryholderBtn {
  margin: 25px;
}

.newcategoryholderBtn a.btn {
  background: rgb(233 239 255 / 70%);
  border: 3px solid rgb(195, 211, 255);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  display: inline;
}

.viewAllChallengesBtn {
  margin-top: 1rem;
}
</style>