<template>
  <div>
    <section class="section">
      <div class="container">
        <h1 class="title is-4 has-text-centered">Upcoming Boxes</h1>
        <div class="columns is-mobile">
          <div class="column">
            <div class="buttons" style="justify-content: center">
              <a class="button" @click="$router.push('/subscription_payments')">
                Payments
              </a>
              <a class="button" @click="cancelSub"> Want to cancel? </a>
            </div>
          </div>
        </div>
        <div class="card" v-for="order in orders" :key="order.id">
          <div class="card-content">
            <div class="columns">
              <div class="column is-3">
                <img :src="order.box.image" alt="" />
              </div>
              <div class="column">
                <h1 class="title is-5">
                  {{ order.box.name }}
                </h1>
                <article class="message is-link">
                  <div class="message-body">
                    <h3
                      v-if="order.due > new Date().getTime()"
                      class="title is-6"
                    >
                      Your box will be shipped on
                      {{ new Date(order.due).toDateString() }}.
                    </h3>
                    <h3
                      v-if="
                        order.due < new Date().getTime() &&
                        order.dispatchDate == 0
                      "
                      class="title is-6"
                    >
                      We are preparing your box for shipment.
                    </h3>
                    <h3
                      v-if="
                        order.due < new Date().getTime() &&
                        order.dispatchDate > 0
                      "
                      class="title is-6"
                    >
                      Your box is on the way.
                    </h3>
                  </div>
                </article>
                <div
                  v-if="
                    order.due < new Date().getTime() && order.dispatchDate > 0
                  "
                >
                  <hr />
                  <a :href="order.tracking.link" target="blank" class="button">
                    Track Your Order
                  </a>
                </div>
                <div v-if="order.delivered">
                  <hr />
                  <a
                    @click="$router.push('/task/' + order.id)"
                    target="blank"
                    class="button"
                  >
                    Tasks
                  </a>
                </div>
              </div>
            </div>
            <!-- {{ order }} -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import VLazyImage from "v-lazy-image";
import { functions } from "@/main.js";
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { mapGetters } from "vuex";
import { Dialog } from "@capacitor/dialog";
export default {
  name: "subscription_orders",
  mounted() {},
  components: {
    // VLazyImage,
  },
  data() {
    return {
      user: {},
      orders: [],
    };
  },
  methods: {
    async cancelSub() {
      let confirmRet = await Dialog.confirm({
        title: "Confirm",
        message: "Are you sure you want to cancel your subscription?",
      });
      if (confirmRet.value) {
        var cancelSubscription = functions.httpsCallable("cancelSubscription");
        cancelSubscription({
          subscrption: "",
        }).then((result) => {
          console.log(result);
          this.profile.premium = false;
          this.$router.push("/");
        });
      }
    },
  },
  beforeMount() {
    db.collection("users")
      .doc(auth.currentUser.uid)
      .get()
      .then((user) => {
        this.user = user.data();
        this.user.id = user.id;
        if (user.data().premium) {
          db.collection("subscriptionorders")
            .where("uid", "==", auth.currentUser.uid)
            .orderBy("due", "desc")
            .get()
            .then((orders) => {
              orders.forEach((order) => {
                var orderObj = order.data();
                orderObj.id = order.id;
                console.log(orderObj);
                db.collection("subscriptionbox")
                  .doc(orderObj.boxId)
                  .get()
                  .then((box) => {
                    orderObj.box = box.data();
                    this.orders.push(orderObj);
                  });
              });
            });
        }
      });
  },
  computed: {
    ...mapGetters({
      profile: "profile",
    }),
  },
};
</script>
<style scoped>
section.section {
  padding-bottom: 100px;
}
.card {
  margin-bottom: 20px;
  border-radius: 15px;
}
.card img {
  border-radius: 10px;
}
a.button {
  color: #000;
  border: 3px solid;
  font-weight: 700;
}
</style>