<template>
  <section style="padding-bottom: 40px" :style="color">
    <div class="profile2">
      <div class="columns has-text-centered">
        <div class="column" style="margin-bottom: 0px; padding-bottom: 0px">
          <div class="columns is-mobile">
            <div class="column has-text-left">
              <h3 class="subtitle badge" style="margin-bottom: 0px">
                <img
                  src="@/assets/img/coin.svg"
                  style="width: 17px; margin-right: 5px"
                />
                <span id="coins" :style="textColor">{{ coins }}</span>
              </h3>
              <!-- <a
                @click="$router.push('/leaderboard')"
                style="margin-left: 3px"
                class="edit"
              >
                <i class="fas fa-trophy"></i>
              </a> -->
            </div>
            <div class="column has-text-right">
              <a @click="report()" style="margin-left: 3px" class="edit">
                <i class="fas fa-exclamation-triangle"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card profileinfocard">
            <div class="card-content">
              <div class="column imagecontainer">
                <div
                  id="profileImg"
                  class="profileImage"
                  :style="picture"
                ></div>
                <!--<img id="profileImg" src="img/user.png" class="profileImage">-->
                <!-- <div class="ranking">
                  <span id="ranking">
                    <img src="@/assets/img/crown.png" class="crown" />
                    <span id="position">#{{ rank }}</span>
                  </span>
                </div> -->
                <img src="@/assets/img/medal.png" alt id="medal" />
              </div>
              <div class="column">
                <h1 class="title">
                  <span id="name" class="has-text-centered">
                    {{ name }}
                  </span>
                  <img
                    v-if="verified"
                    src="@/assets/img/verified.png"
                    style="width: 21px; margin-left: 5px"
                  />
                </h1>
                <h6 id="bio" class="subtitle">
                  {{ bio }}
                </h6>
              </div>
              <div class="column">
                <div class="card profilecard">
                  <div class="columns is-mobile has-text-centered">
                    <div
                      @click="screen = 'posts'"
                      class="column"
                      v-bind:class="{ 'is-active': screen === 'posts' }"
                    >
                      <p v-if="posts > -1" class="numbering">
                        {{ format(posts) }}
                      </p>
                      <p v-else class="numbering">
                        {{ 0 }}
                      </p>
                      <!-- <span
                        v-else
                        class="preload dark"
                        style="width: 100%"
                      ></span> -->
                      <p class="naming">Posts</p>
                    </div>
                    <div
                      @click="screen = 'followers'"
                      id="followers-btn"
                      class="column"
                      v-bind:class="{ 'is-active': screen === 'followers' }"
                    >
                      <p class="numbering">
                        {{ format(followers) }}
                      </p>
                      <!-- <span
                        v-else
                        class="preload dark"
                        style="width: 100%"
                      ></span> -->
                      <p class="naming">Followers</p>
                    </div>
                    <div
                      @click="screen = 'following'"
                      id="following-btn"
                      class="column"
                      v-bind:class="{ 'is-active': screen === 'following' }"
                    >
                      <p class="numbering">
                        {{ format(following) }}
                      </p>
                      <!-- <span
                        v-else
                        class="preload dark"
                        style="width: 100%"
                      ></span> -->
                      <p class="naming">Following</p>
                    </div>
                    <div
                      @click="screen = 'completed'"
                      id="projects-btn"
                      class="column"
                      v-bind:class="{ 'is-active': screen === 'completed' }"
                    >
                      <p v-if="completed > -1" class="numbering">
                        {{ format(completed) }}
                      </p>
                      <p v-else class="numbering">0</p>
                      <!-- <span
                        v-else
                        class="preload dark"
                        style="width: 100%"
                      ></span> -->
                      <p class="naming">Projects</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="column imagecontainer">
          <div id="profileImg" class="profileImage" :style="picture"></div>
          <img id="profileImg" src="img/user.png" class="profileImage">
          <div class="ranking">
            <span id="ranking">
              <img src="@/assets/img/crown.png" class="crown" />
              <span id="position">#{{ rank }}</span>
            </span>
          </div>
          <img src="@/assets/img/medal.png" alt id="medal" />
        </div> -->
        <!-- <div class="column">
          <h1 class="title">
            <span id="name" class="has-text-centered">{{ name }}</span>
            <img
              v-if="verified"
              src="@/assets/img/verified.png"
              style="width: 21px; margin-left: 5px"
            />
          </h1>
          <h6 id="bio" class="subtitle">{{ bio }}</h6>
        </div> -->
        <!-- <div class="column">
          <button
            @click="unfollowUser($route.params.id)"
            v-if="followed"
            class="profilefollow button following"
          >
            <i class="fas fa-check"></i>
          </button>
          <button
            @click="followUser($route.params.id)"
            v-else
            class="profilefollow button follow"
          >
            <i class="fas fa-user-plus"></i>
          </button>
        </div>
        <div class="column">
          <div class="card profilecard">
            <div class="columns is-mobile has-text-centered">
              <div
                @click="screen = 'posts'"
                class="column"
                v-bind:class="{ 'is-active': screen === 'posts' }"
              >
                <span
                  v-if="statLoader"
                  class="preload dark"
                  style="width: 100%"
                ></span>
                <p v-else class="numbering">{{ posts }}</p>
                <p class="naming">Posts</p>
              </div>
              <div
                @click="screen = 'followers'"
                id="followers-btn"
                class="column"
                v-bind:class="{ 'is-active': screen === 'followers' }"
              >
                <span
                  v-if="statLoader"
                  class="preload dark"
                  style="width: 100%"
                ></span>
                <p v-else class="numbering">{{ followers }}</p>
                <p class="naming">Followers</p>
              </div>
              <div
                @click="screen = 'following'"
                id="following-btn"
                class="column"
                v-bind:class="{ 'is-active': screen === 'following' }"
              >
                <span
                  v-if="statLoader"
                  class="preload dark"
                  style="width: 100%"
                ></span>
                <p v-else class="numbering">{{ following }}</p>
                <p class="naming">Following</p>
              </div>
              <div
                @click="screen = 'completed'"
                id="projects-btn"
                class="column"
                v-bind:class="{ 'is-active': screen === 'completed' }"
              >
                <span
                  v-if="statLoader"
                  class="preload dark"
                  style="width: 100%"
                ></span>
                <p v-else class="numbering">{{ completed }}</p>
                <p class="naming">Completed</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div v-if="blocked" class="hero">
      <div style="padding: 40px 35px" class="hero-body has-text-centered">
        <h6 class="subtitle">You have blocked this user.</h6>
        <a class="button is-fullwidth" @click="unblock">Unblock</a>
      </div>
    </div>
    <div v-else class="hero">
      <div class="hero-body profileinfo">
        <div v-if="screen === 'posts'" class="columns">
          <div class="column">
            <div class="columns is-mobile">
              <div class="column">
                <h1 class="title">Posts</h1>
              </div>
              <div class="column is-8 has-text-right">
                <button
                  style="margin-top: 4.5px"
                  class="profilefollow button"
                  @click="shareDialog()"
                >
                  <!-- <i class="fas fa-share-alt"></i> -->
                  <span class="icon">
                    <i class="fas fa-share-alt"></i>
                  </span>
                  <span>Share Profile</span>
                </button>

                <button
                  @click="unfollowUser($route.params.id)"
                  v-if="followed"
                  class="profilefollow button following"
                >
                  <i class="fas fa-check"></i>
                </button>
                <button
                  @click="followUser($route.params.id)"
                  v-else
                  class="profilefollow button follow"
                >
                  <i class="fas fa-user-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div v-if="allposts.length != 0" class="column" style="font-size: 0">
            <div v-for="post in allposts" :key="post.id" class="postcard">
              <v-lazy-image
                :src-placeholder="require('@/assets/img/thumbnail.png')"
                :src="post.thumbnail"
                @error="post.thumbnail = require('@/assets/img/thumbnail.png')"
              />
              <p @click="$router.push('/social/' + post.id)">
                <i class="fas fa-eye"></i>
                <span>{{ post.views }}</span>
                <br />
                <i class="fas fa-star"></i>
                <span>{{ post.rating }}</span>
              </p>
            </div>
          </div>
          <div v-else class="card">
            <div class="card-content">
              <img
                style="padding: 10px 100px"
                src="@/assets/img/albums-outline.svg"
                alt
              />
              <h3
                style="padding-bottom: 45px"
                class="has-text-centered subtitle"
              >
                No Posts Yet
              </h3>
            </div>
          </div>
        </div>
        <div v-else-if="screen === 'followers'" class="columns">
          <div class="column">
            <div class="columns is-mobile">
              <div class="column">
                <h1 class="title">Followers</h1>
              </div>
              <div class="column is-3 has-text-right">
                <button
                  @click="unfollowUser($route.params.id)"
                  v-if="followed"
                  class="profilefollow button following"
                >
                  <i class="fas fa-check"></i>
                </button>
                <button
                  @click="followUser($route.params.id)"
                  v-else
                  class="profilefollow button follow"
                >
                  <i class="fas fa-user-plus"></i>
                </button>
              </div>
            </div>

            <div v-if="allfollowers.length != 0" class="card list">
              <div class="card-content">
                <div
                  v-for="(follower, index) in allfollowers"
                  :key="follower.id + index"
                  class="columns is-mobile"
                >
                  <div class="column">
                    <h6
                      @click="$router.push('/u/' + follower.id), restart()"
                      class="subtitle"
                    >
                      {{ follower.name }}
                    </h6>
                  </div>
                  <div class="column is-3 has-text-right">
                    <button
                      @click="unfollowUser(follower.id, follower)"
                      v-if="follower.following"
                      class="button following"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                    <button
                      @click="followUser(follower.id, follower)"
                      v-else
                      class="button follow"
                    >
                      <i class="fas fa-user-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card">
              <div class="card-content">
                <img
                  style="padding: 10px 100px"
                  src="@/assets/img/walk-outline.svg"
                  alt
                />
                <h3
                  style="padding-bottom: 45px"
                  class="has-text-centered subtitle"
                >
                  No Followers Yet
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="screen === 'following'" class="columns">
          <div class="column">
            <div class="columns is-mobile">
              <div class="column">
                <h1 class="title">Following</h1>
              </div>
              <div class="column is-3 has-text-right">
                <button
                  @click="unfollowUser($route.params.id)"
                  v-if="followed"
                  class="profilefollow button following"
                >
                  <i class="fas fa-check"></i>
                </button>
                <button
                  @click="followUser($route.params.id)"
                  v-else
                  class="profilefollow button follow"
                >
                  <i class="fas fa-user-plus"></i>
                </button>
              </div>
            </div>
            <div v-if="allfollowing.length != 0" class="card list">
              <div class="card-content">
                <div
                  v-for="(following, index) in allfollowing"
                  :key="following.id + index"
                  class="columns is-mobile"
                >
                  <div class="column">
                    <h6
                      @click="$router.push('/u/' + following.id), restart()"
                      class="subtitle"
                    >
                      {{ following.name }}
                    </h6>
                  </div>
                  <div class="column is-3 has-text-right">
                    <button
                      @click="unfollowUser(following.id, following)"
                      v-if="following.following"
                      class="button following"
                    >
                      <i class="fas fa-check"></i>
                    </button>
                    <button
                      @click="followUser(following.id, following)"
                      v-else
                      class="button follow"
                    >
                      <i class="fas fa-user-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="card">
              <div class="card-content">
                <img
                  style="padding: 10px 100px"
                  src="@/assets/img/walk-outline.svg"
                  alt
                />
                <h3
                  style="padding-bottom: 45px"
                  class="has-text-centered subtitle"
                >
                  No Following Yet
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="screen === 'completed'" class="columns">
          <div class="column">
            <div class="columns is-mobile">
              <div class="column">
                <h1 class="title">Projects</h1>
              </div>
              <div class="column is-3 has-text-right">
                <button
                  @click="unfollowUser($route.params.id)"
                  v-if="followed"
                  class="profilefollow button following"
                >
                  <i class="fas fa-check"></i>
                </button>
                <button
                  @click="followUser($route.params.id)"
                  v-else
                  class="profilefollow button follow"
                >
                  <i class="fas fa-user-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div v-if="allcompleted.length != 0" class="column">
            <div class="completedImages">
              <img
                v-for="project in allcompleted"
                :key="project.id"
                @click="projectRoute(project.type, project.id)"
                :src="project.image"
                class="completedproject lazy"
              />
            </div>
          </div>
          <div v-else class="card">
            <div class="card-content">
              <img
                style="padding: 10px 100px"
                src="@/assets/img/school-outline.svg"
                alt
              />
              <h3
                style="padding-bottom: 45px"
                class="has-text-centered subtitle"
              >
                No Projects Yet
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { auth } from "@/main.js";
import { functions } from "@/main.js";
import { db } from "@/main.js";
import { firestore } from "@/main.js";
// import { Plugins } from "@capacitor/core";
import { Dialog } from "@capacitor/dialog";
import VLazyImage from "v-lazy-image";
// import { Webengage } from "@ionic-native/webengage";
import { Share } from "@capacitor/share";
import { createWebengageEvent } from "../helper";

export default {
  name: "user",
  components: {
    VLazyImage,
  },
  data() {
    return {
      screen: "posts",
      name: "",
      bio: "",
      picture: "",
      coins: 0,
      rank: 0,
      posts: 0,
      followers: 0,
      following: 0,
      completed: 0,
      statLoader: true,
      verified: false,
      followed: false,
      allposts: [],
      allfollowers: [],
      allfollowing: [],
      allcompleted: [],
      blocked: false,
      color: "",
      textColor: "",
      userId: "",
    };
  },
  methods: {
    async logout() {
      let confirmRet = await Dialog.confirm({
        title: "Confirm",
        message: "Are you sure you want to logout?",
      });
      if (confirmRet.value) {
        this.$router.push("/login");
        auth.signOut().then((log) => {
          console.log(log);
        });
      }
    },
    arraySum(arr) {
      return arr.reduce(function (a, b) {
        return a + b;
      }, 0);
    },
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
    async report() {
      let confirmRet = await Dialog.confirm({
        title: "Confirm",
        message:
          "Are you sure you want to report & block this person for violating community guidelines?",
      });
      if (confirmRet.value) {
        this.blocked = true;
        db.collection("users")
          .doc(auth.currentUser.uid)
          .update({
            blockedUser: firestore.FieldValue.arrayUnion(this.$route.params.id),
          });
        var reportPerson = functions.httpsCallable("reportPerson");
        reportPerson({
          uid: this.$route.params.id,
          admin: this.$store.state.profile.verified,
        }).then(async () => {
          console.log("report function executed");
        });
        this.$router.back();
        await Dialog.alert({
          title: "Done",
          message: "We're reviewing the person you reported.",
        });
      }
    },
    unblock() {
      this.blocked = false;
      db.collection("users")
        .doc(auth.currentUser.uid)
        .update({
          blockedUser: firestore.FieldValue.arrayRemove(this.$route.params.id),
        });
    },
    async deletePost(pid, index) {
      let confirmRet = await Dialog.confirm({
        title: "Confirm",
        message: "Are you sure you'd like to delete this post?",
      });
      if (confirmRet.value) {
        db.collection("social")
          .doc(pid)
          .delete()
          .then(async () => {
            this.allposts.splice(index, 1);
            await Dialog.alert({
              title: "Done",
              message: "We've deleted your selected post.",
            });
          });
      }
    },
    async repost(pid) {
      let confirmRet = await Dialog.confirm({
        title: "Confirm",
        message: "Are you sure you'd like to repost?",
      });
      if (confirmRet.value) {
        var repostVideo = functions.httpsCallable("repostVideo");
        repostVideo({ postid: pid }).then(async () => {
          await Dialog.alert({
            title: "Done",
            message: "We've reposted your video.",
          });
        });
      }
    },
    fetchPosts(uid) {
      if (!this.$store.state.profile.verified) {
        db.collection("social")
          .where("uid", "==", uid)
          .where("clearance", "==", true)
          .orderBy("created", "desc")
          .limit(50)
          .get()
          .then((snap) => {
            if (!snap.empty) {
              snap.forEach((doc) => {
                db.collection("social")
                  .doc(doc.id)
                  .collection("rating")
                  .get()
                  .then((snap) => {
                    var ratings = [];
                    var rating;
                    snap.forEach((doc) => {
                      ratings.push(doc.data().score);
                    });
                    var avgrating = this.arraySum(ratings) / ratings.length;
                    if (!avgrating) rating = 5;
                    else rating = avgrating.toFixed(1);
                    this.allposts.push({
                      id: doc.id,
                      thumbnail: doc.data().thumbnail,
                      views: doc.data().views,
                      rating: rating,
                    });
                  });
              });
            }
          });
      } else {
        db.collection("social")
          .where("uid", "==", uid)
          .orderBy("created", "desc")
          .limit(50)
          .get()
          .then((snap) => {
            if (!snap.empty) {
              snap.forEach((doc) => {
                db.collection("social")
                  .doc(doc.id)
                  .collection("rating")
                  .get()
                  .then((snap) => {
                    var ratings = [];
                    var rating;
                    snap.forEach((doc) => {
                      ratings.push(doc.data().score);
                    });
                    var avgrating = this.arraySum(ratings) / ratings.length;
                    if (!avgrating) rating = 5;
                    else rating = avgrating.toFixed(1);
                    this.allposts.push({
                      id: doc.id,
                      thumbnail: doc.data().thumbnail,
                      views: doc.data().views,
                      rating: rating,
                    });
                  });
              });
            }
          });
      }
    },
    followUser(toUID, user) {
      if (user) user.following = true;
      else this.followed = true;
      var followUser = functions.httpsCallable("followUser");
      followUser({
        toUID: toUID,
      }).then((result) => {
        createWebengageEvent({
          uid: this.userId,
          eventName: "Followed User",
          eventData: {
            added: new Date().getTime(),
            readableDate: new Date().toLocaleString(),
            user: toUID,
          },
        });
        if (result.data.code == 200) {
          console.log("success");
        } else if (result.data.code == 300) {
          console.log("error", result.data.error);
        }
      });
    },
    unfollowUser(toUID, user) {
      if (user) user.following = false;
      else this.followed = false;
      var unfollowUser = functions.httpsCallable("unfollowUser");
      unfollowUser({
        toUID: toUID,
      }).then((result) => {
        createWebengageEvent({
          uid: this.userId,
          eventName: "Unfollowed User",
          eventData: {
            added: new Date().getTime(),
            readableDate: new Date().toLocaleString(),
            user: toUID,
          },
        });
        if (result.data.code == 200) {
          console.log("success");
        } else if (result.data.code == 300) {
          console.log("error", result.data.error);
        }
      });
    },
    fetchFollowers(uid) {
      db.collection("users")
        .doc(uid)
        .collection("followers")
        .orderBy("created", "desc")
        .limit(50)
        .get()
        .then((snap) => {
          snap.forEach((user) => {
            db.collection("users")
              .doc(user.data().uid)
              .get()
              .then((doc) => {
                db.collection("users")
                  .doc(uid)
                  .collection("following")
                  .where("uid", "==", user.data().uid)
                  .get()
                  .then((snap) => {
                    var following = !snap.empty;
                    this.allfollowers.push({
                      id: user.data().uid,
                      name: doc.data().fname + " " + doc.data().lname,
                      following: following,
                    });
                  });
              });
          });
        });
    },
    fetchFollowing(uid) {
      db.collection("users")
        .doc(uid)
        .collection("following")
        .orderBy("created", "desc")
        .limit(50)
        .get()
        .then((snap) => {
          snap.forEach((user) => {
            db.collection("users")
              .doc(user.data().uid)
              .get()
              .then((doc) => {
                db.collection("users")
                  .doc(uid)
                  .collection("following")
                  .where("uid", "==", user.data().uid)
                  .get()
                  .then((snap) => {
                    var following = !snap.empty;
                    this.allfollowing.push({
                      id: user.data().uid,
                      name: doc.data().fname + " " + doc.data().lname,
                      following: following,
                    });
                  });
              });
          });
        });
    },
    fetchProjects(uid) {
      db.collection("users")
        .doc(uid)
        .collection("projects")
        .orderBy("created", "desc")
        .limit(30)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            if (doc.data().type == "challenge") {
              db.collection("challenges")
                .doc(doc.data().pid)
                .get()
                .then((doc2) => {
                  if (doc2.exists) {
                    this.allcompleted.push({
                      id: doc2.id,
                      image: doc2.data().featuredImg,
                      type: doc.data().type,
                    });
                  }
                });
            } else if (doc.data().type == "story") {
              db.collection("projects")
                .doc(doc.data().pid)
                .get()
                .then((doc2) => {
                  if (doc2.exists) {
                    this.allcompleted.push({
                      id: doc2.id,
                      image: doc2.data().featuredImg,
                      type: doc.data().type,
                    });
                  }
                });
            }
          });
        });
    },
    async projectRoute(type, id) {
      if (type == "challenge") this.$router.push("challenge/" + id);
      else if (type == "story") this.$router.push("project/" + id);
    },
    format(value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
      }
      return newValue;
    },
    restart() {
      this.statLoader = true;
      this.picture = "";
      this.allposts = [];
      this.allfollowers = [];
      this.allfollowing = [];
      this.allcompleted = [];
      //if following
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((user) => {
            if (user.data().blockedUser) {
              user.data().blockedUser.forEach((block) => {
                if (this.$route.params.id == block) this.blocked = true;
              });
            }
          });
        db.collection("users")
          .doc(user.uid)
          .collection("following")
          .where("uid", "==", this.$route.params.id)
          .get()
          .then((snap) => {
            this.followed = !snap.empty;
          });
      });
      //fetch profile details
      db.collection("users")
        .doc(this.$route.params.id)
        .get()
        .then((user) => {
          this.name = user.data().fname + " " + user.data().lname;
          this.bio = user.data().bio;
          this.rank = user.data().ranking;
          this.verified = user.data().verified;
          this.posts = this.format(user.data().posts);
          this.completed = this.format(user.data().projects);
          this.followers = this.format(user.data().followers);
          this.following = this.format(user.data().following);
          if (user.data().picture)
            this.picture = "background: url(" + user.data().picture + ")";
        });
      //fetch profile stats
      var profileStats = functions.httpsCallable("profileStats");
      profileStats({ uid: this.$route.params.id }).then((result) => {
        // console.log(result.data);
        // this.posts = this.format(result.data.posts);
        // this.completed = this.format(result.data.completed);
        // this.followers = this.format(result.data.followers);
        // this.following = this.format(result.data.following);
        if (result.data.coins < 0) this.coins = 0;
        else this.coins = this.format(result.data.coins);
        this.statLoader = false;
      });
      //fetch posts
      this.fetchPosts(this.$route.params.id);
      //fetch followers
      this.fetchFollowers(this.$route.params.id);
      //fetch following
      this.fetchFollowing(this.$route.params.id);
      //fetch completed projects
      this.fetchProjects(this.$route.params.id);
    },
    async shareDialog() {
      await Share.share({
        title: "Check out this maker on WitBlox",
        text: "Checkout this amazing projects & achievements on WitBlox.",
        url: "https://witblox.com/profile/" + this.$route.params.id,
        dialogTitle: "Share with friends",
      });
    },
  },
  beforeMount() {
    this.userId = auth.currentUser.uid;
    this.restart();
    this.getColor();
  },
  mounted() {
    createWebengageEvent({
      uid: this.userId,
      eventName: "Profile Viewed",
      eventData: {
        added: new Date().getTime(),
        readableDate: new Date().toLocaleString(),
        profile: this.$route.params.id,
      },
    });
  },
};
</script>
<style>
.ranking {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  text-align: center;
}
#ranking {
  background: #ffffff;
  padding: 5px 12px 4px 12px;
  border-radius: 7px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.crown {
  width: 16px;
  margin-right: 5px;
}
.postcard a {
  display: block;
  color: #6b43e1;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 8px 0 0;
  border-radius: 50%;
  border: 1px solid;
  background: #ffffff;
  height: 25px;
  width: 25px;
  line-height: 25px;
  font-size: 0.8rem;
  text-align: center;
}
.profileinfo .button {
  width: 100%;
  border-radius: 35px;
  margin: 2px 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  margin-right: 15px;
}
.profileinfo .button.following {
  background: #5a3e90;
  color: #ffffff;
}
.profileinfo .button.follow {
  background: #ffffff;
  color: #5a3e90;
}
.profilefollow.follow {
  background: #ffff;
  color: #8d2de2;
  border: 3px solid #ffff;
  border-radius: 25px;
}
.profilefollow.following {
  background: #8d2de2;
  color: #ffff;
  border: 3px solid;
  border-radius: 25px;
}
</style>