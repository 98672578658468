<template>
  <div class="hero">
    <div class="hero-body">
      <div class="columns is-mobile">
        <div class="column">
          <h1 class="title has-text-centered">
            <i class="fas fa-tools"></i>
            Orders
          </h1>
          <div
            class="card depth order-item"
            v-for="order in orders"
            :key="order.id"
            @click="router('/order/' + order.id)"
          >
            <div class="card-content">
              <div class="columns is-mobile">
                <div class="column is-3">
                  <img :src="order.img" alt="" />
                </div>
                <div class="column">
                  <h3 class="subtitle">{{ order.title }}</h3>
                  <p class="">{{ order.date }}</p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="orders.length === 0" class="empty-cart">
            <img src="~@/assets/img/empty-basket.png" alt="Empty Basket" style="height: 200px;" />
            
            <h1 class="subtitle">Your cart is empty!</h1>
            <a href="/shop" class="shop-now-btn btn">
              Shop Now
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { auth } from "@/main.js";
// import { functions } from "@/main.js";
import { db } from "@/main.js";
export default {
  name: "shoporders",
  data() {
    return {
      orders: [],
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
  },
  beforeMount() {
    auth.onAuthStateChanged((user) => {
      db.collection("shop-orders")
        .where("uid", "==", user.uid)
        .orderBy("added", "desc")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            this.orders.push({
              id: doc.id,
              title: doc.data().title,
              date: new Date(doc.data().added),
              img: doc.data().products[0].img,
            });
          });
        });
    });
  },
  mounted() {},
};
</script>
<style scoped>
.order-item {
  margin-bottom: 15px;
  border-radius: 20px;
}
.order-item .subtitle {
  margin-bottom: 10px;
}
.order-item p {
  font-weight: 500;
  font-size: 0.85rem;
}

.empty-cart{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.empty-cart .subtitle{
  margin-top: 0.5rem;
}

.shop-now-btn{
  font-size: 1.4rem;
  background: #b56a85;
  color: white;
  padding: 1rem 5rem;
  border-radius: 10rem;
  margin-top: 2rem;
  max-width: fit-content;
}
</style>