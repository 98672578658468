<template>
  <section class="section">
    <div class="columns is-mobile">
      <div class="column">
        <h1 class="title">Warranty Registration</h1>
      </div>
    </div>
    <div v-if="registered" class="columns">
      <div class="column"></div>
    </div>
    <div v-else class="columns">
      <div class="column"></div>
    </div>
  </section>
</template>
<script>
import { db } from "@/main.js";
import { auth } from "@/main.js";
// import { functions } from "@/main.js";
export default {
  name: "warranty",
  data() {
    return {
      id: "",
      registered: false,
      gender: "",
      address: "",
      city: "",
      pincode: 0,
      purchase_date: 0,
      purchase_location: "",
      btnLoading: false,
      err: "",
    };
  },
  methods: {
    format(value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
      }
      return newValue;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    register() {
      this.btnLoading = true;
      if (
        this.gender &&
        this.address &&
        this.city &&
        this.pincode &&
        this.purchase_date &&
        this.purchase_location
      ) {
        db.collection("warranty")
          .doc(auth.currentUser.uid)
          .set(
            {
              uid: auth.currentUser.uid,
              created: new Date().getTime(),
              gender: this.gender,
              address: this.address,
              city: this.city,
              pincode: this.pincode,
              purchase_date: this.purchase_date,
              purchase_location: this.purchase_location,
            },
            { merge: true }
          )
          .then((registration) => {
            this.btnLoading = false;
            this.id = registration.id;
            this.registered = true;
          });
      } else {
        this.btnLoading = false;
        this.err = "Please fill the form properly";
      }
    },
  },
  beforeMount() {
    db.collection("warranty")
      .doc(auth.currentUser.uid)
      .get()
      .then((warranty) => {
        console.log("Warranty", warranty);
        if (warranty.exists) this.registered = true;
      });
  },
  mounted() {
  },
};
</script>
<style scoped></style>