<template>
    <div class="homescreen" :style="color">
        <!-- profile-header -->
        <div class="homeprofile">
            <div class="homeheader columns is-mobile">
                <div class="column is-3">
                    <a @click="router('/profile')">
                        <div v-bind:style="{ 'background-image': image }" v-if="profile.picture === ''"
                            class="homeprofileimg"></div>
                        <div :style="profile.picture" v-else class="homeprofileimg"></div>
                    </a>
                </div>
                <div class="column is-7">
                    <h6 class="home title" :style="textColor">
                        <span>
                            <span>Hello</span>
                            <img src="../assets/img/hi.png" width="28px" />
                        </span>
                    </h6>
                </div>
                <div class="column is-2">
                    <a @click="router('/search')" class="searchIcon">
                        <i class="fas fa-search"></i>
                    </a>
                </div>
            </div>
        </div>
        <!-- /profile-header -->
        <main class="container">
            <h1 class="title is-4 has-text-centered is-white">
                What's new
            </h1>
            <div class="columns is-multiline">
                <!-- Card 1 -->
                <div class="column is-4" v-for="campaign in campaigns" :key="campaign.id"
                    @click="$router.push('/crowdfunding/' + campaign.id)">
                    <div class="card">
                        <div class="card-image">
                            <figure class="image is-4by3">
                                <img src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/crowdfunding%2Fimages%2FKBC%20Match%20Announcements_1.png?alt=media&token=bb9e8b3b-d0a5-4864-b613-6e20210abe56"
                                    alt="Featured Image 1">
                            </figure>
                            <h2 class="title is-4 is-hidden">{{ campaign.title }}</h2>
                        </div>
                        <div class="card-content">
                            <h2 class="title is-4">{{ campaign.title }}</h2>
                            <progress class="progress is-small is-warning" :value="(campaign.current / campaign.goal) * 100"
                                max="100">{{ (campaign.current /
                                    campaign.goal) *
                                    100 }}%</progress>
                            <p>
                                <strong>&#8377;{{ addCommaToNumber(campaign.current) }}</strong> of <strong>&#8377;{{
                                addCommaToNumber(campaign.goal)
                            }}</strong> raised via <b>27</b> donors
                            </p>
                        </div>
                        <div class="card-footer">
                            <a class="button is-primary is-fullwidth">Donate</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <img @click="startCampaign" src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/attachments%2FStart%20your%20own%20crowdfunding%20project%20today%20(4).png?alt=media&token=256b89a8-5c3b-4d04-b6ad-17c4d3c89200"
                        alt="">
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import { db } from "@/main.js";
import { auth } from "@/main.js";
export default {
    data() {
        return {
            current: 1000,
            total: 2000,
            campaigns: [],
            color: "",
            textColor: "",
            profile: {
                fname: "",
                picture: ""
            },
            image: "url(" + require("@/assets/img/user.png") + ")"
        }
    },
    methods: {
        addCommaToNumber(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        getColor() {
            this.$store.commit("getColorInfo");
            this.color =
                "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
                this.$store.state.selectedColor.c1 +
                " 100%);";
            this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
        },
        startCampaign() {
            if (auth.currentUser == null) {
                this.$router.push("/login");
                return;
            }
            db.collection("crowdfunding").add({
                about: "",
                budget: [],
                current: 0,
                goal: 0,
                images: [],
                items: [],
                live: false,
                pdf: {
                    link: "",
                    name: "",
                },
                team: [auth.currentUser.uid],
                title: "",
                video: ""
            }).then((doc) => {
                this.$router.push("/crowdfunding_upload/" + doc.id);
            });
        }
    },
    beforeMount() {
        this.getColor();
        // Fetch data from Firebase
        db.collection("crowdfunding").where("live", "==", true).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                var campaign = doc.data();
                campaign.id = doc.id;
                this.campaigns.push(campaign);
            });
        });
    }
}
</script>
<style scoped>
.container {
    padding: 30px 20px;
    padding-bottom: 80px;
}

.columns.is-multiline {}

.card-image {
    position: relative;
}

.card-image .title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
}

.card-footer {
    padding: 10px 20px;
}
</style>