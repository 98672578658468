<template>
  <section class="sction homescreen" :style="color">
    <div class="homeprofile">
      <div class="homeheader columns is-mobile">
        <div class="column is-3">
          <a @click="$router.push('/profile')">
            <div
              v-bind:style="{ 'background-image': image }"
              v-if="profile.picture === ''"
              class="homeprofileimg"
            ></div>
            <div :style="profile.picture" v-else class="homeprofileimg"></div>
          </a>
        </div>
        <div class="column is-7">
          <h6 class="home title" :style="textColor">
            Hi
            <!-- <span>
              <span
                v-if="profile.fname === ''"
                class="preload"
                style="width: 100px"
              ></span>
              <span v-else>{{ profile.fname }}</span>
            </span> -->
            <span> Parents </span>
            <img src="../assets/img/hi.png" width="28px" />
          </h6>
        </div>
        <div class="column is-2">
          <a @click="$router.push('/search')" class="searchIcon">
            <i class="fas fa-search"></i>
          </a>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h2 class="title is-3 has-text-centered" style="margin-bottom: 6px">
            Introducing
          </h2>
          <h1 class="title is-2 has-text-centered">WitBlox Prime</h1>
          <!-- <img
            style="margin-bottom: 40px; border: 5px solid; border-radius: 15px"
            src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/subscription%2Fwitblox-prime.jpeg?alt=media&token=cc0f0082-f906-4dc5-b83a-333449dcbc5e"
            alt=""
          /> -->
          <div class="has-text-centered featuredImage">
            <img
              src="https://emojipedia-us.s3.amazonaws.com/source/skype/289/family-man-woman-girl-boy_1f468-200d-1f469-200d-1f467-200d-1f466.png"
              alt=""
            />
          </div>
          <h3 class="title is-4 has-text-centered">
            India's No.1 Subscription Box for Young Innovators
          </h3>
          <div class="has-text-centered">
            <h4 class="title is-5">Unbox &bull; Play &bull; Learn</h4>
          </div>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <!-- <video-player
            v-if="vid.loaded"
            class="vjs-custom-skin"
            ref="videoPlayer"
            :options="vid"
            :playsinline="true"
          ></video-player> -->
          <video
            v-if="featuredVideo"
            class="featuredVideo"
            autoplay
            muted
            loop
            controls
          >
            <source type="video/mp4" :src="featuredVideo" />
          </video>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <h3 class="title is-4 has-text-centered">
            <img
              src="https://emojipedia-us.s3.amazonaws.com/source/microsoft-teams/337/person-shrugging_1f937.png"
              alt=""
              class="emoji"
            />
            How it works
          </h3>
          <div class="box first listpadding" style="background: #6c49f6; color: #fff">
            <ul>
              <li>Get a surprise box delivered at your home</li>
              <li>All materials to build projects present in the box</li>
              <li>Special tasks to nurture your observation skills</li>
              <li>Build projects & perform tasks to win 2X WitCoins</li>
              <li>Complete 40 projects to achieve DIY Maker Certificate</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h3 class="title is-4 has-text-centered">
            <img
              src="https://emojipedia-us.s3.amazonaws.com/source/skype/289/wrapped-gift_1f381.png"
              alt=""
              class="emoji"
            />
            What you get in a box?
          </h3>
          <div
            class="box first has-text-centered"
            style="background: #3bb0a9; color: #fff"
          >
            <ul class="list1">
              <li>x1 Robotics project</li>
              <li>x1 DIY Challenge</li>
              <li>x1 Origami Project</li>
              <li>x1 LIVE Discussion Session</li>
              <li>Interesting Concept to Learn</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h3 class="title is-4 has-text-centered">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/subscription%2Fa1_delivery%2Fsubscription-robot.png?alt=media&token=17cb35c9-c593-4aab-aaa6-cb3557c9de6b"
              alt=""
              class="emoji"
            />
            Skills your kid will learn
          </h3>
          <div class="box first" style="background: #3bb776; color: #fff">
            <div class="columns is-mobile">
              <div class="column">
                <ul>
                  <li>Creativity</li>
                  <li>Design</li>
                  <li>Logic</li>
                  <li>Electronics</li>
                </ul>
              </div>
              <div class="column">
                <ul>
                  <li>Robotics</li>
                  <li>Craft</li>
                  <li>Innovation</li>
                  <li>Science</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="columns reviews">
        <div class="column">
          <h3 class="title is-4 has-text-centered">
            <img
              src="https://emojipedia-us.s3.amazonaws.com/source/skype/289/family_1f46a.png"
              alt=""
              class="emoji"
            />
            Trusted by 10K+ parents
          </h3>
          <flickity ref="flickity" :options="flickityOptions">
            <div class="columns" style="width: 100%; padding: 0 10%">
              <div class="column">
                <div class="card r1">
                  <div class="card-content">
                    <p class="title">
                      “I am an engineer myself but never have i imagined the
                      subject to be such fun.. My kids loved it.”
                    </p>
                    <img
                      class="review"
                      src="https://witblox.com/assets/reviews/rosh.png"
                    />
                    <p style="display: inline; padding-bottom: 15px">by Rosh</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns" style="width: 100%; padding: 0 10%">
              <div class="column">
                <div class="card r2">
                  <div class="card-content">
                    <p class="title">
                      “I bought this kit to teach robotics to a rural kids camp,
                      had great fun, it was much easier to engage & educate
                      robotics than earlier.”
                    </p>
                    <img
                      class="review"
                      src="https://witblox.com/assets/reviews/vidhi.png"
                    />
                    <p style="display: inline; padding-bottom: 15px">
                      by Vidhi
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns" style="width: 100%; padding: 0 10%">
              <div class="column">
                <div class="card r3">
                  <div class="card-content">
                    <p class="title">
                      “This kit is an excellent example of teaching kids
                      innovative solutions through robotics.”
                    </p>
                    <img
                      class="review"
                      src="https://witblox.com/assets/reviews/shuvro.png"
                    />
                    <p style="display: inline; padding-bottom: 15px">
                      by Shuvro
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </flickity>
        </div>
      </div>
      <section class="hero project grid-bg">
        <div class="hero-body">
          <h2 class="subtitle has-text-centered">
            Kids are making amazing things with
            <span class="underline">Witblox</span>.
          </h2>
          <flickity
            ref="flickity"
            :options="flickityOptions"
            style="padding-bottom: 30px"
          >
            <div class="columns" style="width: 100%">
              <div class="column" style="padding: 0 10%">
                <a class="card c1">
                  <div class="card-image">
                    <video
                      poster="assets/compressed/lazy.jpg"
                      autoplay=""
                      loop=""
                      muted=""
                      class="lazy loaded"
                      data-src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fline-follower.mp4?alt=media&amp;token=cc97ae92-9972-48f2-8769-8bf444996e24"
                      src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fline-follower.mp4?alt=media&amp;token=cc97ae92-9972-48f2-8769-8bf444996e24"
                      data-was-processed="true"
                    >
                      <source
                        type="video/mp4"
                        data-src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fline-follower.mp4?alt=media&amp;token=cc97ae92-9972-48f2-8769-8bf444996e24"
                        src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fline-follower.mp4?alt=media&amp;token=cc97ae92-9972-48f2-8769-8bf444996e24"
                      />
                    </video>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-5">
                          Line Follower Robot made by Kunal
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="columns" style="width: 100%">
              <div class="column" style="padding: 0 10%">
                <a class="card c2">
                  <div class="card-image">
                    <video
                      poster="assets/compressed/lazy.jpg"
                      autoplay=""
                      loop=""
                      muted=""
                      class="lazy loaded"
                      data-src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fair-hockey.mp4?alt=media&amp;token=58593e25-a88b-4086-9239-08f7716bff77"
                      src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fair-hockey.mp4?alt=media&amp;token=58593e25-a88b-4086-9239-08f7716bff77"
                      data-was-processed="true"
                    >
                      <source
                        type="video/mp4"
                        data-src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fair-hockey.mp4?alt=media&amp;token=58593e25-a88b-4086-9239-08f7716bff77"
                        src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fair-hockey.mp4?alt=media&amp;token=58593e25-a88b-4086-9239-08f7716bff77"
                      />
                    </video>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-5">
                          Sameer &amp; Friends playing Air Hockey
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="columns" style="width: 100%">
              <div class="column" style="padding: 0 10%">
                <a class="card c3">
                  <div class="card-image">
                    <video
                      poster="assets/compressed/lazy.jpg"
                      autoplay=""
                      loop=""
                      muted=""
                      class="lazy loaded"
                      data-src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fpage-turner.mp4?alt=media&amp;token=a5e661d8-ca49-4cdf-a14f-dcac306d9e77"
                      src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fpage-turner.mp4?alt=media&amp;token=a5e661d8-ca49-4cdf-a14f-dcac306d9e77"
                      data-was-processed="true"
                    >
                      <source
                        type="video/mp4"
                        data-src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fpage-turner.mp4?alt=media&amp;token=a5e661d8-ca49-4cdf-a14f-dcac306d9e77"
                        src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Fpage-turner.mp4?alt=media&amp;token=a5e661d8-ca49-4cdf-a14f-dcac306d9e77"
                      />
                    </video>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-5">
                          Ishita built a Page Turner for her teacher
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="columns" style="width: 100%">
              <div class="column" style="padding: 0 10%">
                <a class="card c4">
                  <div class="card-image">
                    <video
                      poster="assets/compressed/lazy.jpg"
                      autoplay=""
                      loop=""
                      muted=""
                      class="lazy loaded"
                      data-src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Frobotic-arm.mp4?alt=media&amp;token=40c68947-9f35-4994-bafc-a72d4750fdd8"
                      src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Frobotic-arm.mp4?alt=media&amp;token=40c68947-9f35-4994-bafc-a72d4750fdd8"
                      data-was-processed="true"
                    >
                      <source
                        type="video/mp4"
                        data-src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Frobotic-arm.mp4?alt=media&amp;token=40c68947-9f35-4994-bafc-a72d4750fdd8"
                        src="https://firebasestorage.googleapis.com/v0/b/witbloxapp.appspot.com/o/hosting%2Frobotic-arm.mp4?alt=media&amp;token=40c68947-9f35-4994-bafc-a72d4750fdd8"
                      />
                    </video>
                  </div>
                  <div class="card-content">
                    <div class="media">
                      <div class="media-content">
                        <p class="title is-5">
                          Iron Man Hand designed by Avnish
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </flickity>
        </div>
      </section>
      <!-- cta -->
      <div class="scta">
        <button class="btn btn-theme" @click="$router.push('/plans')">
          Select a plan
        </button>
      </div>
      <!-- /cta -->
    </div>
  </section>
</template>
<script>
// import VLazyImage from "v-lazy-image";
import { mapGetters } from "vuex";
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { functions } from "@/main.js";
import "@/assets/css/video-js.css";
// import { videoPlayer } from "vue-video-player";
import Flickity from "vue-flickity";
export default {
  name: "subscription",
  mounted() {
    console.log(auth.currentUser.uid);
    // this.vid.loaded = true;
  },
  components: {
    // videoPlayer,
    Flickity,
  },
  data() {
    return {
      user: {},
      image: "url(" + require("@/assets/img/user.png") + ")",
      color: "",
      textColor: "",
      btnLoading1: false,
      btnLoading2: false,
      btnLoading3: false,
      btnLoading4: false,
      plans: [
        "plan_JUI7qpqOO0DrFN",
        "plan_JUI9QGtaRVZaVg",
        "plan_JUIACnpUFaCUfz",
        "plan_JUIBQ64NrpVCs2",
      ],
      error: "",
      vid: {
        height: "300px",
        width: "100%",
        autoplay: true,
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: require("@/assets/img/home-lazy.png"),
        loaded: false,
      },
      featuredVideo: "",
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
        // any options from Flickity can be used
      },
    };
  },
  beforeMount() {
    // if (this.$store.state.profile.premium) this.$router.push("/subscribed");
    this.getColor();
    db.collection("users")
      .doc(auth.currentUser.uid)
      .get()
      .then((user) => {
        this.user = user.data();
        this.user.id = user.id;
        // if (user.data().premium) this.$router.push("/subscribed");
      });
    db.collection("config")
      .doc("subscription")
      .get()
      .then((config) => {
        this.featuredVideo = config.data().featuredVideo;
        this.vid.sources = [
          {
            src: config.data().featuredVideo,
            type: "",
          },
        ];
        this.vid.loaded = true;
      });
  },
  computed: {
    ...mapGetters({
      profile: "profile",
    }),
  },
  methods: {
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
    subscribe(plan, this_btn) {
      console.log(this_btn);
      this_btn = true;
      this.error = "";
      var createSubscription = functions.httpsCallable("createSubscription");
      createSubscription({
        plan: plan,
        address: "",
      }).then((result) => {
        console.log(result);
        window.open(result.data.short_url, "_blank");
        this_btn = false;
        /*
        var options = {
          key: "rzp_test_1GopdgS8Pwn6IJ",
          subscription_id: result.data.id,
          name: "WitBlox",
          description: "Start your subscription",
          prefill: {
            name: auth.currentUser.displayName,
            email: auth.currentUser.email,
          },
          handler: function (response) {
            if (response.razorpay_payment_id) {
              //payment successful
              this.error = "";
              this.user.premium = true;
              db.collection("users").doc(auth.currentUser.uid).update({
                premium: true,
              });
            } else {
              this.error = "Payment failed due to an error";
            }
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        btns[this.plans.indexOf(plan) + 1] = false;
        */
      });
    },
  },
};
</script>
<style scoped>
.sction {
  padding-bottom: 80px;
}
.box {
  border-radius: 20px;
}
ul {
  list-style-type: circle;
  padding-left: 30px;
  font-size: 1rem;
  font-weight: 600;
}
</style>
<style>
.videosection,
.video-container,
.video-player,
.vjs_video_3-dimensions,
.video-js {
  width: 100%;
  height: 300px;
}
.scta {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 50px 60px 50px;
}
.grid-bg {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  font-family: Poppins, sans-serif;
  box-sizing: inherit;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4"%3E%3Cpath fill="%239C92AC" fill-opacity="0.4" d="M1 3h1v1H1V3zm2-2h1v1H3V1z"%3E%3C/path%3E%3C/svg%3E');
}

@media only screen and (max-width: 992px) {
  .project .container {
    width: 95%;
  }
}

.project .container {
  width: 80%;
}

.project .card {
  border-radius: 12px;
  display: block;
}
.c1 {
  /* transform: rotate(4deg); */
  background-color: rgb(56, 161, 78);
}
.c2 {
  /* transform: rotate(-2deg); */
  background-color: rgb(189, 17, 78);
}
@media only screen and (max-width: 450px) {
  .project .title,
  .reviews .card .title {
    font-size: 1.25rem;
  }
}
.project .title,
.reviews .card .title {
  font-size: 1rem;
}
.grid-bg .title {
  padding-bottom: 25px;
}
.project .title {
  color: rgb(255, 255, 255);
}
.c3 {
  /* transform: rotate(2deg); */
  background-color: rgb(237, 127, 30);
}
.c4 {
  /* transform: rotate(4deg); */
  background-color: rgb(114, 51, 137);
}
.reviews .card {
  border-radius: 10px;
}
.r1 {
  background: rgb(189, 17, 78);
  /* transform: rotate(3deg); */
}
.r2 {
  background: rgb(237, 127, 30);
  /* transform: rotate(-2deg); */
}
.r3 {
  background: rgb(114, 51, 137);
  /* transform: rotate(2deg); */
}
.reviews .card img {
  width: 45px;
  margin-right: 5px;
  border-radius: 50%;
}

.project .title,
.reviews .card .title {
  font-size: 1rem;
}

.reviews .card .title {
  color: rgb(255, 255, 255);
}

.reviews .card p {
  color: rgb(255, 255, 255);
}
ul.list1 {
  list-style-type: "✓";
}

img.emoji {
  width: 25px;
  /* animation: shake 1s; */

  /* When the animation is finished, start again */
  /* animation-iteration-count: infinite; */
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

video.featuredVideo {
  width: 100%;
  height: 300px;
  /* object-fit: cover; */
}

.featuredImage {
  margin-bottom: 20px;
  /* border: 5px solid;
  border-radius: 15px; */
  padding-top: 25px;
  /* background: #fff; */
}

.has-text-centered .title.is-5 {
  border: 4px solid #6b48ff;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  padding: 5px 15px;
}

.listpadding ul li {
    padding-top: 10px
}
</style>