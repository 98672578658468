<template>
  <section class="hero" style="margin-bottom: 40px">
    <div class="hero-body">
      <h1 class="title has-text-centered">Edit</h1>
      <div class="card" style="margin-bottom: 15px">
        <div class="card-content">
          <div class="columns">
            <div class="column">
              <progress
                class="progress"
                v-bind:class="{ 'is-hidden': progress === 0 }"
                :value="progress"
                max="100"
              ></progress>
              <article v-if="message" class="message is-primary">
                <div class="message-body">{{ message }}</div>
              </article>
              <div class="field">
                <div class="file has-name is-fullwidth">
                  <label class="file-label">
                    <input
                      @change="loadPicture"
                      class="file-input"
                      type="file"
                      name="resume"
                      accept="image/png, image/jpeg"
                    />
                    <span class="file-cta">
                      <span class="file-icon">
                        <i class="fas fa-upload"></i>
                      </span>
                      <span class="file-label">Profile Picture</span>
                    </span>
                    <span class="file-name">{{ fileName }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <div class="field">
                <div class="control">
                  <input
                    v-model="fname"
                    maxlength="10"
                    class="input"
                    type="text"
                    placeholder="First Name"
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <div class="control">
                  <input
                    v-model="lname"
                    maxlength="10"
                    class="input"
                    type="text"
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <div class="control">
                  <input
                    v-on:keyup="countdown"
                    v-model="bio"
                    maxlength="40"
                    class="input"
                    type="text"
                    placeholder="Your Bio"
                  />
                </div>
                <p style="padding: 4px" class="count">
                  <span id="count">{{ remainingCount }}</span
                  >/40
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div class="columns">
            <div class="column">
              <div class="field">
                <div class="control">
                  <input
                    v-model="email"
                    class="input"
                    type="text"
                    placeholder="E-mail"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <div class="control">
                  <input
                    v-model="phone"
                    class="input"
                    type="text"
                    placeholder="Phone Number"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column has-text-centered">
              <button
                @click="update()"
                class="button btn btn-theme"
                v-bind:class="{ 'is-loading': btnLoading }"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="columns">
            <div class="column has-text-centered">
              <button
                @click="deleteAccount()"
                class="button secondary-btn"
                v-bind:class="{ 'is-loading': btnLoading }"
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card" style="margin-bottom: 40px">
        <div class="card-content">
          <div class="columns">
            <div class="column">
              <a class="button is-fullwidth" @click="openTnC"
                >Terms &amp; Conditions</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { auth } from "@/main.js";
import { db } from "@/main.js";
import { storage } from "@/main.js";
import { Browser } from "@capacitor/browser";

export default {
  name: "edit",
  data() {
    return {
      fileName: "",
      fname: "",
      lname: "",
      bio: "",
      email: "",
      phone: "",
      btnLoading: false,
      maxCount: 40,
      remainingCount: 40,
      progress: 0,
      message: "",
    };
  },
  methods: {
    openTnC() {
      Browser.open({
        url: "https://techacks.notion.site/Terms-Conditions-2d91baffc67948eea95886222f1d1e66",
      });
    },
    loadPicture(event) {
      this.fileName = event.target.files[0].name;
      var size = event.target.files[0].size / 1024 / 1024;
      if (size < 5) {
        var storageRef = storage.ref();
        var uploadTask = storageRef
          .child("profile/" + this.randomStr(20, "12345abcde"))
          .put(event.target.files[0]);
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            this.message = "";
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            this.progress = progress;
          },
          function (error) {
            this.message = error;
            console.log(error);
          },
          function () {
            this.message = "";
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at", downloadURL);
              auth.onAuthStateChanged((user) => {
                db.collection("users")
                  .doc(user.uid)
                  .update({
                    picture: downloadURL,
                  })
                  .then(() => {
                    user
                      .updateProfile({
                        photoURL: downloadURL,
                      })
                      .then(() => {
                        this.progress = 0;
                        this.$store.commit("getProfileStats");
                      });
                  });
              });
            });
          }
        );
      } else this.message = "Please upload an image smaller than 5MB";
    },
    update() {
      this.btnLoading = true;
      if ((this.fname, this.lname, this.bio)) {
        auth.onAuthStateChanged((user) => {
          this.message = "";
          db.collection("users")
            .doc(user.uid)
            .update({
              fname: this.fname,
              lname: this.lname,
              bio: this.bio,
            })
            .then(() => {
              user
                .updateProfile({
                  displayName: this.fname,
                })
                .then(() => {
                  this.message = "Profile updated successfully.";
                  this.btnLoading = false;
                });
            });
        });
      } else {
        this.btnLoading = false;
        this.message = "Please fill the form properly";
      }
    },
    async deleteAccount() {
      const isConfirmed = window.confirm(
        "Are you sure you want to delete your account? This action is irreversible."
      );

      if (!isConfirmed) return;

      this.btnLoading = true;
      const userId = auth.currentUser.uid;

      await db.collection("users").doc(userId).delete();

      auth.currentUser.delete().then(() => {
        this.btnLoading = false;
        this.$router.push("/login");
      });
    },
    countdown: function () {
      this.remainingCount = this.maxCount - this.bio.length;
    },
    randomStr(len, arr) {
      var ans = "";
      for (var i = len; i > 0; i--) {
        ans += arr[Math.floor(Math.random() * arr.length)];
      }
      return ans;
    },
  },
  beforeMount() {
    auth.onAuthStateChanged((user) => {
      if (!user) return;
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          this.fname = doc.data().fname;
          this.lname = doc.data().lname;
          this.bio = doc.data().bio;
          this.email = doc.data().email;
          this.phone = doc.data().phone;
          this.countdown();
        });
    });
  },
};
</script>
<style scoped>
.secondary-btn {
  width: 100%;
  background-color: #fff;
  color: #363636;
  border: 1px solid #363636;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  transition: all 0.3s ease-in-out;
}
</style>