import { render, staticRenderFns } from "./kbc.vue?vue&type=template&id=742c42d9&scoped=true&"
import script from "./kbc.vue?vue&type=script&lang=js&"
export * from "./kbc.vue?vue&type=script&lang=js&"
import style0 from "./kbc.vue?vue&type=style&index=0&id=742c42d9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "742c42d9",
  null
  
)

export default component.exports