<template>
  <div class="hero">
    <div class="hero-body" style="padding: 0.5rem 1.5rem 7rem 1.5rem;">
      <!-- video -->
      <video-player
        v-if="vid1.loaded"
        class="vjs-custom-skin postVid"
        ref="videoPlayer"
        :options="vid1"
        :playsinline="true"
        :autoplay="true"
      ></video-player>
      <!-- /video -->
      <!-- comments -->
      <div class="postcomments" style="margin-top: 20px;">
        <div id="postcomments">
          <div id="preloader" class="is-hidden">
            <div class="preload dark" style="width: 100%; height: 50px;"></div>
            <div class="preload dark" style="width: 100%; height: 50px;"></div>
            <div class="preload dark" style="width: 100%; height: 50px;"></div>
            <div class="preload dark" style="width: 100%; height: 50px;"></div>
          </div>
          <div id="nocomment" class="has-text-centered is-hidden" style="padding: 20px 0;">
            <img src="img/comments.png" style="width: 80px;" />
            <h3 class="subtitle">No Comments</h3>
          </div>
          <div
            v-for="(comment, index) in orderedComments"
            :key="index"
            v-bind:class="{'is-hidden': comment.deleted}"
            class="columns is-mobile"
          >
            <div class="column is-2">
              <div
                @click="$router.push('/u/'+comment.author)"
                class="userimage"
                :style="comment.picture"
              ></div>
            </div>
            <div class="column usercomment">
              <h2 @click="$router.push('/u/'+comment.author)">{{comment.name}}</h2>
              <h5 v-html="urlify(comment.text)"></h5>
            </div>
            <div v-if="author" class="column is-2">
              <a @click="deleteComment(comment.id, comment)">
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="commentinput">
          <div class="columns is-mobile">
            <div class="column">
              <input
                v-model="comment"
                id="commentText"
                class="input"
                type="text"
                placeholder="Your comment"
              />
            </div>
            <div class="column is-3">
              <a
                @click="postComment"
                class="commentBtn button is-fullwidth"
                v-bind:class="{'is-loading': btnLoading}"
              >POST</a>
            </div>
          </div>
        </div>
      </div>
      <!-- /comments -->
    </div>
  </div>
</template>
<script>
import { auth } from "@/main.js";
import { db } from "@/main.js";
import { videoPlayer } from "vue-video-player";
import { functions } from "@/main.js";
import _ from "lodash";
export default {
  name: "post",
  components: {
    videoPlayer,
  },
  data() {
    return {
      btnLoading: false,
      author: false,
      comment: "",
      comments: [],
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
      },
    };
  },
  computed: {
    orderedComments: function () {
      return _.orderBy(this.comments, ["index"], ["asc"]);
    },
  },
  methods: {
    fetchComment() {
      db.collection("social")
        .doc(this.$route.params.id)
        .collection("comments")
        .orderBy("created", "desc")
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("users")
              .doc(doc.data().uid)
              .get()
              .then((user) => {
                var picture;
                if (user.data().picture)
                  picture =
                    "background-image: url(" + user.data().picture + ")";
                this.comments.push({
                  id: doc.id,
                  index: doc.data().created,
                  name: user.data().fname + " " + user.data().lname,
                  picture: picture,
                  text: doc.data().text,
                  author: user.id,
                  deleted: false,
                });
              });
          });
        });
    },
    postComment() {
      this.btnLoading = true;
      if (this.comment) {
        auth.onAuthStateChanged((user) => {
          var picture;
          if (user.photoURL)
            picture = "background-image: url(" + user.photoURL + ")";
          this.comments.push({
            id: this.makeid(10),
            name: user.displayName,
            picture: picture,
            text: this.comment,
            author: user.uid,
            deleted: false,
          });
        });
        var postComment = functions.httpsCallable("postComment");
        postComment({ comment: this.comment, pid: this.$route.params.id }).then(
          (result) => {
            console.log(result);
            this.comment = "";
            this.btnLoading = false;
          }
        );
      } else this.btnLoading = false;
    },
    deleteComment(cid, comment) {
      comment.deleted = true;
      db.collection("social")
        .doc(this.$route.params.id)
        .collection("comments")
        .doc(cid)
        .delete()
        .then(() => {
          console.log("deleted");
        });
    },
    urlify(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function (url) {
        return '<a target="blank" href="' + url + '">' + url + "</a>";
      });
    },
    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
  beforeMount() {
    this.fetchComment();
    auth.onAuthStateChanged((user) => {
      db.collection("social")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          console.log(doc.data());
          this.vid1.poster =
            "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
            encodeURIComponent(doc.data().url) +
            "&size=400&mode=orig";
          this.vid1.sources.push({
            type: "video/mp4",
            src: doc.data().url,
          });
          setTimeout(() => {
            this.vid1.loaded = true;
          }, 100);
          if (user.uid == doc.data().uid) this.author = true;
        });
    });
  },
  mounted() {},
};
</script>
<style>
#postcomments {
  padding: 15px;
  background: #ffffff;
  border-radius: 15px;
}
.postcomments .userimage {
  width: 45px;
  height: 45px;
}
.userimage {
  border-radius: 50%;
  border: 1.5px solid #ffff;
  width: 38px;
  height: 38px;
  background-image: url(~@/assets/img/user.png);
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
}
.usercomment h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 700 !important;
  font-size: 1rem;
}
.usercomment h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1rem !important;
}
.commentinput {
  padding: 15px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 10px;
}
.commentBtn {
  border: 3px solid #6f00ff;
  color: #6f00ff;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 100%;
  height: 100%;
}
.video-player.postVid {
  width: 100%;
  height: 300px !important;
  margin-top: 20px;
}
</style>