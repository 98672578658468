<template>
    <main class="is-black">
        <section class="hero is-black">
            <div class="hero-body">
                <div class="container">
                    <div class="columns">
                        <div class="column" style="margin:auto">
                            <img src="https://witblox.com/assets/img/witblox.png" style="width: 50px">
                            <p class="title is-2" style="padding-bottom: 20px; font-weight: 900;">
                                Chandrayaan Robotics Competition
                            </p>
                            <p class="subtitle is-4" style="font-weight: 800;">
                                by <u>Techfest IIT Bombay 2023</u>
                            </p>
                            <div v-if="!registered">
                                <div class="buttons">
                                    <button class="button" @click="registerAs = 'team'; openDialog()">
                                        Register as a school team
                                    </button>
                                    <button class="button" @click="registerAs = 'individual'; openDialog()">
                                        Register as an individual
                                    </button>
                                </div>
                            </div>
                            <div v-else class="buttons are-medium">
                                <button class="button" @click="$router.push('/upload/challenge/dRrNqx2JkBGWH5pEX1qG/1000')">
                                    Upload Your Submission
                                </button>
                            </div>
                        </div>
                        <div class="column is-5" style="margin:auto">
                            <video loop autoplay muted style="border-radius: 10px"
                                src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/files%2FWhatsApp%20Video%202023-11-30%20at%2012.08.39.mp4?alt=media&token=82e31099-5e49-4e45-b8f3-05d32e5f453b"></video>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-3">
                            <h2 class="title is-2">
                                Prizes upto <u>&#8377;50,000</u>
                            </h2>
                        </div>
                        <div class="column">
                            <div class="prz-container">
                                <div class="prz">
                                    <span class="pos">
                                        1<sup>st</sup> Prize
                                    </span>
                                    <div class="amt">
                                        &#8377;25,000
                                    </div>
                                    <p>
                                        <strong>50%</strong> of the total amount.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="prz-container">
                                <div class="prz">
                                    <span class="pos">
                                        2<sup>nd</sup> Prize
                                    </span>
                                    <div class="amt">
                                        &#8377;15,000
                                    </div>
                                    <p>
                                        <strong>33%</strong> of the total amount.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="prz-container">
                                <div class="prz">
                                    <span class="pos">
                                        3<sup>rd</sup> Prize
                                    </span>
                                    <div class="amt">
                                        &#8377;10,000
                                    </div>
                                    <p>
                                        <strong>17%</strong> of the total amount.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column has-text-centered">
                            <div class="buttons are-medium">
                                <a href="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/attachments%2FTechfest%202023%20Chandrayaan%20(1).pdf?alt=media&token=4c3a855e-4aed-46d2-9e6e-1a50e7451848"
                                    target="blank" class="button">
                                    View problem statement
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="columns">
                        <div class="column">
                            <div class="columns">
                                <div class="column">
                                    <h2 class="title is-2">
                                        Competition
                                    </h2>
                                    <p>
                                        You have to make a Line following Robot to go from the starting point on the track
                                        (Earth) to Finish point (Moon) in minimum time.
                                        <br><br>
                                        While Moving on the track, the Robot should have a ball dispensing mechanism to drop
                                        balls at the given location.
                                        <br><br>
                                        Every Team will get 3 trials and the winners will be decided based on the minimum
                                        time and maximum points scored.
                                    </p>
                                    <div class="buttons are-medium" style="margin-top: 30px">
                                        <a target="blank"
                                            href="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/attachments%2FWhatsApp%20Image%202023-12-06%20at%2016.01.12.jpeg?alt=media&token=39365a38-d465-4296-afba-b40a4f51e0f3"
                                            class="button">
                                            View Arena Details
                                        </a>
                                        <a target="blank" href="https://app.witblox.com/product/xGAs33C0nQUR4Xqfl4eX"
                                            class="button">
                                            Order Arena for Trial
                                        </a>
                                    </div>
                                </div>
                                <div class="column is-3">
                                    <video controls autoplay muted
                                        src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/attachments%2FChandrayaan%20Robotics%20Competition%202023%20by%20Techfest%20IIT%20Bombay%20in%20collaboration%20with%20WitBlox.mp4?alt=media&token=cb3b7fcf-30ac-41d2-96ab-b638ce014436"
                                        poster="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/files%2Fapp.witblox.com_challenge_kdifk1ngMPgJ1JfLlFNG(iPhone%20SE).png?alt=media&token=d2eeb083-eca3-42a4-9d4e-836bb136c037"></video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="columns">
                        <div class="column">
                            <h2 class="title is-2">
                                Some ideas to build
                            </h2>
                            <div class="columns">
                                <a class="column" href="https://project.witblox.com/qF3YpW9NcJqnpLsQXeuZ" target="blank">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/projects%2Fline-follower-robot%2F6IxGzYGxhG?alt=media&token=6b3855e2-b7cd-4dde-8239-ac5ca1e393e8"
                                        alt="">
                                </a>
                                <a class="column" href="https://project.witblox.com/iK3S0IdCpWslrC4O59Of" target="blank">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/projects%2Fline-follower-robot-using-arduino%2Fsf5h7epnsg?alt=media&token=84b60060-1a59-4469-9b6e-792bcb79df53"
                                        alt="">
                                </a>
                                <a class="column" href="https://project.witblox.com/caLC6N6msmZSN33i29R1" target="blank">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/projects%2Fball-kicking-mechanism%2FMG5Ui6E9im?alt=media&token=82843efe-af9a-4292-bdfb-f435c6b9ad37"
                                        alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="columns">
                        <div class="column">
                            <div class="columns">
                                <div class="column">
                                    <h2 class="title is-2">
                                        The Rules
                                    </h2>
                                    <h3 class="title is-4">
                                        Game Rules, Rewards and Penalties
                                    </h3>
                                    <ul>
                                        <li>The robot needs to follow the line from the Earth to the Moon.</li>
                                        <li>There are designated spots along the line where the robot needs to drop T.T.
                                            balls. Succeeding in doing so will result in the addition of printed points.
                                            Failing to do so will result in no points. </li>
                                        <li>Once the robot reaches the end of the track, it should stop. Not stopping at the
                                            final point will result in no points.</li>
                                        <li>The timer will start when the robot starts and will stop when the robot reaches
                                            the Moon’s STOP point.</li>
                                        <li>Winner will be the robot with the shortest time to complete the task.</li>
                                        <li>If the robot deviates away from the black line at any point during the journey,
                                            it will result in a restart. Only 2 restarts will be allowed per robot, after
                                            which the robot will be disqualified.</li>
                                        <li>Students can participate as an individual or in teams. A maximum of 3 students
                                            can form a team.</li>
                                        <li>Students from more than one school are allowed to form teams.</li>
                                        <li>No participant is allowed to be a part of more than one team.</li>
                                    </ul>
                                    <h3 class="title is-4" style="padding-top:25px">
                                        Robot Rules
                                    </h3>
                                    <ul>
                                        <li>The robot should fit inside a box of size 10 inch (L) x 10 inch (W) x 10 inch
                                            (H).</li>
                                        <li>For wired remote control robots, the wire should remain slack at all times.</li>
                                        <li>For wireless remote control robots, the power supply should be on board.</li>
                                        <li>For autonomous robots, the power supply should be on board.</li>
                                        <li>The size of the robot will be measured before the commencement of each round.
                                            Failing to adhere to size restrictions will disqualify the robot.</li>
                                        <li>The robot is allowed to increase or decrease in size after the timer starts.
                                        </li>
                                        <li>The robot is not allowed to leave anything on the arena other than the provided
                                            T.T. balls.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="is-hidden">
                    <div class="columns is-hidden">
                        <div class="column">
                            <h2 class="title is-2 has-text-centered">
                                Questions?
                            </h2>
                            <div class="qcontainer">
                                <div class="question">
                                    <p>
                                        <strong>What is the Kickball Challenge?</strong>
                                    </p>
                                </div>
                                <div class="answer">
                                    <p>
                                        The Kick Ball Challenge is all about building your very own Line follower robot with
                                        a special front arm designed to kick a ball off a cup.
                                    </p>
                                </div>
                            </div>
                            <div class="qcontainer">
                                <div class="question">
                                    <p>
                                        <strong>How to participate in the Kickball Challenge?</strong>
                                    </p>
                                </div>
                                <div class="answer">
                                    <p>
                                        To participate, you must build the challenge & upload a short video of your project
                                        on the WitBlox App.
                                    </p>
                                </div>
                            </div>
                            <div class="qcontainer">
                                <div class="question">
                                    <p>
                                        <strong>What can you Win by participating in the Challenge?</strong>
                                    </p>
                                </div>
                                <div class="answer">
                                    <p>
                                        You can win AI Chat Blox usinig which you can create your own Alexa like robot.
                                    </p>
                                </div>
                            </div>
                            <div class="qcontainer">
                                <div class="question">
                                    <p>
                                        <strong>What is the Last Date to participate in the Challenge?</strong>
                                    </p>
                                </div>
                                <div class="answer">
                                    <p>
                                        It is 31st July, 2023
                                    </p>
                                </div>
                            </div>
                            <div class="qcontainer">
                                <div class="question">
                                    <p>
                                        <strong>How to join the Kickball Challenge Competition?</strong>
                                    </p>
                                </div>
                                <div class="answer">
                                    <p>
                                        Go to the Register option, add all your details and submit the form.
                                    </p>
                                </div>
                            </div>
                            <div class="qcontainer">
                                <div class="question">
                                    <p>
                                        <strong>Is there a participation fee for the competition?</strong>
                                    </p>
                                </div>
                                <div class="answer">
                                    <p>
                                        Yes. The participation fee for the event is Rs 199
                                    </p>
                                </div>
                            </div>
                            <div class="qcontainer">
                                <div class="question">
                                    <p>
                                        <strong>What are the Prizes for the Kickball Competition?</strong>
                                    </p>
                                </div>
                                <div class="answer">
                                    <p>
                                        There is a prize pool for the competition, from which top 3 winners will get cash
                                        prizes. Currently the prize pool is Rs 5000. More the participants, more will be the
                                        prize pool.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- register -->
        <div>
            <div class="modal" :class="{ 'is-active': isModalActive }" style="z-index: 100003;">
                <div class="modal-background"></div>
                <div class="modal-card">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Registration</p>
                        <button class="delete" aria-label="close" @click="isModalActive = false"></button>
                    </header>
                    <section class="modal-card-body">
                        <div class="field">
                            <label class="label">Register as</label>
                            <div class="control">
                                <div class="select">
                                    <select v-model="registerAs">
                                        <option value="individual">Individual / Team</option>
                                        <option value="team">School</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div v-if="registerAs === 'individual'">
                            <div class="field" v-for="i in 3" :key="i">
                                <label class="label">Team Member Name {{ i }} <span v-if="i == 1">*</span></label>
                                <div class="control">
                                    <input v-model="teamMembers[i-1]" class="input" type="text"
                                        :placeholder="'Team Member ' + i">
                                </div>
                            </div>
                        </div>

                        <div v-if="registerAs === 'team'">
                            <div class="field">
                                <label class="label">Principal / Coordinator Name *</label>
                                <div class="control">
                                    <input v-model="name" class="input" type="text" placeholder="Your Name">
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">School Name *</label>
                            <div class="control">
                                <input v-model="schoolName" class="input" type="text" placeholder="School Name">
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">City *</label>
                            <div class="control">
                                <input v-model="city" class="input" type="text" placeholder="City">
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Email ID *</label>
                            <div class="control">
                                <input v-model="email" class="input" type="email" placeholder="Email ID">
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Contact Number *</label>
                            <div class="control">
                                <input v-model="contact" class="input" type="tel" placeholder="Contact Number">
                            </div>
                        </div>
                    </section>
                    <footer class="modal-card-foot">
                        <button class="button is-success" @click="register">Register</button>
                        <button class="button" @click="isModalActive = false">Cancel</button>
                    </footer>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { functions } from "@/main.js";
// import { createWebengageEvent } from "../helper";
export default {
    name: "kbc",
    data() {
        return {
            btnLoading: false,
            registered: false,
            user: {},
            isModalActive: false,
            registerAs: 'individual',
            schoolName: '',
            name: '',
            teamMembers: ['', '', ''],
            email: '',
            contact: '',
            city: ''
        }
    },
    beforeMount() {
    },
    mounted() {
        db.collection("users").doc(auth.currentUser.uid).get().then((doc) => {
            if (doc.exists) this.user = doc.data();
            else this.$router.push("/")
        });
        db.collection("config").doc("chandrayan").collection("registrations").where("uid", "==", auth.currentUser.uid).get().then((querySnapshot) => {
            if (querySnapshot.size > 0) this.registered = true;
        });
    },
    methods: {
        addCommaToNumber(num) {
            //remove decimals
            num = num.toFixed(0);
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        openDialog() {
            auth.onAuthStateChanged((user) => {
                if (user) {
                    this.isModalActive = true;
                } else this.$router.push("/login?from=chandrayaan");
            });
        },
        register() {
            this.btnLoading = true;
            //mandatory for school: name, schoolName, contact, email, city
            //mandatory for individual: teamMembers[0], schoolName, contact, email, city
            //check if all mandatory fields are filled
            if (this.registerAs == 'individual') {
                if (this.teamMembers[0] == '' || this.schoolName == '' || this.contact == '' || this.email == '' || this.city == '') {
                    this.btnLoading = false;
                    return window.alert("Please fill all the mandatory fields");
                }
            } else {
                if (this.name == '' || this.schoolName == '' || this.contact == '' || this.email == '' || this.city == '') {
                    this.btnLoading = false;
                    return window.alert("Please fill all the mandatory fields");
                }
            }
            //
            db.collection("config").doc("chandrayan").collection("registrations").add({
                uid: auth.currentUser.uid,
                name: this.name,
                email: this.email,
                contact: this.contact,
                schoolName: this.schoolName,
                teamMembers: this.teamMembers,
                createdAt: new Date(),
                registerAs: this.registerAs,
                city: this.city
            }).then(() => {
                this.btnLoading = false;
                this.isModalActive = false;
                this.registered = true;
                // createWebengageEvent("chandrayan_registration", {
                //     name: this.name,
                //     email: this.email,
                //     contact: this.contact,
                //     schoolName: this.schoolName,
                //     teamMembers: this.teamMembers,
                // });
                var sendEmailForEvent = functions.httpsCallable("sendEmailForEvent");
                sendEmailForEvent({ email: this.email }).then(() => {
                    console.log("email sent");
                });
            });
        }
    }
}
</script>
<style scoped>
.buttons {
    background: #1a1a1c;
    display: inline-block;
    padding: 10px 10px 2px 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .1);
    border-radius: 24px;
    border: 1px solid #393939;
}

.buttons .button {
    background: #fff;
    border: 1px solid #393939;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .1);
    color: #141414 !important;
    font-weight: 800;
    border-radius: 10px;
}

.container {
    max-width: 1000px !important;
    margin: auto;
}

.table-container {
    max-width: 1000px !important;
    margin: auto;
}

.custom-table {
    background-color: #1a1a1c;
    width: 100%;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .1);
    border-radius: 15px;
}

.custom-table th {
    color: #fff;
}

.custom-table img {
    max-width: 50px;
}

table th {
    font-weight: 700;
    font-size: 20px;
    color: #a6a6a6 !important;
}

table td {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}

table thead {
    background-color: #383838 !important;
}

.tag {
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

hr {
    background-color: #474747;
    margin: 50px 0;
}

.hero {
    padding-bottom: 100px;
}

ul {
    /* max-width: 900px;
    margin: auto; */
    list-style-type: circle;
}

ul li,
p {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
}

ul li {
    line-height: 1.75rem;
    font-size: 0.9rem;
}

h2.title.is-2 {
    color: #d9c5a8 !important;
}

.qcontainer {
    max-width: 800px;
    margin: auto;
    margin-bottom: 30px;
}

.qcontainer .question {
    background-color: #181b1d;
    padding: 15px 25px;
    border-radius: 20px 20px 0 0;
}

.qcontainer .question p strong {
    font-size: 25px !important;
}

.qcontainer .answer {
    background-color: #3b3b3b;
    padding: 15px 25px;
    font-size: 20px;
    color: #fff;
    border-radius: 0 0 20px 20px;
}

.prz-container .prz img {
    border-radius: 20px;
}

.prz-container .prz p {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #4a4a4a;
    color: #fff;
    display: inline;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 0 0 15px 15px;
    font-size: 0.9rem;
    width: 100%;
}

.prz-container .prz {
    position: relative;
    background: #fff;
    border-radius: 20px;
}

.prz-container .prz span.pos {
    position: absolute;
    top: 0;
    left: 0;
    background: #000000;
    color: #fff;
    display: inline;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin: 10px;
    padding: 5px 15px;
    font-size: 0.8rem;
    border-radius: 10px;
}

.amt {
    padding: 60px 20px;
    color: #000;
    font-weight: 900;
    text-align: center;
    font-size: 2.5rem;
}
</style>