<template>
  <section class="videosection">
    <a @click="$router.back()" class="closeBtns">
      <span class="icon">
        <i class="far fa-times-circle"></i>
      </span>
    </a>
    <div class="video-container" v-if="screen === 0">
      <video-player
        v-if="vid1.loaded"
        class="vjs-custom-skin"
        ref="videoPlayer"
        :options="vid1"
        :playsinline="true"
        @ended="onPlayerEnded($event)"
      ></video-player>
      <div class="videoBtns">
        <a class="button is-small" id="skipBtn" @click="screen = 1">
          <span>Skip</span>
          <span class="icon">
            <i class="fas fa-chevron-right"></i>
          </span>
        </a>
      </div>
      <div v-if="popupOpen" class="cartoon2">
        <div class="columns">
          <div class="column">
            <div class="scard">
              <h1 class="title">
                Would you like to participate in
                <b>"{{ name }}"</b>?
              </h1>
              <div class="columns">
                <div class="column has-text-centered">
                  <img src="@/assets/img/coin.svg" alt />
                  <h3 class="subtitle">
                    Build &amp; Win
                    {{ coins }} coins.
                  </h3>
                </div>
              </div>
              <button class="btn btn-theme" @click="screen = 1">Yes</button>
              <a class="btn btn-theme" @click="router('/')">No</a>
            </div>
          </div>
          <div class="column">
            <img src="@/assets/img/cartoon.png" class="cartoonImg" />
          </div>
        </div>
      </div>
    </div>
    <div class="video-container" v-else-if="screen === 1">
      <img :src="banner" class="featuredImg" />
      <div class="hero">
        <div class="hero-body">
          <h1 class="title">{{ name }}</h1>
          <div class="separator">MORE INFO</div>
          <h3 class="subtitle">Description</h3>
          <p v-html="desc"></p>
          <hr />
          <h3 class="subtitle">Eligibility</h3>
          <p v-html="content.eligibility"></p>
          <hr />
          <h3 class="subtitle">Prizes</h3>
          <p v-html="content.prizes"></p>
          <hr />
          <h3 class="subtitle">Rules</h3>
          <p v-html="content.rules"></p>
          <hr />
          <h3 class="subtitle">FAQs</h3>
          <p v-html="content.faqs"></p>
          <div style="padding-bottom: 60px"></div>
          <div class="trailerVidBtn">
            <div class="columns">
              <div class="column">
                <button
                  @click="markComplete()"
                  v-bind:class="{ 'is-loading': btnLoading }"
                  class="button btn btn-theme"
                >
                  Participate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a @click="screen = 0" class="screen2Btn">
        <span class="icon">
          <i class="fas fa-chevron-circle-left"></i>
        </span>
      </a>
    </div>
    <div style="padding: 20px" v-else-if="screen === 2">
      <img src="@/assets/img/congrats.svg" class="congrats" />
      <h1 class="title has-text-centered" style="padding-bottom: 15px">
        Congratulations!
      </h1>
      <h3 class="subtitle has-text-centered">
        Upload your project's video to win
        <b style="color: #000000">{{ coins }} coins.</b>
      </h3>
      <a
        @click="router('/upload/competition/' + $route.params.id + '/' + coins)"
        class="btn btn-theme"
        >Upload Video</a
      >
      <a @click="screen = 1" class="screen3Btn">
        <span class="icon">
          <i class="fas fa-chevron-circle-left"></i>
        </span>
      </a>
    </div>
  </section>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { functions } from "@/main.js";
import { db } from "@/main.js";
// const { Browser } = Plugins;
import { Browser } from "@capacitor/browser";
import "@/assets/css/video-js.css";
import { videoPlayer } from "vue-video-player";
export default {
  name: "competition",
  components: {
    videoPlayer,
  },
  data() {
    return {
      screen: 0,
      name: "",
      coins: 0,
      banner: "",
      popupOpen: false,
      btnLoading: false,
      shop_link: "",
      content: {},
      desc: "",
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
      },
    };
  },
  beforeMount() {
    db.collection("config")
      .doc("categories")
      .get()
      .then((doc) => {
        this.shop_link = doc.data().challenge_shop;
      });
    db.collection("competition")
      .doc(this.$route.params.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log(doc.data());
          this.vid1.sources.push({ type: "video/mp4", src: doc.data().video });
          this.vid1.poster =
            "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
            encodeURIComponent(doc.data().video) +
            "&size=400&mode=orig";
          this.name = doc.data().name;
          this.coins = doc.data().coins;
          this.banner = doc.data().featuredImg;
          this.content = doc.data().content;
          this.desc = doc.data().desc;
          setTimeout(() => {
            this.vid1.loaded = true;
          }, 100);
        } else {
          this.router("/");
        }
      });
    auth.onAuthStateChanged((user) => {
      this.useremail = user.email;
      db.collection("users")
        .doc(user.uid)
        .collection("tracking")
        .where("id", "==", this.$route.params.id)
        .get()
        .then((snap) => {
          console.log(snap);
          if (snap.empty) {
            db.collection("users").doc(user.uid).collection("tracking").add({
              id: this.$route.params.id,
              created: new Date().getTime(),
              type: "competition",
            });
          }
        });
    });
  },
  mounted() {},
  methods: {
    router(name) {
      this.$router.push(name);
    },
    async showInApp(link) {
      await Browser.open({ url: link });
    },
    onPlayerEnded() {
      this.popupOpen = true;
    },
    markComplete() {
      this.btnLoading = true;
      var markComplete = functions.httpsCallable("markComplete");
      markComplete({ pid: this.$route.params.id, type: "competition" }).then(
        () => {
          this.btnLoading = false;
          this.screen = 2;
        }
      );
    },
  },
};
</script>
