<template>
  <div class="homescreen" :style="color">
    <!-- intro -->
    <div class="homeprofile">
      <div class="homeheader columns is-mobile">
        <div class="column is-3">
          <a @click="router('/profile')">
            <div
              v-bind:style="{ 'background-image': image }"
              v-if="$store.state.profile.picture === ''"
              class="homeprofileimg"
            ></div>
            <div
              :style="$store.state.profile.picture"
              v-else
              class="homeprofileimg"
            ></div>
          </a>
        </div>
        <div class="column is-7">
          <h6 class="home title" :style="textColor">
            Hi
            <span>
              <span
                v-if="$store.state.profile.fname === ''"
                class="preload"
                style="width: 100px"
              ></span>
              <span v-else>{{ $store.state.profile.fname }}</span>
            </span>
            <img src="../assets/img/hi.png" width="28px" />
          </h6>
        </div>
        <div class="column is-2">
          <a @click="router('/search')" class="searchIcon">
            <i class="fas fa-search"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- /intro -->
    <div class="section">
      <img
        v-if="myclasses"
        src="@/assets/img/myclasses.jpg"
        style="border-radius: 15px"
        @click="router('/schedule')"
      />
      <a
        @click="router('/class/' + cls.id)"
        v-for="(cls, index) in classes"
        :key="index"
      >
        <v-lazy-image
          :src-placeholder="require('@/assets/img/class-lazy.png')"
          :src="cls.featured"
          class="homebanner"
        />
      </a>
      <div class="columns">
        <div class="column" style="padding-top: 50px"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { db } from "@/main.js";
// const { App } = Plugins;
import VLazyImage from "v-lazy-image";
export default {
  name: "classhome",
  components: {
    VLazyImage,
  },
  data() {
    return {
      image: "url(" + require("@/assets/img/user.png") + ")",
      message: "What would you like to learn today?",
      challengetag: "#Featured",
      cid: "",
      banner: "",
      coins: 0,
      classes: [],
      comingsoon: false,
      color: "",
      textColor: "",
      myclasses: false,
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
    fetchClasses() {
      db.collection("classes")
        .get()
        .then((snap) => {
          snap.forEach((cls) => {
            var clsobj = cls.data();
            clsobj.id = cls.id;
            this.classes.push(clsobj);
          });
        });
    },
    visibilityChanged: function (isVisible, entry, id) {
      console.log(id);
      //   console.log(isVisible, entry, id);
      if (isVisible) {
        if (entry.intersectionRatio > 0.5) entry.target.play();
        else entry.target.pause();
      } else {
        if (!entry.isIntersecting) entry.target.pause();
      }
    },
  },
  beforeMount() {
    this.getColor();
    this.fetchClasses();
    auth.onAuthStateChanged((user) => {
      db.collection("class-orders")
        .where("uid", "==", user.uid)
        .where("paid", "==", true)
        .get()
        .then((snap) => {
          if (!snap.empty) this.myclasses = true;
        });
    });
    db.collection("config")
      .doc("classes")
      .get()
      .then((config) => {
        db.collection("classes")
          .doc(config.data().featured)
          .get()
          .then((doc) => {
            this.banner = "url(" + doc.data().img + ")";
            this.cid = doc.id;
          });
      });
  },
  mounted() {
    var schedules = [
      {
        available: true,
        created: new Date().getTime(),
        start: 1622968200000,
        end: 1623317400000,
        joinLink:
          "https://zoom.us/j/96438426181?pwd=ZFRoWmJPUTU0QWl0RmRuYnkzdDZoUT09",
        subtitle: "(2:00 PM to 3:00 PM)",
        title: "6 June - 10 June",
        limit: 5,
        booked: 0,
      },
      {
        available: true,
        created: new Date().getTime(),
        start: 1622986200000,
        end: 1623335400000,
        joinLink:
          "https://zoom.us/j/96438426181?pwd=ZFRoWmJPUTU0QWl0RmRuYnkzdDZoUT09",
        subtitle: "(7:00 PM to 8:00 PM)",
        title: "6 June - 10 June",
        limit: 5,
        booked: 0,
      },
      {
        available: true,
        created: new Date().getTime(),
        start: 1623573000000,
        end: 1623922200000,
        joinLink:
          "https://zoom.us/j/96438426181?pwd=ZFRoWmJPUTU0QWl0RmRuYnkzdDZoUT09",
        subtitle: "(2:00 PM to 3:00 PM)",
        title: "13 June - 17 June",
        limit: 5,
        booked: 0,
      },
      {
        available: true,
        created: new Date().getTime(),
        start: 1623591000000,
        end: 1623940200000,
        joinLink:
          "https://zoom.us/j/96438426181?pwd=ZFRoWmJPUTU0QWl0RmRuYnkzdDZoUT09",
        subtitle: "(7:00 PM to 8:00 PM)",
        title: "13 June - 17 June",
        limit: 5,
        booked: 0,
      },
      {
        available: true,
        created: new Date().getTime(),
        start: 1624177800000,
        end: 1624527000000,
        joinLink:
          "https://zoom.us/j/96438426181?pwd=ZFRoWmJPUTU0QWl0RmRuYnkzdDZoUT09",
        subtitle: "(2:00 PM to 3:00 PM)",
        title: "20 June - 24 June",
        limit: 5,
        booked: 0,
      },
      {
        available: true,
        created: new Date().getTime(),
        start: 1624195800000,
        end: 1624545000000,
        joinLink:
          "https://zoom.us/j/96438426181?pwd=ZFRoWmJPUTU0QWl0RmRuYnkzdDZoUT09",
        subtitle: "(7:00 PM to 8:00 PM)",
        title: "20 June - 24 June",
        limit: 5,
        booked: 0,
      },
      {
        available: true,
        created: new Date().getTime(),
        start: 1624782600000,
        end: 1625131800000,
        joinLink:
          "https://zoom.us/j/96438426181?pwd=ZFRoWmJPUTU0QWl0RmRuYnkzdDZoUT09",
        subtitle: "(2:00 PM to 3:00 PM)",
        title: "27 June - 1 July",
        limit: 5,
        booked: 0,
      },
      {
        available: true,
        created: new Date().getTime(),
        start: 1624800600000,
        end: 1625149800000,
        joinLink:
          "https://zoom.us/j/96438426181?pwd=ZFRoWmJPUTU0QWl0RmRuYnkzdDZoUT09",
        subtitle: "(7:00 PM to 8:00 PM)",
        title: "27 June - 1 July",
        limit: 5,
        booked: 0,
      },
    ];
    console.log(schedules);
    /*
    schedules.forEach((schedule) => {
      db.collection("classes")
        .doc("ObRRDP7ZpdcGbFQHbMRo")
        .collection("schedule")
        .add(schedule)
        .then((doc) => {
          console.log(schedule.title, schedule.subtitle, "=>", doc.id);
        });
    });
    */
  },
};
</script>
<style scoped>
.class {
  padding: 5px 25px;
}
.class img {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
}
</style>