<template>
    <section class="section">
        <div class="container">

            <!-- Title & Description -->
            <div class="content">
                <h1 class="title is-3 has-text-centered" style="font-weight: 800;">
                    Create your campaign
                </h1>
            </div>
            <div class="box">
                <div class="field">
                    <p class="control">
                        <input class="input" type="email" placeholder="Name your campaign" v-model="name">
                    </p>
                </div>
                <div class="field is-hidden">
                    <p class="control">
                        <input class="input" type="number" placeholder="Goal" v-model="total">
                    </p>
                </div>
            </div>
            <!-- Project Details and Story -->
            <div class="box shadow">
                <h2 class="title is-5">About the project</h2>
                <textarea class="textarea is-warning" placeholder="10 lines of textarea" rows="10"
                    v-model="about"></textarea>
            </div>
            <!-- Funding Details -->
            <div class="box">
                <h3 class="subtitle" v-if="pdf.name && pdf.link">
                    <i class="fas fa-check-circle"></i>
                    PDF Uploaded
                </h3>
                <div class="file has-name is-right is-warning">
                    <label class="file-label">
                        <input class="file-input" type="file" name="resume" @change="uploadPDF" accept="application/pdf">
                        <span class="file-cta">
                            <span class="file-icon">
                                <i class="fas fa-upload"></i>
                            </span>
                            <span class="file-label">
                                Upload your PDF
                            </span>
                        </span>
                        <span class="file-name">
                            {{ pdf.name }}
                        </span>
                    </label>
                </div>
                <div class="images" style="padding-top:40px">
                    <div class="image-container" v-for="image in images" :key="image">
                        <img :src="image" alt="">
                        <button class="delete is-small top-right" @click="deleteImage(image)"></button>
                        <span class="number">
                            {{ images.indexOf(image) + 1 }}
                        </span>
                    </div>
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        <div class="file is-boxed is-fullwidth has-text-centered is-warning">
                            <label class="file-label">
                                <input class="file-input" type="file" name="resume" accept="image/jpeg,image/png"
                                    @change="uploadImage">
                                <span class="file-cta">
                                    <span class="file-icon">
                                        <i class="fas fa-upload"></i>
                                    </span>
                                    <span v-if="images.length == 0" class="file-label">
                                        Choose an image...
                                    </span>
                                    <span v-else>
                                        Choose another image...
                                    </span>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <AutocompleteSelect :campaign="campaignId"></AutocompleteSelect>
            <button class="button btn btn-theme" @click="update">
                Upload / Update
            </button>
            <hr>
            <!-- Comments or Updates Section -->
            <div class="box">
                <h2 class="title is-4">Project Updates</h2>
                <div class="columns">
                    <div class="column">
                        <button class="button is-large" style="width: 100%">
                            <span class="icon">
                                <i class="fas fa-plus"></i>
                            </span>
                            <p>Upload</p>
                        </button>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="columns is-mobile">
                    <div class="column">
                        <h2 class="title is-4" style="padding-top: 6px;">Add Team Members</h2>
                    </div>
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        <div class="team-grid">
                            <div class="card" v-for="user in teamObj" :key="user.id">
                                <div class="card-image">
                                    <figure class="image">
                                        <img v-if="user.picture" :src="user.picture">
                                        <img v-else
                                            src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/attachments%2FUntitled%20design.png?alt=media&token=2b46530d-a76e-4c82-85aa-f3552e545b5b">
                                        <button v-if="user.id !== uid" class="delete is-small top-right"
                                            @click="removeMember(image)"></button>
                                    </figure>
                                </div>
                                <div class="card-content">
                                    <p class="title is-6">{{ user.fname + " " + user.lname }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="user-search">
                    <ais-instant-search :search-client="searchClient" index-name="users">
                        <app-debounced-search-box :delay="200" />
                        <ais-state-results>
                            <template v-slot="{ results: { hits, query } }">
                                <div class="results users" style="padding-top: 10px" :class="{ 'is-hidden': query == '*' }">
                                    <h5 class="subtitle">Results for {{ query }}</h5>
                                    <div v-for="item in hits" :key="item.objectID" class="columns is-mobile"
                                        @click="addTeamMember(item.objectID)">
                                        <div class="column is-3 has-text-centered">
                                            <div :style="'background: url(' + itemPicture(item.picture) + '), linear-gradient(to right, #8e2de2, #4a00e0)'"
                                                class="userimg"></div>
                                        </div>
                                        <div class="column">
                                            <h3 v-if="item.fname">
                                                {{ item.fname + " " + item.lname }}
                                            </h3>
                                            <h3 v-else>WitBlox Maker</h3>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </ais-state-results>
                    </ais-instant-search>
                </div>
            </div>
            <div class="box shadow">
                <div class="columns is-mobile">
                    <div class="column">
                        <h2 class="title is-4" style="padding-top: 6px;">Answer questions</h2>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="qcontainer">
                            <div class="question">
                                <p>
                                    <strong>Why are you doing this?</strong>
                                </p>
                            </div>
                            <div class="answer">
                                <p contenteditable>
                                    Just because.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box">
                <h2 class="title is-5">Supporters</h2>
                <table class="table is-fullwidth">
                    <tbody>
                        <tr v-for="(funder, index) in funders" :key="funder.id" :class="{ 'is-selected': index == 0 }">
                            <th>
                                <img v-if="funder.picture" :src="funder.picture" alt="" class="contributer">
                                <img v-else src="@/assets/img/user.png" alt="" class="contributer">
                            </th>
                            <td>
                                <p class="subtitle is-6">&#8377;{{ funder.amount }}</p>
                            </td>
                            <td>
                                <p class="subtitle is-6">
                                    {{ funder.name }}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal">
            <div class="modal-background"></div>
            <div class="modal-content">
                <article class="message is-warning">
                    <div class="message-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. <strong>Pellentesque risus mi</strong>,
                        tempus quis placerat ut, porta nec nulla. Vestibulum rhoncus ac ex sit amet fringilla. Nullam
                        gravida purus diam, et dictum <a>felis venenatis</a> efficitur. Aenean ac <em>eleifend lacus</em>,
                        in mollis lectus. Donec sodales, arcu et sollicitudin porttitor, tortor urna tempor ligula, id
                        porttitor mi magna a neque. Donec dui urna, vehicula et sem eget, facilisis sodales sem.
                    </div>
                </article>
            </div>
            <button class="modal-close is-large" aria-label="close"></button>
        </div>
    </section>
</template>
<script>
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { storage } from "@/main.js";
//
import AppDebouncedSearchBox from "./debouncedSearchBox.vue";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

import AutocompleteSelect from './AutocompleteSelect.vue';

export default {
    name: "crowdfunding_upload",
    data() {
        return {
            uid: auth.currentUser.uid,
            campaignId: this.$route.params.id,
            name: "",
            total: 0,
            current: 0,
            script: "https://checkout.razorpay.com/v1/checkout.js",
            isPaymentLoading: false,
            amount: 101,
            registered: true,
            err: false,
            funders: [],
            items: [
            ],
            posts: [
            ],
            searchClient: null,
            pdf: {
                link: "",
                name: ""
            },
            images: [],
            localContent: this.$root.about,
            //budget / items
            productNameInput: '',
            quantity: 1,
            autocompleteProductNames: [],
            autocompleteProductObj: [],
            price: null,
            team: [],
            teamObj: [],
            about: "",
        }
    },
    methods: {
        itemPicture(picture) {
            if (!picture) return "https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/attachments%2FUntitled%20design.png?alt=media&token=2b46530d-a76e-4c82-85aa-f3552e545b5b"
            else return picture;
        },
        update() {
            console.log(this.about);
            db.collection("crowdfunding").doc(this.campaignId).update({
                about: this.about,
                budget: this.items,
                goal: this.total,
                pdf: this.pdf,
                images: this.images,
                title: this.name
            }).then(() => {
                alert("Updated successfully");
                this.$router.push("/crowdfunding/" + this.campaignId);
            });
        },
        uploadPDF(event) {
            this.selectedFile = event.target.files[0];
            console.log("Uploading PDF");
            //upload to firebase storage
            if (!this.selectedFile) {
                alert('No file selected!');
                return;
            }
            // Create a storage reference
            const fileName = this.selectedFile.name;
            const fileRef = storage.ref().child(`crowdfunding/pdfs/${this.selectedFile.name}`);
            // Upload the file
            fileRef.put(this.selectedFile).then(async (snapshot) => {
                const getDownloadURL = await snapshot.ref.getDownloadURL();
                console.log('File uploaded successfully!', fileName, getDownloadURL);
                db.collection("crowdfunding").doc(this.campaignId).update({
                    pdf: {
                        link: getDownloadURL,
                        name: fileName
                    }
                });
            }).catch((error) => {
                console.log(`Error uploading file: ${error}`);
            });
        },
        uploadImage(event) {
            this.selectedFile = event.target.files[0];
            console.log("Uploading Image");
            //upload to firebase storage
            if (!this.selectedFile) {
                alert('No file selected!');
                return;
            }
            // Create a storage reference
            const fileRef = storage.ref().child(`crowdfunding/images/${this.selectedFile.name}`);
            // Upload the file
            fileRef.put(this.selectedFile).then(async (snapshot) => {
                const getDownloadURL = await snapshot.ref.getDownloadURL();
                console.log('File uploaded successfully!', getDownloadURL);
                this.images.push(getDownloadURL);
                db.collection("crowdfunding").doc(this.campaignId).update({
                    images: this.images
                });
            }).catch((error) => {
                console.log(`Error uploading file: ${error}`);
            });
        },
        deleteImage(image) {
            this.images = this.images.filter((img) => img != image);
            db.collection("crowdfunding").doc(this.campaignId).update({
                images: this.images
            });
        },
        async loadRazorPay() {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = this.script;
                script.onload = () => {
                    resolve(true);
                };
                script.onerror = () => {
                    resolve(false);
                };
                document.body.appendChild(script);
            });
        },
        async handlePayment() {
            if (!this.amount || parseInt(this.amount) < 1)
                return alert("Please enter valid amount");

            this.isPaymentLoading = true;
            const t = await fetch(
                "https://us-central1-witblox-5ae68.cloudfunctions.net/kbcRegistration",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        amount: parseInt(this.amount),
                        currency: "INR",
                    }),
                }
            );
            const data = await t.json();
            const isLoaded = await this.loadRazorPay();
            if (!isLoaded) return alert("Razorpay SDK failed to load.");
            const options = {
                key: "rzp_live_LxqoqZ6JH2rIT4",
                amount: data.data.amount,
                currency: data.data.currency,
                name: "WitBlox",
                description: "KBC Registration",
                image: "https://witblox.com/assets/compressed/witblox.png",
                order_id: data.data.id,
                "prefill": {
                    "name": this.name,
                },
                handler: function (response) {
                    console.log(response);
                    this.isPaymentLoading = false;
                    var userAuth = null;
                    if (auth.currentUser.uid) this.userAuth = auth.currentUser.uid;
                    if (!this.name) this.name = "Anonymous";
                    db.collection("crowdfunding").doc(this.campaignId).collection("funding")
                        .add({
                            amount: parseInt(data.data.amount) / 100,
                            razorpay_details: {
                                razorpay_order_id: data.data.id,
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_signature: response.razorpay_signature,
                            },
                            uid: userAuth,
                            created: new Date().getTime(),
                            points: 0,
                            name: this.name
                        })
                        .then(() => {
                            console.log("Payment successful");
                            this.registered = true;
                        })
                        .catch((err) => {
                            console.log(err);
                            alert("Payment failed");
                        });
                },
                theme: {
                    color: "#3399cc",
                },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.on("payment.failed", function (err) {
                this.isPaymentLoading = false;
                console.log("Payment failed", err);
            });
            rzp1.open();
            this.isPaymentLoading = false;
        },
        addCommaToNumber(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        initUserSearch() {
            const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
                server: {
                    apiKey: "witblox@123",
                    nodes: [{ host: "search.witblox.com", port: "443", protocol: "https" }],
                },
                additionalSearchParameters: { numTypos: 3 },
                collectionSpecificSearchParameters: {
                    users: { query_by: "fname,lname" },
                },
            });

            const searchClient = typesenseInstantsearchAdapter.searchClient;
            this.searchClient = searchClient;
        },
        addTeamMember(id) {
            if (this.team.includes(id)) return;
            this.team.push(id);
            db.collection("crowdfunding").doc(this.campaignId).update({
                team: this.team
            });
            db.collection("users").doc(id).get().then((user) => {
                var userObj = user.data();
                userObj.id = user.id;
                this.teamObj.push(userObj);
            });
        }
    },
    beforeMount() {
        console.log(auth.currentUser.uid);
        db.collection("crowdfunding").doc(this.campaignId).get().then((doc) => {
            this.pdf = doc.data().pdf;
            this.images = doc.data().images;
            this.items = doc.data().items;
            this.team = doc.data().team;
            doc.data().team.forEach((member) => {
                db.collection("users").doc(member).get().then((user) => {
                    var userObj = user.data();
                    userObj.id = user.id;
                    this.teamObj.push(userObj);
                });
            });
        });
        this.initUserSearch();
        db.collection("shop-products").get().then((products) => {
            products.forEach((product) => {
                if (this.autocompleteProductNames.includes(product.data().name)) return;
                this.autocompleteProductNames.push(product.data().name);
                var productObj = product.data();
                productObj.id = product.id;
                this.autocompleteProductObj.push(productObj);
            });
        });
        db.collection("crowdfunding").doc(this.campaignId).collection("funding").orderBy("amount", "desc").get().then((funders) => {
            funders.forEach((funder) => {
                var funderObj = funder.data();
                funderObj.id = funder.id;
                funderObj.picture = "";
                if (!funderObj.name) funderObj.name = "Anonymous";
                if (funderObj.uid) {
                    db.collection("users").doc(funder.uid).get().then((funderUser) => {
                        if (funderUser.data().picture) funderObj.picture = funderUser.data().picture;
                    });
                } else {
                    this.funders.push(funderObj);
                }
            });
        });
    },
    components: {
        AppDebouncedSearchBox,
        AutocompleteSelect
    },
}
</script>
<style scoped>
.team-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}

.container {
    padding-bottom: 40px;
}

iframe {
    width: 100%;
    height: 250px;
}

.contributer {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

td p {
    padding-top: 8px;
}

.table.items {
    min-width: 425px;
}

.table tr.is-selected {
    background-color: #ffdd57;
    color: #fff;
}

button.is-warning {
    width: 100%;
    font-weight: 700;
}

span.thanks {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: auto;
    background: #ffdd57;
    padding: 10px 30px;
    text-align: center;
}

.categorycard {
    width: auto !important;
}

.categorycard .card {
    position: relative;
}

.categorycard .card i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    /* Change color as per requirement */
    font-size: 24px;
    /* Adjust size as per requirement */
    pointer-events: none;
    /* So the icon doesn't block interactions with the image */
}

.qcontainer {
    max-width: 800px;
    margin: auto;
    margin-bottom: 30px;
}

.qcontainer .question {
    background-color: #181b1d;
    padding: 15px 25px;
    border-radius: 20px 20px 0 0;
}

.qcontainer .question p strong {
    font-size: 25px !important;
    color: #fff;
}

.qcontainer .answer {
    background-color: #3b3b3b;
    padding: 15px 25px;
    font-size: 16px;
    color: #fff;
    border-radius: 0 0 20px 20px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
}

.popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.5);
    display: none;
    z-index: 10 !important;
}

.popup .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.modal-content {
    padding: 7%;
}

.image-container {
    position: relative;
    display: inline-block;
    /* This makes the container fit the size of the image */
}

.top-right {
    position: absolute;
    top: 10px;
    /* Padding from the top */
    right: 10px;
    /* Padding from the right */
}

.file-label {
    font-family: 'Poppins', sans-serif;
}

.images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two equal-width columns */
    /* Optional: Set other styling properties like grid-gap, width, height, etc. */

}

.image-container {
    margin: 5px;
}

.image-container img {
    aspect-ratio: 4/3;
}

.images span {
    position: absolute;
    top: 0;
    left: 0;
    margin: 7px;
    padding: 2.5px;
    background: #fff;
    color: #000000;
    font-weight: 700;
    border-radius: 5px;
    font-size: 12px;
    line-height: 1;
    width: 20px;
    height: 20px;
    text-align: center;
    z-index: 1;
}

.team-grid .card .card-image figure img {
    aspect-ratio: 1/1;
    background-color: #5041e1;
}
</style>