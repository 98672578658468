<template>
    <section class="section">
        <div class="container">

            <!-- Title & Description -->
            <div class="content">
                <h1 class="title is-3">
                    {{title}}
                </h1>
            </div>
            <div class="box">
                <div class="column">
                    <div class="scrollingwrapper">
                        <div v-if="video" class="scrolling-card">
                            <video controls autoplay muted loop :src="video"></video>
                        </div>
                        <div v-for="img in images" :key="img" class="scrolling-card">
                            <img :src="img" class="v-lazy-image v-lazy-image-loaded">
                        </div>
                    </div>
                </div>
                <div class="field has-addons is-centered" style="justify-content: center;">
                    <p class="control">
                        <button class="button">
                            <span class="icon">
                                <i class="fas fa-address-book"></i>
                            </span>
                            <span>Share with contacts</span>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button" @click="copyLink('https://app.witblox.com/crowdfunding/' + campaignId)">
                            <span class="icon">
                                <i class="fas fa-link"></i>
                            </span>
                            <span>
                                Copy link
                            </span>
                        </button>
                    </p>
                </div>
            </div>
            <!-- Funding Details -->
            <div class="box">
                <div class="columns is-mobile">
                    <div class="column">
                        <h2 style="padding-top: 38px" class="title is-4">Funding Goal</h2>
                    </div>
                    <div class="column is-4">
                        <img src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/attachments%2FEq9fehXbdO.gif?alt=media&token=4f9f4705-f49c-43f9-b68e-87557edb1568"
                            alt="">
                    </div>
                </div>
                <progress class="progress is-warning" :value="(current / total) * 100" max="100">{{ (current / total) *
                    100 }}%</progress>
                <p><strong>&#8377;{{ addCommaToNumber(current) }}</strong> of <strong>&#8377;{{ addCommaToNumber(total)
                }}</strong> raised</p>
            </div>

            <!-- Rewards Section -->
            <div class="box shadow">
                <h2 class="title is-4">Back this project</h2>
                <div v-if="registered" class="columns is-mobile" style="padding: 30px">
                    <div class="columns" style="position: relative;">
                        <video autoplay muted loop src="@/assets/animation_llf0urb0.mp4"></video>
                        <span class="thanks">
                            <b>Thankyou for your support!</b>
                        </span>
                    </div>
                </div>
                <div v-else class="columns is-mobile" style="padding: 30px">
                    <div class="columns">
                        <div class="control has-icons-left has-icons-right">
                            <input class="input is-large" type="text" placeholder="Name" v-model="name">
                            <span class="icon is-medium is-left">
                                <i class="fas fa-user-shield"></i>
                            </span>
                        </div>
                        <div class="control has-icons-left has-icons-right">
                            <input class="input is-large" type="number" placeholder="Amount" v-model="amount">
                            <span class="icon is-medium is-left">
                                <i class="fas fa-rupee-sign"></i>
                            </span>
                        </div>
                        <button class="button is-large is-warning" @click="handlePayment">Fund us</button>
                        <button class="button is-fullwidth is-hidden" style="margin-top:10px">
                            Offer Mentorship
                        </button>
                    </div>
                </div>
            </div>
            <div class="box">
                <h2 class="title is-4">Budget</h2>
                <div class="table-container">
                    <table class="table items">
                        <thead>
                            <tr>
                                <th><abbr title="Image"></abbr></th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Qty</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in items" :key="item.id">
                                <th>
                                    <img v-if="item.image" :src="item.image" alt="" class="contributer">
                                    <img v-else src="@/assets/img/user.png" alt="" class="contributer">
                                </th>
                                <td>
                                    <p class="subtitle is-6">
                                        {{ item.name }}
                                    </p>
                                </td>
                                <td>
                                    <p class="is-6">
                                        &#8377;{{ addCommaToNumber(item.price) }}
                                    </p>
                                </td>
                                <td>
                                    <p class="is-6">
                                        {{ item.qty }}
                                    </p>
                                </td>
                                <td>
                                    <p class="subtitle is-6">
                                        &#8377;{{ addCommaToNumber(item.total) }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="subtitle is-5">
                    Total = &#8377;{{ addCommaToNumber(total) }}
                </p>
            </div>
            <!-- Project Details and Story -->
            <div class="box shadow">
                <h2 class="title is-5">More about the project</h2>
                <div class="app-header">
                    <template v-if="isLoading">
                        Loading...
                    </template>

                    <template v-else>
                        <span v-if="showAllPages">
                            {{ pageCount }} page(s)
                        </span>

                        <span v-else>
                            <button :disabled="page <= 1" @click="page--">❮</button>

                            {{ page }} / {{ pageCount }}

                            <button :disabled="page >= pageCount" @click="page++">❯</button>
                        </span>

                        <label class="right">
                            <input v-model="showAllPages" type="checkbox">

                            Show all pages
                        </label>
                    </template>
                </div>

                <div class="app-content" :class="{'fullscreen': pdfFullscreen}">
                    <vue-pdf-embed ref="pdfRef" :source="pdfSource" :page="page" @password-requested="handlePasswordRequest"
                        @rendered="handleDocumentRender" />
                </div>
                <a class="button is-fullwidth" @click="fullScreenPdf(true)">
                    View fullscreen
                </a>
                <p style="padding-top: 30px">Detailed description of the project, its objectives, and how the funds will
                    be
                    used.</p>
            </div>

            <!-- Comments or Updates Section -->
            <div class="box">
                <h2 class="title is-4">Project Updates</h2>
                <div class="columns">
                    <div class="column">
                        <div v-for="post in posts" :key="post.id" class="categorycard">
                            <div @click="$router.push('social/' + post.id)" class="card" style="max-width: 90px">
                                <img
                                    :src="'https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=' + encodeURI(post.video) + '&size=400&mode=orig'" />
                                <i class="fas fa-play"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box">
                <h2 class="title is-4">The Team</h2>
                <div class="images" style="padding-top:40px">
                    <div class="image-container" v-for="member in teamObj" :key="member.id">
                        <div class="card" @click="$router.push('/u/' + member.id)">
                            <div class="card-image">
                                <figure class="image">
                                    <img :src="member.picture" alt="Placeholder image">
                                </figure>
                            </div>
                            <div class="card-content">
                                <p class="title is-6">{{ member.fname }} {{ member.lname }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box">
                <h2 class="title is-4">Supporters</h2>
                <table class="table is-fullwidth">
                    <tbody>
                        <tr v-for="(funder, index) in funders" :key="funder.id" :class="{ 'is-selected': index == 0 }">
                            <th>
                                <img v-if="funder.picture" :src="funder.picture" alt="" class="contributer">
                                <img v-else src="@/assets/img/user.png" alt="" class="contributer">
                            </th>
                            <td>
                                <p class="subtitle is-6">&#8377;{{ funder.amount }}</p>
                            </td>
                            <td>
                                <p class="subtitle is-6">
                                    {{ funder.name }}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="box shadow">
                <div class="columns is-mobile">
                    <div class="column">
                        <h2 class="title is-4" style="padding-top: 6px;">Ask your question</h2>
                    </div>
                </div>
                <div v-if="!qSent" class="columns">
                    <div class="column">
                        <div class="field">
                            <p class="control">
                                <textarea v-model="qInput" class="textarea"
                                    placeholder="Write your question here"></textarea>
                            </p>
                        </div>
                    </div>
                </div>
                <div v-if="!qSent" class="columns">
                    <div class="column">
                        <button class="button is-fullwidth" @click="qSubmit">
                            Send
                        </button>
                    </div>
                </div>
                <div v-if="qSent" class="columns">
                    <div class="column">
                        <article class="message is-primary">
                            <div class="message-body">
                                Thanks for your question! We'll let you know when the team responds.
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <div class="box shadow">
                <div class="columns is-mobile">
                    <div class="column">
                        <h2 class="title is-4" style="padding-top: 6px;">FAQs</h2>
                    </div>
                    <div class="column is-hidden">
                        <button class="button is-warning">
                            Ask a question
                        </button>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="qcontainer" v-for="q in questions" :key="q.id">
                            <div class="question">
                                <p>
                                    <strong>{{ q.q }}</strong>
                                </p>
                            </div>
                            <div v-if="q.answered" class="answer">
                                <p>
                                    {{ q.a }}
                                </p>
                            </div>
                            <div v-else class="answer">
                                <p style="font-size: 0.8rem;">
                                    Waiting for answer.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns is-hidden">
                    <div class="column">
                        <div class="qcontainer">
                            <div class="question">
                                <p>
                                    <strong>Who can use this platform?</strong>
                                </p>
                            </div>
                            <div class="answer">
                                <p>
                                    Anyone building a Hobby Project or doing some self funded research project or
                                    preparing
                                    to present their work in an Exhibition can use this platform to raise funds to
                                    complete
                                    their work and also get support from Mentors who have previously worked in a similar
                                    Project.
                                </p>
                            </div>
                        </div>
                        <div class="qcontainer">
                            <div class="question">
                                <p>
                                    <strong>Why should you support a WitBlox Maker?</strong>
                                </p>
                            </div>
                            <div class="answer">
                                <p>
                                    This whole world is built on the work of Innovators and Inventors. However small an
                                    Idea, A Maker working on an Idea gains experience, knowledge and confidence. Every
                                    Project changes the maker in a good way. If you like his/her work, participate in
                                    his
                                    transformation. And who knows, the small Idea the maker is working on has the power
                                    to
                                    change the world.
                                </p>
                            </div>
                        </div>
                        <div class="qcontainer">
                            <div class="question">
                                <p>
                                    <strong>What is the minimum support amount?</strong>
                                </p>
                            </div>
                            <div class="answer">
                                <p>
                                    You can contribute any amount of money you feel comfortable with. You can also
                                    volunteer
                                    to mentor the Maker on the project if you have worked or experience in a similar
                                    domain.
                                    We will connect you with the Maker.
                                </p>
                            </div>
                        </div>
                        <div class="qcontainer">
                            <div class="question">
                                <p>
                                    <strong>Will the funds raised be used for this project ?</strong>
                                </p>
                            </div>
                            <div class="answer">
                                <p>
                                    Yes, We have checks and balances to ensure that funds raised through this process
                                    are
                                    used and all information is transparent. The Maker prepares a budget and list of all
                                    the
                                    materials required for the Project. Most of the materials required to build the
                                    Project
                                    are procured and supplied to the Maker through WitBlox. The Maker posts regular
                                    updates
                                    on the Projects and also discusses his/her work with the mentor.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal">
            <div class="modal-background"></div>
            <div class="modal-content">
                <article class="message is-warning">
                    <div class="message-body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. <strong>Pellentesque risus mi</strong>,
                        tempus quis placerat ut, porta nec nulla. Vestibulum rhoncus ac ex sit amet fringilla. Nullam
                        gravida purus diam, et dictum <a>felis venenatis</a> efficitur. Aenean ac <em>eleifend
                            lacus</em>,
                        in mollis lectus. Donec sodales, arcu et sollicitudin porttitor, tortor urna tempor ligula, id
                        porttitor mi magna a neque. Donec dui urna, vehicula et sem eget, facilisis sodales sem.
                    </div>
                </article>
            </div>
            <button class="modal-close is-large" aria-label="close"></button>
        </div>
        <div class="pdfClose" v-if="pdfFullscreen">
            <button class="button is-warning is-fullwidth" @click="fullScreenPdf(false)">
                Close
            </button>
        </div>
    </section>
</template>
<script>
import { db } from "@/main.js";
import { auth } from "@/main.js";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
// import Flickity from "vue-flickity";
// import { videoPlayer } from "vue-video-player";

export default {
    name: "crowdfunding",
    components: {
        VuePdfEmbed,
        // Flickity,
        // videoPlayer,
    },
    data() {
        return {
            campaignId: this.$route.params.id,
            flickityOptions: {
                initialIndex: 0,
                prevNextButtons: true,
                pageDots: true,
                wrapAround: true,
                autoPlay: true,
                // any options from Flickity can be used
            },
            vid1: {
                height: "100%",
                width: "100%",
                autoplay: false,
                muted: false,
                language: "en",
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [],
                poster: "",
                loaded: false,
            },
            total: 10000,
            current: 0,
            script: "https://checkout.razorpay.com/v1/checkout.js",
            isPaymentLoading: false,
            amount: 101,
            registered: false,
            err: false,
            name: "",
            funders: [],
            items: [
                {
                    id: "1",
                    name: "Raspberry Pi 4",
                    price: 1000,
                    qty: 1,
                    image: "https://in.element14.com/productimages/large/en_GB/3051885-40.jpg",
                    total: 1000,
                },
                {
                    id: "2",
                    name: "Breadboard",
                    price: 70,
                    qty: 2,
                    image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Beginning.jpg/440px-Beginning.jpg",
                    total: 70 * 2,
                },
                {
                    id: "3",
                    name: "Jumper Wires",
                    price: 20,
                    qty: 10,
                    image: "https://robu.in/wp-content/uploads/2016/01/40pcs-20cm-2-54MM-Male-to-Female-for-Dupont-Wire-Jump-Jumper-Cables-For-Arduino-Shield.jpg",
                    total: 20 * 10,
                },
            ],
            posts: [
                {
                    id: "MZv8R28kgimFS7Aqh9fn",
                    video: "https://witblox-social-videos-2.s3.ap-south-1.amazonaws.com/MZv8R28kgimFS7Aqh9fn.mp4"
                },
                {
                    id: "kj2b5TeM1vPbEPh24wge",
                    video: "https://witblox-social-videos-2.s3.ap-south-1.amazonaws.com/kj2b5TeM1vPbEPh24wge.mp4"
                }
            ],
            //
            isLoading: true,
            page: null,
            pageCount: 1,
            pdfSource: 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/web/compressed.tracemonkey-pldi-09.pdf',
            pdfFullscreen: false,
            showAllPages: true,
            images: [],
            teamObj: [],
            questions: [],
            qInput: "",
            qSent: false,
            title: "",
            video: ""
        }
    },
    watch: {
        showAllPages() {
            this.page = this.showAllPages ? null : 1
        },
    },
    methods: {
        fullScreenPdf(action) {
            this.pdfFullscreen = action;
            setTimeout(() => {
                this.$refs.pdfRef.render();
            }, 50);
        },
        qSubmit() {
            console.log(this.qInput);
            this.qSent = true;
            var askedBy = "";
            if (auth.currentUser.uid) askedBy = auth.currentUser.uid;
            db.collection("crowdfunding").doc(this.campaignId).collection("questions").add({
                a: "",
                answered: false,
                answeredBy: "",
                askedBy: askedBy,
                q: this.qInput,
            });
        },
        handleDocumentRender() {
            this.isLoading = false
            this.pageCount = this.$refs.pdfRef.pageCount
        },
        handlePasswordRequest(callback, retry) {
            callback(prompt(retry
                ? 'Enter password again'
                : 'Enter password'
            ))
        },
        async loadRazorPay() {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = this.script;
                script.onload = () => {
                    resolve(true);
                };
                script.onerror = () => {
                    resolve(false);
                };
                document.body.appendChild(script);
            });
        },
        async handlePayment() {
            if (!this.amount || parseInt(this.amount) < 1)
                return alert("Please enter valid amount");

            this.isPaymentLoading = true;
            const t = await fetch(
                "https://us-central1-witblox-5ae68.cloudfunctions.net/kbcRegistration",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        amount: parseInt(this.amount),
                        currency: "INR",
                    }),
                }
            );
            const data = await t.json();
            const isLoaded = await this.loadRazorPay();
            if (!isLoaded) return alert("Razorpay SDK failed to load.");
            const options = {
                key: "rzp_live_LxqoqZ6JH2rIT4",
                amount: data.data.amount,
                currency: data.data.currency,
                name: "WitBlox",
                description: "KBC Registration",
                image: "https://witblox.com/assets/compressed/witblox.png",
                order_id: data.data.id,
                "prefill": {
                    "name": this.name,
                },
                handler: function (response) {
                    console.log(response);
                    this.isPaymentLoading = false;
                    var userAuth = null;
                    if (auth.currentUser.uid) this.userAuth = auth.currentUser.uid;
                    if (!this.name) this.name = "Anonymous";
                    db.collection("crowdfunding").doc(this.campaignId).collection("funding")
                        .add({
                            amount: parseInt(data.data.amount) / 100,
                            razorpay_details: {
                                razorpay_order_id: data.data.id,
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_signature: response.razorpay_signature,
                            },
                            uid: userAuth,
                            created: new Date().getTime(),
                            points: 0,
                            name: this.name
                        })
                        .then(() => {
                            console.log("Payment successful");
                            this.registered = true;
                        })
                        .catch((err) => {
                            console.log(err);
                            alert("Payment failed");
                        });
                },
                theme: {
                    color: "#3399cc",
                },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.on("payment.failed", function (err) {
                this.isPaymentLoading = false;
                console.log("Payment failed", err);
            });
            rzp1.open();
            this.isPaymentLoading = false;
        },
        addCommaToNumber(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        copyLink(link) {
            navigator.clipboard.writeText(link).then(() => {
                alert("Link copied to clipboard!");
            });
        }
    },
    beforeMount() {
        console.log("campaignId", this.campaignId);
        //fetch data
        db.collection("crowdfunding").doc(this.campaignId).get().then((doc) => {
            console.log(doc.data());
            this.images = doc.data().images;
            this.vid1.poster = doc.data().images[0];
            this.title = doc.data().title;
            this.video =  doc.data().video;
            this.items = doc.data().items;
            setTimeout(() => {
                this.vid1.loaded = true;
            }, 100);
            //
            doc.data().team.forEach((member) => {
                db.collection("users").doc(member).get().then((user) => {
                    var userObj = user.data();
                    userObj.id = user.id;
                    this.teamObj.push(userObj);
                });
            });
        });
        //fetch transactions
        db.collection("crowdfunding").doc(this.campaignId).collection("funding").orderBy("amount", "desc").get().then((funders) => {
            funders.forEach((funder) => {
                var funderObj = funder.data();
                this.current = this.current + Number(funderObj.amount);
                funderObj.id = funder.id;
                funderObj.picture = "";
                if (!funderObj.name) funderObj.name = "Anonymous";
                if (funderObj.uid) {
                    db.collection("users").doc(funder.uid).get().then((funderUser) => {
                        if (funderUser.data().picture) funderObj.picture = funderUser.data().picture;
                    });
                } else {
                    this.funders.push(funderObj);
                }
            });
        });
        //fetch questions
        db.collection("crowdfunding").doc(this.campaignId).collection("questions").where("answered", "==", true).orderBy("added").get().then((questions) => {
            questions.forEach((q) => {
                var qObj = q.data();
                qObj.id = q.id;
                this.questions.push(qObj);
            });
        });
    },
    mounted() {
        this.handleDocumentRender();
    }
}
</script>
<style scoped>
.container {
    padding-bottom: 40px;
}

iframe {
    width: 100%;
    height: 250px;
}

.contributer {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

td p {
    padding-top: 8px;
}

.table.items {
    min-width: 425px;
}

.table tr.is-selected {
    background-color: #ffdd57;
    color: #fff;
}

button.is-warning {
    width: 100%;
    font-weight: 700;
}

span.thanks {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: auto;
    background: #ffdd57;
    padding: 10px 30px;
    text-align: center;
}

.categorycard {
    width: auto !important;
}

.categorycard .card {
    position: relative;
}

.categorycard .card i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    /* Change color as per requirement */
    font-size: 24px;
    /* Adjust size as per requirement */
    pointer-events: none;
    /* So the icon doesn't block interactions with the image */
}

.qcontainer {
    max-width: 800px;
    margin: auto;
    margin-bottom: 30px;
}

.qcontainer .question {
    background-color: #181b1d;
    padding: 15px 25px;
    border-radius: 20px 20px 0 0;
}

.qcontainer .question p strong {
    font-size: 25px !important;
    color: #fff;
}

.qcontainer .answer {
    background-color: #3b3b3b;
    padding: 15px 25px;
    font-size: 16px;
    color: #fff;
    border-radius: 0 0 20px 20px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
}

.popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.5);
    display: none;
    z-index: 10 !important;
}

.popup .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 90%;
    max-width: 500px;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.modal-content {
    padding: 7%;
}

.vue-pdf-embed {
    height: 400px;
    overflow: auto;
}

.app-content.fullscreen .vue-pdf-embed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-bottom: 50px;
}

.vue-pdf-button {
    display: none;
}

.app-content.fullscreen .vue-pdf-button {}

.vue-pdf-embed>div {
    margin-bottom: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-header {
    padding: 16px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    background-color: #555;
    color: #ddd;
}

.app-content {
    padding: 24px 16px;
    border: 1px solid #ddd;
}

.right {
    float: right;
}

.scrollingwrapper .scrolling-card {
    min-width: 80%;
    height: 200px;
}

.scrollingwrapper .scrolling-card video {
    width: 100%;
    height: 100%;
    border: 2px solid;
    border-radius: 10px;
}

.scrollingwrapper .scrolling-card img {
    width: 100%;
    height: 100%;
}

.images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two equal-width columns */
    /* Optional: Set other styling properties like grid-gap, width, height, etc. */

}

.image-container {
    margin: 5px;
}

.image-container img {
    aspect-ratio: 1/1;
}

.app-content.fullscreen .vue-pdf-embed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pdfClose {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #fff;
    z-index: 10;
    padding-bottom: 60px;
}
</style>