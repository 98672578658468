<template>
  <div class="homescreen" :style="color">
    <div class="homeprofile">
      <div class="homeheader columns is-mobile">
        <div class="column is-3">
          <a @click="router('/profile')">
            <div
              v-bind:style="{ 'background-image': image }"
              v-if="$store.state.profile.picture === ''"
              class="homeprofileimg"
            ></div>
            <div
              :style="$store.state.profile.picture"
              v-else
              class="homeprofileimg"
            ></div>
          </a>
        </div>
        <div class="column is-7">
          <h6 class="home title" :style="textColor">
            Hi
            <span>
              <span
                v-if="$store.state.profile.fname === ''"
                class="preload"
                style="width: 100px"
              ></span>
              <span v-else>{{ $store.state.profile.fname }}</span>
            </span>
            <img src="../assets/img/hi.png" width="28px" />
          </h6>
        </div>
        <div class="column is-2">
          <a @click="router('/search')" class="searchIcon">
            <i class="fas fa-search"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="columns featured is-mobile">
        <div class="column">
          <div
            @click="htp = true"
            class="card depth2"
            v-bind:style="{ 'background-image': banner }"
          >
            <div class="featured-banner">How To Participate</div>
            <h6 class="featured-headline" style="font-size: 1.25rem">
              {{ cardtext }}
            </h6>
          </div>
        </div>
      </div>
      <div v-if="submissionexist" class="columns">
        <div class="column">
          <h6 class="preview-headline subtitle has-text-centered">
            My Submissions
          </h6>
          <div class="columns is-mobile submissions">
            <div
              v-for="theme in submittedthemes"
              :key="theme.id"
              @click="showSubmisison(theme.id)"
              class="column is-3"
            >
              <img :src="theme.img" />
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <h6 class="preview-headline subtitle has-text-centered">
            Select a theme to participate
          </h6>
        </div>
      </div>
      <div class="themes">
        <div v-for="card in themes" :key="card.id" class="themecard">
          <img @click="router('/festtheme/' + card.id)" :src="card.img" />
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <h6 class="preview-headline subtitle has-text-centered">
            Learn More
          </h6>
        </div>
      </div>
      <div class="themes">
        <div class="themecard">
          <img @click="timeline = true" :src="contents.timeline" />
        </div>
        <div class="themecard">
          <img @click="rules = true" :src="contents.rules" />
        </div>
        <div class="themecard">
          <img @click="faqs = true" :src="contents.faqs" />
        </div>
        <div class="themecard">
          <img @click="eligibility = true" :src="contents.eligibility" />
        </div>
      </div>
      <div class="columns">
        <div class="column" style="padding-top: 50px"></div>
      </div>
      <!-- submission -->
      <div v-if="submissionbox" class="tfcontent modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Your Submission</p>
            <button
              @click="submissionbox = false"
              class="delete"
              aria-label="close"
            ></button>
          </header>
          <div class="modal-card-body">
            <!-- <h3 class="subtitle">Idea</h3>
            <p>
              <b>Title: </b> {{ submission.title }} <br /><br />
              <b>Description: </b>
              <br />
              {{ submission.desc }}
            </p>
            <hr /> -->
            <h3 class="subtitle">Status</h3>
            <div class="notification is-primary is-light">
              <span v-html="status"></span>
            </div>
            <!-- <hr />
            <h3 class="subtitle">Recommendation</h3>
            <div class="scrollingwrapper">
              <a
                @click="router('/project/' + project.id)"
                class="scrolling-card"
                :key="project.index"
                v-for="project in submission.recommendation"
              >
                <v-lazy-image
                  :src-placeholder="require('@/assets/img/home-lazy.png')"
                  :src="project.image"
                  @error="
                    post.thumbnail = require('@/assets/img/home-lazy.png')
                  "
                />
              </a>
            </div> -->
            <!--
            <hr />
            <h3 class="subtitle">Submitted Video</h3>
            <video-player
              v-if="vid1.loaded"
              class="vjs-custom-skin"
              ref="videoPlayer"
              :options="vid1"
              :playsinline="true"
            ></video-player>
            <p v-else>You have not uploaded a video yet.</p>
            <hr />
            <h3 class="subtitle">Team</h3>
            <p>
              <b>#1: </b>{{ submission.team1.name }} <br />
              {{ submission.team1.email }}<br /><br />
              <b>#2: </b>{{ submission.team2.name }} <br /><br />
              {{ submission.team2.email }}<br /><br />
              <b>#3: </b>{{ submission.team3.name }}<br />
              {{ submission.team1.email }}<br /><br />
            </p> -->
          </div>
          <footer class="modal-card-foot">
            <button
              v-if="submission.stage != 4 && submission.stage != 5"
              class="button"
              @click="router('/festtheme/' + submission.id)"
            >
              Go To Theme
            </button>
            <button
              v-if="submission.stage === 4"
              class="button"
              @click="router('/upload/story/' + submission.theme + '/0')"
            >
              Upload Video
            </button>
            <button
              v-if="submission.stage === 5"
              class="button"
              @click="router('/upload/story/' + submission.theme + '/0')"
            >
              Re-Upload Video
            </button>
          </footer>
        </div>
      </div>
      <!-- /submission -->
      <!-- how-to-participate -->
      <div v-if="htp" class="tfcontent modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">How to Participate</p>
            <button
              @click="htp = false"
              class="delete"
              aria-label="close"
            ></button>
          </header>
          <section class="modal-card-body" v-html="content.htp"></section>
          <footer class="modal-card-foot">
            <button class="button" @click="htp = false">Done</button>
          </footer>
        </div>
      </div>
      <!-- /how-to-participate -->
      <!-- rules -->
      <div v-if="rules" class="tfcontent modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Rules</p>
            <button
              @click="rules = false"
              class="delete"
              aria-label="close"
            ></button>
          </header>
          <section class="modal-card-body" v-html="content.rules"></section>
          <footer class="modal-card-foot">
            <button class="button" @click="rules = false">Done</button>
          </footer>
        </div>
      </div>
      <!-- /rules -->
      <!-- eligibility -->
      <div v-if="eligibility" class="tfcontent modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Eligibility</p>
            <button
              @click="eligibility = false"
              class="delete"
              aria-label="close"
            ></button>
          </header>
          <section
            class="modal-card-body"
            v-html="content.eligibility"
          ></section>
          <footer class="modal-card-foot">
            <button class="button" @click="eligibility = false">Done</button>
          </footer>
        </div>
      </div>
      <!-- /eligibility -->
      <!-- timeline -->
      <div v-if="timeline" class="tfcontent modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Timeline</p>
            <button
              @click="timeline = false"
              class="delete"
              aria-label="close"
            ></button>
          </header>
          <section class="modal-card-body" v-html="content.timeline"></section>
          <footer class="modal-card-foot">
            <button class="button" @click="timeline = false">Done</button>
          </footer>
        </div>
      </div>
      <!-- /timeline -->
      <!-- faqs -->
      <div v-if="faqs" class="tfcontent modal is-active">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">FAQs</p>
            <button
              @click="faqs = false"
              class="delete"
              aria-label="close"
            ></button>
          </header>
          <section class="modal-card-body" v-html="content.faqs"></section>
          <footer class="modal-card-foot">
            <button class="button" @click="faqs = false">Done</button>
          </footer>
        </div>
      </div>
      <!-- /faqs -->
    </div>
  </div>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { db } from "@/main.js";
// const { App } = Plugins;
// import VLazyImage from "v-lazy-image";
// import { videoPlayer } from "vue-video-player";
export default {
  name: "techfest",
  components: {
    // VLazyImage,
    // videoPlayer,
  },
  data() {
    return {
      screen: "home2",
      projectstatus: true,
      fname: "",
      image: "url(" + require("@/assets/img/user.png") + ")",
      picture: "",
      banner: "",
      coins: 0,
      themes: [],
      contents: {},
      submissionbox: false,
      submissionexist: false,
      color: "",
      textColor: "",
      cardtext: "",
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster:
          "https://surmon-china.github.io/vue-quill-editor/static/images/surmon-1.jpg",
        loaded: false,
      },
      videoexist: false,
      submission: {
        theme: "",
        id: "",
        title: "",
        desc: "",
        status: "",
        recommendation: [],
        team1: {
          name: "",
          email: "",
        },
        team2: {
          name: "",
          email: "",
        },
        team3: {
          name: "",
          email: "",
        },
      },
      status: "",
      submittedthemes: [],
      rules: false,
      eligibility: false,
      timeline: false,
      faqs: false,
      htp: false,
      content: {
        timeline: "",
        rules: "",
        faqs: "",
        eligibility: "",
        htp: "",
      },
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 82%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
    showSubmisison(id) {
      console.log(id);
      //   this.vid1.sources = [];
      //   this.vid1.loaded = false;
      this.submissionbox = true;
      //   this.submission.id = id;
      db.collection("config")
        .doc("techfest2")
        .collection("submissions")
        .doc(id)
        .get()
        .then((doc) => {
          //   this.submission.theme = doc.data().theme;
          //   this.submission.title = doc.data().idea.title;
          //   this.submission.desc = doc.data().idea.desc;
          //   this.submission.team1.name = doc.data().team[0].name;
          //   this.submission.team1.email = doc.data().team[0].email;
          //   this.submission.team2.name = doc.data().team[1].name;
          //   this.submission.team2.email = doc.data().team[1].email;
          //   this.submission.team3.name = doc.data().team[2].name;
          //   this.submission.team3.email = doc.data().team[2].email;
          //   this.submission.stage = doc.data().stage;
          //   this.submission.recommendation = [];
          //   auth.onAuthStateChanged((user) => {
          //     db.collection("social")
          //       .where("tag", "==", doc.data().theme)
          //       .where("uid", "==", user.uid)
          //       .get()
          //       .then((snap) => {
          //         if (!snap.empty) {
          //           this.vid1.sources.push({
          //             type: "video/mp4",
          //             src: snap.docs[0].data().url,
          //           });
          //           this.vid1.loaded = true;
          //         } else console.log("no video");
          //       });
          //   });
          console.log(doc.data());
          db.collection("config")
            .doc("techfest2")
            .collection("themes")
            .doc(doc.data().theme)
            .get()
            .then((doc2) => {
              console.log(doc2.data());
              //   doc2.data().projects.forEach((project) => {
              //     console.log(project);
              //     db.collection("projects")
              //       .doc(project)
              //       .get()
              //       .then((prjct) => {
              //         this.submission.recommendation.push({
              //           id: prjct.id,
              //           image: prjct.data().featuredImg,
              //         });
              //       });
              //   });
              console.log(doc2.data().status[doc.data().stage]);
              this.status = doc2.data().status[doc.data().stage];
              this.submission.id = doc.data().theme;
            });
        });
    },
  },
  beforeMount() {
    this.getColor();
    db.collection("config")
      .doc("techfest2")
      .get()
      .then((doc) => {
        this.cardtext = doc.data().cardtext;
        this.content.htp = doc.data().htp;
        this.content.timeline = doc.data().timeline;
        this.content.rules = doc.data().rules;
        this.content.faqs = doc.data().faqs;
        this.content.eligibility = doc.data().eligibility;
        this.contents = doc.data().contents;
        this.banner = "url(" + doc.data().techfestbanner + ")";
      });
    db.collection("config")
      .doc("techfest2")
      .collection("themes")
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          this.themes.push({
            id: doc.id,
            img: doc.data().img,
          });
        });
      });
    auth.onAuthStateChanged((user) => {
      console.log(user.uid);
      this.fname = user.displayName;
      if (user.photoURL) {
        this.picture =
          "background: url(" +
          user.photoURL +
          "), linear-gradient(to right, rgb(142, 45, 226), rgb(74, 0, 224))";
      }
      db.collection("config")
        .doc("techfest2")
        .collection("submissions")
        .where("uid", "==", user.uid)
        .get()
        .then((snap) => {
          this.submissionexist = !snap.empty;
          snap.forEach((doc) => {
            console.log(doc.data());
            db.collection("config")
              .doc("techfest2")
              .collection("themes")
              .doc(doc.data().theme)
              .get()
              .then((doc2) => {
                console.log(doc2.id);
                this.submittedthemes.push({
                  id: doc.id,
                  img: doc2.data().img,
                });
              });
          });
        });
    });
  },
};
</script>
<style>
.themes img {
  box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
  border-radius: 10px;
}
.tfcontent {
  padding: 30px 25px 80px 25px;
  z-index: 1111111;
}
.tfcontent .modal-background {
  background: rgba(10, 10, 10, 0.86);
}
.tfcontent .modal-card-title {
  font-weight: 700;
}
.button.is-fullwidth {
  color: #5a3e90;
  border: 4px solid #5a3e90;
  background: #ffffff94;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 100%;
}
.themecard {
  width: 50%;
  display: inline-block;
  padding: 0 6px 10px 6px;
}
.submissions img {
  border-radius: 50%;
  border: 3px solid #5a3e90;
}
</style>