<template>
  <div class="homescreen" :style="color">
    <!-- intro -->
    <div class="homeprofile">
      <div class="homeheader columns is-mobile">
        <div class="column is-3">
          <a @click="router('/profile')">
            <div
              v-bind:style="{ 'background-image': image }"
              v-if="$store.state.profile.picture === ''"
              class="homeprofileimg"
            ></div>
            <div
              :style="$store.state.profile.picture"
              v-else
              class="homeprofileimg"
            ></div>
          </a>
        </div>
        <div class="column is-7">
          <h6 class="home title" :style="textColor">
            Hi
            <span>
              <span
                v-if="$store.state.profile.fname === ''"
                class="preload"
                style="width: 100px"
              ></span>
              <span v-else>{{ $store.state.profile.fname }}</span>
            </span>
            <img src="../assets/img/hi.png" width="28px" />
          </h6>
        </div>
        <div class="column is-2">
          <a @click="router('/search')" class="searchIcon">
            <i class="fas fa-search"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- /intro -->
    <div class="section">
      <img
        v-if="myclasses"
        src="@/assets/img/myclasses.jpg"
        style="border-radius: 15px"
        @click="router('/schedule')"
      />
      <a
        v-for="(challenge, index) in challenges"
        @click="router('/challenge/' + challenge.id)"
        :key="index"
      >
        <v-lazy-image
          :src-placeholder="require('@/assets/img/class-lazy.png')"
          :src="challenge.featuredImg"
          class="homebanner"
        />
      </a>
      <div class="columns">
        <div class="column" style="padding-top: 50px"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import { Plugins } from "@capacitor/core";
// import { auth } from "@/main.js";
import { db } from "@/main.js";
// const { App } = Plugins;
import VLazyImage from "v-lazy-image";
export default {
  name: "classhome",
  components: {
    VLazyImage,
  },
  data() {
    return {
      image: "url(" + require("@/assets/img/user.png") + ")",
      challengetag: "#Featured",
      cid: "",
      banner: "",
      coins: 0,
      challenges: [],
      comingsoon: false,
      color: "",
      textColor: "",
      myclasses: false,
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
    fetchChallenges() {
      db.collection("challenges")
        .orderBy("added", "desc")
        .get()
        .then((snap) => {
          snap.forEach((challenge) => {
            this.challenges.push({ ...challenge.data(), id: challenge.id });
          });
        });
    },
  },
  beforeMount() {
    this.getColor();
    this.fetchChallenges();
  },
  mounted() {},
};
</script>
<style scoped>
.homescreen {
  min-height: 100vh;
}

.class {
  padding: 5px 25px;
}
.class img {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
}
</style>