import fx from "money";
import { db, auth } from "./main";

const WEBENGAGE_CREATE_EVENT_URL =
	"https://us-central1-witblox-5ae68.cloudfunctions.net/createWebengageEvent";

const WEBENGAGE_CREATE_USER_URL =
	"https://us-central1-witblox-5ae68.cloudfunctions.net/setWebengageUserAttribute";

export const createWebengageEvent = ({ uid, eventName, eventData }) => {
	return fetch(WEBENGAGE_CREATE_EVENT_URL, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			uid,
			eventName,
			eventData,
		}),
	});
};

export const createWebengageUser = ({
	uid,
	firstName,
	lastName,
	email,
	phone,
	attrs,
}) => {
	return fetch(WEBENGAGE_CREATE_USER_URL, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			uid,
			firstName,
			lastName,
			email,
			phone,
			attributes: attrs,
		}),
	});
};

export const getUserCountry = async () => {
	const uid = auth?.currentUser?.uid;

	const userDoc = await db.collection("users").doc(uid);

	const user = await userDoc.get();

	const countryData = user.data().country;

	if (!countryData || !countryData.code || !countryData.currency) {
		try {
			const country = await fetch("https://ipapi.co/json/");
			const data = await country.json();

			const { country_code, currency } = data;

			userDoc.update({
				country: {
					code: country_code,
					currency,
				},
			});

			// console.log("got country code and currency from api call");/

			return { code: country_code, currency, apiCall: true };
		} catch (error) {
			console.log("error while getting country code and currency");
			return { code: "IN", currency: "INR", apiCall: false };
		}
	}

	// console.log("got country code and currency from storage");
	return { ...countryData, apiCall: false };
};

export const formatCurrency = (amount, currency, mfd = 2) => {
	const convertedAmount = fx.convert(amount, { from: "INR", to: currency });
	// console.log({convertedAmount});
	return new Intl.NumberFormat(`en-IN`, {
		style: "currency",
		currency: currency,
		maximumFractionDigits: mfd,
	}).format(parseFloat(convertedAmount));
};
