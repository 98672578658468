<template>
  <section class="videosection">
    <a @click="$router.back()" class="closeBtns">
      <span class="icon">
        <i class="far fa-times-circle"></i>
      </span>
    </a>
    <div class="video-container" v-if="screen === 0">
      <video-player
        v-if="vid1.loaded"
        class="vjs-custom-skin"
        ref="videoPlayer"
        :options="vid1"
        :playsinline="true"
      ></video-player>
      <div class="videoBtns">
        <a class="button is-small" id="skipBtn" @click="openLink()">
          <span>{{ btnName }}</span>
          <span class="icon">
            <i class="fas fa-chevron-right"></i>
          </span>
        </a>
      </div>
    </div>
    <div class="video-container" v-else-if="screen === 1">
      <img :src="banner" class="featuredImg" />
      <div class="hero themeabout">
        <div class="hero-body">
          <p class="title">{{ name }}</p>
          <div class="separator">ABOUT</div>
          <div v-html="desc"></div>
          <div class="trailerVidBtn">
            <div class="columns">
              <div class="column">
                <button
                  v-if="rsvped"
                  class="button btn btn-theme"
                  @click="showInApp()"
                >
                  Join Event
                </button>
                <button
                  v-else
                  v-bind:class="{ 'is-loading': btnLoading }"
                  class="button btn btn-theme"
                  @click="rsvp()"
                >
                  RSVP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a @click="screen = 0" class="screen2Btn">
        <span class="icon">
          <i class="fas fa-chevron-circle-left"></i>
        </span>
      </a>
    </div>
  </section>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { db } from "@/main.js";
import "@/assets/css/video-js.css";
import { videoPlayer } from "vue-video-player";
// const { Browser } = Plugins;
import { Browser } from "@capacitor/browser";
export default {
  name: "event",
  components: {
    videoPlayer,
  },
  data() {
    return {
      screen: 0,
      id: this.$route.params.id,
      name: "",
      desc: "",
      banner: "",
      btnLoading: false,
      rsvped: false,
      link: "",
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
      },
      btnTarget: "",
      btnName: "",
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    async showInApp() {
      await Browser.open({ url: this.link });
    },
    async openLink() {
      await Browser.open({ url: this.btnTarget });
    },
    rsvp() {
      this.btnLoading = true;
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((user) => {
            db.collection("events")
              .doc(this.id)
              .collection("rsvp")
              .add({
                name: user.data().fname + " " + user.data().lname,
                phone: user.data().phone,
                school: user.data().school,
                dob: user.data().dob,
                email: user.data().email,
                uid: user.id,
              })
              .then(() => {
                this.btnLoading = false;
                this.rsvped = true;
              });
          });
      });
    },
  },
  beforeMount() {
    auth.onAuthStateChanged((user) => {
      db.collection("events")
        .doc(this.id)
        .collection("rsvp")
        .where("uid", "==", user.uid)
        .get()
        .then((snap) => {
          if (!snap.empty) this.rsvped = true;
        });
      db.collection("events")
        .doc(this.id)
        .get()
        .then((doc) => {
          if (!doc.exists) this.router("/");
          else {
            console.log(doc.data());
            this.name = doc.data().name;
            this.banner = doc.data().featuredImg;
            this.desc = doc.data().desc;
            this.link = doc.data().target;
            this.vid1.sources.push({
              type: "video/mp4",
              src: doc.data().trailer,
            });
            this.vid1.poster =
              "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
              encodeURIComponent(doc.data().trailer) +
              "&size=400&mode=orig";
            this.btnTarget = doc.data().btnTarget;
            this.btnName = doc.data().btnName;
          }
          setTimeout(() => {
            this.vid1.loaded = true;
          }, 100);
        });
    });
  },
};
</script>   
<style scoped>
.themeabout {
  padding-bottom: 25px;
}
</style>