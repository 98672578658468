<template>
  <section>
    <div class="hero uploadForm">
      <div class="hero-body">
        <div
          class="card depth"
          style="
            border-radius: 20px;
            margin-bottom: 50px;
            max-width: 700px;
            margin: auto;
          "
        >
          <header class="card-header">
            <p class="card-header-title">Create a post</p>
          </header>
          <div class="card-content">
            <div class="content">
              <div class="">
                <!-- error-box -->
                <article v-if="error != ''" class="message is-danger">
                  <div class="message-body">{{ error }}</div>
                </article>
                <!-- user-and-post-info -->
                <div class="column">
                  <div
                    class="columns is-mobile"
                    style="max-width: 500px; margin: auto"
                  >
                    <div class="column is-2" style="margin: auto">
                      <div class="homeprofileimg" :style="picture"></div>
                    </div>
                    <div class="column">
                      <h4 class="title">{{ name }}</h4>
                      <div class="select is-small is-rounded">
                        <select>
                          <option :selected="generic" :disabled="!generic">
                            General
                          </option>
                          <option :selected="story" :disabled="!story">
                            Project
                          </option>
                          <option :selected="challenge" :disabled="!challenge">
                            Challenge
                          </option>
                          <option
                            :selected="competition"
                            :disabled="!competition"
                          >
                            Competition
                          </option>
                        </select>
                      </div>
                      <div
                        class="select is-small is-rounded"
                        style="margin-left: 5px"
                      >
                        <select>
                          <option>For You</option>
                          <option disabled>School</option>
                          <option disabled>Class</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- caption -->
                <!-- locked-message -->
                <div v-if="locked" class="column">
                  <article class="message is-warning">
                    <div class="message-header">
                      <p>
                        <i class="fas fa-solid fa-lock"></i>
                        Warning
                      </p>
                    </div>
                    <div class="message-body">
                      You have already posted 3 times in last hour. Please wait
                      for an hour to start posting again.
                    </div>
                  </article>
                </div>
                <!-- /locked-message -->
                <div v-if="!locked" class="column">
                  <textarea
                    v-on:keyup="countdown"
                    v-model="caption"
                    maxlength="100"
                    class="textarea"
                    placeholder="Would you like to say anything about your project?"
                  ></textarea>
                  <p class="count">{{ remainingCount }}</p>
                </div>
                <!-- upload-input -->
                <div v-if="!locked" class="column">
                  <div id="uploadVid"></div>
                  <div class="field">
                    <div class="file has-name is-fullwidth">
                      <label class="file-label">
                        <input
                          @change="fileSelected"
                          class="file-input"
                          type="file"
                          name="resume"
                          accept="video/mp4"
                        />
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fas fa-upload"></i>
                          </span>
                          <span class="file-label">Select a video</span>
                        </span>
                        <span class="file-name">{{ fileName }}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <!-- video-preview -->
                <video-player
                  v-if="vid1.loaded"
                  class="vjs-custom-skin uploadVid"
                  ref="videoPlayer"
                  :options="vid1"
                  :playsinline="true"
                ></video-player>
                <!-- progressbar -->
                <div v-if="!locked" class="columns terms">
                  <div class="column">
                    <label class="checkbox">
                      <input type="checkbox" v-model="agreement" />
                      <br />
                      My post follows WitBlox Community Guidelines. If not, I
                      may lose my WitCoins for this post.
                      <a
                        href="https://blog.witblox.com/2022/02/08/witblox-app-community-guidelines/"
                        target="blank"
                      >
                        Read more.
                      </a>
                    </label>
                  </div>
                </div>
                <div v-if="!locked && agreement" class="column">
                  <div class="myprogress" v-if="progress">
                    <div id="mybar"></div>
                  </div>
                  <a
                    @click="uploadFile"
                    v-bind:class="{
                      'is-loading': btnLoading,
                    }"
                    class="button btn btn-theme"
                    >Post</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal is-active" v-if="modal">
      <div class="modal-background"></div>
      <div class="modal-content has-text-centered">
        <img src="@/assets/img/award.png" width="30%" />
        <h1 class="title" style="color: #ffffffff">Done!</h1>
        <h3 class="subtitle" style="padding: 10px 40px; color: #ffffffff">
          We've credited {{ coins }} WitCoins to your account as a reward.
        </h3>
        <a
          @click="router('/social')"
          class="button"
          style="background-color: #ffffffff"
          >Done</a
        >
      </div>
    </div>
  </section>
</template>
<script>
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { videoPlayer } from "vue-video-player";
import { functions } from "@/main.js";
import { storage } from "@/main.js";
import { createWebengageEvent } from "../helper";
export default {
  name: "upload",
  components: {
    videoPlayer,
  },
  data() {
    return {
      type: this.$route.params.type,
      tagid: this.$route.params.tag,
      coins: this.$route.params.coins,
      generic: false,
      story: false,
      challenge: false,
      competition: false,
      progress: false,
      picture: "",
      name: "",
      caption: "",
      error: "",
      maxCount: 100,
      remainingCount: 100,
      fileName: "",
      vfile: "",
      modal: false,
      btnLoading: false,
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
      },
      eventName: "",
      newuser: false,
      locked: false,
      agreement: false,
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    fileSelected(event) {
      this.fileName = event.target.files[0].name;
      var size = event.target.files[0].size / 1024 / 1024;
      var video = document.createElement("video");
      video.src = URL.createObjectURL(event.target.files[0]);
      if (size < 150) {
        this.vid1.sources = [];
        this.vid1.sources.push({
          type: "video/mp4",
          src: URL.createObjectURL(event.target.files[0]),
        });
        this.vfile = event.target.files[0];
      } else
        this.error =
          "The file size is too big, please upload a video smaller than 150MB.";
      setTimeout(() => {
        this.vid1.loaded = true;
      }, 100);
    },
    uploadFile() {
      if (this.vfile) {
        this.btnLoading = true;
        var storageRef = storage.ref();
        const randomId = this.randomStr(20, "12345abcde");
        var uploadTask = storageRef
          .child("social/" + randomId + ".mp4")
          .put(this.vfile);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.error = "";
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            this.progress = true;
            document.getElementById("mybar").style.width = progress + "%";
          },
          (error) => {
            this.error = error;
            this.false = false;
            console.log(error);
            this.btnLoading = false;
          },
          () => {
            this.error = "";
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log("File available at", downloadURL);
              var createVideoPost = functions.httpsCallable("createVideoPost");
              var caption, tagid;
              if (!this.caption) caption = "";
              else caption = this.caption;
              if (!this.tagid) tagid = "";
              else tagid = this.tagid;
              createVideoPost({
                caption: caption,
                url: downloadURL,
                tag: tagid,
                coins: parseInt(this.coins),
                type: this.type,
                approved: false,
                hashtag: this.eventName,
                randomId: randomId,
              }).then((result) => {
                console.log("posted", result);
                var payload = {
                  added: new Date().getTime(),
                  videoId: result.data.pid,
                  readableDate: new Date().toLocaleString(),
                  type: this.type,
                  coins: parseInt(this.coins),
                  tag: this.eventName,
                  tagid: tagid,
                  randomId: randomId,
                };
                createWebengageEvent({
                  uid: auth.currentUser.uid,
                  eventName: "Video Uploaded",
                  eventData: payload,
                });
                this.modal = true;
                //
                if (this.newuser) {
                  this.$store.state.profile.newuser_stage = 3;
                  this.$store.state.profile.newuser_firstupload = true;
                  this.$store.state.profile.newuser_firstuploadid =
                    result.data.pid;
                  db.collection("users").doc(auth.currentUser.uid).update({
                    newuser_stage: 3,
                    newuser_firstupload: true,
                    newuser_firstuploadid: result.data.pid,
                  });
                  createWebengageEvent({
                    uid: auth.currentUser.uid,
                    eventName: "First Video Uploaded",
                    eventData: payload,
                  });
                }
              });
            });
          }
        );
      } else this.error = "Please select a video first.";
    },
    countdown: function () {
      this.remainingCount = this.maxCount - this.caption.length;
      this.hasError = this.remainingCount < 0;
    },
    randomStr(len, arr) {
      var ans = "";
      for (var i = len; i > 0; i--) {
        ans += arr[Math.floor(Math.random() * arr.length)];
      }
      return ans;
    },
  },
  mounted() {
    console.log(this.type, this.tagid);
    if (this.type == "generic") this.generic = true;
    else if (this.type == "story") {
      this.story = true;
      db.collection("projects")
        .doc(this.tagid)
        .get()
        .then((project) => {
          this.eventName = project.data().name;
        });
    } else if (this.type == "challenge") {
      this.challenge = true;
      db.collection("challenges")
        .doc(this.tagid)
        .get()
        .then((challenge) => {
          this.eventName = challenge.data().name;
        });
    } else if (this.type == "competition") this.competition = true;
    //type = generic / challenge / story
    //tag = newpost / :pid: / :pid:
    var timestamp = new Date().setHours(new Date().getHours() - 1);
    db.collection("social")
      .where("uid", "==", auth.currentUser.uid)
      .where("created", ">", timestamp)
      .get()
      .then((posts) => {
        console.log("Posts in last hour:", posts.size);
        if (posts.size > 2) this.locked = true;
        else this.locked = false;
      });
  },
  beforeMount() {
    auth.onAuthStateChanged((user) => {
      this.name = user.displayName;
      this.picture =
        "background: url(" +
        user.photoURL +
        "), linear-gradient(to right, rgb(142, 45, 226), rgb(74, 0, 224))";
    });
    console.log(this.$store.state.profile.newuser_stage);
    if (this.$store.state.profile.newuser_stage == 2) {
      console.log("new user");
      this.newuser = true;
    }
  },
};
</script>
<style scoped>
.video-player.uploadVid {
  width: 100%;
  height: 250px !important;
  padding: 0 12.5px;
}
.depth {
  -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}
select {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.uploadForm .card-header {
  background: #f12711;
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  background: linear-gradient(to right, #f5af19, #f12711);
  border-radius: 20px 20px 0 0;
}
.uploadForm .card-header-title {
  color: #f4f4f4;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}
.homeprofileimg {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  background-image: url(~@/assets/img/user.png);
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
}
.uploadForm h4 {
  color: #585858;
  margin-bottom: 0.5rem !important;
}
.uploadForm .message-header {
  font-weight: 800;
}
.uploadForm .message-body {
  font-weight: 500;
}
#caption {
  border: none;
  box-shadow: none;
}
.count {
  display: block;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.file .file-cta {
  background-color: #ef6d38;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.modal-background {
  background: #6f00ff;
}
.scard .button,
.modal .button {
  color: #5a3e90;
  border: 4px solid #5a3e90;
  background: #ffffff94;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 100%;
}
.myprogress {
  width: 100%;
  background-color: #dedede;
  border-radius: 10px;
  margin-bottom: 10px;
}
#mybar {
  width: 1%;
  height: 20px;
  background: #f5af19;
  background: linear-gradient(to right, #f5af19, #f12711);
  border-radius: 10px;
}
.terms {
  padding: 0 20px;
  padding-top: 20px;
}
.terms label.checkbox {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.terms label.checkbox a {
  border-bottom: 2px solid;
}
</style>