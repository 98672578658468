<template>
  <section class="videosection">
    <a @click="router('/fest')" class="closeBtns">
      <span class="icon">
        <i class="far fa-times-circle"></i>
      </span>
    </a>
    <div class="video-container" v-if="screen === 0">
      <video-player
        v-if="vid1.loaded"
        class="vjs-custom-skin"
        ref="videoPlayer"
        :options="vid1"
        :playsinline="true"
      ></video-player>
      <div class="videoBtns">
        <a class="button is-small" id="skipBtn" @click="screen = 1">
          <span>Participate</span>
          <span class="icon">
            <i class="fas fa-chevron-right"></i>
          </span>
        </a>
      </div>
    </div>
    <div class="video-container" v-else-if="screen === 1">
      <img :src="banner" class="featuredImg" />
      <div class="hero themeabout">
        <div class="hero-body">
          <div class="notification is-primary is-light">
            <b>My Status: </b>
            <span v-html="status"></span>
          </div>
          <div class="separator">ABOUT</div>
          <div v-html="content"></div>
          <div class="trailerVidBtn">
            <div class="columns">
              <div class="column">
                <button
                  v-if="stage === 0"
                  v-bind:class="{ 'is-loading': btnLoading }"
                  class="button btn btn-theme"
                  @click="submitdetails = true"
                >
                  Participate
                </button>
                <!-- <button
                  v-if="stage === 1"
                  v-bind:class="{ 'is-loading': btnLoading }"
                  class="button btn btn-theme"
                  @click="submitidea = true"
                >
                  Submit Idea
                </button> -->
                <!-- <button
                  v-if="stage === 2"
                  class="button btn btn-theme disabled"
                  disabled
                >
                  Idea Submitted
                </button>
				-->
                <button
                  v-if="paid == false"
                  v-bind:class="{ 'is-loading': btnLoading }"
                  class="button btn btn-theme"
                  @click="payForRegistration()"
                >
                  Pay Registration Fees
                </button>
                <!-- <button
                  v-if="paid == true && stage === 1"
                  v-bind:class="{ 'is-loading': btnLoading }"
                  class="button btn btn-theme"
                  @click="uploadVideo()"
                >
                  Upload Video
                </button>
                <button
                  v-if="paid == true && stage === 2"
                  v-bind:class="{ 'is-loading': btnLoading }"
                  class="button btn btn-theme"
                  @click="router('/upload/story/' + $route.params.id + '/0')"
                >
                  Re-Upload Video
                </button> -->
                <button
                  v-if="paid == true && stage === 1"
                  v-bind:class="{ 'is-loading': btnLoading }"
                  class="button btn btn-theme"
                  @click="$router.push('/submission')"
                >
                  View Your Submission
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a @click="screen = 0" class="screen2Btn">
        <span class="icon">
          <i class="fas fa-chevron-circle-left"></i>
        </span>
      </a>
    </div>
    <!-- submit-details -->
    <div v-if="submitdetails" class="tfcontent modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Personal Details</p>
          <button
            @click="submitdetails = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <div v-if="participant.error" class="notification is-danger">
                {{ participant.error }}
              </div>
              <div class="field">
                <div class="control">
                  <input
                    v-model="participant.name"
                    class="input"
                    type="text"
                    placeholder="Full Name"
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <input
                    v-model="participant.phone"
                    class="input"
                    type="number"
                    maxlength="10"
                    placeholder="Phone Number"
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <input
                    v-model="participant.email"
                    class="input"
                    type="email"
                    placeholder="E-mail"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button @click="participate()" class="button">Signup</button>
        </footer>
      </div>
    </div>
    <!-- /submit-details -->
    <!-- submit-idea -->
    <div v-if="submitidea" class="tfcontent modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Submit Idea</p>
          <button
            @click="submitidea = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="column">
            <div class="column">
              <div v-if="ideaerror" class="notification is-danger">
                {{ ideaerror }}
              </div>
              <div class="field">
                <div class="control">
                  <input
                    v-model="ideatitle"
                    class="input"
                    type="text"
                    placeholder="Idea Title"
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <textarea
                    v-model="ideadesc"
                    class="textarea"
                    placeholder="Idea Description (approx 250 words)"
                    maxlength="2500"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button @click="submitIdea()" class="button">Submit</button>
        </footer>
      </div>
    </div>
    <!-- /submit-idea -->
  </section>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { db } from "@/main.js";
import { Browser } from "@capacitor/browser";
import "@/assets/css/video-js.css";
import { videoPlayer } from "vue-video-player";
export default {
  name: "theme",
  components: {
    videoPlayer,
  },
  data() {
    return {
      screen: 0,
      name: "",
      banner: "",
      popupOpen: false,
      btnLoading: false,
      submitdetails: false,
      submitidea: false,
      content: "",
      status: "",
      stage: 0,
      ideatitle: "",
      ideadesc: "",
      ideaerror: "",
      participant: {
        error: "",
        name: "",
        phone: "",
        email: "",
        school: "",
        city: "",
        about: "",
        terror1: "",
        tn1: "",
        te1: "",
        terror2: "",
        tn2: "",
        te2: "",
        terror3: "",
        tn3: "",
        te3: "",
        mentorName: "",
        mentorEmail: "",
      },
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
      },
      paid: false,
    };
  },
  beforeMount() {
    //check if paid
    auth.onAuthStateChanged((user) => {
      db.collection("shop-orders")
        .where("uid", "==", user.uid)
        .where("paid", "==", true)
        .get()
        .then((snap) => {
          snap.forEach((orders) => {
            if (!this.paid) {
              orders.data().products.forEach((product) => {
                if (!this.paid)
                  if (product.id == "4kZxGIE2wTTwowSrffLv") {
                    this.paid = true;
                    if (orders.id) {
                      db.collection("config")
                        .doc("irc")
                        .collection("submissions")
                        .where("uid", "==", user.uid)
                        .get()
                        .then((submission) => {
                          db.collection("config")
                            .doc("irc")
                            .collection("submissions")
                            .doc(submission.docs[0].id)
                            .update({
                              orderId: orders.id,
                            });
                        });
                    }
                  }
              });
            }
          });
        });
    });
    //others
    db.collection("config")
      .doc("irc")
      .collection("themes")
      .doc(this.$route.params.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.vid1.sources.push({
            type: "video/mp4",
            src: doc.data().trailer,
          });
          this.vid1.poster =
            "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
            encodeURIComponent(doc.data().trailer) +
            "&size=400&mode=orig";
          this.name = doc.data().name;
          this.banner = doc.data().banner;
          this.content = doc.data().content;
          auth.onAuthStateChanged((user) => {
            db.collection("config")
              .doc("irc")
              .collection("submissions")
              .where("uid", "==", user.uid)
              .where("theme", "==", this.$route.params.id)
              .get()
              .then((snap) => {
                if (!snap.empty) {
                  this.status = doc.data().status[snap.docs[0].data().stage];
                  this.stage = snap.docs[0].data().stage;
                } else {
                  this.status = doc.data().status[0];
                }
                db.collection("users")
                  .doc(user.uid)
                  .get()
                  .then((doc) => {
                    this.participant.name =
                      doc.data().fname + " " + doc.data().lname;
                    this.participant.phone = doc.data().phone.substring(3);
                    this.participant.email = user.email;
                  });
              });
          });
          setTimeout(() => {
            this.vid1.loaded = true;
          }, 100);
        } else {
          this.router("/");
        }
      });
  },
  mounted() {},
  methods: {
    router(name) {
      this.$router.push(name);
    },
    async showInApp(link) {
      await Browser.open({ url: link });
    },
    payForRegistration() {
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .get()
          .then((snap) => {
            snap.forEach((item) => {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .doc(item.id)
                .delete();
            });
            db.collection("users")
              .doc(user.uid)
              .collection("cart")
              .add({
                added: new Date().getTime(),
                product: "4kZxGIE2wTTwowSrffLv",
                quantity: 1,
              })
              .then((doc) => {
                console.log(doc.id);
              });
          });
      });
      this.btnLoading = true;
      setTimeout(() => {
        this.router("/cart");
      }, 2000);
    },
    viewSubmission() {
      auth.onAuthStateChanged((user) => {
        db.collection("social")
          .where("uid", "==", user.uid)
          .where("tag", "==", this.$route.params.id)
          .orderBy("created", "desc")
          .limit(1)
          .get()
          .then((snap) => {
            this.btnLoading = true;
            if (!snap.empty) {
              this.router("/p/" + snap.docs[0].id);
            } else this.btnLoading = false;
          });
      });
    },
    participate() {
      var p = this.participant;
      if (p.name && p.phone && p.email) {
        auth.onAuthStateChanged((user) => {
          this.submitdetails = false;
          this.stage = 1;
          db.collection("config")
            .doc("irc")
            .collection("submissions")
            .add({
              name: p.name,
              phone: p.phone,
              email: p.email,
              school: p.school,
              city: p.city,
              about: p.about,
              idea: {
                approved: false,
                desc: "",
                title: "",
              },
              lastupdated: new Date().getTime(),
              stage: 1,
              team: [
                {
                  name: p.tn1,
                  email: p.te1,
                },
                {
                  name: p.tn2,
                  email: p.te2,
                },
                {
                  name: p.tn3,
                  email: p.te3,
                },
              ],
              mentor: {
                name: p.mentorName,
                email: p.mentorEmail,
              },
              theme: this.$route.params.id,
              uid: user.uid,
              schedule: "",
              orderId: "",
            })
            .then(() => {});
        });
      } else p.error = "Please fill the form properly.";
    },
    // submitIdea() {
    //   if (this.ideatitle && this.ideadesc) {
    //     auth.onAuthStateChanged((user) => {
    //       this.submitidea = false;
    //       this.stage = 2;
    //       db.collection("tfsubmission")
    //         .where("uid", "==", user.uid)
    //         .where("theme", "==", this.$route.params.id)
    //         .get()
    //         .then((snap) => {
    //           db.collection("tfsubmission")
    //             .doc(snap.docs[0].id)
    //             .update({
    //               idea: {
    //                 approved: false,
    //                 desc: this.ideadesc,
    //                 title: this.ideatitle,
    //               },
    //               lastupdated: new Date().getTime(),
    //               stage: 2,
    //             });
    //         });
    //     });
    //   } else this.ideaerror = "Please fill all the fields.";
    // },
    uploadVideo() {
      this.btnLoading = true;
      auth.onAuthStateChanged((user) => {
        db.collection("config")
          .doc("irc")
          .collection("submissions")
          .where("uid", "==", user.uid)
          .where("theme", "==", this.$route.params.id)
          .get()
          .then((snap) => {
            db.collection("config")
              .doc("irc")
              .collection("submissions")
              .doc(snap.docs[0].id)
              .update({ lastupdated: new Date().getTime(), stage: 2 })
              .then(() => {
                this.router("/upload/story/" + this.$route.params.id + "/10");
              });
          });
      });
    },
  },
};
</script>
<style>
.themeabout p {
  padding-bottom: 30px;
}
</style>
<style scoped>
.videoBtns {
  text-align: center;
}
.videoBtns .button {
  font-size: 1.2rem;
  background: #000;
  border-radius: 10px;
}
</style>