<template>
  <section style="padding-bottom: 40px" :style="color">
    <div class="profile2">
      <div class="columns">
        <div class="column">
          <div class="columns has-text-centered">
            <div class="column" style="margin-bottom: 0px; padding-bottom: 0px">
              <div class="columns is-mobile">
                <div class="column has-text-left">
                  <h3
                    class="subtitle badge"
                    @click="$router.push('/transactions')"
                    style="margin-bottom: 0px"
                  >
                    <img
                      src="@/assets/img/coin.svg"
                      style="width: 17px; margin-right: 5px"
                    />
                    <span id="coins" :style="textColor">
                      {{ format(stats.coins) }}
                    </span>
                  </h3>
                  <!-- <a
                @click="$router.push('/leaderboard')"
                style="margin-left: 3px"
                class="edit"
              >
                <i class="fas fa-trophy"></i>
              </a> -->
                </div>
                <div class="column has-text-right">
                  <a @click="$router.push('/edit')" class="edit">
                    <i class="fas fa-cog"></i>
                    Edit
                  </a>
                  <a @click="logout()" style="margin-left: 3px" class="edit">
                    <i class="fas fa-sign-out-alt"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="columns has-text-centered">
            <div class="column">
              <div class="card profileinfocard">
                <div class="card-content">
                  <div class="column imagecontainer">
                    <div
                      id="profileImg"
                      class="profileImage"
                      :style="profile.picture"
                    ></div>
                    <!--<img id="profileImg" src="img/user.png" class="profileImage">-->
                    <!-- <div class="ranking">
                  <span id="ranking">
                    <img src="@/assets/img/crown.png" class="crown" />
                    <span id="position">#{{ profile.ranking }}</span>
                  </span>
                </div> -->
                    <img src="@/assets/img/medal.png" alt id="medal" />
                  </div>
                  <div class="column">
                    <h1 class="title">
                      <span
                        id="name"
                        class="has-text-centered"
                        v-if="profile.fname"
                      >
                        {{ profile.fname + " " + profile.lname }}
                      </span>
                      <span id="name" class="has-text-centered" v-else>
                        Loading...
                      </span>
                      <img
                        v-if="profile.verified"
                        src="@/assets/img/verified.png"
                        style="width: 21px; margin-left: 5px"
                      />
                    </h1>
                    <h6 id="bio" class="subtitle">
                      {{ profile.bio }}
                    </h6>
                  </div>
                  <div class="column">
                    <div class="card profilecard">
                      <div class="columns is-mobile has-text-centered">
                        <div
                          @click="screen = 'posts'"
                          class="column"
                          v-bind:class="{ 'is-active': screen === 'posts' }"
                        >
                          <p v-if="profile.posts > -1" class="numbering">
                            {{ format(profile.posts) }}
                          </p>
                          <!-- <p v-else class="numbering">0</p> -->
                          <span
                            v-else
                            class="preload dark"
                            style="width: 100%"
                          ></span>
                          <p class="naming">Posts</p>
                        </div>
                        <div
                          @click="screen = 'followers'"
                          id="followers-btn"
                          class="column"
                          v-bind:class="{ 'is-active': screen === 'followers' }"
                        >
                          <p v-if="profile.followers > -1" class="numbering">
                            {{ format(profile.followers) }}
                          </p>
                          <!-- <p v-else class="numbering">0</p> -->

                          <span
                            v-else
                            class="preload dark"
                            style="width: 100%"
                          ></span>
                          <p class="naming">Followers</p>
                        </div>
                        <div
                          @click="screen = 'following'"
                          id="following-btn"
                          class="column"
                          v-bind:class="{ 'is-active': screen === 'following' }"
                        >
                          <p v-if="profile.following > -1" class="numbering">
                            {{ format(profile.following) }}
                          </p>
                          <!-- <p v-else class="numbering">0</p> -->
                          <span
                            v-else
                            class="preload dark"
                            style="width: 100%"
                          ></span>
                          <p class="naming">Following</p>
                        </div>
                        <div
                          @click="screen = 'completed'"
                          id="projects-btn"
                          class="column"
                          v-bind:class="{ 'is-active': screen === 'completed' }"
                        >
                          <p v-if="profile.projects > -1" class="numbering">
                            {{ format(profile.projects) }}
                          </p>
                          <!-- <p v-else class="numbering">0</p> -->
                          <span
                            v-else
                            class="preload dark"
                            style="width: 100%"
                          ></span>
                          <p class="naming">Projects</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="hero">
            <div class="hero-body profileinfo">
              <div v-if="screen === 'posts'" class="columns">
                <div class="column">
                  <div class="columns is-mobile">
                    <div class="column is-4">
                      <h1 class="title">Posts</h1>
                    </div>
                    <div
                      class="column has-text-right"
                      v-if="allposts.length != 0"
                    >
                      <button
                        style="margin-top: 4.5px"
                        @click="shareDialog()"
                        class="profilefollow button"
                      >
                        <!-- <i class="fas fa-share-alt"></i> -->
                        <span class="icon">
                          <i class="fas fa-share-alt"></i>
                        </span>
                        <span>Share Your Profile</span>
                      </button>
                    </div>
                  </div>
                  <div v-if="allposts.length != 0" class="columns">
                    <div class="column" style="font-size: 0">
                      <div
                        v-for="(post, index) in allposts"
                        :key="post.id"
                        class="postcard"
                      >
                        <v-lazy-image
                          :src-placeholder="
                            require('@/assets/img/thumbnail.png')
                          "
                          :src="post.thumbnail"
                          @error="
                            post.thumbnail = require('@/assets/img/thumbnail.png')
                          "
                        />
                        <a>
                          <i
                            @click="deletePost(post.id, index)"
                            class="fas fa-trash-alt"
                          ></i>
                        </a>
                        <a class="second">
                          <i
                            @click="repost(post.id)"
                            class="fas fa-retweet"
                          ></i>
                        </a>
                        <p @click="$router.push('/social/' + post.id)">
                          <i class="fas fa-eye"></i>
                          <span>{{ post.views }}</span>
                          <br />
                          <i class="fas fa-star"></i>
                          <span>{{ post.rating }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else class="card">
                    <div class="card-content">
                      <img
                        style="padding: 10px 100px"
                        src="@/assets/img/albums-outline.svg"
                        alt
                      />
                      <h3
                        style="padding-bottom: 45px"
                        class="has-text-centered subtitle"
                      >
                        No Posts Yet
                      </h3>
                    </div>
                  </div>
                  <div
                    v-observe-visibility="
                      (isVisible, entry) => visibilityChanged(isVisible, entry)
                    "
                  ></div>
                </div>
              </div>
              <div v-if="screen === 'followers'" class="columns">
                <div class="column">
                  <h1 class="title">Followers</h1>
                  <div v-if="allfollowers.length != 0" class="card list">
                    <div class="card-content">
                      <div
                        v-for="(follower, index) in allfollowers"
                        :key="follower.id + index"
                        class="columns is-mobile"
                      >
                        <div class="column">
                          <h6
                            @click="$router.push('/u/' + follower.id)"
                            class="subtitle"
                          >
                            {{ follower.name }}
                          </h6>
                        </div>
                        <div class="column is-3">
                          <button
                            @click="unfollowUser(follower.id, follower)"
                            v-if="follower.following"
                            class="button following"
                          >
                            <i class="fas fa-check"></i>
                          </button>
                          <button
                            @click="followUser(follower.id, follower)"
                            v-else
                            class="button follow"
                          >
                            <i class="fas fa-user-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="card">
                    <div class="card-content">
                      <img
                        style="padding: 10px 100px"
                        src="@/assets/img/walk-outline.svg"
                        alt
                      />
                      <h3
                        style="padding-bottom: 45px"
                        class="has-text-centered subtitle"
                      >
                        No Followers Yet
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="screen === 'following'" class="columns">
                <div class="column">
                  <h1 class="title">Following</h1>
                  <div v-if="allfollowing.length != 0" class="card list">
                    <div class="card-content">
                      <div
                        v-for="(following, index) in allfollowing"
                        :key="following.id + index"
                        class="columns is-mobile"
                      >
                        <div class="column">
                          <h6
                            @click="$router.push('/u/' + following.id)"
                            class="subtitle"
                          >
                            {{ following.name }}
                          </h6>
                        </div>
                        <div class="column is-3">
                          <button
                            @click="unfollowUser(following.id, following)"
                            v-if="following.following"
                            class="button following"
                          >
                            <i class="fas fa-check"></i>
                          </button>
                          <button
                            @click="followUser(following.id, following)"
                            v-else
                            class="button follow"
                          >
                            <i class="fas fa-user-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="card">
                    <div class="card-content">
                      <img
                        style="padding: 10px 100px"
                        src="@/assets/img/walk-outline.svg"
                        alt
                      />
                      <h3
                        style="padding-bottom: 45px"
                        class="has-text-centered subtitle"
                      >
                        No Following Yet
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="screen === 'completed'" class="columns">
                <div class="column">
                  <div class="columns">
                    <div class="column">
                      <h1 class="title">Projects</h1>
                    </div>
                  </div>
                  <div v-if="allcompleted.length != 0" class="columns">
                    <div class="column">
                      <!-- <div
              v-for="project in allcompleted"
              :key="project.id"
              class="colum"
            >
              <div class="column">
                <img
                  @click="projectRoute(project.type, project.id)"
                  :src="project.image"
                  class="completedproject lazy"
                />
              </div>
            </div> -->
                      <div class="completedImages">
                        <img
                          v-for="project in allcompleted"
                          :key="project.id"
                          @click="projectRoute(project.type, project.id)"
                          :src="project.image"
                          class="completedproject lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else class="columns">
                    <div class="card">
                      <div class="card-content">
                        <img
                          style="padding: 10px 100px"
                          src="@/assets/img/school-outline.svg"
                          alt
                        />
                        <h3
                          style="padding-bottom: 45px"
                          class="has-text-centered subtitle"
                        >
                          No Projects Yet
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
//
import { auth } from "@/main.js";
import { functions } from "@/main.js";
import { db } from "@/main.js";
// import { Plugins } from "@capacitor/core";
import { Dialog } from "@capacitor/dialog";
import { Share } from "@capacitor/share";
import VLazyImage from "v-lazy-image";
// import { Webengage } from "@ionic-native/webengage";
import { createWebengageEvent } from "../helper";
import firebase from "firebase";

export default {
  name: "profile",
  components: {
    VLazyImage,
  },
  data() {
    return {
      screen: "posts",
      uid: "",
      allposts: [],
      allfollowers: [],
      allfollowing: [],
      allcompleted: [],
      allpostids: [],
      lastpost: 0,
      statLoader: false,
      color: "",
      textColor: "",
      //   stats: {},
    };
  },
  methods: {
    async logout() {
      let confirmRet = await Dialog.confirm({
        title: "Confirm",
        message: "Are you sure you want to logout?",
      });
      if (confirmRet.value) {
        await db.collection("users").doc(auth.currentUser.uid).update({
          country: firebase.firestore.FieldValue.delete(),
        });

        auth.signOut().then(() => {
          this.$router.push("/login");
        });
      }
    },
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
    arraySum(arr) {
      return arr.reduce(function (a, b) {
        return a + b;
      }, 0);
    },
    async deletePost(pid, index) {
      let confirmRet = await Dialog.confirm({
        title: "Confirm",
        message: "Are you sure you'd like to delete this post?",
      });
      if (confirmRet.value) {
        db.collection("social")
          .doc(pid)
          .delete()
          .then(async () => {
            this.allposts.splice(index, 1);
            await Dialog.alert({
              title: "Done",
              message: "We've deleted your selected post.",
            });
          });
      }
    },
    async repost(pid) {
      let confirmRet = await Dialog.confirm({
        title: "Confirm",
        message: "Are you sure you'd like to repost?",
      });
      if (confirmRet.value) {
        db.collection("social")
          .doc(pid)
          .get()
          .then((post) => {
            if (
              post.data().current > new Date().setDate(new Date().getDate() - 1)
            ) {
              Dialog.alert({
                title: "Can't Repost",
                message: "You have reposted this video in last 24 hours.",
              });
            } else {
              var repostVideo = functions.httpsCallable("repostVideo");
              repostVideo({ postid: pid }).then(async () => {
                await Dialog.alert({
                  title: "Done",
                  message: "We've reposted your video.",
                });
              });
            }
          });
      }
    },
    fetchPosts(uid, timestamp) {
      db.collection("social")
        .where("uid", "==", uid)
        .where("created", "<", timestamp)
        .orderBy("created", "desc")
        .limit(30)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            snap.forEach((doc) => {
              db.collection("social")
                .doc(doc.id)
                .collection("rating")
                .get()
                .then((snap) => {
                  var ratings = [];
                  var rating;
                  snap.forEach((doc) => {
                    ratings.push(doc.data().score);
                  });
                  var avgrating = this.arraySum(ratings) / ratings.length;
                  if (!avgrating) rating = 5;
                  else rating = avgrating.toFixed(1);
                  this.lastpost = doc.data().created;
                  if (!this.allpostids.includes(doc.id)) {
                    this.allpostids.push(doc.id);
                    this.allposts.push({
                      id: doc.id,
                      thumbnail: doc.data().thumbnail,
                      views: this.format(doc.data().views),
                      rating: rating,
                    });
                  }
                });
            });
          }
        });
    },
    followUser(toUID, user) {
      user.following = true;
      var followUser = functions.httpsCallable("followUser");
      followUser({
        toUID: toUID,
      }).then((result) => {
        createWebengageEvent({
          uid: auth.currentUser.uid,
          eventName: "Followed User",
          eventData: {
            added: new Date().getTime(),
            readableDate: new Date().toLocaleString(),
            user: toUID,
          },
        });
        if (result.data.code == 200) {
          console.log("success");
        } else if (result.data.code == 300) {
          console.log("error", result.data.error);
        }
      });
    },
    unfollowUser(toUID, user) {
      user.following = false;
      var unfollowUser = functions.httpsCallable("unfollowUser");
      unfollowUser({
        toUID: toUID,
      }).then((result) => {
        if (result.data.code == 200) {
          console.log("success");
        } else if (result.data.code == 300) {
          console.log("error", result.data.error);
        }
      });
    },
    fetchFollowers(uid) {
      db.collection("users")
        .doc(uid)
        .collection("followers")
        .orderBy("created", "desc")
        .limit(50)
        .get()
        .then((snap) => {
          snap.forEach((user) => {
            auth.onAuthStateChanged((selfuser) => {
              if (selfuser.uid != user.id) {
                db.collection("users")
                  .doc(user.data().uid)
                  .get()
                  .then((doc) => {
                    db.collection("users")
                      .doc(uid)
                      .collection("following")
                      .where("uid", "==", user.data().uid)
                      .get()
                      .then((snap) => {
                        var following = !snap.empty;
                        this.allfollowers.push({
                          id: user.data().uid,
                          name: doc.data().fname + " " + doc.data().lname,
                          following: following,
                        });
                      });
                  });
              }
            });
          });
        });
    },
    fetchFollowing(uid) {
      db.collection("users")
        .doc(uid)
        .collection("following")
        .orderBy("created", "desc")
        .limit(50)
        .get()
        .then((snap) => {
          snap.forEach((user) => {
            auth.onAuthStateChanged((selfuser) => {
              if (selfuser.uid != user.id) {
                db.collection("users")
                  .doc(user.data().uid)
                  .get()
                  .then((doc) => {
                    var following = true;
                    this.allfollowing.push({
                      id: user.data().uid,
                      name: doc.data().fname + " " + doc.data().lname,
                      following: following,
                    });
                  });
              }
            });
          });
        });
    },
    fetchProjects(uid) {
      db.collection("users")
        .doc(uid)
        .collection("projects")
        .orderBy("created", "desc")
        .limit(30)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            if (doc.data().type == "challenge") {
              db.collection("challenges")
                .doc(doc.data().pid)
                .get()
                .then((doc2) => {
                  if (doc2.exists) {
                    this.allcompleted.push({
                      id: doc2.id,
                      image: doc2.data().featuredImg,
                      type: doc.data().type,
                    });
                  }
                });
            } else if (doc.data().type == "story") {
              db.collection("projects")
                .doc(doc.data().pid)
                .get()
                .then((doc2) => {
                  if (doc2.exists) {
                    this.allcompleted.push({
                      id: doc2.id,
                      image: doc2.data().featuredImg,
                      type: doc.data().type,
                    });
                  }
                });
            } else if (doc.data().type == "competition") {
              db.collection("competition")
                .doc(doc.data().pid)
                .get()
                .then((doc2) => {
                  if (doc2.exists) {
                    this.allcompleted.push({
                      id: doc2.id,
                      image: doc2.data().featuredImg,
                      type: doc.data().type,
                    });
                  }
                });
            }
          });
        });
    },
    async projectRoute(type, id) {
      if (type == "challenge") this.$router.push("challenge/" + id);
      else if (type == "story") this.$router.push("project/" + id);
      else if (type == "competition") this.$router.push("competition/" + id);
    },
    format(value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
      }
      return newValue;
    },
    async shareDialog() {
      await Share.share({
        title: "Check my profile on WitBlox",
        text: "Checkout my projects & achievements on my WitBlox profile.",
        url: "https://witblox.com/profile/" + auth.currentUser.uid,
        dialogTitle: "Share with friends",
      });
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        console.log(entry);
        auth.onAuthStateChanged((user) => {
          this.fetchPosts(user.uid, this.lastpost);
        });
      }
    },
  },
  beforeMount() {
    this.getColor();
    this.$store.commit("getProfileStats");
    // this.stats = this.$store.state.stats;
    // setTimeout(() => {
    //   this.stats = this.$store.state.stats;
    // }, 5000);
    auth.onAuthStateChanged((user) => {
      this.uid = user.uid;
      //fetch posts
      this.fetchPosts(user.uid, new Date().getTime());
      //fetch followers
      this.fetchFollowers(user.uid);
      //fetch following
      this.fetchFollowing(user.uid);
      //fetch completed projects
      this.fetchProjects(user.uid);
    });
  },
  mounted() {
    /*db.collection("groups")
      .get()
      .then((groups) => {
        groups.forEach((group) => {
          console.log(group.id);
          db.collection("groups")
            .doc(group.id)
            .collection("chats")
            .get()
            .then((chats) => {
              chats.forEach((chat) => {
                db.collection("groups")
                  .doc(group.id)
                  .collection("chats")
                  .doc(chat.id)
                  .delete()
                  .then(() => {
                    console.log("Deleted:", group.id, chat.id);
                  });
              });
            });
        });
      });*/
  },
  //   watch: {
  //     "$store.state.stats.coins": function () {
  //       this.stats = this.$store.state.stats;
  //     },
  //   },
  computed: {
    ...mapGetters({
      profile: "profile",
      stats: "stats",
    }),
  },
};
</script>
<style scoped>
.profileinfo .title {
  font-size: 1.8rem;
}
</style>