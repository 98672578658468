<template>
  <section>
    <!-- desc -->
    <div class="desc">
      <div class="descbox" :class="{ expanded: expanded }">
        <div class="columns is-mobile">
          <div class="column is-2">
            <span class="icon is-medium">
              <i
                @click="$router.back()"
                class="fas fa-angle-left"
                style="font-size: 1.3rem"
              ></i>
            </span>
          </div>
          <div class="column has-text-centered">
            <h1 class="title">
              {{ group.name }}
            </h1>
          </div>
          <div class="column is-2">
            <span class="icon is-medium">
              <i
                @click="expanded = false"
                v-if="expanded"
                class="fas fa-times-circle"
                style="font-size: 1.3rem"
              ></i>
              <i
                @click="expanded = true"
                v-else
                class="fas fa-info-circle"
                style="font-size: 1.3rem"
              ></i>
            </span>
          </div>
        </div>
        <div v-if="expanded">
          <hr />
          <div v-if="group.description" class="columns expandedbox">
            <div class="column">
              <p class="description">
                Description:
                <br />
                <b>{{ group.description }}</b>
              </p>
            </div>
          </div>
          <hr v-if="group.description" />
          <div class="columns expandedbox">
            <div class="column">
              <p class="description">
                Members: <b>#{{ group.participants }}</b>
              </p>
            </div>
          </div>
          <hr />
          <div class="columns is-mobile">
            <div class="column">
              <a
                v-if="muted"
                class="button is-fullwidth"
                @click="muteGroup(false)"
                :class="{ 'is-loading': leaveGrpBtnLoading }"
              >
                Unmute
              </a>
              <a
                v-else
                class="button is-fullwidth"
                @click="muteGroup(true)"
                :class="{ 'is-loading': leaveGrpBtnLoading }"
              >
                Mute
              </a>
            </div>
            <div class="column">
              <a
                class="button is-fullwidth"
                @click="leaveGroup"
                :class="{ 'is-loading': leaveGrpBtnLoading }"
              >
                Leave Group
              </a>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <!-- /desc -->
    <!-- chatbox -->
    <div class="chatbox">
      <div
        v-for="message in chats"
        :key="message.id"
        class="messagegroup"
        :class="{ self: message.uid == uid, 'is-hidden': message.hidden }"
      >
        <div v-if="message.type != 'new'" class="columns is-mobile">
          <div v-if="message.uid != uid" class="column is-1">
            <div
              class="userimage"
              @click="msgAction(0, message)"
              :style="'background: url(' + message.picture + ')'"
            ></div>
          </div>
          <div class="column">
            <div
              class="message"
              :class="{ self: message.uid == uid }"
              :style="[message.uid == uid ? groupTheme : {}]"
            >
              <b v-if="message.uid == uid" class="name">You</b>
              <b @click="msgAction(0, message)" v-else class="name">
                {{ message.name }}
              </b>
              <div v-if="message.type == 'image'" class="chatimage">
                <img v-img v-if="message.media" :src="message.media" alt="" />
                <!-- <v-lazy-image
              v-img
              v-if="message.media"
              :src-placeholder="message.media"
              :src="message.media"
            /> -->
              </div>

              <div
                v-if="message.type == 'text' && message.linkPreview"
                class="chatimage"
              >
                <div
                  class="card linkPreview"
                  @click="openExternalLink(message.linkPreview.url)"
                >
                  <div class="card-image">
                    <figure class="image is-4by3">
                      <img :src="message.linkPreview.image.url" />
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="content">
                      <p class="title is-4">
                        {{ message.linkPreview.title }}
                      </p>
                      <p>
                        {{ message.linkPreview.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="message.type == 'file'" class="chatfile">
                <a :href="message.media" download> {{ message.text }} </a>
              </div>
              <p
                style="word-wrap: break-word"
                v-if="message.type != 'file' && message.text"
                v-html="urlify(mentionify(message.text))"
              ></p>
              <div class="time">{{ convertTime(message.timestamp) }}</div>
            </div>
          </div>
          <div v-if="message.uid == uid" class="column is-1">
            <div class="userimage" :style="profile.picture"></div>
          </div>
        </div>
        <div v-else>
          <p class="joined">
            <span> {{ message.name }} {{ message.text }} </span>
          </p>
        </div>
      </div>
      <div ref="scrolldown"></div>
      <!-- <div class="messagegroup">
        <div class="message self">
          <b class="name"> You </b>
          This is amazing trying out this chat feature
          <div class="time">4 mins ago</div>
        </div>
      </div>
      <div class="messagegroup">
        <div class="message">
          <b class="name"> Rishabh Sinha </b>
          WOrks
          <div class="time">11 mins ago</div>
        </div>
      </div> -->
    </div>
    <!-- /chatbox -->
    <!-- chat-input -->
    <form class="chatinput" v-on:submit.prevent="chat('text')">
      <p v-if="attachBtn" class="btns has-text-centered">
        <a
          class="button"
          :class="{ 'is-loading': photoBtnLoading }"
          @click="$refs.photoBtn.click()"
        >
          <span class="icon is-small">
            <i class="fas fa-photo-video"></i>
          </span>
          <span>Photos</span>
        </a>
        <input
          ref="photoBtn"
          type="file"
          class="is-hidden"
          accept="image/png, image/jpeg"
          @change="photoSelected"
        />
        <!-- <a class="button">
          <span class="icon is-small">
            <i class="fas fa-photo-video"></i>
          </span>
          <span>Videos</span>
        </a> -->
        <a
          class="button"
          :class="{ 'is-loading': fileBtnLoading }"
          @click="$refs.fileBtn.click()"
        >
          <span class="icon is-small">
            <i class="fas fa-paperclip"></i>
          </span>
          <span>File</span>
        </a>
        <input
          ref="fileBtn"
          type="file"
          class="is-hidden"
          @change="fileSelected"
        />
      </p>
      <div class="field messageinput">
        <div class="control" :class="{ 'is-loading': msgloading }">
          <!-- <Mentionable
            :keys="['@']"
            :items="participants"
            offset="6"
            @open="onOpen($event)"
          >
            <textarea
              v-model="message"
              class="input is-link"
              type="text"
              placeholder="Message"
            ></textarea>
            <template #no-result>
              <div class="dim">No result</div>
            </template>
            <template #item-@="{ item }">
              <div class="usertag">
                {{ item.fname + " " + item.lname }}
              </div>
            </template>
          </Mentionable> -->
          <Mentionable
            :keys="['@']"
            :items="participants"
            offset="6"
            @open="loadIssues($event)"
          >
            <textarea
              v-model="message"
              rows="1"
              class="input is-link"
              type="text"
              placeholder="Message"
            ></textarea>

            <template #no-result>
              <div class="dim">
                {{ loading ? "Loading..." : "No result" }}
              </div>
            </template>

            <template #item-#="{ item }">
              <div class="issue">
                <span class="number"> #{{ item.value }} </span>
                <span class="dim">
                  {{ item.label }}
                </span>
              </div>
            </template>
          </Mentionable>
          <a
            v-if="message.length > 1"
            class="button is-black"
            @click="chat('text')"
          >
            <span class="icon">
              <i class="fas fa-paper-plane"></i>
            </span>
          </a>
          <a v-else class="button is-black" @click="attachBtn = !attachBtn">
            <span class="icon">
              <i v-if="attachBtn" class="fas fa-times"></i>
              <i v-else class="fas fa-paperclip"></i>
            </span>
          </a>
        </div>
      </div>
      <!-- <input
        v-model="message"
        class="input is-link"
        type="text"
        placeholder="Message"
      /> -->
    </form>
    <!-- /chat-input -->
    <!-- message-action -->
    <div class="msgAction" v-if="adminPopup">
      <article class="panel is-primary">
        <p class="panel-heading">Admin View</p>
        <a class="panel-block" @click="msgAction(1)">
          <span class="panel-icon">
            <i class="fas fa-trash-alt" aria-hidden="true"></i>
          </span>
          Delete Message
        </a>
        <a class="panel-block" @click="msgAction(2)">
          <span class="panel-icon">
            <i class="fas fa-user-lock" aria-hidden="true"></i>
          </span>
          Block User
        </a>
        <a class="panel-block" @click="$router.push('/u/' + adminPopupMsg.uid)">
          <span class="panel-icon">
            <i class="fas fa-user-circle" aria-hidden="true"></i>
          </span>
          View Profile
        </a>
        <a class="panel-block" @click="adminPopup = false">
          <span class="panel-icon">
            <i class="fas fa-times" aria-hidden="true"></i>
          </span>
          Close
        </a>
      </article>
    </div>
    <!-- /message-action -->
  </section>
</template>
<script>
import { auth } from "@/main.js";
import { db } from "@/main.js";
import { mapGetters } from "vuex";
import { storage } from "@/main.js";
import Imgproxy from "imgproxy";
import firebase from "firebase/app";
// import { Share } from "@capacitor/share";
// import { Webengage } from "@ionic-native/webengage";
import { Mentionable } from "vue-mention";
import { FCM } from "@capacitor-community/fcm";
import { createWebengageEvent } from "../helper";

export default {
  name: "chat",
  components: {
    Mentionable,
  },
  data() {
    return {
      admin: false,
      uid: auth.currentUser.uid,
      id: this.$route.params.id,
      group: {},
      message: "",
      msgloading: false,
      vfile: "",
      vtype: "",
      vname: "",
      chats: [],
      attachBtn: false,
      proxy: new Imgproxy({
        key: "fb6333a954da296899362eca74b278dd",
        salt: "7436536ea6bfeea3559b21121f4844c2",
        baseUrl: "https://witblox-imgproxy.herokuapp.com",
        encode: true,
      }),
      picture: "url(" + require("@/assets/img/user.png") + ")",
      groupTheme: {
        backgroundColor: "#000",
      },
      expanded: false,
      photoBtnLoading: false,
      fileBtnLoading: false,
      adminPopup: false,
      adminPopupMsg: {},
      leaveGrpBtnLoading: false,
      muted: false,
      participants: [],
      loading: false,
    };
  },
  methods: {
    onOpen(key) {
      console.log(this);
      console.log(key);
    },
    loadIssues(event) {
      console.log(event);
      //   this.loading = true;
      //   this.items = await fetchIssues(searchText);
      //   this.loading = false;
    },
    convertTime(added) {
      if ((new Date().getTime() - added) / 36e5 < 1) {
        if (((new Date().getTime() - added) / 1000 / 60).toFixed(0) == 0)
          return "now";
        else
          return (
            ((new Date().getTime() - added) / 1000 / 60).toFixed(0) + " min ago"
          );
      } else if ((new Date().getTime() - added) / 36e5 < 23) {
        return ((new Date().getTime() - added) / 36e5).toFixed(0) + " hrs ago";
      } else if (
        (new Date().getTime() - added) / 36e5 > 23 &&
        (new Date().getTime() - added) / 36e5 > 1
      ) {
        return new Date(added).toDateString();
      }
    },
    randomStr(len, arr) {
      var ans = "";
      for (var i = len; i > 0; i--) {
        ans += arr[Math.floor(Math.random() * arr.length)];
      }
      return ans;
    },
    muteGroup(mute) {
      this.muted = mute;
      if (mute) {
        FCM.unsubscribeFrom({ topic: `group-${this.id}` }).then((res) => {
          console.log(`Unsub to group-${this.id}`, JSON.stringify(res));
        });
      } else {
        FCM.subscribeTo({ topic: `group-${this.id}` }).then((res) => {
          console.log(`Sub to group-${this.id}`, JSON.stringify(res));
        });
      }

      db.collection("groups")
        .doc(this.id)
        .collection("participants")
        .doc(auth.currentUser.uid)
        .update({
          muted: mute,
        });
    },
    leaveGroup() {
      this.leaveGrpBtnLoading = true;
      db.collection("groups")
        .doc(this.id)
        .collection("participants")
        .doc(auth.currentUser.uid)
        .delete()
        .then(() => {
          FCM.unsubscribeFrom({ topic: `group-${this.id}` }).then((res) => {
            console.log(`Unsub to group-${this.id}`, JSON.stringify(res));
          });

          db.collection("users")
            .doc(auth.currentUser.uid)
            .collection("groups")
            .doc(this.id)
            .delete()
            .then(() => {
              db.collection("groups")
                .doc(this.id)
                .update({
                  participants: firebase.firestore.FieldValue.increment(-1),
                })
                .then(() => {
                  this.leaveGrpBtnLoading = false;
                  this.$router.push("/chats");
                })
                .catch((err) => {
                  console.log(err);
                  this.leaveGrpBtnLoading = false;
                });
            })
            .catch((err) => {
              console.log(err);
              this.leaveGrpBtnLoading = false;
            });
        })
        .catch((err) => {
          console.log(err);
          this.leaveGrpBtnLoading = false;
        });
    },
    uploadFile() {
      var storageRef = storage.ref();
      var uploadTask = storageRef
        .child("chatfiles/" + this.randomStr(20, "12345abcde"))
        .put(this.vfile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log("Upload error", error);
          this.photoBtnLoading = false;
          this.fileBtnLoading = false;
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log(downloadURL);
            this.chat(this.vtype, downloadURL, this.vname);
            this.photoBtnLoading = false;
            this.fileBtnLoading = false;
          });
        }
      );
    },
    msgAction(action, message) {
      if (action == 0) {
        if (this.admin) {
          this.adminPopup = true;
          this.adminPopupMsg = message;
        } else this.$router.push("/u/" + message.uid);
      } else if (action == 1) {
        //delete action
        db.collection("groups")
          .doc(this.id)
          .collection("chats")
          .doc(this.adminPopupMsg.id)
          .delete()
          .then(() => {
            this.adminPopup = false;
            // this.adminPopupMsg.hidden = true;
          });
      } else if (action == 2) {
        //block action
        db.collection("users")
          .doc(this.adminPopupMsg.uid)
          .collection("groups")
          .doc(this.id)
          .update({
            banned: true,
          })
          .then(() => {
            db.collection("groups")
              .doc(this.id)
              .collection("participants")
              .doc(message.uid)
              .update({
                banned: true,
              })
              .then(() => {
                this.adminPopup = false;
              });
          });
      }
    },
    fileSelected(event) {
      var size = event.target.files[0].size / 1024 / 1024;
      console.log(event.target.files[0].name, size);
      this.vfile = event.target.files[0];
      this.vtype = "file";
      this.vname = event.target.files[0].name;
      this.uploadFile();
      this.fileBtnLoading = true;
    },
    photoSelected(event) {
      var size = event.target.files[0].size / 1024 / 1024;
      console.log(event.target.files[0].name, size);
      this.vfile = event.target.files[0];
      this.vtype = "image";
      this.vname = event.target.files[0].name;
      this.uploadFile();
      this.photoBtnLoading = true;
    },
    chat(type, media, filename) {
      var mediaFile = "";
      var msg = this.message;
      if (media) {
        mediaFile = media;
        msg = filename;
      }
      this.msgloading = true;
      this.message = "";
      var picture = "";
      if (auth.currentUser.photoURL)
        picture = this.proxy
          .builder()
          .resize("auto", 150, 150, 0)
          .generateUrl(auth.currentUser.photoURL);
      db.collection("groups")
        .doc(this.id)
        .collection("chats")
        .add({
          name: this.profile.fname + " " + this.profile.lname,
          uid: auth.currentUser.uid,
          timestamp: new Date().getTime(),
          type: type,
          text: msg,
          media: mediaFile,
          picture: picture,
        })
        .then(() => {
          createWebengageEvent({
            uid: auth.currentUser.uid,
            eventName: "Chat Sent",
            eventData: {
              senderName: this.profile.fname,
              senderId: auth.currentUser.uid,
              groupId: this.id,
              groupName: this.group.name,
              message: msg,
            },
          });

          this.msgloading = false;
          this.$refs.scrolldown.scrollIntoView(); //{ behavior: "smooth" }
          db.collection("groups")
            .doc(this.id)
            .update({
              updated: new Date().getTime(),
            })
            .then(() => {
              db.collection("users")
                .doc(auth.currentUser.uid)
                .collection("groups")
                .doc(this.id)
                .update({
                  lastchecked: new Date().getTime(),
                });
            });
        });
    },
    urlify(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function (url) {
        return '<a target="blank" href="' + url + '">' + url + "</a>";
      });
    },
    mentionify(text) {
      var pattern = /\B@[a-z0-9_-]+/gi;
      return text.replace(pattern, function (mention) {
        return "<u>" + mention + "</u>";
      });
    },
    openExternalLink(url) {
      window.open(url, "_blank");
    },
  },
  beforeMount() {
    db.collection("groups")
      .doc(this.$route.params.id)
      .get()
      .then((group) => {
        this.group = group.data();
        this.groupTheme.backgroundColor = group.data().theme;
        console.log(group.data().theme, this.groupTheme);
        this.admin = group.data().admins.includes(auth.currentUser.uid);
        // console.log(JSON.stringify(this.group));
        //fetch chats
        db.collection("groups")
          .doc(this.$route.params.id)
          .collection("chats")
          .orderBy("timestamp", "desc")
          .limit(250)
          .get()
          .then((chats) => {
            chats.forEach((chat) => {
              // console.log(chat.doc.id, JSON.stringify(chat.doc.data()));
              var chatObj = chat.data();
              chatObj.id = chat.id;
              chatObj.links = chat.data().text.match(/(https?:\/\/[^\s]+)/g);
              //   console.log(this.$refs.scrolldown);
              chatObj.linkPreview = false;
              if (chat.data().linkPreview)
                chatObj.linkPreview = JSON.parse(chat.data().linkPreview);
              this.chats.unshift(chatObj);
              setTimeout(() => {
                this.$refs.scrolldown.scrollIntoView(); //{ behavior: "smooth" }
              }, 100);
            });
            //listen to upcoming chat
            db.collection("groups")
              .doc(this.$route.params.id)
              .collection("chats")
              .where("timestamp", ">", new Date().getTime())
              .orderBy("timestamp", "desc")
              .onSnapshot((chats) => {
                chats.docChanges().forEach((chat) => {
                  if (chat.type === "added") {
                    var chatObj = chat.doc.data();
                    chatObj.id = chat.id;
                    chatObj.links = chat.doc
                      .data()
                      .text.match(/(https?:\/\/[^\s]+)/g);
                    if (chat.doc.data().linkPreview)
                      chatObj.linkPreview = JSON.parse(
                        chat.doc.data().linkPreview
                      );
                    this.chats.push(chatObj);
                    // console.log(this.$refs.scrolldown);
                    setTimeout(() => {
                      this.$refs.scrolldown.scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 100);
                  }
                });
              });
          });
      });
  },
  mounted() {
    db.collection("users")
      .doc(auth.currentUser.uid)
      .collection("groups")
      .doc(this.id)
      .update({
        lastchecked: new Date().getTime(),
      });
    db.collection("groups")
      .doc(this.id)
      .collection("participants")
      .doc(auth.currentUser.uid)
      .get()
      .then((participant) => {
        console.log(participant.data().muted);
        if (participant.data().muted) this.muted = true;
      });
    db.collection("groups")
      .doc(this.id)
      .collection("participants")
      .limit(500)
      .get()
      .then((participants) => {
        participants.forEach((participant) => {
          db.collection("users")
            .doc(participant.id)
            .get()
            .then((participantUser) => {
              var participantObj = {
                value: participantUser.data().username,
                label:
                  participantUser.data().fname +
                  " " +
                  participantUser.data().lname,
              };
              this.participants.push(participantObj);
            });
          //   console.log(participantObj);
        });
      });
    //update textarea size
    const tx = document.getElementsByTagName("textarea");
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute(
        "style",
        "height:" + tx[i].scrollHeight + "px;overflow-y:hidden;"
      );
      tx[i].addEventListener("input", OnInput, false);
    }
    function OnInput() {
      this.style.height = "auto";
      this.style.height = this.scrollHeight + "px";
    }
  },
  computed: {
    ...mapGetters({
      profile: "profile",
    }),
  },
};
</script>
<style scoped>
.chatinput {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 25px;
  background: rgb(255, 255, 255, 0.85);
}

.desc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.descbox {
  margin: 20px;
  border-radius: 15px;
  background: rgb(236, 236, 236);
  padding: 10px 30px;
  box-shadow: 0 8px 17px 2px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%),
    0 5px 5px -3px rgb(0 0 0 / 20%);
  /* transition: margin 2s;
  transition: border-radius 2s; */
}

.descbox h1.title {
  font-size: 1rem;
  padding: 5px 0;
}

.descbox.expanded {
  margin: 0px;
  padding: 30px;
  border-radius: 0px;
}

#chatbox {
  padding-top: 15px;
  padding-bottom: 80px;
  overflow: scroll;
}

.chatbox {
  padding: 150px 20px;
}

.messagegroup {
  /* padding: 12px 20px 10px 20px; */
  padding-bottom: 35px;
  font-family: "Poppins", "sans-serif";
}

.message {
  padding: 12px 50px 30px 20px;
  margin-right: 8%;
  border-radius: 0 20px 20px 20px;
  position: relative;
  font-family: "Poppins", "sans-serif" !important;
  font-weight: 500;
}

.message.self {
  margin-right: 0px;
  margin-left: 8%;
  border-radius: 20px 0 20px 20px;
  background: #000;
  color: #fff;
}

.messagegroup b.name {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.message .time {
  font-size: 0.7rem;
  font-weight: 500;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
}
.message img {
  border-radius: 10px;
  margin-top: 5px;
}
p.btns .button {
  margin: 10px 5px;
}

.chatfile a {
  display: block;
  width: 100%;
  padding: 10px 30px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  font-weight: 800;
  margin-bottom: 20px;
  color: #000 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fullscreen-v-img {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.messageinput a.button {
  position: absolute;
  right: 0;
  bottom: 0;
}
.messageinput input.input {
  border-color: #000;
}

.messagegroup .columns {
  max-width: 100%;
}
.messagegroup .columns .column {
  padding: 0px !important;
  max-width: 100%;
}
.messagegroup .userimage {
  border-radius: 50% 0 0 50%;
  border: 1.5px solid #ffff;
  width: 32.5px;
  height: 32.5px;
  background-image: url(~@/assets/img/user.png);
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  background: url(~@/assets/img/user.png),
    linear-gradient(to right, #8e2de2, #4a00e0);
}
.messagegroup.self .userimage {
  border-radius: 0 50% 50% 0;
}
textarea.input {
  padding-right: 50px !important;
}
.expandedbox {
  padding: 0 30px;
}
.chatimage img {
  min-height: 100px;
}
.descbox hr {
  background-color: #bababa;
}
.msgAction {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1111;
  padding: 200px 50px;
  background: rgba(0, 0, 0, 0.7);
}
.msgAction .panel {
  background: #fff;
}

p.joined {
  text-align: center;
}

p.joined span {
  background: #dadada;
  color: #2d2d2d;
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  font-size: 0.7rem;
  display: inline-block;
  padding: 3.5px 15px;
  border-radius: 10px;
}
</style>
<style>
/* mention */
.v-popper--theme-dropdown .v-popper__inner {
  padding: 6px;
}
.v-popper--theme-my-theme .v-popper__inner {
  background: #fff;
  color: black;
  padding: 24px;
  border-radius: 6px;
  border: 1px solid #ddd;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
}

.v-popper--theme-my-theme .v-popper__arrow-inner {
  visibility: visible;
  border-color: #fff;
}

.v-popper--theme-my-theme .v-popper__arrow-outer {
  border-color: #ddd;
}

/* Transition */

.v-popper--theme-my-theme.v-popper__popper--hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.v-popper--theme-my-theme.v-popper__popper--shown {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.v-popper--theme-my-theme.v-popper__popper--skip-transition {
  transition: none !important;
}

.card.linkPreview {
  border-radius: 20px;
  margin-bottom: 20px;
}

.card.linkPreview p {
  font-size: 0.7rem;
}

.card.linkPreview p.title {
  font-size: 1rem;
}

.image.is-4by3 img {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  border-radius: 15px 15px 0 0;
}
</style>