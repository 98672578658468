<template>
  <section class="videosection">
    <a @click="router('/classes')" class="closeBtns">
      <span class="icon">
        <i class="far fa-times-circle"></i>
      </span>
    </a>
    <div class="video-container" v-if="screen === 0">
      <video-player
        v-if="vid1.loaded"
        class="vjs-custom-skin"
        ref="videoPlayer"
        :options="vid1"
        :playsinline="true"
        @ended="onPlayerEnded($event)"
      ></video-player>
      <div class="videoBtns">
        <a class="button is-small" id="skipBtn" @click="screen = 1">
          <span>Join this Class</span>
          <span class="icon">
            <i class="fas fa-chevron-right"></i>
          </span>
        </a>
      </div>
    </div>
    <div
      class="video-container"
      :class="{ blur: blur }"
      v-else-if="screen === 1"
    >
      <img :src="cls.img" class="featuredImg desktop-video" />
      <div class="hero desktop-video-info">
        <div class="hero-body">
          <p class="title">{{ cls.name }}</p>
          <p class="subtitle">&#8377;{{ cls.price }}</p>
          <div class="separator">ABOUT</div>
          <img src="@/assets/img/class-features.png" alt="" />
          <img src="@/assets/img/class-certificate.png" alt="" />
          <div class="separator">DESCRIPTION</div>
          <div v-html="cls.desc"></div>
          <div class="separator">LESSONS</div>
          <div class="lessons">
            <div
              v-for="lesson in cls.lessons"
              :key="lesson.id"
              class="columns is-mobile"
            >
              <div class="column is-3">
                <img :src="lesson.img" alt="" />
              </div>
              <div class="column">
                <h6>{{ lesson.title }}</h6>
                <p>{{ lesson.description }}</p>
              </div>
            </div>
          </div>
          <div class="separator">FAQs</div>
          <div class="faq">
            <article v-for="faq in faqs" :key="faq.id" class="message">
              <div class="message-header">
                <p @click="faq.open = !faq.open">{{ faq.title }}</p>
              </div>
              <div class="message-body is-collapsible" v-if="faq.open">
                <div class="message-body-content">
                  {{ faq.content }}
                </div>
              </div>
            </article>
          </div>
          <div class="classcontainer"></div>
          <div class="trailerVidBtn">
            <div class="columns">
              <div class="column">
                <button
                  v-if="paid && completed"
                  class="button btn btn-theme"
                  disabled
                >
                  Completed
                </button>
                <button
                  v-if="paid && !completed"
                  @click="showInApp"
                  class="button btn btn-theme"
                >
                  Join Link
                </button>
                <button
                  v-if="!paid && !completed"
                  @click="openCheckbox"
                  class="button btn btn-theme"
                >
                  Register
                </button>
                <button
                  v-if="!paid && completed"
                  @click="openCheckbox"
                  class="button btn btn-theme"
                >
                  Register
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a @click="screen = 0" class="screen2Btn">
        <span class="icon">
          <i class="fas fa-chevron-circle-left"></i>
        </span>
      </a>
    </div>
    <div
      class="popupbox"
      :class="{ open: checkbox }"
      v-if="screen == 1 && checkbox"
    >
      <div class="columns is-mobile" style="padding-bottom: 20px">
        <div class="column">
          <h1 class="title">You Also Need</h1>
        </div>
        <div class="column is-2 has-text-right">
          <span class="icon" @click="closeAllPopup">
            <i class="far fa-times-circle"></i>
          </span>
        </div>
      </div>
      <div
        class="columns is-mobile cartproduct"
        v-for="item in mayNeed"
        :key="item.id"
      >
        <div class="column is-4 has-text-centered">
          <img :src="item.img" />
        </div>
        <div class="column">
          <p class="title">{{ item.name }}</p>
          <p class="subtitle">&#8377;{{ numberWithCommas(item.price) }}</p>
        </div>
        <div class="column is-3">
          <div class="select is-small is-fullwidth">
            <select v-model="item.qty">
              <option v-for="option in item.options" :key="option">
                {{ option }}
              </option>
            </select>
          </div>
          <a
            class="button is-small is-fullwidth"
            :class="{ 'is-loading': item.loading }"
            @click="addItemToCart(item.id, item.qty, item)"
          >
            <i v-if="item.added" class="fas fa-check"></i>
            <i v-else class="fas fa-plus"></i>
          </a>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <button @click="openSchedule" class="button btn btn-theme">
            Next
          </button>
        </div>
      </div>
    </div>
    <div
      class="popupbox"
      :class="{ open: schedulebox }"
      v-if="screen == 1 && schedulebox"
    >
      <div class="columns is-mobile">
        <div class="column">
          <h1 class="title">Schedule</h1>
        </div>
        <div class="column is-2 has-text-right">
          <span class="icon" @click="closeAllPopup">
            <i class="far fa-times-circle"></i>
          </span>
        </div>
      </div>
      <div
        v-for="schedule in schedules"
        :key="schedule.id"
        class="columns schedule"
      >
        <div class="column">
          <div
            @click="scheduleId = schedule.id"
            class="card"
            :class="{ selected: schedule.id == scheduleId }"
          >
            <div class="card-content">
              <h6>{{ schedule.title }}</h6>
              <p>{{ schedule.subtitle }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <button @click="sendToCart()" class="button btn btn-theme">
            Book This Slot
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { db } from "@/main.js";
import "@/assets/css/video-js.css";
import { videoPlayer } from "vue-video-player";
import { Browser } from "@capacitor/browser";
// import { Webengage } from "@ionic-native/webengage";
import { createWebengageEvent } from '../helper';
export default {
  name: "classdetails",
  components: {
    videoPlayer,
  },
  data() {
    return {
      userId: "",
      screen: 0,
      id: this.$route.params.id,
      name: "",
      desc: "",
      banner: "",
      btnLoading: false,
      checkbox: false,
      schedulebox: false,
      blur: false,
      paid: false,
      completed: false,
      mayNeed: [],
      schedules: [],
      scheduleId: "",
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
        joinLink: "",
      },
      cls: {},
      faqs: [],
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    onPlayerEnded() {
      this.screen = 1;
    },
    async showInApp() {
      await Browser.open({ url: this.joinLink });
    },
    closeAllPopup() {
      this.checkbox = false;
      this.schedulebox = false;
      this.blur = false;
    },
    openCheckbox() {
      if (this.mayNeed.length > 0) {
        this.checkbox = true;
        this.schedulebox = false;
      } else {
        this.openSchedule();
      }
      this.blur = true;
    },
    openPayment() {
      this.checkbox = false;
      this.schedulebox = false;
      this.blur = true;
    },
    openSchedule() {
      this.checkbox = false;
      this.schedulebox = true;
      this.blur = true;
    },
    openFaq(faq) {
      faq.open = !faq.open;
      console.log(faq);
      //   this.cls.qstns[i].open = !this.cls.qstns[i].open;
      //   console.log(this.cls.qstns[i]);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    addItemToCart(id, qty, object) {
      if (object) object.loading = true;
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .where("product", "==", id)
          .get()
          .then((snap) => {
            if (snap.empty) {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .add({
                  added: new Date().getTime(),
                  product: id,
                  quantity: Number(qty),
                })
                .then(() => {
                  if (object) {
                    object.added = true;
                    object.loading = false;
                  }
                });
            } else {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .doc(snap.docs[0].id)
                .update({
                  quantity: Number(qty),
                })
                .then(() => {
                  if (object) {
                    object.added = true;
                    object.loading = false;
                  }
                });
            }
          });
      });
    },
    sendToCart() {
      if (this.scheduleId) {
        auth.onAuthStateChanged((user) => {
          db.collection("class-orders")
            .where("uid", "==", user.uid)
            .where("class", "==", this.id)
            .limit(1)
            .get()
            .then((snap) => {
              if (snap.empty) {
                //schedule not picked
                db.collection("class-orders")
                  .add({
                    created: new Date().getTime(),
                    updated: new Date().getTime(),
                    class: this.id,
                    schedule: this.scheduleId,
                    uid: user.uid,
                    paid: false,
                    orderId: "",
                  })
                  .then(() => {
                    this.addItemToCart(this.cls.product, 1);
                    setTimeout(() => {
                      this.router("/cart");
                    }, 1000);
                  });
              } else {
                //schedule picked
                db.collection("class-orders")
                  .doc(snap.docs[0].id)
                  .update({
                    updated: new Date().getTime(),
                    schedule: this.scheduleId,
                  })
                  .then(() => {
                    this.addItemToCart(this.cls.product, 1);
                    setTimeout(() => {
                      this.router("/cart");
                    }, 1000);
                  });
              }
            });
        });
      }
    },
  },
  beforeMount() {
    this.userId = auth.currentUser.uid;
    auth.onAuthStateChanged((user) => {
      db.collection("class-orders")
        .where("uid", "==", user.uid)
        .where("class", "==", this.id)
        .limit(1)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            this.paid = snap.docs[0].data().paid;
            db.collection("classes")
              .doc(this.id)
              .collection("schedule")
              .doc(snap.docs[0].data().schedule)
              .get()
              .then((schedule) => {
                if (schedule.data().end < new Date().getTime())
                  this.completed = true;
                this.joinLink = schedule.data().joinLink;
              });
          }
        });
    });
    db.collection("classes")
      .doc(this.id)
      .get()
      .then((doc) => {
        // if (!doc.exists) this.router("/");
        // else {
        this.cls = doc.data();
        this.cls.id = doc.id;
        createWebengageEvent({
          uid: this.userId,
          eventName: "Class Viewed",
          eventData: {
            added: new Date().getTime(),
            readableDate: new Date().toLocaleString(),
            name: doc.data().name,
          },
        })
        doc.data().faqs.forEach((faq, index) => {
          this.faqs.push({
            id: doc.id + String(index),
            title: faq.title,
            content: faq.content,
            open: false,
          });
        });
        doc.data()["mayNeed"].forEach((product) => {
          if (product.id != "enLRPEK6DQMsObUrtnfb") {
            db.collection("shop-products")
              .doc(product.id)
              .get()
              .then((item) => {
                var options = [];
                for (
                  var i = 1;
                  i < Number(item.data()["purchase-limit"]) + 1;
                  i++
                ) {
                  options.push(i);
                }
                this.mayNeed.push({
                  id: item.id,
                  name: item.data().name,
                  price: item.data()["pricing"]["in-mrp"],
                  img: item.data().imgs[0],
                  loading: false,
                  added: false,
                  options: options,
                  qty: product.qty,
                });
              });
          }
        });
        this.vid1.sources.push({
          type: "video/mp4",
          src: doc.data().trailer,
        });
        this.vid1.poster =
          "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
          encodeURIComponent(doc.data().trailer) +
          "&size=400&mode=orig";
        // }
        setTimeout(() => {
          this.vid1.loaded = true;
        }, 100);
        db.collection("classes")
          .doc(this.id)
          .collection("schedule")
          .where("end", ">", new Date().getTime())
          .where("available", "==", true)
          .orderBy("end")
          .get()
          .then((schedules) => {
            for (let index = 0; index < schedules.size; index++) {
              const schedule = schedules.docs[index];
              if (index == 0) this.scheduleId = schedule.id;
              var pushObj = schedule.data();
              pushObj.id = schedule.id;
              pushObj.selected = true;
              this.schedules.push(pushObj);
            }
          });
      });
  },
};
</script>   
<style scoped>
.classcontainer {
  padding-bottom: 70px;
}
.popupbox {
  position: fixed;
  display: block;
  width: 100%;
  padding: 30px;
  bottom: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%),
    0 11px 15px -7px rgb(0 0 0 / 20%);
  border-radius: 30px 30px 0 0;
  height: 0px;
  transition: height 2s;
}
.popupbox.open {
  height: auto;
  max-height: 60%;
  overflow: auto;
}
.popupbox .icon {
  padding-top: 13px;
  font-size: 1.4rem;
}
.subtotal .title {
  font-size: 1.25rem;
}
.subtotal .subtitle {
  font-size: 1.1rem;
}
.subtotal .column {
  padding: 0.5rem 0.75rem 0 0.75rem;
}
.blur {
  transition: filter 1s;
  filter: blur(6px);
}
.schedule {
  margin-bottom: 5px;
}
.schedule .card {
  border-radius: 20px;
}
.schedule .card.selected {
  border: 3px solid #000;
  background: #ece8e8;
}
.schedule .card-content {
  padding: 1rem 0.75rem 0.75rem 1.5rem;
}
.schedule h6 {
  font-size: 1.15rem;
  color: #000;
  font-weight: 600;
}
.schedule p {
  font-size: 1.05rem;
}
.lessons img {
  border-radius: 20px 0 0 20px;
}
.lessons p {
  font-weight: 500;
  font-size: 0.9rem;
}
.lessons h6 {
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 4px;
}
.videoBtns {
  text-align: center;
}
.videoBtns .button {
  font-size: 1.2rem;
  background: #000;
  border-radius: 10px;
}
.button.is-hovered,
.button:hover {
  color: #ffffff;
}
</style>