<template>
  <section class="videosection">
    <a @click="router('/')" class="closeBtns">
      <span class="icon">
        <i class="far fa-times-circle"></i>
      </span>
    </a>
    <div class="video-container">
      <video-player
        v-if="vid1.loaded"
        class="vjs-custom-skin"
        ref="videoPlayer"
        :options="vid1"
        :playsinline="true"
      ></video-player>
      <div class="videoBtns">
        <a class="button is-small" id="skipBtn" @click="$router.push(route)">
          <span>Next</span>
          <span class="icon">
            <i class="fas fa-chevron-right"></i>
          </span>
        </a>
      </div>
    </div>
  </section>
</template>
<script>
// import { Plugins } from "@capacitor/core";
// import { auth } from "@/main.js";
import { db } from "@/main.js";
import "@/assets/css/video-js.css";
import { videoPlayer } from "vue-video-player";
// const { Browser } = Plugins;
import { Browser } from "@capacitor/browser";
export default {
  name: "trailer",
  components: {
    videoPlayer,
  },
  data() {
    return {
      id: this.$route.params.id,
      route: "",
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
      },
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    async showInApp() {
      await Browser.open({ url: this.link });
    },
  },
  beforeMount() {
    db.collection("config")
      .doc("bloxes")
      .collection("all")
      .doc(this.id)
      .get()
      .then((doc) => {
        if (!doc.exists) this.router("/");
        else {
          console.log(doc.data());
          this.route = doc.data().route;
          this.vid1.sources.push({
            type: "video/mp4",
            src: doc.data().video,
          });
          this.vid1.poster =
            "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
            encodeURIComponent(doc.data().video) +
            "&size=400&mode=orig";
        }
        setTimeout(() => {
          this.vid1.loaded = true;
        }, 100);
      });
  },
};
</script>   
<style scoped>
.themeabout {
  padding-bottom: 25px;
}
.videoBtns {
  text-align: center;
}
.videoBtns .button {
  font-size: 1.2rem;
  background: #000;
  border-radius: 10px;
}
</style>