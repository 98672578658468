<template>
  <main class="loginscreen">
    <div class="hero">
      <div class="userauth">
        <!-- animation -->
        <div v-if="screen === 1" id="img1">
          <img src="@/assets/img/witblox-login-screen/1.png" alt="" />
          <!-- <img src="@/assets/img/login/login1.png" />
          <h6 class="title has-text-centered">Hi! I'm Power Blox</h6>
          <h6 class="subtitle has-text-centered">Please enter your email.</h6> -->
        </div>
        <div v-else-if="screen === 2" id="img2">
          <img src="@/assets/img/witblox-login-screen/1.png" alt="" />
          <!-- <img src="@/assets/img/login/img2.jpg" />
          <h6 class="title has-text-centered">Enter your password</h6> -->
        </div>
        <div v-else-if="screen === 3" id="img3">
          <img src="@/assets/img/witblox-login-screen/2.png" alt="" />
          <!-- <img src="@/assets/img/login/login2.png" />
          <h6 class="title has-text-centered">Hi! I'm Lamp Blox</h6>
          <h6 class="subtitle has-text-centered">Please enter your name.</h6> -->
        </div>
        <div v-else-if="screen === 4" id="img4">
          <img src="@/assets/img/witblox-login-screen/3.png" alt="" />
          <!-- <img src="@/assets/img/login/login3.png" />
          <h6 class="title has-text-centered">Hi! I'm Buzz Blox</h6>
          <h6 class="subtitle has-text-centered">
            Please enter your school name.
          </h6> -->
        </div>
        <div v-else-if="screen === 5" id="img5">
          <img src="@/assets/img/witblox-login-screen/4.png" alt="" />
          <!-- <img src="@/assets/img/login/login4.png" />
          <h6 class="title has-text-centered">Hi! I'm Dark Blox</h6>
          <h6 class="subtitle has-text-centered">
            Please enter your birthdate.
          </h6> -->
        </div>
        <div v-else-if="screen === 6" id="img6">
          <img src="@/assets/img/witblox-login-screen/5.png" alt="" />
          <!-- <img src="@/assets/img/login/login5.png" />
          <h6 class="title has-text-centered">Hi! I'm Driver Blox</h6>
          <h6 class="subtitle has-text-centered">
            Please enter your phone number.
          </h6> -->
        </div>
        <div v-else-if="screen === 7" id="img8">
          <img src="@/assets/img/witblox-login-screen/6.png" alt="" />
          <!-- <img src="@/assets/img/login/img2.jpg" />
          <h6 class="title has-text-centered">Create your password</h6> -->
        </div>
        <div v-else-if="screen === 8" id="img7">
          <img src="@/assets/img/login/img7.jpg" alt="" />
          <!-- <img src="@/assets/img/login/img7.jpg" />
          <h6 class="title has-text-centered">Check your email</h6> -->
        </div>
        <!-- /animation -->
        <div v-if="screen === 1" class="login-signup">
          <div class="columns">
            <div class="column">
              <div v-if="emailStatus !== ''" class="notification">
                {{ emailStatus }}
              </div>
              <div class="field">
                <p class="control has-icons-left">
                  <input
                    v-model="email"
                    class="input"
                    type="email"
                    placeholder="Email"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                </p>
              </div>
              <a @click="getStarted" ref="btn1" class="button btn btn-theme"
                >Get Started</a
              >
            </div>
          </div>
        </div>
        <div v-else-if="screen === 2" class="login-signup">
          <div class="columns">
            <div class="column">
              <nav
                class="pagination is-rounded"
                role="navigation"
                aria-label="pagination"
              >
                <ul class="pagination-list">
                  <li>
                    <a class="pagination-link" aria-label onclick="route(0)">
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link is-current" aria-label></a>
                  </li>
                </ul>
              </nav>
              <div v-if="loginStatus" class="notification">
                {{ loginStatus }}
              </div>
              <div class="field">
                <div class="control has-icons-left" id="password-input-wrapper">
                  <input
                    v-model="password"
                    class="input"
                    :type="passwordType"
                    placeholder="Password"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-key"></i>
                  </span>
                  <span
                    style="pointer-events: all; cursor: pointer"
                    id="password-toggle"
                    class="icon is-small is-right"
                    @click="togglePasswordType"
                  >
                    <i
                      v-if="passwordType === 'password'"
                      class="fas fa-eye"
                    ></i>
                    <i v-else class="fas fa-eye-slash"></i>
                  </span>
                </div>
              </div>
              <a @click="login" ref="btn2" class="button btn btn-theme"
                >Login</a
              >
              <div class="has-text-centered" style="padding-top: 20px">
                <a class="resetBtn" @click="resetPass">Reset Your Password</a>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="screen === 3" class="login-signup">
          <div class="columns">
            <div class="column">
              <nav
                class="pagination is-rounded"
                role="navigation"
                aria-label="pagination"
              >
                <ul class="pagination-list">
                  <li>
                    <a class="pagination-link is-current" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link is-current" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link is-current" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link is-current" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                </ul>
              </nav>
              <div class="notification" v-if="nameStatus">{{ nameStatus }}</div>
              <div class="columns is-mobile">
                <div class="column" style="padding-right: 0px">
                  <div class="field">
                    <p class="control has-icons-left">
                      <input
                        v-model="fname"
                        class="input"
                        type="text"
                        placeholder="First Name"
                        maxlength="10"
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-user"></i>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="column">
                  <div class="field">
                    <p class="control">
                      <input
                        v-model="lname"
                        class="input"
                        type="text"
                        placeholder="Last Name"
                        maxlength="10"
                      />
                    </p>
                  </div>
                </div>
              </div>
              <a @click="nameEntered()" class="button btn btn-theme">Next</a>
            </div>
          </div>
        </div>
        <div v-else-if="screen === 4" class="login-signup">
          <div class="columns">
            <div class="column">
              <nav
                class="pagination is-rounded"
                role="navigation"
                aria-label="pagination"
              >
                <ul class="pagination-list">
                  <li>
                    <a class="pagination-link" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link is-current" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link is-current" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link is-current" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                </ul>
              </nav>
              <div class="notification" v-if="schoolStatus">
                {{ schoolStatus }}
              </div>
              <div class="field">
                <p class="control has-icons-left">
                  <input
                    v-model="school"
                    class="input"
                    type="text"
                    placeholder="School Name"
                    maxlength="35"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-school"></i>
                  </span>
                </p>
              </div>
              <a @click="schoolEntered()" class="button btn btn-theme">Next</a>
            </div>
          </div>
        </div>
        <div v-else-if="screen === 5" class="login-signup">
          <div class="columns">
            <div class="column">
              <nav
                class="pagination is-rounded"
                role="navigation"
                aria-label="pagination"
              >
                <ul class="pagination-list">
                  <li>
                    <a class="pagination-link" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link is-current" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link is-current" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                </ul>
              </nav>
              <div class="notification" v-if="dateStatus">{{ dateStatus }}</div>
              <div class="field">
                <p class="control">
                  <input
                    v-model="date"
                    class="input"
                    type="date"
                    placeholder="Date of Birth"
                  />
                </p>
              </div>
              <a @click="checkAge()" class="button btn btn-theme">Next</a>
            </div>
          </div>
        </div>
        <div v-else-if="screen === 6" class="login-signup">
          <div class="columns">
            <div class="column">
              <nav
                class="pagination is-rounded"
                role="navigation"
                aria-label="pagination"
              >
                <ul class="pagination-list">
                  <li>
                    <a class="pagination-link" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link is-current" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                </ul>
              </nav>
              <div class="notification" v-if="phoneStatus">
                {{ phoneStatus }}
              </div>
              <div class="columns is-mobile">
                <div class="column is-3">
                  <div class="select">
                    <select v-model="code" class="">
                      <option disabled>Choose your country</option>
                      <option value="+91">+91</option>
                      <option value="+93">+93</option>
                      <option value="+355">+355</option>
                      <option value="+213">+213</option>
                      <option value="+1684">+1684</option>
                      <option value="+376">+376</option>
                      <option value="+244">+244</option>
                      <option value="+1264">+1264</option>
                      <option value="+672">+672</option>
                      <option value="+1268">+1268</option>
                      <option value="+54">+54</option>
                      <option value="+374">+374</option>
                      <option value="+297">+297</option>
                      <option value="+247">+247</option>
                      <option value="+61">+61</option>
                      <option value="+672">+672</option>
                      <option value="+43">+43</option>
                      <option value="+994">+994</option>
                      <option value="+1242">+1242</option>
                      <option value="+973">+973</option>
                      <option value="+880">+880</option>
                      <option value="+1246">+1246</option>
                      <option value="+1268">+1268</option>
                      <option value="+375">+375</option>
                      <option value="+32">+32</option>
                      <option value="+501">+501</option>
                      <option value="+229">+229</option>
                      <option value="+1441">+1441</option>
                      <option value="+975">+975</option>
                      <option value="+591">+591</option>
                      <option value="+387">+387</option>
                      <option value="+267">+267</option>
                      <option value="+55">+55</option>
                      <option value="+1284">+1284</option>
                      <option value="+673">+673</option>
                      <option value="+359">+359</option>
                      <option value="+226">+226</option>
                      <option value="+257">+257</option>
                      <option value="+855">+855</option>
                      <option value="+237">+237</option>
                      <option value="+1">+1</option>
                      <option value="+238">+238</option>
                      <option value="+1345">+1345</option>
                      <option value="+236">+236</option>
                      <option value="+235">+235</option>
                      <option value="+64">+64</option>
                      <option value="+56">+56</option>
                      <option value="+86">+86</option>
                      <option value="+61">+61</option>
                      <option value="+57">+57</option>
                      <option value="+269">+269</option>
                      <option value="+242">+242</option>
                      <option value="+243">+243</option>
                      <option value="+682">+682</option>
                      <option value="+506">+506</option>
                      <option value="+225">+225</option>
                      <option value="+385">+385</option>
                      <option value="+53">+53</option>
                      <option value="+5399">+5399</option>
                      <option value="+599">+599</option>
                      <option value="+357">+357</option>
                      <option value="+420">+420</option>
                      <option value="+45">+45</option>
                      <option value="+246">+246</option>
                      <option value="+253">+253</option>
                      <option value="+1767">+1767</option>
                      <option value="+670">+670</option>
                      <option value="+56">+56</option>
                      <option value="+593">+593</option>
                      <option value="+20">+20</option>
                      <option value="+503">+503</option>
                      <option value="+8812">+8812</option>
                      <option value="+88213">+88213</option>
                      <option value="+240">+240</option>
                      <option value="+291">+291</option>
                      <option value="+372">+372</option>
                      <option value="+251">+251</option>
                      <option value="+500">+500</option>
                      <option value="+298">+298</option>
                      <option value="+679">+679</option>
                      <option value="+358">+358</option>
                      <option value="+33">+33</option>
                      <option value="+596">+596</option>
                      <option value="+594">+594</option>
                      <option value="+689">+689</option>
                      <option value="+241">+241</option>
                      <option value="+220">+220</option>
                      <option value="+995">+995</option>
                      <option value="+49">+49</option>
                      <option value="+233">+233</option>
                      <option value="+350">+350</option>
                      <option value="+8818">+8818</option>
                      <option value="+30">+30</option>
                      <option value="+299">+299</option>
                      <option value="+1473">+1473</option>
                      <option value="+590">+590</option>
                      <option value="+1671">+1671</option>
                      <option value="+5399">+5399</option>
                      <option value="+502">+502</option>
                      <option value="+245">+245</option>
                      <option value="+224">+224</option>
                      <option value="+592">+592</option>
                      <option value="+509">+509</option>
                      <option value="+504">+504</option>
                      <option value="+852">+852</option>
                      <option value="+36">+36</option>
                      <option value="+8810">+8810</option>
                      <option value="+354">+354</option>
                      <option value="+91">+91</option>
                      <option value="+62">+62</option>
                      <option value="+871">+871</option>
                      <option value="+874">+874</option>
                      <option value="+873">+873</option>
                      <option value="+872">+872</option>
                      <option value="+870">+870</option>
                      <option value="+800">+800</option>
                      <option value="+808">+808</option>
                      <option value="+98">+98</option>
                      <option value="+964">+964</option>
                      <option value="+353">+353</option>
                      <option value="+8816">+8816</option>
                      <option value="+972">+972</option>
                      <option value="+39">+39</option>
                      <option value="+1876">+1876</option>
                      <option value="+81">+81</option>
                      <option value="+962">+962</option>
                      <option value="+7">+7</option>
                      <option value="+254">+254</option>
                      <option value="+686">+686</option>
                      <option value="+850">+850</option>
                      <option value="+82">+82</option>
                      <option value="+965">+965</option>
                      <option value="+996">+996</option>
                      <option value="+856">+856</option>
                      <option value="+371">+371</option>
                      <option value="+961">+961</option>
                      <option value="+266">+266</option>
                      <option value="+231">+231</option>
                      <option value="+218">+218</option>
                      <option value="+423">+423</option>
                      <option value="+370">+370</option>
                      <option value="+352">+352</option>
                      <option value="+853">+853</option>
                      <option value="+389">+389</option>
                      <option value="+261">+261</option>
                      <option value="+265">+265</option>
                      <option value="+60">+60</option>
                      <option value="+960">+960</option>
                      <option value="+223">+223</option>
                      <option value="+356">+356</option>
                      <option value="+692">+692</option>
                      <option value="+596">+596</option>
                      <option value="+222">+222</option>
                      <option value="+230">+230</option>
                      <option value="+269">+269</option>
                      <option value="+52">+52</option>
                      <option value="+691">+691</option>
                      <option value="+1808">+1808</option>
                      <option value="+373">+373</option>
                      <option value="+377">+377</option>
                      <option value="+976">+976</option>
                      <option value="+382">+382</option>
                      <option value="+1664">+1664</option>
                      <option value="+212">+212</option>
                      <option value="+258">+258</option>
                      <option value="+95">+95</option>
                      <option value="+264">+264</option>
                      <option value="+674">+674</option>
                      <option value="+977">+977</option>
                      <option value="+31">+31</option>
                      <option value="+599">+599</option>
                      <option value="+1869">+1869</option>
                      <option value="+687">+687</option>
                      <option value="+64">+64</option>
                      <option value="+505">+505</option>
                      <option value="+227">+227</option>
                      <option value="+234">+234</option>
                      <option value="+683">+683</option>
                      <option value="+672">+672</option>
                      <option value="+1670">+1670</option>
                      <option value="+47">+47</option>
                      <option value="+968">+968</option>
                      <option value="+92">+92</option>
                      <option value="+680">+680</option>
                      <option value="+970">+970</option>
                      <option value="+507">+507</option>
                      <option value="+675">+675</option>
                      <option value="+595">+595</option>
                      <option value="+51">+51</option>
                      <option value="+63">+63</option>
                      <option value="+48">+48</option>
                      <option value="+351">+351</option>
                      <option value="+1787">+1787</option>
                      <option value="+974">+974</option>
                      <option value="+262">+262</option>
                      <option value="+40">+40</option>
                      <option value="+7">+7</option>
                      <option value="+250">+250</option>
                      <option value="+290">+290</option>
                      <option value="+1869">+1869</option>
                      <option value="+1758">+1758</option>
                      <option value="+508">+508</option>
                      <option value="+1784">+1784</option>
                      <option value="+685">+685</option>
                      <option value="+378">+378</option>
                      <option value="+239">+239</option>
                      <option value="+966">+966</option>
                      <option value="+221">+221</option>
                      <option value="+381">+381</option>
                      <option value="+248">+248</option>
                      <option value="+232">+232</option>
                      <option value="+65">+65</option>
                      <option value="+421">+421</option>
                      <option value="+386">+386</option>
                      <option value="+677">+677</option>
                      <option value="+252">+252</option>
                      <option value="+27">+27</option>
                      <option value="+34">+34</option>
                      <option value="+94">+94</option>
                      <option value="+249">+249</option>
                      <option value="+597">+597</option>
                      <option value="+268">+268</option>
                      <option value="+46">+46</option>
                      <option value="+41">+41</option>
                      <option value="+963">+963</option>
                      <option value="+886">+886</option>
                      <option value="+992">+992</option>
                      <option value="+255">+255</option>
                      <option value="+66">+66</option>
                      <option value="+88216">+88216</option>
                      <option value="+670">+670</option>
                      <option value="+228">+228</option>
                      <option value="+690">+690</option>
                      <option value="+676">+676</option>
                      <option value="+1868">+1868</option>
                      <option value="+216">+216</option>
                      <option value="+90">+90</option>
                      <option value="+993">+993</option>
                      <option value="+1649">+1649</option>
                      <option value="+688">+688</option>
                      <option value="+256">+256</option>
                      <option value="+380">+380</option>
                      <option value="+971">+971</option>
                      <option value="+44">+44</option>
                      <option value="+1">+1</option>
                      <option value="+1340">+1340</option>
                      <option value="+878">+878</option>
                      <option value="+598">+598</option>
                      <option value="+998">+998</option>
                      <option value="+678">+678</option>
                      <option value="+39">+39</option>
                      <option value="+58">+58</option>
                      <option value="+84">+84</option>
                      <option value="+808">+808</option>
                      <option value="+681">+681</option>
                      <option value="+967">+967</option>
                      <option value="+260">+260</option>
                      <option value="+255">+255</option>
                      <option value="+263">+263</option>
                    </select>
                  </div>
                </div>
                <div class="column" style="padding-left: 0px">
                  <div class="field">
                    <p class="control has-icons-left">
                      <input
                        class="input"
                        type="number"
                        v-model="phone"
                        placeholder="Phone Number"
                        minlength="10"
                        maxlength="10"
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-phone"></i>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <a @click="checkPhone()" class="button btn btn-theme">Next</a>
            </div>
          </div>
        </div>
        <div v-else-if="screen === 7" class="login-signup">
          <div class="columns">
            <div class="column">
              <nav
                class="pagination is-rounded"
                role="navigation"
                aria-label="pagination"
              >
                <ul class="pagination-list">
                  <li>
                    <a class="pagination-link" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                  <li>
                    <span class="pagination-ellipsis">&mdash;</span>
                  </li>
                  <li>
                    <a class="pagination-link" aria-label>
                      <i class="fas fa-check"></i>
                    </a>
                  </li>
                </ul>
              </nav>
              <div class="notification" v-if="newPassStatus">
                {{ newPassStatus }}
              </div>
              <div class="notification" v-if="!newPassStatus">
                By signing up, you agree to our
                <b @click="openTnC">Terms & Conditions</b>.
              </div>
              <div class="field">
                <p class="control has-icons-left">
                  <input
                    v-model="newpassword"
                    class="input"
                    :type="passwordType"
                    placeholder="Password"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-key"></i>
                  </span>

                  <span
                    style="pointer-events: all; cursor: pointer"
                    id="password-toggle"
                    class="icon is-small is-right"
                    @click="togglePasswordType"
                  >
                    <i
                      v-if="passwordType === 'password'"
                      class="fas fa-eye"
                    ></i>
                    <i v-else class="fas fa-eye-slash"></i>
                  </span>
                </p>
              </div>
              <a @click="registerUser()" ref="btn3" class="button btn btn-theme"
                >Finish</a
              >
            </div>
          </div>
        </div>
        <div v-else-if="screen === 8" class="login-signup">
          <div class="columns">
            <div class="column">
              <div v-if="resetStatus" class="notification">
                {{ resetStatus }}
              </div>
              <a class="button btn btn-theme" @click="screen = 2">
                Back to Login
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { functions } from "@/main.js";
// import { Webengage, WebengageUser } from "@ionic-native/webengage";
import { SplashScreen } from "@capacitor/splash-screen";
// import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { createWebengageEvent, createWebengageUser } from "../helper";

export default {
  name: "login",
  data() {
    return {
      screen: 1,
      //eslint-disable-next-line
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      email: "",
      emailStatus: "",
      password: "",
      newpassword: "",
      fname: "",
      lname: "",
      nameStatus: "",
      school: "",
      schoolStatus: "",
      date: "",
      dateStatus: "",
      code: "+91",
      phone: "",
      phoneNumber: "",
      phoneStatus: "",
      loginStatus: "",
      resetStatus: "",
      newPassStatus: "",
      from: this.$route.query.from,
      passwordType: "password",
    };
  },
  methods: {
    openTnC() {
      Browser.open({
        url: "https://techacks.notion.site/Terms-Conditions-2d91baffc67948eea95886222f1d1e66",
      });
    },
    login() {
      this.$refs.btn2.setAttribute("disabled", "");
      this.$refs.btn2.classList.add("is-loading");
      auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          createWebengageEvent({
            uid: auth.currentUser.uid,
            eventName: "User Logged In",
            eventData: {
              added: new Date().getTime(),
              readableDate: new Date().toLocaleString(),
            },
          });
          this.$router.push(this.from ? this.from : "/");
        })
        .catch((error) => {
          this.loginStatus = error.message;
          this.$refs.btn2.removeAttribute("disabled");
          this.$refs.btn2.classList.remove("is-loading");
        });
    },
    getStarted() {
      this.$refs.btn1.classList.add("is-loading");
      if (this.reg.test(this.email)) {
        this.emailStatus = "";
        db.collection("users")
          .where("email", "==", this.email)
          .get()
          .then((snap) => {
            this.$refs.btn1.classList.remove("is-loading");
            if (!snap.empty) {
              this.screen = 2;
            } else {
              this.screen = 3;
            }
          });
      } else {
        this.$refs.btn1.classList.remove("is-loading");
        this.emailStatus = "Please enter your correct e-mail address";
      }
    },
    resetPass() {
      this.$refs.btn2.setAttribute("disabled", "");
      auth
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.resetStatus =
            "Please check your inbox for our email which contains the reset password link.";
          this.screen = 8;
          this.$refs.btn2.removeAttribute("disabled");
        })
        .catch((error) => {
          console.log(error);
          this.resetStatus = "An unexpected error occured.";
          this.$refs.btn2.removeAttribute("disabled");
        });
    },
    nameEntered() {
      if (this.fname && this.lname) this.screen = 4;
      else this.nameStatus = "Please enter your first & last name.";
    },
    schoolEntered() {
      if (this.school) this.screen = 5;
      else this.schoolStatus = "Please enter your school name.";
    },
    checkAge() {
      var userage = new Date(this.date).getTime();
      var minimum = new Date();
      minimum.setFullYear(minimum.getFullYear() - 7);
      if (userage < minimum.getTime()) {
        this.screen = 6;
      } else {
        this.dateStatus = "You need to be atleast 7 years old to register.";
      }
    },
    checkPhone() {
      var num = this.phone;
      if(this.code === "+91"){
        if (num.length < 10) {
          this.phoneStatus = "Phone number is invalid.";
          return;
        }
      }

      this.phoneNumber = this.code + this.phone;
      this.screen = 7;
    },
    registerUser() {
      this.$refs.btn3.setAttribute("disabled", "");
      this.$refs.btn3.classList.add("is-loading");
      auth
        .createUserWithEmailAndPassword(this.email, this.newpassword)
        .then((user) => {
          console.log(user);
          //send to server
          var ruid = "";
          if (this.$route.params.uid) ruid = this.$route.params.uid;
          var completeProfile = functions.httpsCallable("completeProfile");
          completeProfile({
            fname: this.fname,
            lname: this.lname,
            school: this.school,
            email: this.email,
            dob: new Date(this.date).getTime(),
            phone: this.phoneNumber,
            referral: ruid,
          }).then(() => {
            auth.currentUser
              .updateProfile({
                displayName: this.fname,
              })
              .then(() => {
                this.$refs.btn3.removeAttribute("disabled");
                this.$refs.btn3.classList.remove("is-loading");
                // if (Capacitor.getPlatform() != "ios") Webengage.engage();
                // WebengageUser.login(auth.currentUser.uid);
                db.collection("users")
                  .doc(auth.currentUser.uid)
                  .get()
                  .then((profile) => {
                    var userProfile = profile.data();
                    // WebengageUser.setAttribute(
                    //   "created",
                    //   new Date(userProfile.created)
                    // );
                    const date = new Date(userProfile.dob);
                    const dob =
                      date.getFullYear() +
                      "-" +
                      (date.getMonth() + 1) +
                      "-" +
                      date.getDate();
                    // WebengageUser.setAttribute("we_birth_date", dob);
                    // WebengageUser.setAttribute("we_email", userProfile.email);
                    // WebengageUser.setAttribute(
                    //   "we_first_name",
                    //   userProfile.fname
                    // );
                    // WebengageUser.setAttribute(
                    //   "we_last_name",
                    //   userProfile.lname
                    // );
                    // WebengageUser.setAttribute("we_phone", userProfile.phone);
                    // WebengageUser.setAttribute("ranking", userProfile.ranking);
                    // WebengageUser.setAttribute(
                    //   "we_company",
                    //   userProfile.school
                    // );
                    // WebengageUser.setAttribute(
                    //   "totalcoins",
                    //   userProfile.totalcoins
                    // );
                    // WebengageUser.setAttribute(
                    //   "verified",
                    //   userProfile.verified
                    // );

                    createWebengageUser({
                      uid: auth.currentUser.uid,
                      firstName: userProfile.fname,
                      lastName: userProfile.lname,
                      email: userProfile.email,
                      phone: userProfile.phone,
                      attrs: {
                        created: userProfile.created,
                        dob: dob,
                        school: userProfile.school,
                        ranking: userProfile.ranking,
                        totalcoins: userProfile.totalcoins,
                        verified: userProfile.verified,
                      },
                    }).then(() => {
                      createWebengageEvent({
                        uid: auth.currentUser.uid,
                        eventName: "User Signed Up",
                        eventData: {
                          added: new Date().getTime(),
                          readableDate: new Date().toLocaleString(),
                        },
                      });
                    });
                    this.$router.push("/home/new");
                  });
              });
          });
        })
        .catch((error) => {
          this.$refs.btn3.removeAttribute("disabled");
          this.$refs.btn3.classList.remove("is-loading");
          this.newPassStatus = error.message;
        });
    },
    togglePasswordType() {
      console.log("toggle");
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
  },
  mounted() {
    SplashScreen.hide();
    // console.log(this.$route.params.uid);
  },
  beforeMount() {
    if (auth.currentUser) this.$router.push("/");
  },
};
</script>
<style scoped>
.ios .login-signup {
  /* padding: 30px 20px 50px 20px; */
}
.hero {
  max-width: 400px;
  margin: auto;
}

.login-signup .columns.is-mobile {
  padding: 0px;
}

#password-input-wrapper {
  position: relative;
}
#password-toggle {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>