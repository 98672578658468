import Vue from "vue";
import Vuex from "vuex";
import MainApp from "./App.vue";
import Router from "vue-router";
import VueImg from "v-img";
import VueMeta from "vue-meta";

// import { App } from '@capacitor/app';
// import { PushNotifications } from "@capacitor/push-notifications";

import "./assets/css/bulma.min.css";
import "./assets/css/all.min.css";
import "./assets/css/fonts.css";
import "floating-vue/dist/style.css";
import "./assets/css/main.css";
/* sections */
// import home from "./components/home2.vue"
import home from "./components/home.vue";
import search from "./components/search.vue";
// import build from "./components/build.vue"
// import homelanding from "./components/home-landing.vue"
import category from "./components/category.vue";
import fest from "./components/fest.vue";
import techfest from "./components/fest-original.vue";
import theme from "./components/theme.vue";
import festtheme from "./components/festtheme.vue";
import submission from "./components/submission.vue";
import challenge from "./components/challenge.vue";
import challenges from "./components/challenges.vue";
import competition from "./components/competition.vue";
import project from "./components/project.vue";
import event from "./components/event.vue";
import trailer from "./components/trailer.vue";
import login from "./components/login.vue";
import intro from "./components/intro.vue";
import profile from "./components/profile.vue";
import edit from "./components/edit.vue";
import referral from "./components/referral.vue";
import rewards from "./components/rewards.vue";
import prizes from "./components/prizes.vue";
import chadgpt from "./components/chadgpt.vue";
import chadgpt_payment from "./components/chadgpt-payment.vue";
import onboarding from "./components/onboarding.vue";
/* witblox-social */
import social from "./components/social.vue";
import upload from "./components/upload.vue";
import activity from "./components/activity.vue";
import leaderboard from "./components/leaderboard.vue";
import user from "./components/user.vue";
import post from "./components/post.vue";
/* Witblox-shop */
import shophome from "./components/shop.vue";
import shopcategory from "./components/shop-category.vue";
import shopcart from "./components/shop-cart.vue";
import shopproduct from "./components/shop-product.vue";
import shoporders from "./components/shop-orders.vue";
import shoporder from "./components/shop-order.vue";
import shoptransactions from "./components/shop-transactions.vue";
/* witblox-classes */
import classhome from "./components/class-home.vue";
import classdetails from "./components/class-details.vue";
import schedule from "./components/class-schedule.vue";
import warranty from "./components/warranty.vue";
import quiz from "./components/quiz.vue";
import chats from "./components/chats.vue";
import chat from "./components/chat.vue";
import groups from "./components/groups.vue";
/* subscription */
import subscription from "./components/subscription.vue";
import subscribed from "./components/subscribed.vue";
import subscription_payments from "./components/subscription_payments.vue";
import subscription_orders from "./components/subscription_orders.vue";
import subscription_plans from "./components/subscription_plans.vue";
import subscription_tasks from "./components/subscription_tasks.vue";
import mentors from "./components/mentors.vue";
import kbc from "./components/kbc.vue";
import crowdfunding from "./components/crowdfunding.vue";
import crowdfunding_upload from "./components/crowdfunding_upload.vue";
import campaigns from "./components/campaigns.vue";

/* firebase */
import { ObserveVisibility } from "vue-observe-visibility";
// import VueFire from "vuefire"
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/analytics";

/* analytics */
// import { Plugins } from "@capacitor/core";
// const { FirebaseAnalytics } = Plugins;
// FirebaseAnalytics.setCollectionEnabled({
//     enabled: false,
// });
// FirebaseAnalytics.enable();
const firebaseConfig = {
	apiKey: "AIzaSyCQOZ2Re2ytVpz7KiKhqrQcV3GNG9r8pZk",
	authDomain: "witblox-5ae68.firebaseapp.com",
	databaseURL: "https://witblox-5ae68.firebaseio.com",
	projectId: "witblox-5ae68",
	storageBucket: "witblox-5ae68.appspot.com",
	messagingSenderId: "532604685774",
	appId: "1:532604685774:web:a895ff5bd01df136edaf9f",
	measurementId: "G-YKYYG1KZKB",
};
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
FirebaseAnalytics.initializeFirebase(firebaseConfig);
firebase.initializeApp(firebaseConfig);
//
export const db = firebase.firestore();
export const firestore = firebase.firestore;
export const auth = firebase.auth();
export const functions = firebase.functions();
export const storage = firebase.storage();
import InstantSearch from "vue-instantsearch";
// import Imgproxy from "imgproxy";
import fx from "money";

Vue.use(InstantSearch);
Vue.use(Vuex);
Vue.use(VueMeta);
Vue.use(Router);
Vue.use(VueImg);

Vue.config.productionTip = false;
Vue.directive("observe-visibility", ObserveVisibility);

// var proxy = new Imgproxy({
// 	key: "fb6333a954da296899362eca74b278dd",
// 	salt: "7436536ea6bfeea3559b21121f4844c2",
// 	baseUrl: "https://witblox-imgproxy.herokuapp.com",
// 	encode: true,
// });

//state management
const store = new Vuex.Store({
	state: {
		count: 0,
		profile: {
			uid: "",
			picture: "",
		},
		events: [],
		featuredEvents: [],
		categories: [],
		projects: [],
		trending: [],
		trendingCheck: [],
		banner: "",
		bannerRoute: "",
		homePageBanner: [],
		homePageBannerDesktop: [],
		stats: {
			posts: null,
			completed: null,
			followers: null,
			following: null,
			coins: null,
			statLoader: true,
		},
		allposts: [],
		deeplink: "",
		projectData: [],
		categoryData: [],
		colors: [
			{
				c1: "#ffdd7e",
				c2: "#000",
			},
			{
				c1: "#ffdd7e",
				c2: "#000",
			},
			{
				c1: "#ffdd7e",
				c2: "#000",
			},
			{
				c1: "#ffdd7e",
				c2: "#000",
			},
			{
				c1: "#c8ff7e",
				c2: "#000",
			},
			{
				c1: "#c8ff7e",
				c2: "#000",
			},
			{
				c1: "#7eff94",
				c2: "#000",
			},
			{
				c1: "#7eff94",
				c2: "#000",
			},
			{
				c1: "#7effe7",
				c2: "#000",
			},
			{
				c1: "#7effe7",
				c2: "#000",
			},
			{
				c1: "#e4ef38",
				c2: "#000",
			},
			{
				c1: "#e4ef38",
				c2: "#000",
			},
			{
				c1: "#ef6c38",
				c2: "#fff",
			},
			{
				c1: "#ef6c38",
				c2: "#fff",
			},
			{
				c1: "#7767ff",
				c2: "#fff",
			},
			{
				c1: "#7767ff",
				c2: "#fff",
			},
			{
				c1: "#b667ff",
				c2: "#fff",
			},
			{
				c1: "#b667ff",
				c2: "#fff",
			},
			{
				c1: "#f567ff",
				c2: "#fff",
			},
			{
				c1: "#f567ff",
				c2: "#fff",
			},
			{
				c1: "#ff678a",
				c2: "#fff",
			},
			{
				c1: "#ff678a",
				c2: "#fff",
			},
			{
				c1: "#3e00ff",
				c2: "#fff",
			},
			{
				c1: "#3e00ff",
				c2: "#fff",
			},
		],
		selectedColor: {},
		classes: [],
		rewardBox: {},
		rewardBoxState: false,
		homeopened: false,
		quizzes: [],
		challenges: [],
	},
	mutations: {
		getProfile(state) {
			var homeOpened = state.profile.homeOpened;
			auth.onAuthStateChanged((user) => {
				if (!user) return;
				db.collection("users")
					.doc(user.uid)
					.get()
					.then((profile) => {
						state.profile = profile.data();
						state.profile.verified = false;
						if (profile.data().verified) state.profile.verified = true;
						state.profile.premium = false;
						if (profile.data().premium) state.profile.premium = true;
						state.profile.uid = profile.id;
						if (user.photoURL) {
							state.profile.picture = "background: url(" + user.photoURL + ")";
							// state.profile.picture =
							// 	"background: url(" +
							// 	proxy
							// 		.builder()
							// 		.resize("auto", 150, 150, 0)
							// 		.generateUrl(user.photoURL) +
							// 	"), linear-gradient(to right, rgb(142, 45, 226), rgb(74, 0, 224))";
						} else state.profile.picture = "";
						if (homeOpened > profile.data().homeOpened)
							state.profile.homeOpened = homeOpened;
					})
					.catch((err) => {
						console.log(err);
						state.profile = {
							uid: "",
							picture: "",
							fname: "Maker",
							lname: "",
							verified: false,
							bio: "",
							homeOpened: 3,
							ranking: 0,
						};
						store.commit("getProfile");
					});
			});
		},
		getTrendingPosts(state) {
			var d = new Date();
			d.setDate(d.getDate() - 1);
			db.collection("social")
				.where("clearance", "==", true)
				.where("current", ">", d.getTime())
				.orderBy("current")
				.orderBy("views", "desc")
				.limit(50)
				.get()
				.then((snap) => {
					snap.forEach((post) => {
						// console.log("trending", post.id, post.data());
						if (!state.trendingCheck.includes(post.data().uid)) {
							state.trendingCheck.push(post.data().uid);
							db.collection("users")
								.doc(post.data().uid)
								.get()
								.then((author) => {
									var likes = 0;
									if (post.data().likes) likes = post.data().likes;
									state.trending.push({
										id: post.id,
										views: post.data().views,
										likes: likes,
										thumbnail:
											"background: url(" + post.data().thumbnail + ");",
										author: author.data().fname,
									});
								});
						}
					});
				});
		},
		getCategories(state) {
			db.collection("config")
				.doc("categories")
				.get()
				.then((doc) => {
					for (var i = 0; i < doc.data().order.length; i++) {
						db.collection("category")
							.doc(doc.data().order[i])
							.get()
							.then((cat) => {
								state.categories.push({
									id: cat.id,
									img: cat.data().img,
									banner: cat.data().banner,
									route: cat.data().route,
									hashtag: cat.data().hashtag,
									name: cat.data().name,
									icon: cat.data().icon,
									desc: cat.data().desc,
									tag: cat.data().tag,
									product: cat.data().product,
								});
							});
					}
				});
		},
		getProjects(state) {
			db.collection("projects")
				.orderBy("added", "desc")
				.limit(10)
				.get()
				.then((snap) => {
					snap.forEach((project) => {
						state.projects.push({
							id: project.id,
							img: project.data().featuredImg,
						});
					});
				});
		},
		getEvents(state) {
			db.collection("config")
				.doc("events")
				.get()
				.then((doc) => {
					state.eventsName = doc.data().sectionName;
					doc.data().docs.forEach((evnt) => {
						db.collection("events")
							.doc(evnt)
							.get()
							.then((info) => {
								state.events.push({
									id: info.id,
									img: info.data().featuredImg,
								});
							});
					});
					db.collection("challenges")
						.orderBy("added", "desc")
						.limit(10)
						.get()
						.then((snap) => {
							snap.forEach((challenge) => {
								var chlngObj = challenge.data();
								chlngObj.id = challenge.id;
								chlngObj.thumbnailStyle =
									"background: url(" + chlngObj.featuredImg + ");";
								state.challenges.push(chlngObj);
							});
						});
					doc.data().events.forEach((event) => {
						db.collection("events")
							.doc(event)
							.get()
							.then((info) => {
								var eventInfo = info.data();
								eventInfo.id = info.id;
								state.featuredEvents.push(eventInfo);
							});
					});
				});
			//quizzes
			db.collection("quizzes")
				.orderBy("added", "desc")
				.limit(10)
				.get()
				.then((quizzes) => {
					quizzes.forEach((quiz) => {
						var quizData = quiz.data();
						quizData.id = quiz.id;
						state.quizzes.push(quizData);
					});
				});
		},
		getHomepageConfig(state) {
			db.collection("config")
				.doc("homepage")
				.get()
				.then((doc) => {
					state.shopBanner = "url(" + doc.data().shopBanner + ")";
					state.banner = "url(" + doc.data().banner + ")";
					state.bannerRoute = doc.data().bannerRoute;
					// state.homePageBanner = doc.data().homePageBanner;
					doc.data().homePageBanner.forEach((banner) => {
						state.homePageBanner.push({
							banner: "url(" + banner.banner + ")",
							route: banner.route,
							tag: banner.tag,
						});
					});
					doc.data().homePageBannerDesktop.forEach((banner) => {
						state.homePageBannerDesktop.push({
							banner: "url(" + banner.banner + ")",
							route: banner.route,
							tag: banner.tag,
						});
						// console.log({
						//     banner: "url(" + banner.banner + ")",
						//     route: banner.route,
						//     tag: banner.tag
						// });
					});
				});
		},
		getProfileStats(state) {
			state.stats.statLoader = true;
			auth.onAuthStateChanged((user) => {
				if (!user) return;
				var profileStats = functions.httpsCallable("profileStats");
				profileStats({ uid: user.uid }).then((result) => {
					console.log(result.data);
					state.stats.posts = result.data.posts;
					state.stats.completed = result.data.completed;
					state.stats.followers = result.data.followers;
					state.stats.following = result.data.following;
					state.stats.coins = result.data.coins;
					if (result.data.coins < 0) state.stats.coins = 0;
					else state.stats.coins = result.data.coins;
					state.stats.statLoader = false;
				});
			});
		},
		getCategoryData(state) {
			db.collection("category")
				.get()
				.then((snap) => {
					snap.forEach((category) => {
						var ctr = category.data();
						ctr.id = category.id;
						state.categoryData.push(ctr);
					});
				});
		},
		getProjectData(state) {
			db.collection("projects")
				.get()
				.then((snap) => {
					snap.forEach((project) => {
						auth.onAuthStateChanged((user) => {
							if (!user) return;
							db.collection("users")
								.doc(user.uid)
								.collection("projects")
								.where("pid", "==", project.id)
								.get()
								.then((sts) => {
									var prjct = project.data();
									prjct.id = project.id;
									prjct.done = !sts.empty;
									state.projectData.push(prjct);
									if (prjct.id == "7suk7FremS5QvRRR6tVs") console.log(prjct);
								});
						});
					});
				});
		},
		getColorInfo(state) {
			state.selectedColor = state.colors[new Date().getHours()];
		},
		getClasses(state) {
			db.collection("classes")
				.orderBy("created", "desc")
				.get()
				.then((snap) => {
					snap.forEach((cls) => {
						var clsObj = cls.data();
						clsObj.id = cls.id;
						state.classes.push(clsObj);
					});
				});
		},
		openRewardBox(state, payload) {
			console.log("opening reward box:", payload);
			if (payload.open) {
				state.rewardBox = payload;
				state.rewardBoxState = true;
			} else state.rewardBoxState = false;
		},
		getExchangeRates() {
			db.collection("config")
				.doc("currencies")
				.get()
				.then((doc) => {
					fx.base = "INR";
					fx.rates = doc.data().rates;
				});
		},
	},
	getters: {
		profile: (state) => state.profile,
		stats: (state) => state.stats,
		rewardBoxState: (state) => state.rewardBoxState,
		rewardBox: (state) => state.rewardBox,
	},
});
//homepage
store.commit("getProfile");
store.commit("getTrendingPosts");
store.commit("getCategories");
store.commit("getProjects");
store.commit("getEvents");
store.commit("getHomepageConfig");
store.commit("getClasses");
//profile
store.commit("getProfileStats");
//category
store.commit("getCategoryData");
store.commit("getProjectData");
store.commit("getColorInfo");
store.commit("getExchangeRates");

let app;
auth.onAuthStateChanged(() => {
	if (!app) {
		app = new Vue({
			router,
			store: store,
			render: (h) => h(MainApp),
		}).$mount("#app");
	}
});

//paths
const router = new Router({
	mode: "history",
	routes: [
		// {
		//     path: "/",
		//     name: "home2",
		//     component: home2,
		//     meta: {
		//         requiresAuth: true
		//     }
		// },
		{
			path: "/",
			name: "home",
			component: home,
		},
		{
			path: "/home/:new?",
			name: "home",
			component: home,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/search",
			name: "search",
			component: search,
			meta: {
				requiresAuth: true,
			},
		},
		// {
		//     path: "/build",
		//     name: "build",
		//     component: build,
		//     meta: {
		//         requiresAuth: true
		//     }
		// },
		// {
		//     path: "/landing",
		//     name: "homelanding",
		//     component: homelanding,
		//     meta: {
		//         requiresAuth: true
		//     }
		// },
		{
			path: "/category/:id",
			name: "category",
			component: category,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/fest",
			name: "fest",
			component: fest,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/techfest",
			name: "techfest",
			component: techfest,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/theme/:id",
			name: "theme",
			component: theme,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/festtheme/:id",
			name: "festtheme",
			component: festtheme,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/submission",
			name: "submission",
			component: submission,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/challenge/:id/:page?",
			name: "challenge",
			component: challenge,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/challenges",
			name: "challenges",
			component: challenges,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/competition/:id",
			name: "competition",
			component: competition,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/project/:id/:page?",
			name: "project",
			component: project,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/event/:id",
			name: "event",
			component: event,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/trailer/:id",
			name: "trailer",
			component: trailer,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/login/:uid?",
			name: "login",
			component: login,
		},
		{
			path: "/intro",
			name: "intro",
			component: intro,
		},
		{
			path: "/social/:postid?",
			name: "social",
			component: social,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/upload/:type/:tag/:coins",
			name: "upload",
			component: upload,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/activity",
			name: "activity",
			component: activity,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/profile",
			name: "profile",
			component: profile,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/edit",
			name: "edit",
			component: edit,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/leaderboard",
			name: "leaderboard",
			component: leaderboard,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/u/:id",
			name: "user",
			component: user,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/p/:id",
			name: "post",
			component: post,
			meta: {
				requiresAuth: true,
			},
		},
		/* shop-navigation */
		{
			path: "/shop",
			name: "shophome",
			component: shophome,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/product/:id",
			name: "shopproduct",
			component: shopproduct,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/products/:id",
			name: "shopcategory",
			component: shopcategory,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/transactions",
			name: "shoptransactions",
			component: shoptransactions,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/cart",
			name: "shopcart",
			component: shopcart,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/orders",
			name: "shoporders",
			component: shoporders,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/order/:id",
			name: "shoporder",
			component: shoporder,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/classes",
			name: "classhome",
			component: classhome,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/class/:id",
			name: "classdetails",
			component: classdetails,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/schedule",
			name: "classschedule",
			component: schedule,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/referral",
			name: "referral",
			component: referral,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/rewards",
			name: "rewards",
			component: rewards,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/prizes",
			name: "prizes",
			component: prizes,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/warranty",
			name: "warranty",
			component: warranty,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/quiz/:id",
			name: "quiz",
			component: quiz,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/chats",
			name: "chats",
			component: chats,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/chat/:id",
			name: "chat",
			component: chat,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/groups",
			name: "groups",
			component: groups,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/subscription",
			name: "subscription",
			component: subscription,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/subscribed",
			name: "subscribed",
			component: subscribed,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/subscription_orders",
			name: "subscription_orders",
			component: subscription_orders,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/subscription_payments",
			name: "subscription_payments",
			component: subscription_payments,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/task/:id",
			name: "subscription_tasks",
			component: subscription_tasks,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/plans",
			name: "subscription_plans",
			component: subscription_plans,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/mentors",
			name: "mentors",
			component: mentors,
			// meta: {
			// 	requiresAuth: true,
			// },
		},
		{
			path: "/chadgpt",
			name: "chadgpt",
			component: chadgpt,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/chadgpt-payment/:plan?",
			name: "chadgpt_payment",
			component: chadgpt_payment,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/onboarding",
			name: "onboarding",
			component: onboarding,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/chandrayaan",
			name: "kbc",
			component: kbc,
			meta: {
				requiresAuth: false,
			},
		},
		{
			path: "/chandrayan",
			name: "kbc",
			component: kbc,
			meta: {
				requiresAuth: false,
			},
		},
		{
			path: "/crowdfunding/:id",
			name: "crowdfunding",
			component: crowdfunding,
			meta: {
				requiresAuth: false,
			},
		},
		{
			path: "/crowdfunding_upload/:id",
			name: "crowdfunding_upload",
			component: crowdfunding_upload,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/campaigns",
			name: "campaigns",
			component: campaigns,
			meta: {
				requiresAuth: true,
			},
		},
	],
});

//auth
router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
	if (requiresAuth && !auth.currentUser) {
		next("/intro?from=" + String(to.fullPath));
	} else {
		next();
	}
});

//scroll to top
router.afterEach((to, from) => {
	console.log(to, from);
	FirebaseAnalytics.setScreenName({
		screenName: to.path,
		nameOverride: to.path,
	});
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
});
