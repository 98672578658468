<template :key="$route.fullPath">
  <section class="shop">
    <div class="hero">
      <div class="hero-body">
        <div class="columns is-mobile">
          <div class="column">
            <h1 class="title" @click="router('/shop')">
              <img
                src="https://witblox.com/assets/img/witblox.png"
                width="25px"
              />
              Shop
            </h1>
          </div>
          <div class="column is-narrow" style="margin: auto">
            <a @click="$router.push('/search')" class="searchIcon">
              <i class="fas fa-search"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="categories">
      <div class="columns">
        <div class="column ordersBtn">
          <a
            class="button is-small is-rounded"
            @click="router('/transactions')"
          >
            <img
              src="@/assets/img/coin.svg"
              style="width: 15px; margin-right: 5px"
            />
            <span id="coins">{{ coins }}</span>
          </a>
          <a class="button is-small is-rounded" @click="router('/orders')">
            <i class="fas fa-shopping-bag" style="margin-right: 4px"></i>
            <span id="coins">Track Orders</span>
          </a>
        </div>
      </div>
      <div class="columns tagsholder">
        <div class="column">
          <div class="columns is-mobile">
            <div class="column">
              <h1 class="title">
                <b>{{ name }}</b>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="categories">
            <div class="columns">
                <div class="column has-text-centered ordersBtn">
                    <a class="button is-small is-rounded" @click="router('/transactions')">
                        <img src="@/assets/img/coin.svg" style="width: 15px; margin-right: 5px" />
                        <span id="coins">{{ coins }}</span>
                    </a>
                    <a class="button is-small is-rounded" @click="router('/orders')">
                        <i class="fas fa-shopping-bag" style="margin-right: 4px"></i>
                        <span id="coins">Orders</span>
                    </a>
                </div>
            </div>
            <hr style="margin: 0.25rem 0px 1.5rem 0px; background-color: #d7d7d7" />
            <nav class="pagination notification-filter" role="navigation" aria-label="pagination">
                <ul class="pagination-list">
                    <li v-for="category in categories" :key="category.id">
                        <a class="pagination-link" :class="{ 'is-current': category.active }" @click="
              router('/products/' + category.id);
          init();
                        ">{{ category.name }}</a>
                    </li>
                </ul>
            </nav>
        </div> -->
    <!-- <div class="products">
      <div class="cardholder" v-for="product in products" :key="product.id">
        <div class="card depth">
          <div class="card-image" @click="router('/product/' + product.id)">
            <figure class="image is-4by3">
              <img :src="product.img" alt="Placeholder image" />
d          </div>
          <div class="card-content" @click="router('/product/' + product.id)">
            <strike class="striked">{{ product.original }}</strike>
            <p class="title">{{ product.mrp }}</p>
            <p class="subtitle">{{ product.name }}</p>
          </div>
          <div class="card-footer">
            <a class="card-footer-item" v-if="product.added">
              <i class="fas fa-check"></i>
            </a>
            <a
              v-else
              class="card-footer-item"
              @click="addItemToCart(product.id, product)"
            >
              <i class="fas fa-plus"></i>
            </a>
            <a class="card-footer-item"><i class="fas fa-share-alt"></i></a>
          </div>
        </div>
      </div>
    </div> -->
    <div class="products">
      <div class="cardholder" v-for="product in products" :key="product.id">
        <div class="card depth">
          <div class="card-image" @click="router('/product/' + product.id)">
            <figure class="image is-4by3">
              <img :src="product.img" alt="Placeholder image" />
            </figure>
          </div>
          <div class="card-content" @click="router('/product/' + product.id)">
            <p class="subtitle">{{ product.name }}</p>
            <p class="title" :style="{ color: '#595959' }">
              <span>
                {{ numberWithCommas(product.mrpmax) }}
              </span>
              <!-- <span v-if="product.mrpmax"
                >- {{ numberWithCommas(product.mrpmax) }}</span
              > -->
              <!-- <strike v-else class="striked">{{ product.original }}</strike> -->
            </p>
          </div>
          <div class="card-footer">
            <a class="card-footer-item" v-if="product.added">
              <i class="fas fa-check"></i>
            </a>
            <a v-else class="card-footer-item" @click="openMayNeed(product)">
              <i class="fas fa-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="cartaction" v-if="incart > 0">
      <div class="columns is-mobile" @click="router('/cart')">
        <div class="column is-3">
          <h1 class="title has-text-right">{{ incart }}</h1>
        </div>
        <div class="column">
          <h3 class="subtitle">Items in cart</h3>
        </div>
        <div class="column is-3">
          <i class="fas fa-arrow-circle-right" style="font-size: 2.2rem"></i>
        </div>
      </div>
    </div>
    <div v-if="cartbox" class="cartbox">
      <div class="content depth">
        <div class="columns is-mobile">
          <div class="column is-2">
            <img :src="selected.img" alt="" />
          </div>
          <div class="column">
            <p
              class="subtitle"
              style="margin-bottom: 0.5em; padding-bottom: 0px"
            >
              {{ selected.name }}
            </p>
            <p class="subtitle" style="font-size: 1rem; padding-bottom: 0px">
              {{ numberWithCommas(selected.price) }}
            </p>
            <div class="select is-rounded is-fullwidth is-small">
              <select v-model.number="qty" @change="updateQty()">
                <option v-for="option in options" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="column is-2 has-text-right">
            <a
              style="font-size: 1.6rem; color: #000000"
              @click="cartbox = false"
            >
              <i class="fas fa-times-circle"></i>
            </a>
          </div>
        </div>
        <div
          class="notification has-text-centered"
          style="padding: 1rem; margin-bottom: 1rem"
          v-if="selected.popupmsg"
        >
          <img
            src="https://cdn-icons-png.flaticon.com/512/5290/5290058.png"
            style="display: inline"
            width="12px"
          />
          {{ selected.popupmsg }}
        </div>
        <hr v-if="mayNeed.length > 0" style="margin: 1rem 0" />
        <div v-if="mayNeed.length > 0">
          <div class="columns">
            <div class="column">
              <p
                class="title has-text-centered"
                style="font-size: 1.3rem; padding-bottom: 10px"
              >
                To build this project,<br />you will also need
              </p>
            </div>
          </div>
          <div
            class="columns is-mobile cartproduct"
            v-for="item in mayNeed"
            :key="item.id"
          >
            <div class="column is-4 has-text-centered">
              <img :src="item.img" />
            </div>
            <div class="column">
              <p class="title">{{ item.name }}</p>
              <p class="subtitle">{{ numberWithCommas(item.price) }}</p>
            </div>
            <div class="column is-3">
              <div class="select is-small is-fullwidth">
                <select v-model="item.qty">
                  <option v-for="option in item.options" :key="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <a
                class="button is-small is-fullwidth"
                :class="{ 'is-loading': item.loading, active: item.added }"
              >
                <i
                  v-if="item.added"
                  @click="removeItemFromCart(item)"
                  class="fas fa-check"
                ></i>
                <i
                  v-else
                  @click="addItemToCart(item.id, item.qty, item, item.price)"
                  class="fas fa-plus"
                ></i>
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div class="footerfixed">
          <a @click="router('/cart')" class="btn btn-theme"
            >Total: {{ numberWithCommas(selected.kitTotal) }} &rarr;</a
          >
        </div>
      </div>
    </div>

    <div v-if="nostock" class="cartbox nostock">
      <div class="content depth">
        <div class="columns is-mobile">
          <div class="column">
            <div
              class="notification has-text-centered"
              style="padding: 1rem; margin-bottom: 1rem"
            >
              This product is out of stock.
            </div>
          </div>
          <div class="column is-2 has-text-right">
            <a
              style="font-size: 1.6rem; color: #000000"
              @click="nostock = false"
            >
              <i class="fas fa-times-circle"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { auth } from "@/main.js";
// import { functions } from "@/main.js";
import { db } from "@/main.js";
// import { Webengage } from "@ionic-native/webengage";
import {
  createWebengageEvent,
  getUserCountry,
  formatCurrency,
} from "../helper";

export default {
  name: "shopcategory",
  data() {
    return {
      id: this.$route.params.id,
      name: "",
      incart: 0,
      coins: 0,
      categories: [],
      products: [],
      cartbox: false,
      nostock: false,
      qty: 1,
      mayNeed: [],
      options: [],
      selected: {
        name: "",
        img: "",
        price: 0,
        popupmsg: "",
        stockAvailable: true,
        kitTotal: 0,
      },
      update: false,
      country: {
        code: "IN",
        currency: "INR",
      },
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    numberWithCommas(x) {
      return formatCurrency(x, this.country.currency);
    },
    openMayNeed(product) {
      if (product.stockAvailable) {
        const pid = product.id;
        this.qty = 1;
        this.mayNeed = [];
        this.options = [];
        this.cartbox = true;
        console.log(product);
        if (product.stockAvailable)
          this.addToCart(pid, product.name, product.total);
        db.collection("shop-products")
          .doc(pid)
          .get()
          .then((doc) => {
            this.selected.name = doc.data().name;
            this.selected.img = doc.data().imgs[0];
            this.selected.price = doc.data().pricing["in-mrp"];
            this.selected.popupmsg = doc.data().popupmsg;
            this.selected.kitTotal = Number(doc.data().pricing["in-mrp"]);
            console.log("kittotal", this.selected.kitTotal);
            if (doc.data().stock < 1) this.selected.stockAvailable = false;
            else this.selected.stockAvailable = true;
            var options = [];
            for (var q = 1; q < Number(doc.data()["purchase-limit"]) + 1; q++) {
              options.push(q);
            }
            this.options = options;
            doc.data()["may-need"].forEach((product) => {
              db.collection("shop-products")
                .doc(product.id)
                .get()
                .then((item) => {
                  var options = [];
                  for (
                    var i = 1;
                    i < Number(item.data()["purchase-limit"]) + 1;
                    i++
                  ) {
                    options.push(i);
                  }
                  this.mayNeed.push({
                    id: item.id,
                    name: item.data().name,
                    price: item.data()["pricing"]["in-mrp"],
                    img: item.data().imgs[0],
                    loading: false,
                    added: true,
                    options: options,
                    qty: product.qty,
                  });
                  this.addItemToCart(
                    item.id,
                    product.qty,
                    product,
                    item.data()["pricing"]["in-mrp"]
                  );
                });
            });
          });
      } else {
        console.log("stock not available");
        this.nostock = true;
      }
    },
    addToCart(pid, name, total) {
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .where("product", "==", pid)
          .get()
          .then((snap) => {
            if (snap.empty) {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .add({
                  quantity: 1,
                  product: pid,
                  added: new Date().getTime(),
                })
                .then((doc) => {
                  this.cartid = doc.id;
                  createWebengageEvent({
                    uid: auth.currentUser.uid,
                    eventName: "Product Added To Cart",
                    eventData: {
                      added: new Date().getTime(),
                      readableDate: new Date().toLocaleString(),
                      name: name,
                      amounr: total,
                    },
                  });
                });
            } else {
              this.qty = snap.docs[0].data().quantity;
              this.cartid = snap.docs[0].id;
            }
          });
      });
    },
    updateQty() {
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .doc(this.cartid)
          .update({ quantity: this.qty });
      });
    },
    addItemToCart(id, qty, object, price) {
      object.loading = true;
      if (!qty) qty = object.qty;
      this.selected.kitTotal =
        this.selected.kitTotal + Number(price) * Number(object.qty);
      this.update = !this.update;
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .where("product", "==", id)
          .get()
          .then((snap) => {
            if (snap.empty) {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .add({
                  added: new Date().getTime(),
                  product: id,
                  quantity: Number(qty),
                })
                .then(() => {
                  object.added = true;
                  object.loading = false;
                });
            } else {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .doc(snap.docs[0].id)
                .update({
                  quantity: Number(qty),
                })
                .then(() => {
                  object.added = true;
                  object.loading = false;
                });
            }
          });
      });
    },
    removeItemFromCart(item) {
      item.added = false;
      this.selected.kitTotal =
        this.selected.kitTotal - Number(item.price) * Number(item.qty);
      console.log(
        "kittotal",
        this.selected.kitTotal,
        Number(item.price),
        Number(item.qty)
      );
      this.update = !this.update;
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("cart")
        .where("product", "==", item.id)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("users")
              .doc(auth.currentUser.uid)
              .collection("cart")
              .doc(doc.id)
              .delete();
          });
        });
    },
    init() {
      this.id = this.$route.params.id;
      this.incart = 0;
      this.coins = 0;
      this.categories = [];
      this.products = [];
      this.cartbox = false;
      this.qty = 1;
      this.mayNeed = [];
      this.options = [];
      this.selected = {
        name: "",
        img: "",
        price: 0,
        popupmsg: "",
      };
      auth.onAuthStateChanged((user) => {
        //fetch cart size
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .onSnapshot((snap) => {
            this.incart = snap.size;
          });
        //fetch usable coins
        db.collection("users")
          .doc(user.uid)
          .collection("coins")
          .get()
          .then((snap) => {
            var coinsCollection = [];
            for (var i = 0; i < snap.size; i++) {
              coinsCollection.push(snap.docs[i].data().value);
            }
            function arraySum(arr) {
              return arr.reduce(function (a, b) {
                return a + b;
              }, 0);
            }
            this.coins = this.numberWithCommas(arraySum(coinsCollection));
          });
      });
      //fetch categories
      db.collection("shop-category")
        .get()
        .then((snap) => {
          snap.forEach((category) => {
            if (!category.data().ignore) {
              var active = category.id == this.id;
              if (active) this.name = category.data().name;
              this.categories.push({
                id: category.id,
                name: category.data().name,
                active: active,
              });
            }
          });
        });
      //fetch projects
      db.collection("shop-category")
        .doc(this.id)
        .get()
        .then((category) => {
          category.data().products.forEach((pid) => {
            db.collection("shop-products")
              .doc(pid)
              .get()
              .then((product) => {
                var mrpmax = false;
                if (product.data()["pricing"]["in-mrpmax"])
                  mrpmax = product.data()["pricing"]["in-mrpmax"];
                this.products.push({
                  id: product.id,
                  name: product.data().name,
                  img: product.data().imgs[0],
                  original: this.numberWithCommas(
                    product.data()["pricing"]["in-original"]
                  ),
                  mrp: this.numberWithCommas(
                    product.data()["pricing"]["in-mrp"]
                  ),
                  added: false,
                  stockAvailable: product.data().stock > 0,
                  mrpmax: mrpmax,
                });
              });
          });
        });
    },
  },
  async beforeMount() {
    this.init();
    const data = await getUserCountry();
    this.country = {
      code: data.code,
      currency: data.currency,
    };
  },
  mounted() {},
  beforeRouterUpdate(to) {
    console.log("does it work: ", to);
  },
};
</script>
<style scoped>
.cartbox {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff73;
}

.cartbox .content {
  overflow: scroll;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70%;
  padding: 30px;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  border: 4px solid;
  padding-bottom: 100px;
}

.cartbox.nostock .content {
  height: 30%;
}

.cartproduct {
  background: #f4f8fbab;
  border-radius: 10px;
  margin-bottom: 20px !important;
}

.cartproduct img {
  border-radius: 10px 0 0 10px;
  max-height: 80px;
}

.cartproduct p.title {
  font-size: 1rem;
  padding-bottom: 5px;
}

.cartproduct p.subtitle {
  font-size: 0.9rem;
  margin-bottom: 0;
  padding-top: 6px;
}

.cartproduct .button {
  border: 3px solid;
}

.cartbox .btn {
  margin-bottom: 60px;
}

/* you may need styling */
.shop .hero-body {
  padding: 1rem 1.5rem;
}

.shop .card-footer {
  bottom: 0;
  position: absolute;
  width: 100%;
  background: #5743e1;
  border-radius: 0 0 0 20px;
}

.cardholder .card-content {
  padding: 1.2rem;
}

.products .card-content {
  padding-bottom: 50px;
}

.shop .card-footer-item {
  font-weight: 600;
  font-size: 1.2rem;
}

.products.two {
  border-radius: 0;
  padding-top: 20px;
}

.featured.title {
  text-align: center;
  color: #ffffff;
  padding-top: 10px;
}

.cartaction {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 35px 35px 80px 35px;
}

.cartaction .columns {
  padding: 0 15px;
  border-radius: 15px;
  background: #5743e1;
  -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.cartaction .title,
.cartaction .subtitle,
.cartaction i {
  color: #ffffff;
}

.cartaction .subtitle {
  height: 100%;
  line-height: 2.25rem;
  font-size: 1rem;
}

.add-to-cart {
  border: 4px solid;
}

.cardholder .card p.title {
  font-size: 1.05rem;
  color: #414141;
  font-weight: 700;
}

.cardholder .card .subtitle {
  font-size: 1rem;
  margin-bottom: 20px !important;
}

.cardholder .card strike {
  font-size: 0.86rem;
  color: #464646;
  font-weight: 500;
}

.ordersBtn a.button {
  margin-left: 3px;
  border: 2px solid;
  color: #5743e1;
}

.ordersBtn span {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.products .image.is-4by3 {
  padding-top: 100%;
}

/* */
/* */
.products {
  padding: 12px 10px 150px 10px;
  border-radius: 30px 30px 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
  grid-gap: 10px;
}

.products .cardholder {
}

.products .cardholder .card {
  border-radius: 30px 30px 0 20px;
  height: 100%;
}

.products .cardholder .card img {
  border-radius: 30px 30px 0 0;
}

.cartaction {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 35px 35px 80px 35px;
}

.cartaction .columns {
  padding: 0 15px;
  border-radius: 15px;
}

.cartaction .title,
.cartaction .subtitle,
.cartaction i {
  color: #ffffff;
}

.card-footer-item i {
  color: #ffffff;
}

.categories {
  padding: 20px 10px 0px 10px;
  /* background: #8e2de2;
  background: linear-gradient(to right, #4a00e0, #8e2de2); */
  border-radius: 30px 30px 0 0;
}

.categories .columns {
  padding: 0 10px;
}

.categories .column {
  padding: 0.3rem;
}

@media only screen and (min-width: 600px) {
  .cartaction .columns {
    max-width: 400px;
    margin: auto;
  }

  .cartaction {
    padding: 35px 35px 100px 35px;
  }

  .products {
    grid-template-columns: repeat(4, 1fr);
  }

  .products {
    padding: 12px 10px 200px 10px;
  }
}

.tagsholder {
  padding: 20px !important;
  margin: 10px !important;
}
</style>