<template>
  <section class="hero">
    <div class="hero-body">
      <h1 class="title has-text-centered">
        <i class="fas fa-shopping-cart"></i>
        Pay
      </h1>
      <div
        v-if="emergency.enabled"
        class="notification is-danger"
        v-html="emergency.msg"
      ></div>
      <div v-if="empty" class="card depth" style="border-radius: 20px">
        <div class="card-content has-text-centered">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/category%2Fempty-cart.png?alt=media&token=d38e38a2-02f7-49fb-b84f-42805b0da6a1"
            width="50%"
            style="padding: 20px 0"
          />
          <br />
          <a class="btn btn-theme" @click="router('/shop')">Browse Shop</a>
        </div>
      </div>
      <div v-else class="card depth">
        <div class="card-content">
          <div
            class="columns is-mobile cartproduct"
            v-for="item in items"
            :key="item.id"
          >
            <div class="column is-4 has-text-centered">
              <img :src="item.img" @click="router('/product/' + item.id)" />
              <a
                class="button is-small is-rounded deleteBtn"
                @click="deleteFromCart(item.key, item.name, item.total)"
              >
                <i class="far fa-trash-alt"></i>
              </a>
            </div>
            <div class="column">
              <p
                class="title"
                style="margin-bottom: 0; font-size: 1.1rem"
                @click="router('/product/' + item.id)"
              >
                {{ item.name }}
              </p>
              <p class="p_qty">
                {{ numberWithCommas(item.pricing) }} x
                <span class="select is-small">
                  <select
                    v-model="item.qt"
                    @change="updateQty(item.key, item.qt)"
                  >
                    <option v-for="option in item.qty.options" :key="option">
                      {{ option }}
                    </option>
                  </select>
                </span>
              </p>
              <p class="subtitle">{{ numberWithCommas(item.total) }}</p>
            </div>
            <!-- <div class="column is-3 has-text-right">
                <strike
                  class="subtitle striked"
                  style="font-size: 0.8rem; color: #6f6f6f"
                  >&#8377;{{ numberWithCommas(item.original * item.qt) }}</strike
                >
                <p class="subtitle">&#8377;{{ item.total }}</p>
              </div> -->
          </div>
          <hr />
          <div class="columns">
            <div class="colum">
              <div class="card promopicker">
                <div class="card-content">
                  <div class="columns is-mobile">
                    <div class="column is-2">
                      <i class="fas fa-coins"></i>
                    </div>
                    <div class="column" @click="removeCode()">
                      <h1 class="title">
                        Use
                        {{ Math.trunc(witcoins * 10) }}
                        WitCoins
                      </h1>
                      <h3 class="subtitle">
                        Get {{ numberWithCommas(Math.trunc(witcoins)) }}
                        discount
                      </h3>
                    </div>
                    <div class="column is-2">
                      <i
                        @click="removeCode"
                        v-if="promo.exist"
                        class="fas fa-minus-square"
                      ></i>
                      <i
                        @click="removeWitCoins"
                        v-else
                        style="color: #00a906"
                        class="fas fa-check-square"
                      ></i>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <div class="message is-info">
                        <span v-html="codeText"></span>
                        <a class="btn" @click="removeWitCoins">
                          Apply <b>TRYNEW</b>
                        </a>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="columns" style="margin: 0 0px 20px 0px">
                    <div class="column" v-if="codeErr">
                      <div class="notification is-danger">
                        {{ codeErr }}
                      </div>
                      <div
                        class="notification is-success"
                        v-if="codeMsg"
                        v-html="codeMsg"
                      ></div>
                    </div>
                  </div>
                  <div class="columns is-mobile">
                    <div class="column is-2">
                      <i class="fas fa-percentage"></i>
                    </div>
                    <div class="column">
                      <h1 class="title">Use Coupon</h1>
                      <div class="columns is-mobile promosection">
                        <div
                          class="column"
                          style="padding: 0.75rem 0 0.75rem 0.75rem"
                        >
                          <div class="field">
                            <div class="control">
                              <input
                                maxlength="20"
                                class="input"
                                type="text"
                                placeholder="PROMO CODE"
                                v-model="code"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="column is-3"
                          style="padding: 0.75rem 0.75rem 0.75rem 0"
                        >
                          <a
                            class="button is-fullwidth"
                            :class="{ 'is-loading': codeLoad }"
                          >
                            <i
                              v-if="promo.exist"
                              @click="removeCode()"
                              class="fas fa-minus-circle"
                            ></i>
                            <i
                              v-else
                              @click="checkCode()"
                              class="fas fa-plus"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="columns is-mobile subtotal">
            <div class="column">
              <p class="subtitle">Subtotal</p>
            </div>
            <div class="column has-text-right">
              <p class="subtitle">+ {{ numberWithCommas(subtotal) }}</p>
            </div>
          </div>
          <div class="columns is-mobile subtotal">
            <div class="column">
              <p class="subtitle">
                Shipping
                <i
                  @click="shippingdialog = true"
                  class="fas fa-info-circle"
                ></i>
              </p>
            </div>
            <div class="column has-text-right">
              <p class="subtitle">+ {{ numberWithCommas(shipping) }}</p>
            </div>
          </div>
          <div class="columns is-mobile subtotal" v-if="promo.exist">
            <div class="column">
              <p class="subtitle">Promo</p>
            </div>
            <div class="column has-text-right">
              <p class="subtitle">- {{ numberWithCommas(promo.discount) }}</p>
            </div>
          </div>
          <div v-else class="columns is-mobile subtotal">
            <div class="column">
              <p class="subtitle">
                WitCoins
                <i
                  @click="witcoinsdialog = true"
                  class="fas fa-info-circle"
                ></i>
              </p>
            </div>
            <div class="column has-text-right">
              <p class="subtitle">- {{ numberWithCommas(witcoins) }}</p>
            </div>
          </div>
          <div class="columns is-mobile subtotal">
            <div class="column">
              <p class="title">Total</p>
            </div>
            <div class="column has-text-right">
              <p class="title">{{ numberWithCommas(total) }}</p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <p
                class="notification has-text-centered"
                style="font-size: 0.9rem"
              >
                You're saving {{ numberWithCommas(savings) }} on this order.
              </p>
            </div>
          </div>
          <hr />
          <div class="columns">
            <div class="column">
              <p
                class="notification tree-message"
                style="font-size: 0.9rem; background-color: lightgreen"
              >
                <img src="@/assets/img/tree.svg" alt="..." />
                <span> We will plant a tree for every order you place. </span>
              </p>
            </div>
          </div>
          <hr />

          <p class="subtitle has-text-centered">Select Address</p>
          <a
            class="button is-fullwidth addressBtn"
            @click="addressForm.open = true"
            >ADD ADDRESS</a
          >
          <div
            class="addressbox card"
            v-for="a in addresses"
            :key="a.id"
            :class="{ active: address.key == a.id, 'is-hidden': a.deleted }"
          >
            <div class="card-content">
              <p>
                {{ a.full }}
                <br /><br />
                <b>Name:</b> {{ a.name }}
                <br />
                <b>City:</b> {{ a.city }}, {{ a.state }}
                <br />
                <b>PIN:</b> {{ a.pincode }}
                <br />
                <b>Phone:</b> {{ a.phone }}
              </p>
              <div class="buttons">
                <a class="button" @click="selectAddress(a)">
                  <span v-if="address.key == a.id">Selected</span>
                  <span v-else>Select</span>
                </a>
                <a class="button is-danger" @click="deleteAddress(a)">
                  <i class="fas fa-trash-alt"></i>
                </a>
              </div>
            </div>
          </div>
          <hr v-if="address.key" />
          <div v-if="address.key">
            <a
              class="button btn btn-theme"
              :class="{ 'is-loading': po_btn }"
              @click="createOrder(false, po_btn)"
              :disabled="btns.disabled"
              >Pay Online</a
            >
            <p
              v-if="codAllowed"
              class="subtitle has-text-centered"
              style="font-size: 1.3rem; margin: 5px 0; padding: 0"
            >
              OR
            </p>
            <a
              v-if="codAllowed"
              class="button btn btn-theme"
              :class="{ 'is-loading': cod_btn }"
              @click="createOrder(true, cod_btn)"
              :disabled="btns.disabled"
              >Pay via COD</a
            >
            <p
              v-if="codAllowed"
              class="notification has-text-centered"
              style="font-size: 0.9rem; margin-top: 10px"
            >
              &#8377;20 extra charge will be added if you use <b>COD</b>. You
              can save it by using <b>Online Payment</b>
            </p>
          </div>
        </div>
      </div>
      <div style="padding-bottom: 60px"></div>
    </div>
    <div v-if="addressForm.open" class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="field">
          <div class="control">
            <h4 class="subtitle has-text-centered" style="padding-bottom: 20px">
              Add Address
            </h4>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <div class="notification is-danger" v-if="addressForm.error">
              {{ addressForm.error }}
            </div>
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model="addressForm.name"
              class="input"
              type="text"
              placeholder="Full Name"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model="addressForm.full"
              class="input"
              type="text"
              placeholder="Full Address"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model="addressForm.city"
              class="input"
              type="text"
              placeholder="City"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model="addressForm.state"
              class="input"
              type="text"
              placeholder="State"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model.number="addressForm.pincode"
              class="input"
              type="number"
              placeholder="6-digit PIN Code"
            />
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input
              v-model.number="addressForm.phone"
              class="input"
              type="number"
              placeholder="10-digit Phone Number"
            />
          </div>
        </div>
        <div class="field">
          <a class="button" @click="addAddress()">Add</a>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="addressForm.open = false"
      ></button>
    </div>
    <div class="modal is-active" v-if="shippingdialog">
      <div class="modal-background"></div>
      <div class="modal-content" style="background: #0000">
        <p class="notification">
          <button class="delete" @click="shippingdialog = false"></button>
          You can get <b>free shipping</b> on all orders above &#8377;1,000.
        </p>
      </div>
    </div>
    <div class="modal is-active" v-if="witcoinsdialog">
      <div class="modal-background"></div>
      <div class="modal-content" style="background: #0000">
        <p class="notification">
          <button class="delete" @click="witcoinsdialog = false"></button>
          You can redeem <b>WitCoins</b> totalling to 30% of your cart value.
        </p>
      </div>
    </div>
  </section>
</template>
<script>
import { auth } from "@/main.js";
import { functions } from "@/main.js";
import { db } from "@/main.js";
// import { Plugins } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
// import { Webengage } from "@ionic-native/webengage";
import {
  createWebengageEvent,
  getUserCountry,
  formatCurrency,
} from "../helper";

export default {
  name: "shopcart",
  data() {
    return {
      codAllowed: true,
      items: [],
      subtotal: 0,
      savings: 0,
      savingsBeforePromo: 0,
      coins: 0,
      witcoins: 0,
      total: 0,
      totalBeforePromo: 0,
      shipping: 0,
      code: "",
      codeName: "",
      codeText: "",
      codeErr: "",
      codeMsg: "",
      codeLoad: false,
      codeLoaded: false,
      empty: true,
      cod_btn: false,
      po_btn: false,
      phone: "",
      promo: {
        exist: false,
        final: 0,
        discount: 0,
        value: 0,
        type: "",
      },
      address: {
        key: "",
        full: "",
        name: "",
        city: "",
        pincode: 0,
        phone: "",
        state: "",
      },
      addresses: [],
      addressForm: {
        open: false,
        name: "",
        full: "",
        city: "",
        pincode: "",
        phone: "",
        error: "",
        state: "",
      },
      btns: {
        disabled: false,
      },
      shippingdialog: false,
      witcoinsdialog: false,
      emergency: {},
      isClass: false,
      classId: [],
      className: "",
      country: {
        code: "",
        currency: "",
      },
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    numberWithCommas(x) {
      return formatCurrency(x, this.country.currency);
      // var y = Number(x).toFixed(1);
      // return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    arraySum(arr) {
      return arr.reduce(function (a, b) {
        return a + b;
      }, 0);
    },
    removeCode() {
      this.promo = {
        exist: false,
        final: 0,
        value: 0,
        type: "",
        discount: 0,
      };
      this.codeErr = "";
      this.codeMsg = "";
      this.init();
    },
    removeWitCoins() {
      this.code = this.codeName;
      this.checkCode();
    },
    checkCode() {
      this.total = this.totalBeforePromo;
      this.savings = this.savingsBeforePromo;
      this.codeMsg = "";
      this.promo = {
        exist: false,
        final: 0,
        value: 0,
        type: "",
        discount: 0,
      };
      this.code = this.code.toUpperCase();
      if (this.code) {
        this.codeErr = "";
        this.codeLoad = true;
        this.codeLoaded = false;
        console.log({
          promo: this.code,
          cvalue: this.subtotal + this.shipping,
          products: this.items,
        });
        var checkPromo = functions.httpsCallable("checkPromo");
        checkPromo({
          promo: this.code,
          cvalue: this.subtotal + this.shipping,
          products: this.items,
        })
          .then((result) => {
            if (result.data.valid) {
              this.codeErr = "";
              this.codeLoad = false;
              this.codeLoaded = true;
              this.codeMsg =
                "Congrats, you have received a discount of &#8377;" +
                this.numberWithCommas(Number(result.data.discount)) +
                ".";
              this.promo = {
                exist: true,
                final: Number(result.data.final),
                value: Number(result.data.pvalue),
                type: result.data.ptype,
                discount: Number(result.data.discount),
              };
              this.total = Number(result.data.final);
              this.savings =
                Number(this.savings) + Number(result.data.discount);
            } else {
              this.codeLoad = false;
              this.codeLoaded = false;
              this.codeErr = result.data.msg;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.codeErr = "Please fill a promo code.";
      }
    },
    selectAddress(a) {
      this.address.key = a.id;
      this.address.full = a.full;
      this.address.name = a.name;
      this.address.city = a.city;
      this.address.pincode = a.pincode;
      this.address.phone = a.phone;
      this.address.state = a.state;
    },
    deleteAddress(a) {
      a.deleted = true;
      if (this.address.key == a.id) {
        this.address = {
          key: "",
          full: "",
          name: "",
          city: "",
          phone: "",
          pincode: 0,
          state: "",
        };
      }
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("address")
          .doc(a.id)
          .delete()
          .then(() => {
            console.log("deleted");
          });
      });
    },
    updateQty(id, qty) {
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .doc(id)
          .update({ quantity: Number(qty) })
          .then(() => {
            // this.$router.go();
            this.init();
          });
      });
    },
    deleteFromCart(id, name, total) {
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .doc(id)
          .delete()
          .then(() => {
            createWebengageEvent({
              uid: user.uid,
              eventName: "Product Removed From Cart",
              eventData: {
                added: new Date().getTime(),
                readableDate: new Date().toLocaleString(),
                name: name,
                amount: total,
              },
            });
            // this.$router.go();
            this.init();
          });
      });
    },
    addAddress() {
      if (
        this.addressForm.name !== "" &&
        this.addressForm.full !== "" &&
        this.addressForm.city !== "" &&
        this.addressForm.pincode !== "" &&
        this.addressForm.state !== "" &&
        this.addressForm.phone !== ""
      ) {
        if (String(this.addressForm.pincode).length == 6) {
          if (String(this.addressForm.phone).length == 10) {
            auth.onAuthStateChanged((user) => {
              db.collection("users")
                .doc(user.uid)
                .collection("address")
                .add({
                  name: this.addressForm.name,
                  full: this.addressForm.full,
                  city: this.addressForm.city,
                  pincode: this.addressForm.pincode,
                  phone: this.addressForm.phone,
                  added: new Date().getTime(),
                  state: this.addressForm.state,
                })
                .then((doc) => {
                  this.addressForm.open = false;
                  this.addresses.push({
                    id: doc.id,
                    name: this.addressForm.name,
                    full: this.addressForm.full,
                    city: this.addressForm.city,
                    pincode: this.addressForm.pincode,
                    phone: this.addressForm.phone,
                    state: this.addressForm.state,
                    added: 0,
                    deleted: false,
                  });
                });
            });
          } else {
            this.addressForm.error = "Please enter 10-digit Phone Number.";
          }
        } else {
          this.addressForm.error = "Please enter 6-digit PIN Code.";
        }
      } else {
        this.addressForm.error = "Please fill all fields.";
      }
    },
    isOutOfStock() {
      let out = false;
      let productName = "";

      this.items.forEach((item) => {
        if (parseInt(item.stock) < 1) {
          out = true;
          productName = item.name;
        }
      });
      return { out, productName };
    },
    createOrder(cod) {
      const { out: isOut, productName } = this.isOutOfStock();

      if (isOut) {
        alert(
          `${productName} is out of stock. Please remove it from cart and try again.`
        );
        return;
      }

      if (!this.btns.disabled) {
        this.btns.disabled = true;
        if (cod) {
          this.cod_btn = true;
          this.po_btn = false;
          this.total = Number(this.total) + 20;
        } else {
          this.cod_btn = false;
          this.po_btn = true;
        }
        var createOrder = functions.httpsCallable("createOrder");
        createOrder({
          address: this.address.full,
          cod: cod,
          phone: this.address.phone,
          pincode: this.address.pincode,
          city: this.address.city,
          state: this.address.state,
          a_name: this.address.name,
          code: this.code,
          products: this.items,
          shipping: this.shipping,
          subtotal: this.subtotal,
          savings: this.savings,
          promo: this.promo.discount,
          promoDetails: this.promo,
          total: this.total,
          witcoins: this.witcoins,
          isClass: this.isClass,
          classId: this.classId,
        })
          .then((result) => {
            if (!cod) {
              Browser.open({
                url: "https://pay.witblox.com/" + result.data.id,
              });
              setTimeout(() => {
                this.router("/order/" + result.data.id);
              }, 10000);
            } else {
              this.router("/order/" + result.data.id);
            }
            var products = [];
            for (let index = 0; index < this.items.length; index++) {
              const item = this.items[index];
              if (parseInt(item.stock) < 1) return;
              products.push(item.name);
            }
            if (this.isClass) {
              createWebengageEvent({
                uid: auth.currentUser.uid,
                eventName: "Class Booked",
                eventData: {
                  added: new Date().getTime(),
                  readableDate: new Date().toLocaleString(),
                  name: this.className,
                  oid: result.data.id,
                  title: result.data.title,
                  products: products,
                  total: this.total,
                  city: this.address.city,
                  coupon: this.promo,
                  coins: this.witcoins,
                },
              });
            } else {
              createWebengageEvent({
                uid: auth.currentUser.uid,
                eventName: "Order Created",
                eventData: {
                  added: new Date().getTime(),
                  readableDate: new Date().toLocaleString(),
                  oid: result.data.id,
                  title: result.data.title,
                  products: products,
                  total: Number(this.total),
                  city: this.address.city,
                  coupon: this.promo,
                  coins: this.witcoins,
                  cod: cod,
                  shipping: this.shipping,
                  witcoins: this.witcoins,
                  orderInfoLink:
                    "https://app.witblox.com/order/" + result.data.id,
                },
              });
            }
            console.log(result.data);
          })
          .catch((err) => {
            this.btns.disabled = false;
            console.log(err);
            if (cod) {
              this.cod_btn = false;
              this.po_btn = true;
            } else {
              this.cod_btn = false;
              this.po_btn = true;
            }
          });
      }
    },
    async init() {
      this.items = [];
      this.subtotal = 0;
      this.savings = 0;
      this.savingsBeforePromo = 0;
      this.isClass = false;
      this.coins = 0;
      this.witcoins = 0;
      this.total = 0;
      this.totalBeforePromo = 0;
      this.shipping = 0;
      this.code = "";
      this.codeErr = "";
      this.codeLoad = false;
      this.codeLoaded = false;
      this.empty = true;
      this.cod_btn = false;
      this.po_btn = false;
      this.phone = "";
      this.promo = {
        exist: false,
        final: 0,
        discount: 0,
        value: 0,
        type: "",
      };
      this.address = {
        key: "",
        full: "",
        name: "",
        city: "",
        pincode: 0,
        phone: "",
        state: "",
      };
      this.addresses = [];
      this.addressForm = {
        open: false,
        name: "",
        full: "",
        city: "",
        pincode: "",
        phone: "",
        error: "",
        state: "",
      };
      this.btns = {
        disabled: false,
      };

      // If the user is not logged in, ignore the rest of the code
      if (!auth?.currentUser?.uid) return;

      // fetch user country code & currency
      const data = await getUserCountry();
      if (data.code !== "IN") {
        this.codAllowed = false;
      }
      this.country = {
        code: data.code,
        currency: data.currency,
      };

      db.collection("users")
        .doc(auth.currentUser.uid)
        .get()
        .then((u) => {
          this.addressForm.name = u.data().fname + " " + u.data().lname;
          this.phone = u.data().phone;
          this.addressForm.phone = Number(u.data().phone.substring(3));
        });
      //fetch all address
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("address")
        .get()
        .then((snap) => {
          snap.forEach((address) => {
            this.addresses.push({
              id: address.id,
              name: address.data().name,
              city: address.data().city,
              full: address.data().full,
              pincode: address.data().pincode,
              phone: address.data().phone,
              added: address.data().added,
              state: address.data().state,
              deleted: false,
            });
            this.address.key = address.id;
            this.address.full = address.data().full;
            this.address.name = address.data().name;
            this.address.city = address.data().city;
            this.address.pincode = address.data().pincode;
            this.address.phone = address.data().phone;
            this.address.state = address.data().state;
          });
        });
      //fetch usable coins
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("coins")
        .get()
        .then((snap) => {
          var coinsCollection = [];
          for (var i = 0; i < snap.size; i++) {
            coinsCollection.push(snap.docs[i].data().value);
          }
          this.coins = this.arraySum(coinsCollection) / 10;
        });
      //fetch cart details
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("cart")
        .get()
        .then((snap) => {
          this.empty = snap.empty;
          var subtotal = [];
          var savings = [];
          snap.forEach((item) => {
            db.collection("shop-products")
              .doc(item.data().product)
              .get()
              .then((product) => {
                if (product.data().type == "class") {
                  // console.log(product.data());
                  this.classId.push(product.id);
                  this.className = product.data().name;
                  this.isClass = true;
                }
                if (product.data().codAllowed == false)
                  this.codAllowed = product.data().codAllowed;
                subtotal.push(
                  Number(product.data().pricing["in-mrp"]) *
                    Number(item.data().quantity)
                );
                savings.push(
                  Number(product.data().pricing["in-original"]) *
                    Number(item.data().quantity) -
                    Number(product.data().pricing["in-mrp"]) *
                      Number(item.data().quantity)
                );
                this.subtotal = this.arraySum(subtotal);
                db.collection("config")
                  .doc("shop")
                  .get()
                  .then(async (config) => {
                    this.codeName = config.data().code;
                    this.codeText = config.data().codeText;
                    //
                    this.emergency = config.data().emergency;

                    if (this.country.code.toLowerCase() !== "in") {
                      console.log("not indian", this.country.code);
                      const shippingConfigs = await db
                        .collection("config")
                        .doc("shipping-configs")
                        .get();

                      const countries = shippingConfigs.data().countries;
                      const zones = shippingConfigs.data().zones;
                      let country = countries.find(
                        (country) => country.country_code === this.country.code
                      );

                      if (!country) {
                        country = countries.find(
                          (country) => country.country_code === "USA"
                        );
                      }

                      const zone = zones.find(
                        (zone) => zone.index === country.zone
                      );
                      this.shipping = zone.shipping;
                    } else {
                      console.log("indian");
                      if (this.isClass) {
                        this.shipping = 0;
                      } else if (
                        this.arraySum(subtotal) < config.data().shipping_amt[0]
                      ) {
                        this.shipping = config.data().shipping_cost[0];
                      } else if (
                        this.arraySum(subtotal) < config.data().shipping_amt[1]
                      ) {
                        this.shipping = config.data().shipping_cost[1];
                      } else if (
                        this.arraySum(subtotal) > config.data().shipping_amt[2]
                      ) {
                        this.shipping = config.data().shipping_cost[2];
                      }
                      if (product.data().shippingCharged == false)
                        this.shipping = 0;
                    }
                    var coins = 0;
                    if (
                      Number(this.coins) >
                      (this.arraySum(subtotal) / 100) *
                        Number(config.data().dv[this.items.length])
                    ) {
                      this.witcoins = (
                        (this.arraySum(subtotal) / 100) *
                        Number(config.data().dv[this.items.length])
                      ).toFixed(0);
                      coins =
                        (this.arraySum(subtotal) / 100) *
                        Number(config.data().dv[this.items.length]).toFixed(0);
                    } else {
                      this.witcoins = this.coins;
                      coins = this.coins;
                    }
                    this.total = (
                      this.arraySum(subtotal) +
                      Number(this.shipping) -
                      Number(coins)
                    ).toFixed(0);
                    this.totalBeforePromo = (
                      this.arraySum(subtotal) +
                      Number(this.shipping) -
                      Number(coins)
                    ).toFixed(0);
                    this.savings = Number(this.arraySum(savings)) + coins;
                    this.savingsBeforePromo =
                      Number(this.arraySum(savings)) + coins;
                    var options = [];
                    for (
                      var i = 1;
                      i < Number(product.data()["purchase-limit"]) + 1;
                      i++
                    ) {
                      options.push(i);
                    }
                    this.items.push({
                      key: item.id,
                      id: product.id,
                      qt: item.data().quantity,
                      name: product.data().name,
                      stock: product.data().stock,
                      img: product.data().imgs[0],
                      pricing: product.data().pricing["in-mrp"],
                      total: product.data().pricing["in-mrp"] * item.data().quantity, 
                      original: product.data().pricing["in-original"],
                      qty: {
                        options: options,
                      },
                    });
                  });
              });
          });
        });
    },
  },
  beforeMount() {
    this.init();
  },
  mounted() {
    setTimeout(() => {
      var products = [];
      for (let index = 0; index < this.items.length; index++) {
        const item = this.items[index];
        products.push(item.name);
      }
      createWebengageEvent({
        uid: auth.currentUser.uid,
        eventName: "Cart Viewed",
        eventData: {
          added: new Date().getTime(),
          readableDate: new Date().toLocaleString(),
          total: Number(this.total),
          products: products,
        },
      });
    }, 10000);
  },
};
</script>
<style scoped>
.notification {
  padding: 1.25rem 1.5rem 1.25rem 1.5rem !important;
}

.card-content {
  padding: 1.25rem;
}

.subtotal .title {
  font-size: 1.25rem;
}

.subtotal .subtitle {
  font-size: 1.1rem;
}

.addressbox {
  border-radius: 10px;
  border: 3px solid;
}

.subtotal .column {
  padding: 0.5rem 0.75rem 0 0.75rem;
}

.button.is-fullwidth.addressBtn {
  margin-bottom: 20px;
  color: #000000;
  border: 3px solid;
  border-radius: 10px;
}

.cartproduct .column {
  padding: 0.5rem;
}

.button.deleteBtn {
  border: 1.5px solid;
  font-size: 0.85rem;
}

.modal .modal-background {
  background: rgba(10, 10, 10, 0.86);
}

.modal .modal-content {
  padding: 20px;
  background: #ffffff;
}

.addressbox {
  margin-bottom: 10px;
}

.addressbox.active {
  background: rgb(188, 253, 188);
}

.addressbox .card-content p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.addressbox .buttons {
  padding-top: 15px;
}

.addressbox .button {
  border: 3px solid;
  font-weight: 700;
}

.cartproduct .subtitle {
  font-size: 0.9rem;
}

.p_qty {
  font-weight: 600;
  line-height: 1.7rem;
  padding-top: 10px;
}

.cartproduct {
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #faf7f0;
  border: 3px solid #ffeec8;
  padding-right: 15px;
}

.promosection a.button {
  background-color: #000000;
  color: #fff;
  border: 0px solid #000000;
  border-radius: 0px 10px 10px 0;
}

.promosection input {
  color: #000000;
  border: solid #000000;
  border-width: 3px 0 3px 3px;
  border-radius: 10px 0px 0px 10px;
  text-transform: uppercase;
}

.promosection input::placeholder {
  color: #4a4a4a;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.promopicker {
  margin: 35px 4px 0 4px;
  border-radius: 10px;
  border: 3px solid #ffeec8;
  background: #faf7f0;
}

hr {
  background: #ffe6b1;
}

.promopicker .card-content .column {
  margin: auto;
}

.promopicker .column.is-2 i {
  font-size: 2rem;
}

.promopicker h1.title {
  font-size: 1.2rem;
}

.promopicker h3.subtitle {
  font-size: 0.95rem;
}

@media only screen and (min-width: 600px) {
  .hero {
    max-width: 400px;
    margin: auto;
  }
}

.promopicker .message.is-info {
  padding: 15px 20px;
}

.promopicker .message.is-info a.btn {
  background: #000;
  color: #fff;
  margin-top: 15px;
}

.tree-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.tree-message img {
  width: 50px;
  height: 50px;
  animation: animateTree 500ms ease-in-out infinite;
}

@keyframes animateTree {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>