<template>
  <section>
    <!-- desc -->
    <div class="desc">
      <div class="descbox" :class="{ expanded: expanded }">
        <div class="columns is-mobile">
          <div class="column is-2">
            <span class="icon is-medium">
              <i
                @click="$router.back()"
                class="fas fa-angle-left"
                style="font-size: 1.3rem"
              ></i>
            </span>
          </div>
          <div class="column has-text-centered">
            <h1 class="title">ChadGPT</h1>
          </div>
          <div class="column is-2">
            <span class="icon is-medium">
              <i
                @click="expanded = false"
                v-if="expanded"
                class="fas fa-times-circle"
                style="font-size: 1.3rem"
              ></i>
              <i
                @click="expanded = true"
                v-else
                class="fas fa-info-circle"
                style="font-size: 1.3rem"
              ></i>
            </span>
          </div>
        </div>
        <div v-if="expanded">
          <hr />
          <div v-if="true" class="columns expandedbox">
            <div class="column">
              <p class="description">
                ChadGPT is a chatbot that uses GPT-3 to generate responses to
                your messages.
              </p>
              <br />
              <p class="description">
                <b> You get 5 free credits.</b> After that, you can purchase
                credits to continue using the bot.
              </p>
            </div>
          </div>
          <hr />
          <div class="columns is-mobile">
            <div class="column">
              <a class="button is-fullwidth" href="/chadgpt-payment">
                Purchase Credits
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /desc -->
    <!-- chatbox -->
    <div class="chatbox">
      <div class="messagegroup">
        <div
          v-for="chat in chats"
          :key="chat.id"
          :class="['message', { self: chat.name === 'bot' ? '' : 'self' }]"
        >
          <b class="name"> {{ chat.name === "bot" ? "ChadGPT" : "You" }} </b>
          {{ chat.text }}
          <div class="time">{{ convertTime(chat.added) }}</div>
        </div>

        <template v-if="chadgpt_credits === 0">
          <div>
            <p class="joined">
              <span> You have exhausted your free questions limit. </span>
            </p>
          </div>

          <div class="message_btn_wrapper" style="margin-top: 1rem">
            <a href="/chadgpt-payment/normal">
              <b>
                Pay 10₹ and ask <br />
                5 questions
              </b>
            </a>
            <a href="/chadgpt-payment/unlimited">
              <b>
                Pay 250₹ and ask <br />
                unlimited questions
              </b>
            </a>
          </div>
        </template>

        <div class="scrolldown" ref="scrolldown"></div>
      </div>
    </div>
    <!-- /chatbox -->
    <!-- chat-input -->
    <form class="chatinput" v-on:submit.prevent="handleChatSend">
      <div class="field messageinput">
        <div class="control" :class="{ 'is-loading': msgloading }">
          <textarea
            v-model="message"
            class="input is-link"
            type="text"
            placeholder="Message"
          ></textarea>
          <a class="button is-black" @click="handleChatSend">
            <span class="icon">
              <i class="fas fa-paper-plane"></i>
            </span>
          </a>
        </div>
      </div>
    </form>
    <!-- /chat-input -->
  </section>
</template>
<script>
import { auth } from "@/main.js";
import { db } from "@/main.js";
import Imgproxy from "imgproxy";
import { createWebengageEvent } from "../helper";

export default {
  name: "chat",
  data() {
    return {
      admin: false,
      uid: auth.currentUser.uid,
      id: this.$route.params.id,
      group: {},
      message: "",
      msgloading: false,
      vfile: "",
      vtype: "",
      vname: "",
      chats: [],
      chadgpt_credits: 1,
      attachBtn: false,
      proxy: new Imgproxy({
        key: "fb6333a954da296899362eca74b278dd",
        salt: "7436536ea6bfeea3559b21121f4844c2",
        baseUrl: "https://witblox-imgproxy.herokuapp.com",
        encode: true,
      }),
      picture: "url(" + require("@/assets/img/user.png") + ")",
      groupTheme: {
        backgroundColor: "#000",
      },
      expanded: false,
      photoBtnLoading: false,
      fileBtnLoading: false,
      adminPopup: false,
      adminPopupMsg: {},
      leaveGrpBtnLoading: false,
      muted: false,
      participants: [],
      loading: false,
    };
  },
  methods: {
    onOpen(key) {
      console.log(this);
      console.log(key);
    },
    loadIssues(event) {
      console.log(event);
      //   this.loading = true;
      //   this.items = await fetchIssues(searchText);
      //   this.loading = false;
    },
    convertTime(added) {
      if ((new Date().getTime() - added) / 36e5 < 1) {
        if (((new Date().getTime() - added) / 1000 / 60).toFixed(0) == 0)
          return "now";
        else
          return (
            ((new Date().getTime() - added) / 1000 / 60).toFixed(0) + " min ago"
          );
      } else if ((new Date().getTime() - added) / 36e5 < 23) {
        return ((new Date().getTime() - added) / 36e5).toFixed(0) + " hrs ago";
      } else if (
        (new Date().getTime() - added) / 36e5 > 23 &&
        (new Date().getTime() - added) / 36e5 > 1
      ) {
        return new Date(added).toDateString();
      }
    },
    randomStr(len, arr) {
      var ans = "";
      for (var i = len; i > 0; i--) {
        ans += arr[Math.floor(Math.random() * arr.length)];
      }
      return ans;
    },
    urlify(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function (url) {
        return '<a target="blank" href="' + url + '">' + url + "</a>";
      });
    },
    mentionify(text) {
      var pattern = /\B@[a-z0-9_-]+/gi;
      return text.replace(pattern, function (mention) {
        return "<u>" + mention + "</u>";
      });
    },
    openExternalLink(url) {
      window.open(url, "_blank");
    },
    scrollToBottom() {
      this.$refs.scrolldown.scrollIntoView();
    },
    addChat(payload) {
      db.collection("users")
        .doc(this.uid)
        .collection("chadgpt")
        .add(payload)
        .then(() => {
          this.message = "";
          this.chats.push(payload);
          this.scrollToBottom();

          createWebengageEvent({
            uid: auth.currentUser.uid,
            eventName: "ChadGPT: Message Sent",
            eventData: payload,
          });
        })
        .catch((err) => {
          alert(err.message);
        });
      this.scrollToBottom();
    },
    handleChatSend() {
      if (this.chadgpt_credits <= 0) {
        alert("You have no credits left. Please purchase credits to continue.");

        createWebengageEvent({
          uid: auth.currentUser.uid,
          eventName: "ChadGPT: No Credits Left",
          eventData: {
            uid: auth.currentUser.uid,
          },
        });
        return;
      }

      if (!this.message) return;
      const payload = {
        name: "user",
        text: this.message,
        added: new Date().getTime(),
        timestamp: new Date().getTime(),
      };

      this.addChat(payload);

      fetch(
        "https://us-central1-witblox-5ae68.cloudfunctions.net/sendChatgptMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: this.message,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.status == "error") {
            alert(res.message);
            return;
          }
          this.addChat({
            ...payload,
            text: res.message,
            name: "bot",
            added: new Date().getTime(),
            timestamp: new Date().getTime(),
          });
          this.chadgpt_credits = this.chadgpt_credits - 1;
          db.collection("users").doc(this.uid).update({
            chadgpt_credits: this.chadgpt_credits,
          });
          db.collection("users")
            .doc(this.uid)
            .collection("chadgpt-usage")
            .add({
              created: new Date().getTime(),
              readableDate: new Date().toLocaleDateString("en", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }),
              credit_used: false,
              prompt: payload.text,
              completion: res.message,
              prompt_tokens: res.usage.prompt_tokens,
              completion_tokens: res.usage.completion_tokens,
              total_tokens: res.usage.total_tokens,
            });
        })
        .catch((err) => {
          alert(err.message);
        });
    },
  },
  async beforeMount() {
    createWebengageEvent({
      uid: auth.currentUser.uid,
      eventName: "ChadGPT: Opened",
      eventData: {
        uid: auth.currentUser.uid,
      },
    });

    const messageSnap = await db
      .collection("users")
      .doc(this.uid)
      .collection("chadgpt")
      .orderBy("timestamp", "asc")
      .limit(100)
      .get();

    const messages = messageSnap.docs.map((doc) => doc.data());

    this.chats = messages;
    setTimeout(() => {
      this.scrollToBottom();
    }, 500);

    const user = await db.collection("users").doc(this.uid).get();

    if (user.data().chadgpt_credits === 0) {
      this.chadgpt_credits = 0;
      return;
    }

    if (!user.data().chadgpt_credits) {
      await db.collection("users").doc(this.uid).update({
        chadgpt_credits: 5,
      });
    }

    this.chadgpt_credits = user.data().chadgpt_credits
      ? user.data().chadgpt_credits
      : 5;
  },
};
</script>
<style scoped>
.chatinput {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 25px;
  background: rgb(255, 255, 255, 0.85);
}

.desc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.descbox {
  margin: 20px;
  border-radius: 15px;
  background: rgb(236, 236, 236);
  padding: 10px 30px;
  box-shadow: 0 8px 17px 2px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%),
    0 5px 5px -3px rgb(0 0 0 / 20%);
  /* transition: margin 2s;
  transition: border-radius 2s; */
}

.descbox h1.title {
  font-size: 1rem;
  padding: 5px 0;
}

.descbox.expanded {
  margin: 0px;
  padding: 30px;
  border-radius: 0px;
}

#chatbox {
  padding-top: 15px;
  padding-bottom: 80px;
  overflow: scroll;
}

.chatbox {
  padding: 150px 20px;
}

.messagegroup {
  /* padding: 12px 20px 10px 20px; */
  padding-bottom: 15px;
  font-family: "Poppins", "sans-serif";
}

.message {
  padding: 12px 50px 30px 20px;
  margin-right: 8%;
  border-radius: 0 20px 20px 20px;
  position: relative;
  font-family: "Poppins", "sans-serif" !important;
  font-weight: 500;
  background: #000;
  color: #fff;
}

.message.self {
  margin-right: 0px;
  margin-left: 8%;
  border-radius: 20px 0 20px 20px;
  /* background: #fff; */
  background: rgb(236, 236, 236);
  color: #000;
}

.messagegroup b.name {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.message .time {
  font-size: 0.7rem;
  font-weight: 500;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px 20px;
}
.message img {
  border-radius: 10px;
  margin-top: 5px;
}
p.btns .button {
  margin: 10px 5px;
}

.chatfile a {
  display: block;
  width: 100%;
  padding: 10px 30px;
  background: #fff;
  border-radius: 10px;
  text-align: center;
  font-weight: 800;
  margin-bottom: 20px;
  color: #000 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fullscreen-v-img {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.messageinput a.button {
  position: absolute;
  right: 0;
  bottom: 0;
}
.messageinput input.input {
  border-color: #000;
}

.messagegroup .columns {
  max-width: 100%;
}
.messagegroup .columns .column {
  padding: 0px !important;
  max-width: 100%;
}
.messagegroup .userimage {
  border-radius: 50% 0 0 50%;
  border: 1.5px solid #ffff;
  width: 32.5px;
  height: 32.5px;
  background-image: url(~@/assets/img/user.png);
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  background: url(~@/assets/img/user.png),
    linear-gradient(to right, #8e2de2, #4a00e0);
}
.messagegroup.self .userimage {
  border-radius: 0 50% 50% 0;
}
textarea.input {
  padding-right: 50px !important;
}
.expandedbox {
  padding: 0 30px;
}
.chatimage img {
  min-height: 100px;
}
.descbox hr {
  background-color: #bababa;
}
.msgAction {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1111;
  padding: 200px 50px;
  background: rgba(0, 0, 0, 0.7);
}
.msgAction .panel {
  background: #fff;
}

p.joined {
  text-align: center;
}

p.joined span {
  background: #dadada;
  color: #2d2d2d;
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  font-size: 0.7rem;
  display: inline-block;
  padding: 3.5px 15px;
  border-radius: 10px;
}
.message_btn_wrapper {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.message_btn_wrapper a {
  text-align: center;
  border-radius: 2px;
  border: 4px solid #8e2de2;
  color: #8e2de2;
  font-size: 0.8rem;
  font-family: monospace sans-serif;
  padding: 0.5rem 1rem;
}
</style>
<style>
/* mention */
.v-popper--theme-dropdown .v-popper__inner {
  padding: 6px;
}
.v-popper--theme-my-theme .v-popper__inner {
  background: #fff;
  color: black;
  padding: 24px;
  border-radius: 6px;
  border: 1px solid #ddd;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
}

.v-popper--theme-my-theme .v-popper__arrow-inner {
  visibility: visible;
  border-color: #fff;
}

.v-popper--theme-my-theme .v-popper__arrow-outer {
  border-color: #ddd;
}

/* Transition */

.v-popper--theme-my-theme.v-popper__popper--hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.v-popper--theme-my-theme.v-popper__popper--shown {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.v-popper--theme-my-theme.v-popper__popper--skip-transition {
  transition: none !important;
}

.card.linkPreview {
  border-radius: 20px;
  margin-bottom: 20px;
}

.card.linkPreview p {
  font-size: 0.7rem;
}

.card.linkPreview p.title {
  font-size: 1rem;
}

.image.is-4by3 img {
  width: 100%;
  height: 100%;
  margin-top: 0px;
  border-radius: 15px 15px 0 0;
}
</style>