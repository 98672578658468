<template>
  <div>
    <section class="section">
      <div class="container">
        <h1 class="title is-4 has-text-centered">Manage</h1>
        <article class="message is-warning">
          <div class="message-body">
            Your subscription is valid till
            {{ new Date(user.validTill).toDateString() }}
          </div>
        </article>
        <hr />
        <h1 class="title is-4 has-text-centered">Payments</h1>
        <div class="card" v-for="payment in payments" :key="payment.id">
          <div class="card-content">
            You paid <b>{{ payment.currency }} {{ payment.amount }}</b> via
            <b>{{ payment.method }}</b> on
            {{ new Date(payment.created_at * 1000).toDateString() }}
            <hr />
            <a :href="payment.invoice_link" target="blank" class="button">
              Download Invoice
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import VLazyImage from "v-lazy-image";
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { functions } from "@/main.js";
export default {
  name: "subscription_payments",
  mounted() {},
  components: {
    // VLazyImage,
  },
  data() {
    return {
      user: {},
      payments: [],
    };
  },
  beforeMount() {
    db.collection("users")
      .doc(auth.currentUser.uid)
      .get()
      .then((user) => {
        this.user = user.data();
        this.user.id = user.id;
        db.collection("users")
          .doc(auth.currentUser.uid)
          .collection("subscription")
          .orderBy("created_at", "desc")
          .get()
          .then((payments) => {
            payments.forEach((payment) => {
              var paymentObj = payment.data();
              paymentObj.id = payment.id;
              this.payments.push(paymentObj);
            });
          });
      });
  },
  methods: {
    cancel(customer_id) {
      var cancelSubscription = functions.httpsCallable("cancelSubscription");
      cancelSubscription({
        customer_id: customer_id,
      }).then((result) => {
        console.log(result);
        this.user.premium = false;
      });
    },
  },
};
</script>
<style scoped>
section.section {
  padding-bottom: 100px;
}

.card {
  margin-bottom: 20px;
  border-radius: 15px;
}
a.button {
    color: #000;
    border: 3px solid;
    font-weight: 700;
}
</style>