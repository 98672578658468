var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero"},[_c('div',{staticClass:"hero-body",staticStyle:{"padding":"3rem 1.5rem 6rem 1.5rem"}},[_c('h1',{staticClass:"title has-text-centered"},[_vm._v("Search")]),_c('div',{staticClass:"card depth",staticStyle:{"max-width":"700px","margin":"auto"}},[_c('div',{staticClass:"card-content"},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":"projects"}},[_c('app-debounced-search-box',{attrs:{"delay":200}}),_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_results = ref.results;
var hits = ref_results.hits;
var query = ref_results.query;
return [(!query)?_c('div',[_c('hr'),_c('h2',{staticClass:"subtitle"},[_vm._v("Top Searches")]),_c('div',{staticClass:"tags are-medium"},_vm._l((hits),function(item){return _c('span',{key:item.objectID,staticClass:"tag",on:{"click":function($event){return _vm.$router.push('/project/' + item.objectID)}}},[_c('i',{staticClass:"fas fa-bolt"}),_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])}),0),_c('h2',{staticClass:"subtitle"},[_vm._v("Trending Projects")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column userstories"},_vm._l((_vm.$store.state.trending),function(post){return _c('div',{key:post.id,staticClass:"storycontainer",on:{"click":function($event){return _vm.$router.push('social/' + post.id)}}},[_c('div',{staticClass:"userphoto",style:(post.thumbnail)}),_c('p',[_vm._v(_vm._s(post.author))])])}),0)])]):_vm._e(),(hits.length > 0 && query)?_c('hr'):_vm._e(),(hits.length > 0 && query)?_c('h5',{staticClass:"subtitle"},[_vm._v(" Projects ")]):_vm._e(),(hits.length > 0 && query)?_c('div',{staticClass:"results products"},_vm._l((hits),function(item){return _c('div',{key:item.objectID,staticClass:"columns is-mobile",on:{"click":function($event){return _vm.$router.push('/project/' + item.objectID)}}},[_c('div',{staticClass:"column is-4"},[_c('img',{attrs:{"src":item.featuredImg,"alt":""}})]),_c('div',{staticClass:"column"},[_c('h3',[_vm._v(_vm._s(item.name))])])])}),0):_vm._e()]}}])}),_c('ais-index',{attrs:{"index-name":"products"}},[_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_results = ref.results;
var hits = ref_results.hits;
var query = ref_results.query;
return [(hits.length > 0 && query)?_c('hr'):_vm._e(),(hits.length > 0 && query)?_c('h5',{staticClass:"subtitle"},[_vm._v(" Products ")]):_vm._e(),(hits.length > 0 && query)?_c('div',{staticClass:"results products"},_vm._l((hits),function(item){return _c('div',{key:item.objectID,staticClass:"columns is-mobile",on:{"click":function($event){return _vm.$router.push('/product/' + item.objectID)}}},[_c('div',{staticClass:"column is-4"},[_c('img',{attrs:{"src":item.imgs[0],"alt":""}})]),_c('div',{staticClass:"column"},[_c('h3',[_vm._v(_vm._s(item.name))])])])}),0):_vm._e()]}}])})],1),_c('ais-index',{attrs:{"index-name":"users"}},[_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_results = ref.results;
var hits = ref_results.hits;
var query = ref_results.query;
return [(hits.length > 0 && query)?_c('hr'):_vm._e(),(hits.length > 0 && query)?_c('h5',{staticClass:"subtitle"},[_vm._v(" Makers ")]):_vm._e(),(hits.length > 0 && query)?_c('div',{staticClass:"results users"},_vm._l((hits),function(item){return _c('div',{key:item.objectID,staticClass:"columns is-mobile",on:{"click":function($event){return _vm.$router.push('/u/' + item.objectID)}}},[_c('div',{staticClass:"column is-3 has-text-centered"},[_c('div',{staticClass:"userimg",style:('background: url(' +
                          item.picture +
                          '),linear-gradient(to right, #8e2de2, #4a00e0)')})]),_c('div',{staticClass:"column"},[(item.fname)?_c('h3',[_vm._v(" "+_vm._s(item.fname + " " + item.lname)+" ")]):_c('h3',[_vm._v("WitBlox Maker")])])])}),0):_vm._e()]}}])})],1),_c('ais-index',{attrs:{"index-name":"social"}},[_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var ref_results = ref.results;
                          var hits = ref_results.hits;
                          var query = ref_results.query;
return [(hits.length > 0 && query)?_c('hr'):_vm._e(),(hits.length > 0 && query)?_c('h5',{staticClass:"subtitle"},[_vm._v(" Social ")]):_vm._e(),(hits.length > 0 && query)?_c('div',{staticClass:"results"},_vm._l((hits),function(item){return _c('div',{key:item.objectID,staticClass:"columns is-mobile",on:{"click":function($event){return _vm.$router.push('/social/' + item.objectID)}}},[_c('div',{staticClass:"column is-3"},[_c('img',{attrs:{"src":item.thumbnail,"alt":""}})]),_c('div',{staticClass:"column"},[_c('p',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.caption)+" ")])])])}),0):_vm._e()]}}])})],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }