<template>
    <section>
        <img src="~@/assets/img/referandearn.png" class="is-hidden-tablet" />
        <div class="container">
            <h6 class="title">Refer &amp; Earn</h6>
            <p class="subtitle">
                When your friend signs up using your referral link, you both get 250
                WitCoins each.
            </p>
            <div class="referrallink">
                <div class="columns is-mobile">
                    <div class="column">
                        <p class="code">
                            {{ code }}
                        </p>
                    </div>
                    <div class="column is-4">
                        <a @click="share" class="button is-fullwidth">Share</a>
                    </div>
                </div>
            </div>
            <div v-if="referral.length > 0" class="card">
                <div class="card-content">
                    <h3 class="title">Referred Friends</h3>
                    <div v-for="user in referral" :key="user.id" class="columns is-mobile">
                        <div class="column is-narrow">
                            <div class="profileImage" :style="user.picture"></div>
                        </div>
                        <div class="column">
                            <h1 class="name" @click="$router.push('/u/' + user.id)">
                                {{ user.fname + " " + user.lname }}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!referred" class="card">
                <div class="card-content">
                    <div class="columns">
                        <div class="column">
                            <h3 class="title">Did someone refer you?</h3>
                            <p>Add your friend's code to get 250 WitCoins.</p>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="notification" v-if="error">
                                {{ error }}
                            </div>
                            <input class="input" type="text" placeholder="Friend's Code" v-model="usercode" />
                            <a @click="addreferral" class="button is-fullwidth" v-bind:class="{
                              'is-loading': btnLoading,
                            }">Add</a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="card">
                <div class="card-content">
                    <article class="message is-primary">
                        <div class="message-body">
                            Congratulations! You have received 250 WitCoins for signing up
                            using your friend's referral code.
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { auth } from "@/main.js";
import { db } from "@/main.js";
import { Share } from "@capacitor/share";
// import { Webengage } from "@ionic-native/webengage";
import { createWebengageEvent } from '../helper';
export default {
  name: "referral",
  data() {
    return {
      uid: auth.currentUser.uid,
      referral: [],
      code: "",
      usercode: "",
      error: "",
      referred: false,
      btnLoading: false,
    };
  },
  methods: {
    format(value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
      }
      return newValue;
    },
    async share() {
      console.log(
        "https://witblox.page.link/?link=https://app.witblox.com/login/" +
          auth.currentUser.uid
      );
      var text =
        "Hi! I have joined WitBlox App 👋\n\nNow I can learn to build Robots, Science Projects Drones and much more.\n\nJoin me on the WitBlox App, use my code - (" +
        this.code +
        ") and get 500 WitCoins.";
      console.log(text);
      await Share.share({
        title: "Signup for WitBlox",
        text: text,
        url: "https://app.witblox.com/",
        dialogTitle: "Share with friends",
      });
    },
    addreferral() {
      if (this.usercode) {
        if(auth.currentUser.emailVerified){
        this.btnLoading = true;
        db.collection("users")
          .where("refcode", "==", this.usercode)
          .limit(1)
          .get()
          .then((snap) => {
            if (snap.empty) {
              this.btnLoading = false;
              this.error = "Sorry, this code does not exist.";
            } else {
              if (this.code == this.usercode) {
                this.btnLoading = false;
                this.error = "This is your code, please put your friend's.";
              } else if (this.referred) {
                this.btnLoading = false;
                this.error = "You have already added a referral code.";
              } else {
                this.error = "";
                this.btnLoading = true;
                db.collection("users")
                  .doc(auth.currentUser.uid)
                  .update({
                    referral: snap.docs[0].id,
                  })
                  .then(() => {
                    db.collection("users")
                      .doc(snap.docs[0].id)
                      .collection("coins")
                      .add({
                        created: new Date().getTime(),
                        from: "referral",
                        value: 250,
                      })
                      .then(() => {
                        db.collection("users")
                          .doc(auth.currentUser.uid)
                          .collection("coins")
                          .add({
                            created: new Date().getTime(),
                            from: "referral",
                            value: 250,
                          })
                          .then(() => {
                            this.referred = true;
                            createWebengageEvent({
                              uid: auth.currentUser.uid,
                              eventName: "Referral Received",
                              eventData: {
                                sentBy: snap.docs[0].id,
                                fname: snap.docs[0].data().fname,
                              }
                            })
                          });
                      });
                  });
              }
            }
          });
      } else {
        auth.currentUser.sendEmailVerification();
        this.btnLoading = false;
        this.error = "Please verify your email address to proceed. We have sent verification link in your inbox.";
    }
    }else this.error = "Please fill the code.";
    },
  },
  beforeMount() {
    db.collection("users")
      .doc(auth.currentUser.uid)
      .get()
      .then((user) => {
        if (user.data().refcode) this.code = user.data().refcode;
        if (user.data().referral) this.referred = true;
      });
    db.collection("users")
      .where("referral", "==", auth.currentUser.uid)
      .get()
      .then((users) => {
        users.forEach((user) => {
          var userObj = user.data();
          userObj.id = user.id;
          if (userObj.picture)
            userObj.picture =
              "background: url(" +
              userObj.picture +
              "), linear-gradient(to right, rgb(142, 45, 226), rgb(74, 0, 224))";
          else userObj.picture = "";
          this.referral.push(userObj);
        });
      });
  },
  mounted() {},
};
</script>
<style scoped>
.container {
    padding: 10px 25px 100px 25px;
}

h6.title {
    font-size: 1.7rem;
    padding: 20px 0;
}

h3.title {
    font-size: 1.35rem;
}

.card {
    margin-top: 25px;
    border-radius: 10px;
}

.subtitle {
    color: rgb(102, 102, 102);
    font-weight: 600;
    font-size: 1.2rem;
}

.referrallink {
    background: rgb(241, 241, 241);
    border-radius: 5px;
    padding: 2px 15px;
}

.column,
.columns {
    margin: auto;
}

.column.is-3 {
    text-align: center;
}

h1.name {
    font-size: 1.2rem;
    font-weight: 600;
}

.profileImage {
    width: 50px;
    height: 50px;
}

hr {
    margin: 0.25rem 0;
}

p.code {
    font-size: 1.2rem;
    font-weight: 800;
}
</style>