<template>
  <div class="hero">
    <div class="hero-body">
      <h1 class="title has-text-centered">
        <i class="fas fa-tools"></i>
        Orders
      </h1>
      <div class="card depth">
        <div class="card-content">
          <div v-if="!cod && !paid" class="notification is-danger">
            Your payment failed for this order. Please revise your payment to
            avoid any delay in shipping.
            <a class="button is-fullwidth" @click="revisePayment()"
              >Revise Payment</a
            >
          </div>
          <div v-else class="notification is-success">
            {{ status }}
          </div>
          <h3 class="subtitle">Order ID: #{{ id }}</h3>
          <hr />
          <h1 class="title">{{ title }}</h1>
          <hr />
          <h3 class="subtitle">Ordered On:</h3>
          <p>{{ date }}</p>
          <hr />
          <h3 v-if="tracking_id" class="subtitle">
            Tracking ID: #{{ tracking_id }}
          </h3>
          <div v-if="orderCancellable">
            <a @click="cancelOrder()" class="button is-fullwidth"
              >CANCEL ORDER</a
            >
            <hr />
          </div>
          <a
            v-if="tracking_url"
            class="button is-fullwidth"
            @click="showInApp(tracking_url)"
            >TRACK ORDER</a
          >
          <hr v-if="tracking_url" />
          <div
            class="columns is-mobile cartproduct"
            v-for="item in items"
            :key="item.id"
          >
            <div class="column is-3">
              <img :src="item.img" alt="" />
            </div>
            <div class="column">
              <p class="title" style="margin-bottom: 0; font-size: 1.1rem">
                {{ item.name }}
              </p>
              <p style="font-weight: 600">
                &#8377;{{ item.pricing }} x {{ item.qt }}
              </p>
            </div>
            <div class="column is-4 has-text-right">
              <p class="subtitle">&#8377;{{ item.total }}</p>
            </div>
          </div>
          <hr />
          <div class="columns is-mobile subtotal">
            <div class="column">
              <p class="subtitle">Subtotal</p>
            </div>
            <div class="column has-text-right">
              <p class="subtitle">+ &#8377;{{ numberWithCommas(subtotal) }}</p>
            </div>
          </div>
          <div class="columns is-mobile subtotal">
            <div class="column">
              <p class="subtitle">Shipping</p>
            </div>
            <div class="column has-text-right">
              <p class="subtitle">+ &#8377;{{ numberWithCommas(shipping) }}</p>
            </div>
          </div>
          <div class="columns is-mobile subtotal">
            <div class="column">
              <p class="subtitle">WitCoins</p>
            </div>
            <div class="column has-text-right">
              <p class="subtitle">- &#8377;{{ numberWithCommas(witcoins) }}</p>
            </div>
          </div>
          <div v-if="cod" class="columns is-mobile subtotal">
            <div class="column">
              <p class="subtitle">COD</p>
            </div>
            <div class="column has-text-right">
              <p class="subtitle">+ &#8377;20</p>
            </div>
          </div>
          <div class="columns is-mobile subtotal">
            <div class="column">
              <p class="title">Total</p>
            </div>
            <div class="column has-text-right">
              <p class="title">&#8377;{{ numberWithCommas(total) }}</p>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <p
                class="notification has-text-centered"
                style="font-size: 0.9rem"
              >
                You saved &#8377;{{ numberWithCommas(savings) }} on this order.
              </p>
            </div>
          </div>
          <hr />
          <p class="subtitle has-text-centered">Delivery Address</p>
          <div class="addressbox card">
            <div class="card-content">
              {{ address.full }}
              <br />
              PIN: {{ address.pincode }}
            </div>
          </div>
          <hr />
          <a class="btn btn-theme" @click="router('/shop')"
            >Build More Projects</a
          >
        </div>
      </div>
      <div style="padding-bottom: 60px"></div>
    </div>
  </div>
</template>
<script>
import { auth } from "@/main.js";
// import { functions } from "@/main.js";
import { db } from "@/main.js";
// import { Plugins } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { Dialog } from "@capacitor/dialog";
export default {
  name: "shoporder",
  data() {
    return {
      id: this.$route.params.id,
      items: [],
      subtotal: 0,
      savings: 0,
      coins: 0,
      witcoins: 0,
      total: 0,
      shipping: 0,
      title: "",
      date: "",
      tracking_id: "",
      tracking_url: "",
      address: {
        full: "",
        pincode: 0,
      },
      paid: true,
      cod: false,
      status: "",
      orderCancellable: false,
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    numberWithCommas(x) {
      var y = Number(x).toFixed(1);
      return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async showInApp(url) {
      await Browser.open({ url: url });
    },
    async revisePayment() {
      await Browser.open({ url: "https://pay.witblox.com/" + this.id });
    },
    async cancelOrder() {
      let confirmRet = await Dialog.confirm({
        title: "Confirm",
        message: "Are you sure you want to cancel this order?",
      });
      if (confirmRet.value) {
        this.orderCancellable = false;
        db.collection("shop-orders")
          .doc(this.id)
          .update({
            internalStatus: Number(2),
            status: "Your order has been cancelled.",
            wc_refunded: false,
          });
      }
    },
  },
  beforeMount() {
    db.collection("shop-orders")
      .doc(this.id)
      .onSnapshot((doc) => {
        if (auth.currentUser.uid != doc.data().uid)
          this.$router.push("/orders");
        this.items = doc.data().products;
        this.subtotal = doc.data().subtotal;
        this.shipping = doc.data().shipping;
        this.witcoins = doc.data().witcoins;
        this.savings = doc.data().savings;
        this.total = doc.data().total;
        this.title = doc.data().title;
        this.tracking_id = doc.data().tracking_id;
        this.tracking_url = doc.data().tracking_url;
        this.date = new Date(doc.data().added);
        this.address.full = doc.data().address;
        this.address.pincode = doc.data().pincode;
        this.paid = doc.data().paid;
        this.status = doc.data().status;
        this.cod = doc.data().cod;
        console.log(doc.data().cod, doc.data().internalStatus);
        if (doc.data().cod) {
          if (doc.data().internalStatus == 0) {
            this.orderCancellable = true;
          } else this.orderCancellable = false;
        } else this.orderCancellable = false;
      });
  },
  mounted() {},
};
</script>
<style scoped>
.subtotal .title {
  font-size: 1.25rem;
}
.subtotal .subtitle {
  font-size: 1.1rem;
}
.subtotal .column {
  padding: 0.5rem 0.75rem 0 0.75rem;
}
.notification .button {
  margin-top: 20px;
  color: #ffffff;
  border: 2px solid #ffffff;
}
.addressbox .card-content {
  font-family: "Poppins", sans-serif;
}
</style>