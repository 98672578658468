<template>
  <section class="shop">
    <div class="hero">
      <div class="hero-body">
        <div class="columns is-mobile">
          <div class="column">
            <h1 class="title">
              <img src="https://witblox.com/assets/img/witblox.png" width="25px" />
              Shop
            </h1>
          </div>
          <div class="column is-narrow" style="margin: auto">
            <a @click="$router.push('/search')" class="searchIcon">
              <i class="fas fa-search"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="categories">
      <div class="columns">
        <div class="column ordersBtn">
          <a class="button is-small is-rounded" @click="router('/transactions')">
            <img src="@/assets/img/coin.svg" style="width: 15px; margin-right: 5px" />
            <span id="coins">{{ coins }}</span>
          </a>
          <a class="button is-small is-rounded" @click="router('/orders')">
            <i class="fas fa-shopping-bag" style="margin-right: 4px"></i>
            <span id="coins">Track Orders</span>
          </a>
        </div>
      </div>
      <!-- <hr style="margin: 0.25rem 0px 1.5rem 0px; background-color: #d7d7d7" /> -->
    </div>
    <div class="productpage">
      <div class="container">
        <div class="columns">
          <div class="column">
            <flickity
              v-if="imgs.length > 0"
              ref="flickity"
              :options="flickityOptions"
              style="height: fit-content;"
            >
              <img
                v-for="img in imgs"
                :key="img"
                class="carousel-cell"
                :data-flickity-lazyload="img"
                alt=""
              />
            </flickity>
            <div v-if="!outofstock" class="buttons is-centered" style="margin-top: 4rem;">
              <!-- <a class="button" @click="addToCart()">
              <i class="fas fa-plus"></i>
            </a> -->
              <button class="button dark" @click="addToCart()">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span>Buy Now</span>
              </button>
              <a class="button" @click="share">
                <i class="fas fa-share-alt"></i>
              </a>
            </div>
          </div>
          <div class="column">
            <div class="container-content">
              <p class="subtitle" style="font-size: 0.9rem">
                <b>from</b> {{ from }}
              </p>
              <h1 class="title">{{ name }}</h1>
              <div class="columns is-mobile">
                <div class="column">
                  <p class="subtitle">
                    <span>
                      {{ numberWithCommas(mrpmax) }}
                    </span>
                  </p>
                </div>
              </div>
              <div v-if="outofstock">
                <p class="subtitle has-text-centered">OUT OF STOCK</p>
                <div class="has-text-centered" style="margin-bottom: 2rem">
                  <button
                    class="button dark has-text-centered"
                    @click="handleStockNotify()"
                  >
                    <span class="icon">
                      <i class="fas fa-bell"></i>
                    </span>
                    <span>Notify when available</span>
                  </button>
                </div>
              </div>
              <video-player
                v-if="vid1.sources.length > 0"
                class="vjs-custom-skin"
                ref="videoPlayer"
                :options="vid1"
                :playsinline="true"
              ></video-player>
              <!-- featuredGIFs-->
              <div v-if="featuredGIFs.length > 0" class="columns">
                <div class="column">
                  <h3 class="subtitle">How To Use</h3>
                  <div class="columns is-mobile">
                    <div class="column">
                      <img
                        style="border: 1px solid grey"
                        v-if="featuredGIFs[0]"
                        :src="featuredGIFs[0]"
                        alt=""
                      />
                    </div>
                    <div class="column">
                      <img
                        style="border: 1px solid grey"
                        v-if="featuredGIFs[1]"
                        :src="featuredGIFs[1]"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="columns is-mobile">
                    <div class="column">
                      <img
                        style="border: 1px solid grey"
                        v-if="featuredGIFs[2]"
                        :src="featuredGIFs[2]"
                        alt=""
                      />
                    </div>
                    <div class="column">
                      <img
                        style="border: 1px solid grey"
                        v-if="featuredGIFs[3]"
                        :src="featuredGIFs[3]"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- /featuredGIFs-->
              <!-- warranty-->
              <div v-if="warranty" class="columns">
                <div class="column">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/webshop%2FGet%20free%20warranty%20%26%20support%20for%206%20months.png?alt=media&token=1ee2435e-054e-49ce-8af6-7a3b7674a8b6"
                    alt=""
                  />
                </div>
              </div>
              <!-- /warranty -->
              <hr v-if="short.length > 0" />
              <ul v-if="short.length > 0">
                <li v-for="point in short" :key="point">
                  {{ point }}
                </li>
              </ul>
              <hr v-if="short.length > 0" />
              <div v-html="description"></div>
              <hr v-if="reviews.rating" />
              <!-- reviews -->
              <div v-if="reviews.rating" class="columns">
                <div class="column">
                  <h3 class="subtitle">Reviews</h3>
                  <p class="rating">
                    <i
                      v-for="star in rating"
                      :key="star.id"
                      class="fas"
                      :class="{
                        'fa-star': star.full,
                        'fa-star-half-alt': !star.full,
                      }"
                    ></i>
                    ({{ ratingScore }})
                    <br />
                    {{ reviews.text }}
                    <br /><br />
                    <b>{{ reviews.name }}</b>
                  </p>
                </div>
              </div>
              <!-- /reviews -->
              <hr v-if="downloads.length > 0" />
              <!-- downloads -->
              <div v-if="downloads.length > 0" class="columns">
                <div class="column">
                  <h3 class="subtitle">Downloads</h3>
                  <div
                    v-for="download in downloads"
                    :key="download.thumbnail"
                    class="scrollingwrapper"
                  >
                    <a
                      class="scrolling-card"
                      target="blank"
                      :href="download.link"
                    >
                      <img
                        style="border: 1px solid grey"
                        :src="download.thumbnail"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <!-- /downloads -->
              <hr v-if="similar.length > 0" />
              <!-- similar-products -->
              <div v-if="similar.length > 0" class="columns">
                <div class="column">
                  <h3 class="subtitle">Similar</h3>
                  <div class="scrollingwrapper">
                    <a class="scrolling-card">
                      <div data-v-84b08ff4="" class="cardholder">
                        <div data-v-84b08ff4="" class="card">
                          <div data-v-84b08ff4="" class="card-image">
                            <figure data-v-84b08ff4="" class="image is-4by3">
                              <img
                                data-v-84b08ff4=""
                                src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/products%2Fwitblox-mega-diy-robotics-kit%2FYfjf2pyBkJ?alt=media&amp;token=5a1863a0-561c-4225-80b0-5efe522e1e6f"
                                alt="Placeholder image"
                              />
                            </figure>
                          </div>
                          <div data-v-84b08ff4="" class="card-content">
                            <p data-v-84b08ff4="" class="subtitle">
                              WitBlox Mega DIY Robotics kit
                            </p>
                            <p data-v-84b08ff4="" class="title">
                              ₹2,499
                              <strike data-v-84b08ff4="" class="striked"
                                >₹3,000</strike
                              >
                            </p>
                          </div>
                          <div data-v-84b08ff4="" class="card-footer">
                            <a data-v-84b08ff4="" class="card-footer-item"
                              ><i data-v-84b08ff4="" class="fas fa-plus"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a class="scrolling-card">
                      <div data-v-84b08ff4="" class="cardholder">
                        <div data-v-84b08ff4="" class="card">
                          <div data-v-84b08ff4="" class="card-image">
                            <figure data-v-84b08ff4="" class="image is-4by3">
                              <img
                                data-v-84b08ff4=""
                                src="https://d2j6dbq0eux0bg.cloudfront.net/images/12892174/1581438382.jpg"
                                alt="Placeholder image"
                              />
                            </figure>
                          </div>
                          <div data-v-84b08ff4="" class="card-content">
                            <p data-v-84b08ff4="" class="subtitle">
                              WitBlox Remote Control kit
                            </p>
                            <p data-v-84b08ff4="" class="title">
                              ₹2,999
                              <strike data-v-84b08ff4="" class="striked"
                                >₹4,000</strike
                              >
                            </p>
                          </div>
                          <div data-v-84b08ff4="" class="card-footer">
                            <a data-v-84b08ff4="" class="card-footer-item"
                              ><i data-v-84b08ff4="" class="fas fa-plus"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a class="scrolling-card">
                      <div data-v-84b08ff4="" class="cardholder">
                        <div data-v-84b08ff4="" class="card">
                          <div data-v-84b08ff4="" class="card-image">
                            <figure data-v-84b08ff4="" class="image is-4by3">
                              <img
                                data-v-84b08ff4=""
                                src="https://d2j6dbq0eux0bg.cloudfront.net/images/12892174/1283699330.jpg"
                                alt="Placeholder image"
                              />
                            </figure>
                          </div>
                          <div data-v-84b08ff4="" class="card-content">
                            <p data-v-84b08ff4="" class="subtitle">
                              Arduino Starter Kit ( For WitBlox Users)
                            </p>
                            <p data-v-84b08ff4="" class="title">
                              ₹1,399
                              <strike data-v-84b08ff4="" class="striked"
                                >₹2,000</strike
                              >
                            </p>
                          </div>
                          <div data-v-84b08ff4="" class="card-footer">
                            <a data-v-84b08ff4="" class="card-footer-item"
                              ><i data-v-84b08ff4="" class="fas fa-plus"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </a>
                    <a class="scrolling-card">
                      <div data-v-84b08ff4="" class="cardholder">
                        <div data-v-84b08ff4="" class="card">
                          <div data-v-84b08ff4="" class="card-image">
                            <figure data-v-84b08ff4="" class="image is-4by3">
                              <img
                                data-v-84b08ff4=""
                                src="https://d2j6dbq0eux0bg.cloudfront.net/images/12892174/1257235888.jpg"
                                alt="Placeholder image"
                              />
                            </figure>
                          </div>
                          <div data-v-84b08ff4="" class="card-content">
                            <p data-v-84b08ff4="" class="subtitle">
                              Pluto Drone kit - Smartphone Controlled
                            </p>
                            <p data-v-84b08ff4="" class="title">
                              ₹6,999
                              <strike data-v-84b08ff4="" class="striked"
                                >₹10,000</strike
                              >
                            </p>
                          </div>
                          <div data-v-84b08ff4="" class="card-footer">
                            <a data-v-84b08ff4="" class="card-footer-item"
                              ><i data-v-84b08ff4="" class="fas fa-plus"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <!-- /similar-products -->
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cartaction" v-if="incart > 0">
      <div class="columns is-mobile" @click="router('/cart')">
        <div class="column is-3">
          <h1 class="title has-text-right">{{ incart }}</h1>
        </div>
        <div class="column">
          <h3 class="subtitle">
            <span v-if="incart < 2">Item</span>
            <span v-else>Items</span>
            in cart
          </h3>
        </div>
        <div class="column is-3">
          <i class="fas fa-arrow-circle-right" style="font-size: 2.2rem"></i>
        </div>
      </div>
    </div>
    <div v-if="cartbox" class="cartbox">
      <div class="content depth">
        <div class="columns is-mobile">
          <div class="column is-2">
            <img :src="selected.img" alt="" />
          </div>
          <div class="column">
            <p
              class="subtitle"
              style="margin-bottom: 0.5em; padding-bottom: 0px"
            >
              {{ selected.name }}
            </p>
            <p class="subtitle" style="font-size: 1rem; padding-bottom: 0px">
              {{ numberWithCommas(selected.price) }}
            </p>
            <div class="select is-rounded is-fullwidth is-small">
              <select v-model.number="qty" @change="updateQty()">
                <option v-for="option in options" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="column is-2 has-text-right">
            <a
              style="font-size: 1.6rem; color: #000000"
              @click="cartbox = false"
            >
              <i class="fas fa-times-circle"></i>
            </a>
          </div>
        </div>
        <div
          class="notification has-text-centered"
          style="padding: 1rem; margin-bottom: 1rem"
          v-if="selected.popupmsg"
        >
          <img
            src="https://cdn-icons-png.flaticon.com/512/5290/5290058.png"
            style="display: inline"
            width="12px"
          />
          {{ selected.popupmsg }}
        </div>
        <hr v-if="mayNeed.length > 0" style="margin: 1rem 0" />
        <div v-if="mayNeed.length > 0">
          <div class="columns">
            <div class="column">
              <p
                class="title has-text-centered"
                style="font-size: 1.3rem; padding-bottom: 10px"
              >
                To build this project,<br />you will also need
              </p>
            </div>
          </div>
          <div
            class="columns is-mobile cartproduct"
            v-for="item in mayNeed"
            :key="item.id"
          >
            <div class="column is-4 has-text-centered">
              <img :src="item.img" />
            </div>
            <div class="column">
              <p class="title">{{ item.name }}</p>
              <p class="subtitle">{{ numberWithCommas(item.price) }}</p>
            </div>
            <div class="column is-3">
              <div class="select is-small is-fullwidth">
                <select v-model="item.qty">
                  <option v-for="option in item.options" :key="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <a
                v-if="item.added"
                @click="removeItemFromCart(item)"
                class="button is-small is-fullwidth"
                :class="{ 'is-loading': item.loading, active: item.added }"
              >
                <i class="fas fa-check"></i>
              </a>
              <a
                v-else
                @click="addItemToCart(item.id, item.qty, item)"
                class="button is-small is-fullwidth"
                :class="{ 'is-loading': item.loading, active: item.added }"
              >
                <i class="fas fa-plus"></i>
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div class="footerfixed">
          <a @click="router('/cart')" class="btn btn-theme"
            >Total: {{ numberWithCommas(kitTotal) }} &rarr;</a
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { auth } from "@/main.js";
// import { functions } from "@/main.js";
import { db } from "@/main.js";
import { Share } from "@capacitor/share";
import Flickity from "vue-flickity";
import "@/assets/css/video-js.css";
import { videoPlayer } from "vue-video-player";
import {
  createWebengageEvent,
  formatCurrency,
  getUserCountry,
} from "../helper";
export default {
  name: "shopproduct",
  data() {
    return {
      id: this.$route.params.id,
      coins: 0,
      cartbox: false,
      outofstock: false,
      cartid: "",
      incart: 0,
      imgs: [],
      name: "",
      original: 0,
      mrp: 0,
      mrpmax: 0,
      short: [],
      options: [],
      qty: 1,
      description: "",
      mayNeed: [],
      rating: [],
      ratingScore: 0,
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: true,
        autoPlay: true,
        imagesLoaded: true,
        resize: true,
        lazyLoad: true,
      },
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: true,
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        loaded: false,
      },
      selected: {
        name: "",
        img: "",
        price: 0,
        popupmsg: "",
      },
      kitTotal: 0,
      featuredGIFs: ["", ""],
      from: "", //merchant name
      warranty: true,
      reviews: {
        rating: 4.5,
        text: "",
        name: "",
      },
      downloads: [
        {
          thumbnail: "",
          link: "",
        },
      ],
      similar: [""],
      country: {
        currency: "INR",
        code: "IN",
      },
    };
  },
  components: {
    videoPlayer,
    Flickity,
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    numberWithCommas(x) {
      return formatCurrency(x, this.country.currency);
    },
    async share() {
      console.log("https://shop.witblox.com/" + this.id);
      await Share.share({
        title: this.name,
        text: "Checkout " + this.name + " from WitBlox Shop.",
        url: "https://shop.witblox.com/" + this.id,
        dialogTitle: "Share with friends",
      });
    },
    buyNow() {
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .where("product", "==", this.id)
          .get()
          .then((snap) => {
            if (snap.empty) {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .add({
                  quantity: 1,
                  product: this.id,
                  added: new Date().getTime(),
                })
                .then(() => {
                  this.router("/cart");
                });
            } else this.router("/cart");
          });
      });
    },
    async handleStockNotify() {
      const user = await db.collection("users").doc(auth.currentUser.uid).get();

      db.collection("shop-products")
        .doc(this.id)
        .collection("notifiers")
        .doc(user.id)
        .set({
          email: user.data().email,
          name: user.data().fname + " " + user.data().lname,
          phone: user.data().phone,
          uid: user.id,
          created: new Date().getTime(),
        })
        .then(() => {
          alert("You will be notified when the product is back in stock.");
        })
        .catch(() => {
          alert("Something went wrong. Please try again later.");
        });
    },
    addToCart() {
      this.cartbox = true;
      this.kitTotal = Number(this.mrp);
      this.mayNeed.forEach((product) => {
        if (product.stock == 0) return;
        this.addItemToCart(product.id, product.quantity, product);
        // this.kitTotal = this.kitTotal + Number(product.price * product.qty)
      });
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .where("product", "==", this.id)
          .get()
          .then((snap) => {
            if (snap.empty) {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .add({
                  quantity: 1,
                  product: this.id,
                  added: new Date().getTime(),
                })
                .then((doc) => {
                  this.cartid = doc.id;
                  createWebengageEvent({
                    uid: auth.currentUser.uid,
                    eventName: "Product Added To Cart",
                    eventData: {
                      added: new Date().getTime(),
                      readableDate: new Date().toLocaleString(),
                      name: this.name,
                      amount: this.mrp,
                    },
                  });
                });
            } else {
              this.qty = snap.docs[0].data().quantity;
              this.cartid = snap.docs[0].id;
            }
          });
      });
    },
    updateQty() {
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .doc(this.cartid)
          .update({ quantity: this.qty });
      });
    },
    addItemToCart(id, qty, object) {
      if (object.stock === 0) return alert(`This product is out of stock.`);

      object.loading = true;
      if (!qty) qty = object.qty;
      this.kitTotal = this.kitTotal + Number(object.price) * Number(object.qty);
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .where("product", "==", id)
          .get()
          .then((snap) => {
            if (snap.empty) {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .add({
                  added: new Date().getTime(),
                  product: id,
                  quantity: Number(qty),
                })
                .then(() => {
                  object.added = true;
                  object.loading = false;
                });
            } else {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .doc(snap.docs[0].id)
                .update({
                  quantity: Number(qty),
                })
                .then(() => {
                  object.added = true;
                  object.loading = false;
                });
            }
          });
      });
    },
    removeItemFromCart(item) {
      item.added = false;
      this.kitTotal = this.kitTotal - Number(item.price) * Number(item.qty);
      console.log(item);
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("cart")
        .where("product", "==", item.id)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("users")
              .doc(auth.currentUser.uid)
              .collection("cart")
              .doc(doc.id)
              .delete();
          });
        });
    },
    getMaxMrp(mayNeedItem){
      this.mrpmax = parseInt(this.mrpmax) + (parseInt(mayNeedItem.price) * parseInt(mayNeedItem.qty));
    }
  },
  beforeMount() {
    auth.onAuthStateChanged((user) => {
      //get cart size
      db.collection("users")
        .doc(user.uid)
        .collection("cart")
        .onSnapshot((snap) => {
          this.incart = snap.size;
        });
      //fetch usable coins
      db.collection("users")
        .doc(user.uid)
        .collection("coins")
        .get()
        .then((snap) => {
          var coinsCollection = [];
          for (var i = 0; i < snap.size; i++) {
            coinsCollection.push(snap.docs[i].data().value);
          }
          function arraySum(arr) {
            return arr.reduce(function (a, b) {
              return a + b;
            }, 0);
          }
          this.coins = this.numberWithCommas(arraySum(coinsCollection));
        });
    });
    db.collection("shop-products")
      .doc(this.id)
      .get()
      .then((doc) => {
        createWebengageEvent({
          uid: auth.currentUser.uid,
          eventName: "Product Viewed",
          eventData: {
            added: new Date().getTime(),
            readableDate: new Date().toLocaleString(),
            name: doc.data().name,
          },
        });
        this.name = doc.data().name;
        this.original = doc.data().pricing["in-original"];
        this.mrp = doc.data().pricing["in-mrp"];
        this.mrpmax = this.mrp;
        doc.data().imgs.forEach((img) => {
          this.imgs.push(img);
        });
        doc.data()["short-description"].forEach((desc) => {
          this.short.push(desc);
        });
        //
        this.featuredGIFs = doc.data().featuredGIFs;
        this.from = doc.data().from;
        this.warranty = doc.data().warranty;
        this.reviews = doc.data().reviews;
        this.downloads = doc.data().downloads;
        this.similar = doc.data().similar;
        //
        this.selected.name = doc.data().name;
        this.selected.img = doc.data().imgs[0];
        this.selected.price = doc.data().pricing["in-mrp"];
        this.selected.popupmsg = doc.data().popupmsg;
        if (doc.data().stock < 1) this.outofstock = true;
        else this.outofstock = false;
        this.description = doc.data()["full-description"];
        if (doc.data().video)
          this.vid1.sources.push({ type: "video/mp4", src: doc.data().video });
        this.ratingScore = doc.data().review;
        var rating = doc.data().review;
        for (var i = 1; i < 6; i++) {
          if (rating == 0.5) this.rating.push({ id: i, full: false });
          else this.rating.push({ id: i, full: true });
          rating = rating - 1;
        }
        var options = [];
        for (var q = 1; q < Number(doc.data()["purchase-limit"]) + 1; q++) {
          options.push(q);
        }
        this.options = options;
        doc.data()["may-need"].forEach((product) => {
          db.collection("shop-products")
            .doc(product.id)
            .get()
            .then((item) => {
              var options = [];
              for (
                var i = 1;
                i < Number(item.data()["purchase-limit"]) + 1;
                i++
              ) {
                options.push(i);
              }
              this.mayNeed.push({
                id: item.id,
                name: item.data().name,
                price: item.data()["pricing"]["in-mrp"],
                img: item.data().imgs[0],
                loading: false,
                added: false,
                options: options,
                qty: product.qty,
                stock: parseInt(item.data().stock),
              });
              this.getMaxMrp({price: item.data()["pricing"]["in-mrp"], qty: product.qty});
            });
          });
      });
  },
  async mounted() {
    const data = await getUserCountry();

    this.country = {
      code: data.code,
      currency: data.currency,
    };
  },
};
</script>
<style scoped>

.categories {
  padding: 10px 0px 10px 10px;
}

.shop .hero-body {
  padding: 1rem 1.5rem;
}

.productpage {
  padding: 12px 10px 150px 10px;
  min-height: 100vh;
  /* background: #8e2de2;
  background: linear-gradient(to right, #4a00e0, #8e2de2); */
}

.productpage .container {
  background: #ffffff;
  min-height: 100vh;
  border-radius: 0 0 0 30px;
}

.container-content {
  padding: 10px 25px 50px 25px;
}

.productpage ul {
  list-style-type: circle;
  padding-left: 21px;
  padding-bottom: 20px;
}

.productpage ul li {
  padding-bottom: 5px;
}

p {
  padding-bottom: 10px;
}

.buttons .button {
  border: 3px solid;
  font-weight: 600;
}

.rating {
  font-weight: 600;
  font-size: 1rem;
}

.video-player {
  width: 100%;
  height: 220px;
  margin-bottom: 30px;
}

.cartbox {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff73;
}

.cartbox .content {
  overflow: scroll;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70%;
  padding: 30px;
  padding-bottom: 100px;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  border: 4px solid;
}

.cartproduct {
  background: #f4f8fbab;
  border-radius: 10px;
  margin-bottom: 20px !important;
}

.cartproduct img {
  border-radius: 10px 0 0 10px;
  max-height: 80px;
}

.cartproduct p.title {
  font-size: 1rem;
  padding-bottom: 5px;
}

.cartproduct p.subtitle {
  font-size: 0.9rem;
  margin-bottom: 0;
  padding-top: 6px;
}

.cartproduct .button {
  border: 3px solid;
}

.cartproduct .button.active {
  background: #5743e1;
  border-color: #5743e1;
  color: #fff;
}

.cartbox .btn {
  margin-bottom: 60px;
}

.shop .rating i {
  color: #ffd700;
}

.shop img.carousel-cell {
  padding: 0 60px;
}

/* */
/* .cartproduct select {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  padding-left: 12px;
  margin-top: 0px;
  margin-left: 0px;
} */
.button.dark {
  color: #ffffff;
  background: #5743e1;
  border-color: #5743e1;
}

.button {
  color: #5743e1;
}

.cartaction {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 35px 35px 80px 35px;
}

.cartaction .columns {
  padding: 0 15px;
  border-radius: 15px;
  background: #5743e1;
  -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.cartaction .title,
.cartaction .subtitle,
.cartaction i {
  color: #ffffff;
}

.cartaction .subtitle {
  height: 100%;
  line-height: 2.25rem;
  font-size: 1rem;
}

.ordersBtn a.button {
  margin-left: 3px;
  border: 2px solid;
  color: #5743e1;
}

.ordersBtn span {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.video-player {
  height: 200px !important;
}

@media only screen and (min-width: 600px) {
  .cartaction .columns {
    max-width: 400px;
    margin: auto;
  }

  .cartaction {
    padding: 35px 35px 100px 35px;
  }

  .video-player {
    height: 400px !important;
  }
}
</style>