<template>
  <section class="leaderboardscreen">
    <div class="columns is-mobile">
      <div class="column is-2"></div>
      <div class="column">
        <h1 class="title has-text-centered">Leaderboard</h1>
      </div>
      <div class="column is-2" style="margin: auto">
        <a @click="$router.push('/prizes')" class="searchIcon">
          <i class="fas fa-gift"></i>
        </a>
      </div>
      <!-- <h4 class="subtitle has-text-centered">
        We're currently working on Maker Rank, they will be updated soon.
        <br /><br />
        Till then checkout the creations of your friends on WitBlox Social.
      </h4>
      <div class="has-text-centered">
        <a class="button" @click="$router.push('/social')"> Watch </a>
      </div> -->
    </div>
    <!-- <div style="padding: 60px 0px" class="columns">
      <div class="column">
        <img
          src="https://cdn.dribbble.com/users/2026891/screenshots/8949027/image.png?compress=1&resize=800x600"
          alt=""
        />
      </div>
    </div> -->
    <div style="padding: 0 15px" class="columns is-mobile">
      <div class="column">
        <div class="circleholder lower">
          <div
            v-if="winners[1].image"
            class="profileImage"
            :style="'background: url(' + winners[1].image + ')'"
          ></div>
          <div v-else class="profileImage"></div>
          <div class="circle">2</div>
        </div>
        <p @click="$router.push('/u/' + winners[1].id)" class="winnername">
          {{ winners[1].name }}
        </p>
      </div>
      <div class="column">
        <div class="circleholder">
          <div
            v-if="winners[0].image"
            class="profileImage"
            :style="'background: url(' + winners[0].image + ')'"
          ></div>
          <div v-else class="profileImage"></div>
          <div class="circle">
            <i class="fas fa-trophy"></i>
          </div>
        </div>
        <p @click="$router.push('/u/' + winners[0].id)" class="winnername">
          {{ winners[0].name }}
        </p>
      </div>
      <div class="column">
        <div class="circleholder lower">
          <div
            v-if="winners[2].image"
            class="profileImage"
            :style="'background: url(' + winners[2].image + ')'"
          ></div>
          <div v-else class="profileImage"></div>
          <div class="circle">3</div>
        </div>
        <p @click="$router.push('/u/' + winners[2].id)" class="winnername">
          {{ winners[2].name }}
        </p>
      </div>
    </div>
    <div id="leaderboard">
      <div class="columns is-mobile">
        <div class="column is-3">
          <div
            v-if="winners[3].image"
            class="profileImage"
            :style="'background: url(' + winners[3].image + ')'"
          ></div>
          <div v-else class="profileImage"></div>
          <span>#4</span>
        </div>
        <div class="column">
          <h1 @click="$router.push('/u/' + winners[3].id)">
            {{ winners[3].name }}
          </h1>
        </div>
        <div class="column is-3">
          <span style="background: none">
            <img src="@/assets/img/coin.svg" width="17px" />
            {{ format(winners[3].coins) }}
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-3">
          <div
            v-if="winners[4].image"
            class="profileImage"
            :style="'background: url(' + winners[4].image + ')'"
          ></div>
          <div v-else class="profileImage"></div>
          <span>#5</span>
        </div>
        <div class="column">
          <h1 @click="$router.push('/u/' + winners[4].id)">
            {{ winners[4].name }}
          </h1>
        </div>
        <div class="column is-3">
          <span style="background: none">
            <img src="@/assets/img/coin.svg" width="17px" />
            {{ format(winners[4].coins) }}
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-3">
          <div
            v-if="winners[5].image"
            class="profileImage"
            :style="'background: url(' + winners[5].image + ')'"
          ></div>
          <div v-else class="profileImage"></div>
          <span>#6</span>
        </div>
        <div class="column">
          <h1 @click="$router.push('/u/' + winners[5].id)">
            {{ winners[5].name }}
          </h1>
        </div>
        <div class="column is-3">
          <span style="background: none">
            <img src="@/assets/img/coin.svg" width="17px" />
            {{ format(winners[5].coins) }}
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-3">
          <div
            v-if="winners[6].image"
            class="profileImage"
            :style="'background: url(' + winners[6].image + ')'"
          ></div>
          <div v-else class="profileImage"></div>

          <span>#7</span>
        </div>
        <div class="column">
          <h1 @click="$router.push('/u/' + winners[6].id)">
            {{ winners[6].name }}
          </h1>
        </div>
        <div class="column is-3">
          <span style="background: none">
            <img src="@/assets/img/coin.svg" width="17px" />
            {{ format(winners[6].coins) }}
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-3">
          <div
            v-if="winners[7].image"
            class="profileImage"
            :style="'background: url(' + winners[7].image + ')'"
          ></div>
          <div v-else class="profileImage"></div>

          <span>#8</span>
        </div>
        <div class="column">
          <h1 @click="$router.push('/u/' + winners[7].id)">
            {{ winners[7].name }}
          </h1>
        </div>
        <div class="column is-3">
          <span style="background: none">
            <img src="@/assets/img/coin.svg" width="17px" />
            {{ format(winners[7].coins) }}
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-3">
          <div
            v-if="winners[8].image"
            class="profileImage"
            :style="'background: url(' + winners[8].image + ')'"
          ></div>
          <div v-else class="profileImage"></div>
          <span>#9</span>
        </div>
        <div class="column">
          <h1 @click="$router.push('/u/' + winners[8].id)">
            {{ winners[8].name }}
          </h1>
        </div>
        <div class="column is-3">
          <span style="background: none">
            <img src="@/assets/img/coin.svg" width="17px" />
            {{ format(winners[8].coins) }}
          </span>
        </div>
      </div>
      <div class="columns is-mobile">
        <div class="column is-3">
          <div
            v-if="winners[9].image"
            class="profileImage"
            :style="'background: url(' + winners[9].image + ')'"
          ></div>
          <div v-else class="profileImage"></div>
          <span>#10</span>
        </div>
        <div class="column">
          <h1 @click="$router.push('/u/' + winners[9].id)">
            {{ winners[9].name }}
          </h1>
        </div>
        <div class="column is-3">
          <span style="background: none">
            <img src="@/assets/img/coin.svg" width="17px" />
            {{ format(winners[9].coins) }}
          </span>
        </div>
      </div>
      <div class="columns is-mobile rankinginfo">
        <div class="column">
          <p>
            Ranking is calculated based on total <b>WitCoins</b> earned by each user.
            <br><br>
            Spending <b>WitCoins</b> on shop does not affect your ranking.
          </p>
        </div>
      </div>
      <div style="padding-bottom: 100px"></div>
    </div>
  </section>
</template>
<script>
import { db } from "@/main.js";
export default {
  name: "leaderboard",
  data() {
    return {
      winners: [],
    };
  },
  methods: {
    format(value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
      }
      return newValue;
    },
  },
  beforeMount() {},
  mounted() {
    db.collection("users")
      .orderBy("totalcoins", "desc")
      .limit(10)
      .get()
      .then((users) => {
        users.forEach((winner) => {
          this.winners.push({
            name: winner.data().fname + " " + winner.data().lname,
            id: winner.id,
            image: winner.data().picture,
            coins: winner.data().totalcoins,
          });
        });
      });
    // db.collection("config")
    //   .doc("leaderboard")
    //   .get()
    //   .then((doc) => {
    //     doc.data().winners.forEach((winner) => {
    //       this.winners.push({
    //         name: winner.name,
    //         id: winner.uid,
    //         image: winner.image,
    //         coins: winner.coins,
    //       });
    //     });
    //   });
  },
};
</script>
<style scoped>
section {
  height: 100% !important;
}
h1.title {
  padding-bottom: 18px;
}
/* .leaderboardscreen {
  padding-bottom: 250px;
} */
/* h4.subtitle {
  padding: 0 30px;
  color: #e0e0e0;
  font-weight: 500;
  font-size: 1.1rem;
}
a.button {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
  background: #00000000;
  border: 3px solid;
} */
.rankinginfo {
  padding: 15px;
  border: none !important;
}
.rankinginfo p {
  font-size: 0.85rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
</style>