var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title is-4 has-text-centered"},[_vm._v("Upcoming Boxes")]),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"buttons",staticStyle:{"justify-content":"center"}},[_c('a',{staticClass:"button",on:{"click":function($event){return _vm.$router.push('/subscription_payments')}}},[_vm._v(" Payments ")]),_c('a',{staticClass:"button",on:{"click":_vm.cancelSub}},[_vm._v(" Want to cancel? ")])])])]),_vm._l((_vm.orders),function(order){return _c('div',{key:order.id,staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('img',{attrs:{"src":order.box.image,"alt":""}})]),_c('div',{staticClass:"column"},[_c('h1',{staticClass:"title is-5"},[_vm._v(" "+_vm._s(order.box.name)+" ")]),_c('article',{staticClass:"message is-link"},[_c('div',{staticClass:"message-body"},[(order.due > new Date().getTime())?_c('h3',{staticClass:"title is-6"},[_vm._v(" Your box will be shipped on "+_vm._s(new Date(order.due).toDateString())+". ")]):_vm._e(),(
                      order.due < new Date().getTime() &&
                      order.dispatchDate == 0
                    )?_c('h3',{staticClass:"title is-6"},[_vm._v(" We are preparing your box for shipment. ")]):_vm._e(),(
                      order.due < new Date().getTime() &&
                      order.dispatchDate > 0
                    )?_c('h3',{staticClass:"title is-6"},[_vm._v(" Your box is on the way. ")]):_vm._e()])]),(
                  order.due < new Date().getTime() && order.dispatchDate > 0
                )?_c('div',[_c('hr'),_c('a',{staticClass:"button",attrs:{"href":order.tracking.link,"target":"blank"}},[_vm._v(" Track Your Order ")])]):_vm._e(),(order.delivered)?_c('div',[_c('hr'),_c('a',{staticClass:"button",attrs:{"target":"blank"},on:{"click":function($event){return _vm.$router.push('/task/' + order.id)}}},[_vm._v(" Tasks ")])]):_vm._e()])])])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }