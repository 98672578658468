<template>
  <section class="socialcomponent">
    <!-- lists -->
    <div class="social-list">
      <span @click="(list = 'foryou'), scrollToTop()" class="link" v-bind:class="{ active: list === 'foryou' }">For
        You</span>
      <span @click="(list = 'following'), scrollToTop()" class="link"
        v-bind:class="{ active: list === 'following' }">Following</span>
      <span @click="(list = 'news'), scrollToTop()" class="link" v-bind:class="{ active: list === 'news' }">News</span>
      <!-- <span
        @click="list = 'school'"
        class="link"
        v-bind:class="{ active: list === 'school' }"
        >Group</span
      > -->
    </div>
    <!-- /lists -->
    <!-- for-you -->
    <div class="app__videos" v-if="list === 'foryou'">
      <div v-if="foryou.length < 4" class="preloader has-text-centered">
        <img src="@/assets/animations/social-loading.gif" alt />
      </div>
      <vue-scroll-snap v-else>
        <div v-for="(post, index) in orderedFYPosts" v-bind:key="post.id" v-bind:class="{ 'is-hidden': post.reported }"
          class="video">
          <!-- shop-action -->
          <div class="shopaction" v-if="post.isProject">
            <button class="button is-small is-rounded" @click="$router.push('/project/' + post.tagId)">
              Make this!
            </button>
          </div>
          <!-- /shop-action -->
          <!-- video -->
          <video @click="post.paused = true" :ref="post.id" :src="post.video" :poster="post.thumbnail" loop playsinline
            preload class="video__player"></video>
          <!-- /video -->
          <!-- video-footer -->
          <div class="vfooter">
            <!-- author-picture -->
            <div class="user">
              <div @click="openModal(post.author)" class="userimage-container">
                <div v-if="post.dp" class="userimage" :style="post.picture"></div>
                <div v-else class="userimage" :style="picture"></div>
              </div>
            </div>
            <!-- /author-picture -->
            <!-- author-name -->
            <p @click="openModal(post.author)" class="aname" v-observe-visibility="{
              callback: (isVisible, entry) =>
                visibilityChanged(isVisible, entry, index, post),
              throttle: 100,
            }">
              {{ post.name }}
              <img v-if="post.verification" src="@/assets/img/verified.png" width="14px" style="margin-left: 4px" />
            </p>
            <!-- /author-name -->
            <!-- post-tags -->
            <span class="posttag">{{ post.tag }}</span>
            <span class="posttag second">
              <i class="fas fa-eye"></i>
              {{ format(post.views) }}
            </span>
            <!-- /post-tags -->
            <!-- caption -->
            <p v-if="!post.expanded && post.length > 50">
              <span>{{ post.caption.substring(0, 25) }}</span>
              <br />
              <span @click="post.expanded = true">... read more</span>
            </p>
            <p v-else>
              <span v-html="urlify(post.caption)"></span>
            </p>
            <!-- /caption -->
            <!-- rating-box -->
            <div v-if="!post.selfAuthor" class="ratingBox" v-bind:class="{ hidden: post.rated }">
              <i v-bind:class="{ 'is-active': post.stars[0].status }" @click="ratePost(post, 0)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[1].status }" @click="ratePost(post, 1)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[2].status }" @click="ratePost(post, 2)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[3].status }" @click="ratePost(post, 3)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[4].status }" @click="ratePost(post, 4)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[5].status }" v-if="superlikeavailable" @click="ratePost(post, 5)"
                class="fas fa-trophy superlike"></i>
            </div>
            <!-- /rating-box -->
          </div>
          <!-- /video-footer -->
          <!-- video-sidebar -->
          <div class="vsidebar">
            <i v-if="post.loading" class="fas fa-compact-disc"></i>
            <i @click="likePost(post)" v-bind:class="{ liked: !post.liked }" class="fas fa-heart"></i>
            <p>{{ format(post.likes) }}</p>
            <i @click="loadComments(post.id, post.author)" class="fas fa-comment"></i>
            <p>{{ format(post.comments) }}</p>
            <i @click="sharePost(post)" class="fas fa-share"></i>
            <i @click="reportPost(post)" class="fas fa-exclamation-circle"></i>
          </div>
          <!-- /video-sidebar -->
          <!-- play-btn -->
          <div v-if="post.paused" class="vidbtns" @click="post.paused = false">
            <i class="fas fa-play"></i>
          </div>
          <!-- /play-btn -->
          <!-- rating-animation -->
          <transition name="fade">
            <div v-if="post.ratinganimation" class="vidanimation">
              <div class="holder">
                <p class="title">+{{ lastrating }}</p>
                <p class="subtitle">WitCoins</p>
              </div>
            </div>
          </transition>
          <!-- /rating-animation -->
        </div>
      </vue-scroll-snap>
    </div>
    <!-- /for-you -->
    <!-- following -->
    <div class="app__videos" v-else-if="list === 'following'">
      <div v-if="following.length < 1" class="preloader has-text-centered">
        <img src="@/assets/animations/empty.gif" alt />
        <h1 class="subtitle has-text-centered">No New Posts</h1>
      </div>
      <vue-scroll-snap v-else>
        <div v-for="(post, index) in orderedFLPosts" v-bind:key="post.id" v-bind:class="{ 'is-hidden': post.reported }"
          class="video">
          <!-- video -->
          <video @click="post.paused = true" :ref="post.id" :src="post.video" :poster="post.thumbnail" loop playsinline
            preload class="video__player"></video>
          <!-- /video -->
          <!-- video-footer -->
          <div class="vfooter">
            <!-- author-picture -->
            <div class="user">
              <div @click="openModal(post.author)" class="userimage-container">
                <div v-if="post.dp" class="userimage" :style="post.picture"></div>
                <div v-else class="userimage" :style="picture"></div>
              </div>
            </div>
            <!-- /author-picture -->
            <!-- author-name -->
            <p @click="openModal(post.author)" class="aname" v-observe-visibility="{
              callback: (isVisible, entry) =>
                visibilityChanged(isVisible, entry, index, post),
              throttle: 100,
            }">
              {{ post.name }}
              <img v-if="post.verification" src="@/assets/img/verified.png" width="14px" style="margin-left: 4px" />
            </p>
            <!-- /author-name -->
            <!-- post-tags -->
            <span class="posttag">{{ post.tag }}</span>
            <span class="posttag second">
              <i class="fas fa-eye"></i>
              {{ format(post.views) }}
            </span>
            <!-- /post-tags -->
            <!-- caption -->
            <p v-if="!post.expanded && post.length > 50">
              <span>{{ post.caption.substring(0, 25) }}</span>
              <br />
              <span @click="post.expanded = true">... read more</span>
            </p>
            <p v-else>
              <span v-html="urlify(post.caption)"></span>
            </p>
            <!-- /caption -->
            <!-- rating-box -->
            <div v-if="!post.selfAuthor" class="ratingBox" v-bind:class="{ hidden: post.rated }">
              <i v-bind:class="{ 'is-active': post.stars[0].status }" @click="ratePost(post, 0)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[1].status }" @click="ratePost(post, 1)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[2].status }" @click="ratePost(post, 2)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[3].status }" @click="ratePost(post, 3)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[4].status }" @click="ratePost(post, 4)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[5].status }" v-if="superlikeavailable" @click="ratePost(post, 5)"
                class="fas fa-trophy superlike"></i>
            </div>
            <!-- /rating-box -->
          </div>
          <!-- /video-footer -->
          <!-- video-sidebar -->
          <div class="vsidebar">
            <i v-if="post.loading" class="fas fa-compact-disc"></i>
            <i @click="likePost(post)" v-bind:class="{ liked: !post.liked }" class="fas fa-heart"></i>
            <p>{{ format(post.likes) }}</p>
            <i @click="loadComments(post.id, post.author)" class="fas fa-comment"></i>
            <p>{{ format(post.comments) }}</p>
            <i @click="sharePost(post)" class="fas fa-share"></i>
            <i @click="reportPost(post)" class="fas fa-exclamation-circle"></i>
          </div>
          <!-- /video-sidebar -->
          <!-- play-btn -->
          <div v-if="post.paused" class="vidbtns" @click="post.paused = false">
            <i class="fas fa-play"></i>
          </div>
          <!-- /play-btn -->
          <!-- rating-animation -->
          <transition name="fade">
            <div v-if="post.ratinganimation" class="vidanimation">
              <div class="holder">
                <p class="title">+{{ lastrating }}</p>
                <p class="subtitle">WitCoins</p>
              </div>
            </div>
          </transition>
          <!-- /rating-animation -->
        </div>
      </vue-scroll-snap>
    </div>
    <!-- /following -->
    <!-- school -->
    <div class="app__videos" v-else-if="list === 'school'">
      <div v-if="school.length < 1" class="preloader has-text-centered">
        <img src="@/assets/animations/empty.gif" alt />
        <h1 class="subtitle has-text-centered">No New Posts</h1>
      </div>
      <vue-scroll-snap v-else>
        <div v-for="(post, index) in school" v-bind:key="post.id" v-bind:class="{ 'is-hidden': post.reported }"
          class="video">
          <!-- video -->
          <video @click="post.paused = true" :ref="post.id" :src="post.video" :poster="post.thumbnail" loop playsinline
            preload class="video__player"></video>
          <!-- /video -->
          <!-- video-footer -->
          <div class="vfooter">
            <!-- author-picture -->
            <div class="user">
              <div @click="openModal(post.author)" class="userimage-container">
                <div v-if="post.dp" class="userimage" :style="post.picture"></div>
                <div v-else class="userimage" :style="picture"></div>
              </div>
            </div>
            <!-- /author-picture -->
            <!-- author-name -->
            <p @click="openModal(post.author)" class="aname" v-observe-visibility="{
              callback: (isVisible, entry) =>
                visibilityChanged(isVisible, entry, index, post),
              throttle: 100,
            }">
              {{ post.name }}
              <img v-if="post.verification" src="@/assets/img/verified.png" width="14px" style="margin-left: 4px" />
            </p>
            <!-- /author-name -->
            <!-- post-tags -->
            <span class="posttag">{{ post.tag }}</span>
            <span class="posttag second">
              <i class="fas fa-eye"></i>
              {{ format(post.views) }}
            </span>
            <!-- /post-tags -->
            <!-- caption -->
            <p v-if="!post.expanded && post.length > 50">
              <span>{{ post.caption.substring(0, 25) }}</span>
              <br />
              <span @click="post.expanded = true">... read more</span>
            </p>
            <p v-else>
              <span v-html="urlify(post.caption)"></span>
            </p>
            <!-- /caption -->
            <!-- rating-box -->
            <div v-if="!post.selfAuthor" class="ratingBox" v-bind:class="{ hidden: post.rated }">
              <i v-bind:class="{ 'is-active': post.stars[0].status }" @click="ratePost(post, 0)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[1].status }" @click="ratePost(post, 1)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[2].status }" @click="ratePost(post, 2)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[3].status }" @click="ratePost(post, 3)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[4].status }" @click="ratePost(post, 4)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[5].status }" v-if="superlikeavailable" @click="ratePost(post, 5)"
                class="fas fa-trophy superlike"></i>
            </div>
            <!-- /rating-box -->
          </div>
          <!-- /video-footer -->
          <!-- video-sidebar -->
          <div class="vsidebar">
            <i v-if="post.loading" class="fas fa-compact-disc"></i>
            <i @click="likePost(post)" v-bind:class="{ liked: !post.liked }" class="fas fa-heart"></i>
            <p>{{ format(post.likes) }}</p>
            <i @click="loadComments(post.id, post.author)" class="fas fa-comment"></i>
            <p>{{ format(post.comments) }}</p>
            <i @click="sharePost(post)" class="fas fa-share"></i>
            <i @click="reportPost(post)" class="fas fa-exclamation-circle"></i>
          </div>
          <!-- /video-sidebar -->
          <!-- play-btn -->
          <div v-if="post.paused" class="vidbtns" @click="post.paused = false">
            <i class="fas fa-play"></i>
          </div>
          <!-- /play-btn -->
          <!-- rating-animation -->
          <transition name="fade">
            <div v-if="post.ratinganimation" class="vidanimation">
              <div class="holder">
                <p class="title">+{{ lastrating }}</p>
                <p class="subtitle">WitCoins</p>
              </div>
            </div>
          </transition>
          <!-- /rating-animation -->
        </div>
      </vue-scroll-snap>
    </div>
    <!-- /school -->
    <!-- news -->
    <div class="app__videos" v-if="list === 'news'">
      <div v-if="news.length < 4" class="preloader has-text-centered">
        <img src="@/assets/animations/social-loading.gif" alt />
      </div>
      <vue-scroll-snap v-else>
        <div v-for="(post, index) in news" v-bind:key="post.id" v-bind:class="{ 'is-hidden': post.reported }"
          class="video">
          <!-- shop-action -->
          <div class="shopaction" v-if="post.isProject">
            <button class="button is-small is-rounded" @click="$router.push('/project/' + post.tagId)">
              Make this!
            </button>
          </div>
          <!-- /shop-action -->
          <!-- video -->
          <video @click="post.paused = true" :ref="post.id" :src="post.video" :poster="post.thumbnail" loop playsinline
            preload class="video__player"></video>
          <!-- /video -->
          <!-- video-footer -->
          <div class="vfooter">
            <!-- author-picture -->
            <div class="user">
              <div @click="openModal(post.author)" class="userimage-container">
                <div v-if="post.dp" class="userimage" :style="post.picture"></div>
                <div v-else class="userimage" :style="picture"></div>
              </div>
            </div>
            <!-- /author-picture -->
            <!-- author-name -->
            <p @click="openModal(post.author)" class="aname" v-observe-visibility="{
              callback: (isVisible, entry) =>
                visibilityChanged(isVisible, entry, index, post),
              throttle: 100,
            }">
              {{ post.name }}
              <img v-if="post.verification" src="@/assets/img/verified.png" width="14px" style="margin-left: 4px" />
            </p>
            <!-- /author-name -->
            <!-- post-tags -->
            <span class="posttag">{{ post.tag }}</span>
            <span class="posttag second">
              <i class="fas fa-eye"></i>
              {{ format(post.views) }}
            </span>
            <!-- /post-tags -->
            <!-- caption -->
            <p v-if="!post.expanded && post.length > 50">
              <span>{{ post.caption.substring(0, 25) }}</span>
              <br />
              <span @click="post.expanded = true">... read more</span>
            </p>
            <p v-else>
              <span v-html="urlify(post.caption)"></span>
            </p>
            <!-- /caption -->
            <!-- rating-box -->
            <div v-if="!post.selfAuthor" class="ratingBox" v-bind:class="{ hidden: post.rated }">
              <i v-bind:class="{ 'is-active': post.stars[0].status }" @click="ratePost(post, 0)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[1].status }" @click="ratePost(post, 1)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[2].status }" @click="ratePost(post, 2)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[3].status }" @click="ratePost(post, 3)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[4].status }" @click="ratePost(post, 4)" class="fas fa-star"></i>
              <i v-bind:class="{ 'is-active': post.stars[5].status }" v-if="superlikeavailable" @click="ratePost(post, 5)"
                class="fas fa-trophy superlike"></i>
            </div>
            <!-- /rating-box -->
          </div>
          <!-- /video-footer -->
          <!-- video-sidebar -->
          <div class="vsidebar">
            <i v-if="post.loading" class="fas fa-compact-disc"></i>
            <i @click="likePost(post)" v-bind:class="{ liked: !post.liked }" class="fas fa-heart"></i>
            <p>{{ format(post.likes) }}</p>
            <i @click="loadComments(post.id, post.author)" class="fas fa-comment"></i>
            <p>{{ format(post.comments) }}</p>
            <i @click="sharePost(post)" class="fas fa-share"></i>
            <i @click="reportPost(post)" class="fas fa-exclamation-circle"></i>
          </div>
          <!-- /video-sidebar -->
          <!-- play-btn -->
          <div v-if="post.paused" class="vidbtns" @click="post.paused = false">
            <i class="fas fa-play"></i>
          </div>
          <!-- /play-btn -->
          <!-- rating-animation -->
          <transition name="fade">
            <div v-if="post.ratinganimation" class="vidanimation">
              <div class="holder">
                <p class="title">+{{ lastrating }}</p>
                <p class="subtitle">WitCoins</p>
              </div>
            </div>
          </transition>
          <!-- /rating-animation -->
        </div>
      </vue-scroll-snap>
    </div>
    <!-- /news -->
    <!-- comment-box -->
    <div v-if="commentOpen" id="comment-box" class>
      <div class="newcomment">
        <div class="columns is-mobile">
          <div class="column" style="padding-right: 0">
            <input v-model="currentcomment" class="input" type="text" placeholder="Comment" />
          </div>
          <div class="column is-narrow">
            <a @click="postComment()" v-bind:class="{ 'is-loading': btnLoading }" id="commentBtn" class="button">POST</a>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="postcomments columns is-mobile" style="margin-bottom: 0px !important">
          <div class="column">
            <h6 class="subtitle">Comments</h6>
          </div>
          <div class="column is-3">
            <i class="fas fa-times-circle" @click="commentOpen = false"></i>
          </div>
        </div>
        <div id="comments">
          <div v-for="comment in orderedComments" :key="comment.id" class="columns is-mobile"
            v-bind:class="{ 'is-hidden': comment.deleted }">
            <div class="column is-narrow">
              <div @click="$router.push('/u/' + comment.author)" class="userimage" :style="comment.picture"></div>
            </div>
            <div class="column usercomment">
              <h2>{{ comment.name }}</h2>
              <h5 v-html="urlify(comment.text)"></h5>
            </div>
            <div class="column is-2" v-if="comment.owner">
              <a @click="deleteComment(comment.pid, comment.id, comment)">
                <i class="fas fa-trash-alt"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /comment-box -->
    <!-- profile-popup -->
    <div class="profile-popup modal is-active" v-if="usermodal.open">
      <div @click="usermodal.open = false" class="modal-background"></div>
      <div class="modal-content">
        <div class="profile">
          <div class="columns has-text-centered">
            <div class="column">
              <div class="columns is-mobile">
                <div class="column has-text-left">
                  <h3 class="subtitle badge">
                    <img src="@/assets/img/coin.svg" width="17px" style="margin-right: 8px" />
                    <span id="coins">
                      <div v-if="usermodal.loading" class="preload" style="padding-right: 10px; width: 60px"></div>
                      <span v-else>{{ format(usermodal.coins) }}</span>
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div class="column imagecontainer">
              <div :style="usermodal.picture" class="profileImage"></div>
              <!-- <div class="ranking">
                <span id="ranking">
                  <img src="@/assets/img/crown.png" class="crown" />
                  <span>{{ format(usermodal.rank) }}</span>
                </span>
              </div> -->
              <img src="img/medal.png" alt id="medal" />
            </div>
            <div class="column" style="padding-top: 0px">
              <h1 class="title">
                <span class="has-text-centered">
                  <div v-if="usermodal.loading" class="preload" style="width: 30%"></div>
                  <div v-else>
                    {{ usermodal.name }}
                    <img v-if="usermodal.verification" src="@/assets/img/verified.png" width="21px"
                      style="margin-left: 8px" />
                  </div>
                </span>
              </h1>
              <h6 id="bio" class="subtitle">
                <div v-if="usermodal.loading" class="preload" style="width: 90%"></div>
                <div v-else>{{ usermodal.bio }}</div>
              </h6>
            </div>
            <div class="column">
              <div class="card profilecard">
                <div class="columns is-mobile has-text-centered">
                  <div id="posts-btn" class="column">
                    <p id="posts" class="numbering">
                      <span v-if="usermodal.loading" class="preload dark" style="width: 100%"></span>
                      <span v-else>{{ format(usermodal.posts) }}</span>
                    </p>
                    <p class="naming">Posts</p>
                  </div>
                  <div id="followers-btn" class="column">
                    <p id="followers" class="numbering">
                      <span v-if="usermodal.loading" class="preload dark" style="width: 100%"></span>
                      <span v-else>{{ format(usermodal.followers) }}</span>
                    </p>
                    <p class="naming">Followers</p>
                  </div>
                  <div id="following-btn" class="column">
                    <p id="following" class="numbering">
                      <span v-if="usermodal.loading" class="preload dark" style="width: 100%"></span>
                      <span v-else>{{ format(usermodal.following) }}</span>
                    </p>
                    <p class="naming">Following</p>
                  </div>
                  <div id="projects-btn" class="column">
                    <p id="projects" class="numbering">
                      <span v-if="usermodal.loading" class="preload dark" style="width: 100%"></span>
                      <span v-else>{{ format(usermodal.projects) }}</span>
                    </p>
                    <p class="naming">Projects</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="has-text-centered">
                <button @click="unfollowUser(usermodal.id, usermodal)" v-if="usermodal.followed" class="button following">
                  <i class="fas fa-check"></i>
                </button>
                <button @click="followUser(usermodal.id, usermodal)" v-else class="button follow">
                  <i class="fas fa-user-plus"></i>
                </button>
                <button @click="$router.push('/u/' + usermodal.id)" class="button follow">
                  View Profile
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /profile-popup -->
  </section>
</template>
<script>
// import { auth } from "@/main.js";
import _ from "lodash";
import firebase from "firebase/app";
import { db } from "@/main.js";
import { auth } from "@/main.js";
import { functions } from "@/main.js";
// import { Plugins } from "@capacitor/core";
import VueScrollSnap from "vue-scroll-snap";
import { Dialog } from "@capacitor/dialog";
import { Share } from "@capacitor/share";
import Imgproxy from "imgproxy";
import { createWebengageEvent } from "../helper";

export default {
  name: "social",
  components: { VueScrollSnap },
  data() {
    return {
      picture: "url(" + require("@/assets/img/user.png") + ")",
      list: "foryou",
      fy: "",
      fl: "",
      foryou: [],
      foryouposts: [],
      foryoutriggers: [],
      //
      following: [],
      followingposts: [],
      //
      news: [],
      newsposts: [],
      //
      school: [],
      comments: [],
      commentOpen: false,
      currentpost: "",
      currentauthor: "",
      currentcomment: "",
      superlikeavailable: false,
      lastrating: 0,
      btnLoading: false,
      uid: "",
      usermodal: {
        id: "",
        open: false,
        loading: false,
        name: "",
        verification: false,
        picture: "",
        bio: "",
        coins: "",
        rank: "",
        posts: "",
        followers: "",
        following: "",
        projects: "",
        followed: false,
        lastindex: 0,
      },
      proxy: new Imgproxy({
        key: "fb6333a954da296899362eca74b278dd",
        salt: "7436536ea6bfeea3559b21121f4844c2",
        baseUrl: "https://witblox-imgproxy.herokuapp.com",
        encode: true,
      }),
    };
  },
  computed: {
    orderedFYPosts: function () {
      return _.orderBy(this.foryou, ["created"], ["desc"]);
    },
    orderedFLPosts: function () {
      return _.orderBy(this.following, ["created"], ["desc"]);
    },
    orderedComments: function () {
      return _.orderBy(this.comments, ["index"], ["asc"]);
    },
  },
  methods: {
    visibilityChanged: function (isVisible, entry, index, post) {
      if (isVisible) {
        console.log(index, post.id);
        if (post.paused) this.$refs[post.id][0].pause();
        else {
          if ((index + 5) % 10 == 0) {
            console.log("add new posts: ", (index + 5) % 10);
            if (post.list == "fy") {
              if (!this.foryoutriggers.includes(post.id)) {
                this.foryoutriggers.push(post.id);
                this.fetchForYou(10);
              }
            } else if (post.list == "fl") this.fetchFollowing(post.created);
          }
          if (!post.viewed) {
            db.collection("social")
              .doc(post.id)
              .update({
                views: firebase.firestore.FieldValue.increment(1),
              });
            post.viewed = true;
            post.views += 1;
            //is liked
            auth.onAuthStateChanged((user) => {
              db.collection("social")
                .doc(post.id)
                .collection("views")
                .where("uid", "==", user.uid)
                .get()
                .then((snap) => {
                  if (snap.empty) {
                    db.collection("social")
                      .doc(post.id)
                      .collection("views")
                      .add({
                        added: new Date().getTime(),
                        uid: user.uid,
                      });
                  }
                });
            });
          }
          setTimeout(() => {
            this.$refs[post.id][0].play();
          }, 200);
        }
      } else this.$refs[post.id][0].pause();
    },
    scrollToTop() {
      setTimeout(() => {
        document.getElementsByClassName("app__videos")[0].scrollTop = 0;
      }, 100);
    },
    fetchForYou(limit) {
      db.collection("social")
        .where("clearance", "==", true)
        .where("current", "<", this.lastindex)
        .orderBy("current", "desc")
        .limit(limit)
        .get()
        .then((snap) => {
          console.log("fetching for you", this.lastindex);
          snap.forEach((doc) => {
            if (!this.foryouposts.includes(doc.id))
              this.addPost(doc, "fy", doc.data().current);
            if (this.lastindex > doc.data().current) {
              this.lastindex = doc.data().current;
              console.log("lastIndex updated from", doc.id, timestampToRelative(this.lastindex), this.lastindex);
            }
          });
        });
    },
    fetchNews(limit) {
      db.collection("social")
        .where("list", "==", "news")
        .orderBy("current", "desc")
        .limit(limit)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            if (!this.newsposts.includes(doc.id))
              this.addPost(doc, "news", doc.data().current);
            // if (this.lastindex > doc.data().current) this.lastindex = doc.data().current;
          });
        });
    },
    fetchFollowing(timestamp) {
      auth.onAuthStateChanged((currentuser) => {
        db.collection("users")
          .doc(currentuser.uid)
          .collection("following")
          .limit(40)
          .get()
          .then((snap) => {
            if (!snap.empty) {
              snap.forEach((doc) => {
                db.collection("social")
                  .where("uid", "==", doc.data().uid)
                  .where("current", "<", timestamp)
                  .orderBy("current", "desc")
                  .limit(1)
                  .get()
                  .then((snap) => {
                    if (!snap.empty) {
                      snap.forEach((doc) => {
                        if (!this.followingposts.includes(doc.id))
                          this.addPost(doc, "fl", doc.data().current);
                      });
                    }
                  });
              });
            }
          });
      });
    },
    addPost(doc, list, created, top) {
      if (list == "fy") this.foryouposts.push(doc.id);
      else if (list == "fl") this.followingposts.push(doc.id);
      else if (list == "news") this.newsposts.push(doc.id);
      var tag;
      var tagId = doc.data().tag;
      var isProject = doc.data().uploadType == "story";
      if (
        doc.data().uploadType == "story" ||
        doc.data().uploadType == "challenge" ||
        doc.data().uploadType == "competition"
      ) {
        db.collection(this.tagType(doc.data().uploadType))
          .doc(doc.data().tag)
          .get()
          .then((tags) => {
            tag = "#" + this.removeSpaces(tags.data().name);
          });
      } else {
        tag = "#NewPost";
      }
      //fetch post likes
      db.collection("social")
        .doc(doc.id)
        .collection("likes")
        .get()
        .then((likesnap) => {
          //fetch post comments
          db.collection("social")
            .doc(doc.id)
            .collection("comments")
            .get()
            .then((commentsnap) => {
              //fetch user details
              db.collection("users")
                .doc(doc.data().uid)
                .get()
                .then((user) => {
                  var name = user.data().fname + " " + user.data().lname;
                  var verification = user.data().verified;
                  var dp;
                  if (user.data().picture == "" || user.data().picture == null)
                    dp = false;
                  else dp = true;
                  var picture =
                    "background: url(" +
                    this.proxy
                      .builder()
                      .resize("auto", 100, 100, 0)
                      .generateUrl(user.data().picture) +
                    "),linear-gradient(to right, #8e2de2, #4a00e0)";
                  auth.onAuthStateChanged((currentuser) => {
                    //check if like
                    db.collection("social")
                      .doc(doc.id)
                      .collection("likes")
                      .where("uid", "==", currentuser.uid)
                      .get()
                      .then((liked) => {
                        db.collection("social")
                          .doc(doc.id)
                          .collection("rating")
                          .where("uid", "==", currentuser.uid)
                          .get()
                          .then((rating) => {
                            var selfAuthor = false;
                            if (!rating.empty) selfAuthor = true;
                            db.collection("users")
                              .doc(currentuser.uid)
                              .collection("following")
                              .where("uid", "==", doc.data().uid)
                              .get()
                              .then((following) => {
                                var index = created;
                                db.collection("social")
                                  .doc(doc.id)
                                  .collection("views")
                                  .where("uid", "==", currentuser.uid)
                                  .get()
                                  .then((viewed) => {
                                    var backdate = new Date();
                                    backdate.setDate(backdate.getDate() - 1);
                                    if (!top) {
                                      if (!viewed.empty)
                                        index = backdate.getTime();
                                    } else index = new Date().getTime();
                                    if (currentuser.uid == doc.data().uid)
                                      selfAuthor = true;
                                    var json = {
                                      id: doc.id,
                                      author: doc.data().uid,
                                      selfAuthor: selfAuthor,
                                      video: doc.data().url,
                                      thumbnail:
                                        "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
                                        encodeURIComponent(doc.data().url) +
                                        "&size=400&mode=orig",
                                      caption: doc.data().caption,
                                      length: doc.data().caption.length,
                                      liked: liked.empty,
                                      rated: !rating.empty,
                                      followed: false,
                                      views: doc.data().views,
                                      likes: likesnap.size,
                                      comments: commentsnap.size,
                                      tag: tag,
                                      dp: dp,
                                      picture: picture,
                                      verification: verification,
                                      following: following.empty,
                                      name: name,
                                      index: index,
                                      created: created,
                                      viewed: false,
                                      loading: false,
                                      paused: false,
                                      expanded: false,
                                      reported: false,
                                      ratinganimation: false,
                                      list: list,
                                      stars: [
                                        {
                                          status: false,
                                        },
                                        {
                                          status: false,
                                        },
                                        {
                                          status: false,
                                        },
                                        {
                                          status: false,
                                        },
                                        {
                                          status: false,
                                        },
                                        {
                                          status: false,
                                        },
                                      ],
                                      tagId: tagId,
                                      isProject: isProject,
                                    };
                                    if (list == "fl") this.following.push(json);
                                    else if (list == "fy")
                                      this.foryou.push(json);
                                    else if (list == "scl")
                                      this.school.push(json);
                                    else if (list == "news")
                                      this.news.push(json);
                                  });
                              });
                          });
                      });
                  });
                });
            });
        });
    },
    likePost(post) {
      //send like
      if (!post.liked) {
        post.liked = true;
        post.likes -= 1;
      } else {
        post.liked = false;
        post.likes += 1;
      }
      var likePost = functions.httpsCallable("likePost");
      likePost({ pid: post.id }).then(() => {
        console.log("like function executed");
        var payload = {
          added: new Date().getTime(),
          readableDate: new Date().toLocaleString(),
          author: post.author,
          hashtag: post.tag,
        };
        createWebengageEvent({
          uid: auth.currentUser.uid,
          eventName: "Post Liked",
          eventData: payload,
        });
      });
    },
    ratePost(post, index) {
      var coins;
      if (index == 5) {
        coins = 10;
        auth.onAuthStateChanged((user) => {
          db.collection("users")
            .doc(user.uid)
            .update({
              superlike: new Date().getTime(),
            })
            .then(() => {
              this.superlikeavailable = false;
            });
        });
      } else coins = index + 1;
      this.lastrating = coins;
      for (var i = 0; i < post.stars.length; i++) {
        if (i < index || i == index) {
          post.stars[i].status = true;
          post.rated = true;
          setTimeout(() => {
            post.selfAuthor = true;
            post.ratinganimation = true;
            setTimeout(() => {
              post.ratinganimation = false;
            }, 1000);
          }, 1000);
        }
      }
      var postRating = firebase.functions().httpsCallable("postRating");
      postRating({
        pid: post.id,
        ruid: post.author,
        score: coins,
        coins: coins,
      })
        .then(() => {
          var payload = {
            added: new Date().getTime(),
            readableDate: new Date().toLocaleString(),
            author: post.author,
            hashtag: post.tag,
            rating: index,
          };
          createWebengageEvent({
            uid: auth.currentUser.uid,
            eventName: "Post Rated",
            eventData: payload,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    commentOnPost(post) {
      console.log(post.id);
      post.comments += 1;
      //send comment
    },
    tagType(type) {
      if (type == "story") return "projects";
      else if (type == "challenge") return "challenges";
      else if (type == "competition") return "competition";
    },
    removeSpaces(text) {
      var originalText = text;
      return originalText.split(" ").join("");
    },
    async reportPost(post) {
      let confirmRet = await Dialog.confirm({
        title: "Confirm",
        message: "Are you sure you want to report this post?",
      });
      if (confirmRet.value) {
        post.reported = true;
        var reportPost = functions.httpsCallable("reportPost");
        reportPost({
          pid: post.id,
          admin: this.$store.state.profile.verified,
        }).then(() => {
          console.log("report function executed");
          //   await Dialog.alert({
          //     title: "Done",
          //     message: "We're reviewing the post you reported.",
          //   });
        });
      }
    },
    async sharePost(post) {
      await Share.share({
        title: "Check this post on WitBlox",
        text: "Checkout " + post.name + "'s Project on WitBlox:",
        url: "https://post.witblox.com/" + post.id,
        dialogTitle: "Share with friends",
      });
      var payload = {
        added: new Date().getTime(),
        readableDate: new Date().toLocaleString(),
        author: post.author,
        hashtag: post.tag,
      };
      createWebengageEvent({
        uid: auth.currentUser.uid,
        eventName: "Post Shared",
        eventData: payload,
      });
    },
    openModal(uid) {
      this.usermodal.open = true;
      this.usermodal.loading = true;
      (this.usermodal.picture =
        "background: url(" + require("@/assets/img/user.png") + ")"),
        db
          .collection("users")
          .doc(uid)
          .get()
          .then((doc) => {
            this.usermodal.id = doc.id;
            this.usermodal.name = doc.data().fname + " " + doc.data().lname;
            this.usermodal.bio = doc.data().bio;
            this.usermodal.rank = "#" + doc.data().ranking;
            this.usermodal.verification = doc.data().verified;
            var profileStats = functions.httpsCallable("profileStats");
            auth.onAuthStateChanged((user) => {
              db.collection("users")
                .doc(user.uid)
                .collection("following")
                .where("uid", "==", uid)
                .get()
                .then((snap) => {
                  this.usermodal.followed = !snap.empty;
                });
            });
            if (doc.data().picture)
              this.usermodal.picture =
                "background: url(" +
                this.proxy
                  .builder()
                  .resize("auto", 200, 200, 0)
                  .generateUrl(doc.data().picture) +
                ")";
            profileStats({ uid: doc.id }).then((result) => {
              this.usermodal.posts = result.data.posts;
              this.usermodal.projects = result.data.completed;
              this.usermodal.followers = result.data.followers;
              this.usermodal.following = result.data.following;
              this.usermodal.coins = result.data.coins;
              this.usermodal.loading = false;
            });
          });
    },
    followUser(toUID, user) {
      user.followed = true;
      var followUser = functions.httpsCallable("followUser");
      followUser({
        toUID: toUID,
      }).then((result) => {
        const payload = {
          added: new Date().getTime(),
          readableDate: new Date().toLocaleString(),
          user: toUID,
        };
        createWebengageEvent({
          uid: auth.currentUser.uid,
          eventName: "Followed User",
          eventData: payload,
        });
        if (result.data.code == 200) {
          console.log("success");
        } else if (result.data.code == 300) {
          console.log("error", result.data.error);
        }
      });
    },
    unfollowUser(toUID, user) {
      user.followed = false;
      var unfollowUser = functions.httpsCallable("unfollowUser");
      unfollowUser({
        toUID: toUID,
      }).then((result) => {
        if (result.data.code == 200) {
          console.log("success");
        } else if (result.data.code == 300) {
          console.log("error", result.data.error);
        }
      });
    },
    loadComments(pid, author) {
      this.comments = [];
      this.commentOpen = true;
      this.currentpost = pid;
      this.currentauthor = author;
      var owner = false;
      db.collection("social")
        .doc(pid)
        .get()
        .then((post) => {
          var author = post.data().uid == this.uid;
          db.collection("social")
            .doc(pid)
            .collection("comments")
            .get()
            .then((snap) => {
              snap.forEach((doc) => {
                db.collection("users")
                  .doc(doc.data().uid)
                  .get()
                  .then((user) => {
                    if (author) owner = true;
                    else owner = this.uid == doc.data().uid;
                    var picture;
                    if (user.data().picture)
                      picture =
                        "background: url(" +
                        user.data().picture +
                        "), linear-gradient(to right, rgb(142, 45, 226), rgb(74, 0, 224))";
                    this.comments.push({
                      index: doc.data().created,
                      pid: post.id,
                      id: doc.id,
                      author: doc.data().uid,
                      name: user.data().fname + " " + user.data().lname,
                      text: doc.data().text,
                      picture: picture,
                      owner: owner,
                      deleted: false,
                    });
                  });
              });
            });
        });
    },
    postComment() {
      this.btnLoading = true;
      if (this.currentcomment != "") {
        auth.onAuthStateChanged((user) => {
          var picture;
          if (user.photoURL)
            picture = "background-image: url(" + user.photoURL + ")";
          this.comments.push({
            id: "",
            name: user.displayName,
            picture: picture,
            text: this.currentcomment,
            author: user.uid,
            owner: true,
            deleted: false,
          });
        });
        var postComment = functions.httpsCallable("postComment");
        postComment({
          comment: this.currentcomment,
          pid: this.currentpost,
        }).then((result) => {
          console.log(result);
          this.currentcomment = "";
          this.btnLoading = false;
          var payload = {
            added: new Date().getTime(),
            readableDate: new Date().toLocaleString(),
            author: this.currentauthor,
            comment: this.currentcomment,
          };
          createWebengageEvent({
            uid: auth.currentUser.uid,
            eventName: "Post Commented On",
            eventData: payload,
          });
        });
      }
    },
    deleteComment(pid, cid, comment) {
      comment.deleted = true;
      db.collection("social")
        .doc(pid)
        .collection("comments")
        .doc(cid)
        .delete()
        .then(() => {
          console.log("deleted");
        });
    },
    urlify(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function (url) {
        return '<a target="blank" href="' + url + '">' + url + "</a>";
      });
    },
    format(value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
      }
      return newValue;
    },
    scrollToPost() {
      console.log("scrolling to post");
      setTimeout(() => {
        document
          .getElementsByClassName("scroll-snap-container")[0]
          .classList.add("hide-overflow");
      }, 800);
    },
    fetchSchool() {
      db.collection("config")
        .doc("techfest")
        .get()
        .then((doc) => {
          doc.data().finalists.forEach((pid) => {
            db.collection("social")
              .doc(pid)
              .get()
              .then((post) => {
                this.addPost(post, "scl", post.data().created);
              });
          });
        });
    },
  },
  beforeMount() {
    console.log(this.$store.state.profile.verified);
    if (this.$route.params.postid) {
      db.collection("social")
        .doc(this.$route.params.postid)
        .get()
        .then((doc) => {
          this.addPost(doc, "fy", new Date().getTime(), true);
        });
    }
    this.lastindex = new Date().getTime();
    this.fetchForYou(10);
    this.fetchNews(40);
    this.fetchFollowing(new Date().getTime());
    // this.fetchSchool();
    auth.onAuthStateChanged((user) => {
      this.uid = user.uid;
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.data().superlike) {
            var d = new Date();
            d.setDate(d.getDate() - 1);
            var oneday = d.getTime();
            if (oneday < doc.data().superlike) this.superlikeavailable = false;
            else if (oneday > doc.data().superlike)
              this.superlikeavailable = true;
          } else {
            db.collection("users")
              .doc(user.uid)
              .update({
                superlike: new Date().getTime(),
              })
              .then(() => {
                this.superlikeavailable = true;
              });
          }
        });
    });
  },
  mounted() {
    createWebengageEvent({
      uid: auth.currentUser.uid,
      eventName: "Social Viewed",
      eventData: {
        added: new Date().getTime(),
        readableDate: new Date().toLocaleString(),
      },
    });
  },
};

function timestampToRelative(timestamp) {
  var date = new Date(timestamp);
  var now = new Date();
  var seconds = Math.floor((now - date) / 1000);
  var interval = Math.floor(seconds / 31536000);
  if (interval > 1) return interval + " years ago";
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) return interval + " months ago";
  interval = Math.floor(seconds / 86400);
  if (interval > 1) return interval + " days ago";
  interval = Math.floor(seconds / 3600);
  if (interval > 1) return interval + " hours ago";
  interval = Math.floor(seconds / 60);
  if (interval > 1) return interval + " minutes ago";
  return Math.floor(seconds) + " seconds ago";
}
</script>
<style>
html,
body {
  height: 100% !important;
  width: 100% !important;
  margin: 0px !important;
}

main.hide-overflow,
.socialcomponent,
.app__videos {
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-snap-container {
  height: 100% !important;
  width: 100% !important;
  overflow-x: hidden !important;
  scroll-snap-type: mandatory;
  scroll-snap-type: y mandatory;
  overscroll-behavior-y: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

main::-webkit-scrollbar,
.app__videos::-webkit-scrollbar,
.socialcomponent::-webkit-scrollbar,
.scroll-snap-container::-webkit-scrollbar {
  display: none;
}

.social-list {
  position: fixed;
  padding: 25px 0 40px 25px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  color: #ffffffb0;
  will-change: transform;
  background: linear-gradient(to top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 56%,
      rgba(0, 0, 0, 0.65) 100%);
}

.social-list .link {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  margin-right: 13px;
}

.social-list .link.active {
  border-bottom: 4px solid;
  color: #fff !important;
}

.video {
  position: relative;
  background-color: black;
  width: 100%;
  min-height: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  transition: height 0.66s ease-out;
}

.video__player {
  position: absolute;
  object-fit: contain;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.video__player::before {
  will-change: transform;
}

.shopaction {
  display: inline;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  padding: 22.5px 15px 0 0;
}

.shopaction .button {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  border-radius: 20px;
  background-color: #e84c6e;
  color: #fff;
}

.vfooter {
  width: 100%;
  padding: 10px 30% 70px 25px;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 56%,
      rgba(0, 0, 0, 0.65) 100%);
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  position: absolute;
  color: #ffffff;
  bottom: 0;
  z-index: 2;
}

.vfooter a {
  color: #fff;
  border-bottom: 2px solid;
}

.userimage-container {
  padding-bottom: 10px;
}

.userimage {
  border-radius: 50%;
  border: 1.5px solid #ffff;
  width: 38px;
  height: 38px;
  background-image: url(~@/assets/img/user.png);
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  background: url(~@/assets/img/user.png),
    linear-gradient(to right, #8e2de2, #4a00e0);
}

.vfooter p {
  font-family: "Poppins", sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px;
}

.aname {
  font-size: 1.15rem !important;
  font-weight: 900 !important;
  display: block;
  color: #ffffff;
  border-radius: 6px;
}

.posttag {
  font-size: 0.75rem !important;
  display: inline-block;
  background: #ffffff94;
  color: #000;
  padding: 3px 8px 2px 8px;
  border-radius: 5px;
  margin: 5px 0 15px 0;
}

.posttag.second {
  margin-left: 7px !important;
}

.ratingBox {
  padding-top: 15px;
}

.ratingBox i {
  font-size: 1.3rem;
  padding-right: 5px;
}

.ratingBox i.is-active {
  color: #ffd700;
}

.superlike {
  margin-left: 8px;
}

.vsidebar {
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 15px 80px 0;
  width: 45px;
  color: #ffffff;
  z-index: 2;
}

.vsidebar .fas {
  font-size: 26px;
  color: #ffffff;
  display: block;
}

.vidbtns {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  z-index: 1;
}

.vidbtns i {
  color: #ffff;
  font-size: 45px;
}

.vidanimation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: #ffffffbf;
  z-index: 2;
}

.vidanimation .holder {
  padding: 0 60px;
  text-align: center;
}

.vidanimation p.title {
  font-size: 3rem;
}

.vidanimation p {
  color: #000000;
}

.vsidebar p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 18px;
  color: #ffffff;
}

.vsidebar .fa-share,
.vsidebar .fa-plus-circle {
  margin-bottom: 30px;
}

.liked {
  color: #ff0000 !important;
}

.vsidebar .fa-compact-disc {
  margin-bottom: 40px;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.profile-popup .modal-background {
  background-color: rgba(10, 10, 10, 0.86) !important;
}

.profile-popup .profile {
  border-radius: 25px;
}

.profile {
  background: -webkit-linear-gradient(to right, #4a00e0, #8e2de2);
  background: linear-gradient(to right, #4a00e0, #8e2de2);
  border-radius: 0px 0px 25px 25px;
  padding: 30px 15px 40px 15px;
}

.profile-popup .modal-card,
.profile-popup .modal-content {
  width: 90%;
}

.modal .button {
  margin: 0 5px;
}

.modal .button.following {
  background: #5a3e90;
  color: #ffffff;
}

.modal .button.follow {
  background: #ffffff;
  color: #5a3e90;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

#comment-box {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 102;
  height: 100%;
  background: linear-gradient(45deg, black, transparent);
  overflow-y: hidden;
}

#comment-box .newcomment {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1.25rem;
  z-index: 1;
  background: #ffffff;
  margin-bottom: 47px;
}

#comment-box .column.is-3 {
  font-size: 25px;
  text-align: right;
}

#commentBtn {
  border: 3px solid #6f00ff;
  color: #6f00ff;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 100%;
  height: 100%;
}

#comment-box .box {
  min-height: 200px;
  max-height: 80%;
  background: #ffff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 25px 25px 0 0;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

#comments .userimage {
  width: 48px !important;
  height: 48px !important;
}

#comment-box h2 {
  font-weight: 600;
}

.usercomment h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  font-size: 0.85rem;
}

.usercomment a {
  color: #6f00ff;
  border-bottom: 2px solid;
}

#comment-box h5 {
  font-size: 20px;
}

#comments {
  height: 400px;
  overflow: scroll;
  padding-bottom: 120px;
}

#comments .columns.is-mobile {
  max-width: 100%;
}

.userimage {
  border-radius: 50%;
  border: 1.5px solid #ffff;
  width: 38px;
  height: 38px;
  background-image: url(~@/assets/img/user.png);
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.usercomment h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1rem !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s cubic-bezier(0.3, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to {
  transform: translateX(15px);
  opacity: 0;
}

.holder .title,
.holder .subtitle {
  color: #daa520;
}

@media only screen and (min-width: 600px) {
  .vfooter {
    padding: 10px 30% 100px 25px;
  }

  .vsidebar {
    margin: 0 15px 100px 0 !important;
  }

  .box {
    display: block;
    padding: 1.25rem 1.25rem 120px 1.25rem;
  }

  #comment-box .newcomment {
    margin-bottom: 100px;
  }
}
</style>