<template>
  <div class="homescreen" :style="color">
    <!-- intro -->
    <div class="homeprofile">
      <div class="homeheader columns is-mobile">
        <div class="column is-3">
          <a @click="router('/profile')">
            <div
              v-bind:style="{ 'background-image': image }"
              v-if="$store.state.profile.picture === ''"
              class="homeprofileimg"
            ></div>
            <div
              :style="$store.state.profile.picture"
              v-else
              class="homeprofileimg"
            ></div>
          </a>
        </div>
        <div class="column is-7">
          <h6 class="home title" :style="textColor">
            Hi
            <span>
              <span
                v-if="$store.state.profile.fname === ''"
                class="preload"
                style="width: 100px"
              ></span>
              <span v-else>{{ $store.state.profile.fname }}</span>
            </span>
            <img src="../assets/img/hi.png" width="28px" />
          </h6>
        </div>
        <div class="column is-2">
          <a @click="router('/search')" class="searchIcon">
            <i class="fas fa-search"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- /intro -->
    <div class="section">
      <div class="columns">
        <div v-for="card in cards" :key="card.id" class="column">
          <div class="card schedule">
            <div class="card-content">
              <h5>Indian Robotics Championship</h5>
              <hr />
              <span v-if="card.sc" class="tag is-dark">Your Schedule:</span>
              <h6 v-if="card.sc" class="timing">
                {{ card.sc.date }}
                <br />
                <span>{{ card.sc.time }}</span>
              </h6>
              <hr />
              <div class="buttons">
                <div
                  class="button is-black"
                  v-if="!card.sc.over"
                  @click="showInApp(card.sc.joinLink)"
                >
                  Join Link
                </div>
                <div
                  class="button is-black"
                  @click="router('/order/' + card.orderId)"
                >
                  Track Kit
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column" style="padding-top: 50px"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { db } from "@/main.js";
import { Browser } from "@capacitor/browser";
// const { App } = Plugins;
// import VLazyImage from "v-lazy-image";
export default {
  name: "submission",
  //   components: {
  //     VLazyImage,
  //   },
  data() {
    return {
      image: "url(" + require("@/assets/img/user.png") + ")",
      message: "What would you like to learn today?",
      challengetag: "#Featured",
      cid: "",
      banner: "",
      coins: 0,
      cards: [],
      comingsoon: false,
      color: "",
      textColor: "",
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    async showInApp(link) {
      await Browser.open({ url: link });
    },
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
  },
  beforeMount() {
    this.getColor();
    auth.onAuthStateChanged((user) => {
      db.collection("config")
        .doc("irc")
        .collection("submissions")
        .where("uid", "==", user.uid)
        .get()
        .then((snap) => {
          if (!snap.empty) {
            var obj = snap.docs[0].data();
            obj.id = snap.docs[0].id;
            if (obj.schedule) {
              db.collection("config")
                .doc("irc")
                .collection("schedule")
                .doc(obj.schedule)
                .get()
                .then((schedule) => {
                  obj.sc = {
                    date: schedule.data().title,
                    time: schedule.data().subtitle,
                    over: schedule.data().end < new Date().getTime(),
                    joinLink: schedule.data().joinLink,
                  };
                  this.cards.push(obj);
                  console.log(obj);
                });
            } else {
              obj.sc = false;
              this.cards.push(obj);
            }
          }
        });
    });
  },
  mounted() {},
};
</script>
<style scoped>
.homescreen {
  height: 80%;
}
.schedule {
  border-radius: 15px;
}
.schedule h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 1.25rem;
}
.schedule span.tag {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 15px;
}
.schedule .timing {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  padding-left: 5px;
}

.schedule .timing span {
  font-weight: 600;
  font-size: 1rem;
}
.schedule .buttons .button {
  font-weight: 700;
  font-size: 1rem;
}
</style>