<template>
  <div class="homescreen" :style="color">
    <!-- intro -->
    <div class="homeprofile">
      <div class="homeheader columns is-mobile">
        <div class="column is-3">
          <a @click="router('/profile')">
            <div
              v-bind:style="{ 'background-image': image }"
              v-if="$store.state.profile.picture === ''"
              class="homeprofileimg"
            ></div>
            <div
              :style="$store.state.profile.picture"
              v-else
              class="homeprofileimg"
            ></div>
          </a>
        </div>
        <div class="column is-7">
          <h6 class="home title" :style="textColor">
            Hi
            <span>
              <span
                v-if="$store.state.profile.fname === ''"
                class="preload"
                style="width: 100px"
              ></span>
              <span v-else>{{ $store.state.profile.fname }}</span>
            </span>
            <img src="../assets/img/hi.png" width="28px" />
          </h6>
        </div>
        <div class="column is-2">
          <a @click="router('/search')" class="searchIcon">
            <i class="fas fa-search"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- /intro -->
    <div class="section">
      <div class="columns">
        <div v-for="card in cards" :key="card.id" class="column">
          <div class="card schedule">
            <div class="card-content">
              <h5>{{ card.class }}</h5>
              <hr />
              <span class="tag is-dark">Your Schedule:</span>
              <h6 class="timing">
                {{ card.date }}
                <br />
                <span>{{ card.time }}</span>
              </h6>
              <hr />
              <div class="buttons">
                <div
                  class="button is-black"
                  v-if="!card.over"
                  @click="showInApp(card.joinLink)"
                >
                  Join Link
                </div>
                <div
                  class="button is-black"
                  @click="router('/order/' + card.orderId)"
                >
                  Payment Details
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column" style="padding-top: 50px"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { db } from "@/main.js";
import { Browser } from "@capacitor/browser";
// const { App } = Plugins;
// import VLazyImage from "v-lazy-image";
export default {
  name: "schedule",
  //   components: {
  //     VLazyImage,
  //   },
  data() {
    return {
      image: "url(" + require("@/assets/img/user.png") + ")",
      message: "What would you like to learn today?",
      challengetag: "#Featured",
      cid: "",
      banner: "",
      coins: 0,
      cards: [],
      comingsoon: false,
      color: "",
      textColor: "",
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    async showInApp(link) {
      await Browser.open({ url: link });
    },
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
  },
  beforeMount() {
    this.getColor();
    auth.onAuthStateChanged((user) => {
      db.collection("class-orders")
        .where("uid", "==", user.uid)
        .where("paid", "==", true)
        .orderBy("updated", "desc")
        .get()
        .then((orders) => {
          orders.forEach((order) => {
            console.log(order.data());
            db.collection("classes")
              .doc(order.data().class)
              .get()
              .then((cls) => {
                db.collection("classes")
                  .doc(cls.id)
                  .collection("schedule")
                  .doc(order.data().schedule)
                  .get()
                  .then((schedule) => {
                    this.cards.push({
                      id: order.id,
                      class: cls.data().name,
                      date: schedule.data().title,
                      time: schedule.data().subtitle,
                      over: schedule.data().end < new Date().getTime(),
                      joinLink: schedule.data().joinLink,
                      orderId: order.data().orderId,
                    });
                  });
              });
          });
        });
    });
  },
  mounted() {},
};
</script>
<style scoped>
.homescreen {
  height: 80%;
}
.schedule {
  border-radius: 15px;
}
.schedule h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 1.25rem;
}
.schedule span.tag {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 15px;
}
.schedule .timing {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  padding-left: 5px;
}

.schedule .timing span {
  font-weight: 600;
  font-size: 1rem;
}
.schedule .buttons .button {
  font-weight: 700;
  font-size: 1rem;
}
</style>