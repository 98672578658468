<template>
  <div class="homescreen" :style="color">
    <div class="homeprofile">
      <div class="homeheader columns is-mobile">
        <div class="column is-3">
          <a @click="$router.push('/profile')">
            <div
              v-bind:style="{ 'background-image': image }"
              v-if="profile.picture === ''"
              class="homeprofileimg"
            ></div>
            <div :style="profile.picture" v-else class="homeprofileimg"></div>
          </a>
        </div>
        <div class="column is-7">
          <h6 class="home title" :style="textColor">
            Hi
            <span>
              <span
                v-if="profile.fname === ''"
                class="preload"
                style="width: 100px"
              ></span>
              <span v-else>{{ profile.fname }}</span>
            </span>
            <img src="../assets/img/hi.png" width="28px" />
          </h6>
        </div>
        <div class="column is-2">
          <a @click="$router.push('/search')" class="searchIcon">
            <i class="fas fa-search"></i>
          </a>
        </div>
      </div>
      <div class="columns">
        <div class="column has-text-centered">
          <h1 class="title is-5 yourtasks">Your Tasks</h1>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <flickity ref="flickity" :options="flickityOptions">
            <!-- project -->
            <div class="cardholder">
              <div class="card">
                <video-player
                  v-if="vid1.loaded"
                  class="vjs-custom-skin uploadVid"
                  ref="videoPlayer"
                  :options="vid1"
                  :playsinline="true"
                ></video-player>
                <div class="cardtype">
                  <span>
                    Robotics: <b>{{ project.name }}</b>
                  </span>
                </div>
                <!-- <img :src="project.featuredImg" alt="Placeholder image" /> -->
                <div class="card-content">
                  <div class="content">
                    {{ project.description }}
                  </div>
                </div>
                <div class="cardbtns">
                  <button
                    class="btn btn-theme"
                    @click="$router.push('/project/' + project.id + '/2')"
                  >
                    Start
                  </button>
                  <a
                    class="link"
                    @click="setreminder(project.name, project.description)"
                  >
                    <i class="fas fa-bell"></i>
                    <span>Set a reminder</span>
                  </a>
                </div>
                <div class="pagination">
                  <span>1 / 5</span>
                </div>
              </div>
            </div>
            <!-- /project -->
            <!-- origami -->
            <div class="cardholder">
              <div class="card">
                <video-player
                  v-if="vid2.loaded"
                  class="vjs-custom-skin uploadVid"
                  ref="videoPlayer"
                  :options="vid2"
                  :playsinline="true"
                ></video-player>
                <div class="cardtype">
                  <span>
                    Origami: <b>{{ origami.name }}</b>
                  </span>
                </div>
                <div class="card-content">
                  <div class="content">
                    {{ origami.description }}
                  </div>
                </div>
                <div class="cardbtns">
                  <button
                    class="btn btn-theme"
                    @click="$router.push('/project/' + origami.id + '/2')"
                  >
                    Start
                  </button>
                  <a
                    class="link"
                    @click="setreminder(origami.name, origami.description)"
                  >
                    <i class="fas fa-bell"></i>
                    <span>Set a reminder</span>
                  </a>
                </div>
                <div class="pagination">
                  <span>2 / 5</span>
                </div>
              </div>
            </div>
            <!-- /origami -->
            <!-- challenge -->
            <div class="cardholder">
              <div class="card">
                <img :src="challenge.featuredImg" alt="" />
                <div class="cardtype">
                  <span>
                    <b>{{ challenge.name }}</b>
                  </span>
                </div>
                <div class="card-content">
                  <div class="content">
                    {{ challenge.description }}
                  </div>
                </div>
                <div class="cardbtns">
                  <button
                    class="btn btn-theme"
                    @click="$router.push('/challenge/' + challenge.id)"
                  >
                    Start
                  </button>
                  <a
                    class="link"
                    @click="setreminder(challenge.name, challenge.description)"
                  >
                    <i class="fas fa-bell"></i>
                    <span>Set a reminder</span>
                  </a>
                </div>
                <div class="pagination">
                  <span>3 / 5</span>
                </div>
              </div>
            </div>
            <!-- /challenge -->
            <!-- votw -->
            <div class="cardholder">
              <div class="card">
                <video-player
                  v-if="vid3.loaded"
                  class="vjs-custom-skin uploadVid"
                  ref="videoPlayer"
                  :options="vid3"
                  :playsinline="true"
                ></video-player>
                <div class="cardtype">
                  <span> Take This Quiz </span>
                </div>
                <div class="card-content">
                  <div class="content">
                    <h3 class="subtitle is-5">
                      {{ votw.text }}
                    </h3>
                    <div v-if="!quizanswered">
                      <div class="columns is-mobile">
                        <div class="column">
                          <a
                            class="button is-small is-fullwidth"
                            @click="useranswer = votw.options[0]"
                            :class="{
                              'is-right': votw.answer == votw.options[0],
                              'is-wrong': votw.answer != votw.options[0],
                              'is-selected': useranswer == votw.options[0],
                            }"
                          >
                            {{ votw.options[0] }}
                          </a>
                        </div>
                        <div class="column">
                          <a
                            class="button is-small is-fullwidth"
                            @click="useranswer = votw.options[1]"
                            :class="{
                              'is-right': votw.answer == votw.options[1],
                              'is-wrong': votw.answer != votw.options[1],
                              'is-selected': useranswer == votw.options[1],
                            }"
                          >
                            {{ votw.options[1] }}
                          </a>
                        </div>
                      </div>
                      <div class="columns is-mobile">
                        <div class="column">
                          <a
                            class="button is-small is-fullwidth"
                            @click="useranswer = votw.options[2]"
                            :class="{
                              'is-right': votw.answer == votw.options[2],
                              'is-wrong': votw.answer != votw.options[2],
                              'is-selected': useranswer == votw.options[2],
                            }"
                          >
                            {{ votw.options[2] }}
                          </a>
                        </div>
                        <div class="column">
                          <a
                            class="button is-small is-fullwidth"
                            @click="useranswer = votw.options[3]"
                            :class="{
                              'is-right': votw.answer == votw.options[3],
                              'is-wrong': votw.answer != votw.options[3],
                              'is-selected': useranswer == votw.options[3],
                            }"
                          >
                            {{ votw.options[3] }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p v-if="useranswer == votw.answer">
                        Congratulations, you got it right!
                      </p>
                      <p v-else>You are wrong!</p>
                    </div>
                  </div>
                </div>
                <!-- <div class="cardbtns">
                  <button
                    class="btn btn-theme"
                    @click="submitQuiz"
                    v-if="!quizanswered"
                  >
                    Submit
                  </button>
                </div> -->
                <div class="pagination">
                  <span>4 / 5</span>
                </div>
              </div>
            </div>
            <!-- /votw -->
            <!-- session -->
            <div class="cardholder">
              <div class="card">
                <img :src="session.image" alt="Live Session" />
                <div class="cardtype has-text-centered">
                  <span>
                    Live Workshop on {{ new Date(session.date).getDate() }}
                    {{ month[new Date(session.date).getMonth()] }}
                  </span>
                </div>
                <div class="card-content">
                  <div class="content">
                    {{ session.description }}
                  </div>
                </div>
                <div class="cardbtns">
                  <button
                    class="btn btn-theme"
                    :disabled="new Date().getTime > session.date"
                  >
                    Attend
                  </button>
                  <a
                    class="link"
                    :href="session.participantLink"
                    target="blank"
                    v-if="new Date().getTime < session.date"
                  >
                    <i class="fas fa-bell"></i>
                    <span>Join as Participant</span>
                  </a>
                </div>
                <div class="pagination">
                  <span>5 / 5</span>
                </div>
              </div>
            </div>
            <!-- /session -->
          </flickity>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { auth } from "@/main.js";
// import { functions } from "@/main.js";
import { db } from "@/main.js";
// import { functions } from "@/main.js";
// import { Browser } from "@capacitor/browser";
import { mapGetters } from "vuex";
import Flickity from "vue-flickity";
import { videoPlayer } from "vue-video-player";
import * as Add2Calendar from "add2calendar";

export default {
  name: "subscription_tasks",
  data() {
    return {
      id: this.$route.params.id,
      color: "",
      textColor: "",
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
        autoPlay: false,
        // any options from Flickity can be used
      },
      project: {},
      origami: {},
      challenge: {},
      votw: {},
      useranswer: "",
      quizanswered: false,
      live: {},
      session: {},
      week: "",
      vid1: {
        height: "100%",
        width: "100%",
        autoplay: false,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
      },
      vid2: {
        height: "100%",
        width: "100%",
        autoplay: false,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
      },
      vid3: {
        height: "100%",
        width: "100%",
        autoplay: false,
        muted: false,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
      },
            month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

    };
  },
  components: {
    Flickity,
    videoPlayer,
  },
  methods: {
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 65%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
    submitQuiz() {
      this.quizanswered = true;
      db.collection("subscriptionweek")
        .doc(this.week)
        .collection("quiz")
        .doc(auth.currentUser.uid)
        .set({
          answer: this.useranswer,
          user: auth.currentUser.uid,
          timestamp: Date.now(),
        });
    },
    setreminder(title, desc) {
      var date = new Date();
      date.setDate(date.getDate() + 1);
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var month = months[date.getMonth()];
      console.log(
        month + " " + date.getDate() + ", " + date.getFullYear() + " 10:30"
      );
      var singleEventArgs = {
        title: title,
        start:
          month + " " + date.getDate() + ", " + date.getFullYear() + " 10:30",
        end:
          month + " " + date.getDate() + ", " + date.getFullYear() + " 10:30",
        location: "https://app.witblox.com/subscribed",
        description: desc,
        isAllDay: true,
      };
      var singleEvent = new Add2Calendar(singleEventArgs);
      window.open(singleEvent.getGoogleUrl(), "_blank");
      //   console.log(singleEvent.getICalUrl());
    },
  },
  beforeMount() {
    this.getColor();
    console.log(this.id);
    db.collection("subscriptionorders")
      .doc(this.id)
      .get()
      .then((order) => {
        db.collection("subscriptionbox")
          .doc(order.data().boxId)
          .get()
          .then((box) => {
            console.log("box data:", box.id, box.data());
            //fetch project
            db.collection("projects")
              .doc(box.data().projects[0])
              .get()
              .then((project) => {
                this.project = project.data();
                this.project.id = project.id;
                this.vid1.poster = project.data().featuredImg;
                this.vid1.sources = [
                  {
                    src: project.data().trailer,
                    type: "video/mp4",
                  },
                ];
                setTimeout(() => {
                  this.vid1.loaded = true;
                }, 100);
              });
            //fetch week
            var dispatchDate = order.data().dispatchDate;
            db.collection("subscriptionweek")
              .where("start", ">", dispatchDate)
              .orderBy("start", "asc")
              .limit(1)
              .get()
              .then((week) => {
                this.week = week.docs[0].id;
                console.log("week data:", week.docs[0].id, week.docs[0].data());
                //live session
                this.session = week.docs[0].data().session;
                //votw
                this.votw = week.docs[0].data().votw;
                this.useranswer = "";
                db.collection("subscriptionweek")
                  .doc(this.week)
                  .collection("quiz")
                  .doc(auth.currentUser.uid)
                  .get()
                  .then((quiz) => {
                    if (quiz.exists) {
                      this.quizanswered = true;
                      this.useranswer = quiz.data().answer;
                    }
                  });
                this.vid3.sources = [
                  {
                    src: week.docs[0].data().votw.video,
                    type: "video/mp4",
                  },
                ];
                setTimeout(() => {
                  this.vid3.loaded = true;
                }, 100);
                //fetch origami
                console.log(week.docs[0].data().origami[0]);
                db.collection("projects")
                  .doc(week.docs[0].data().origami[0])
                  .get()
                  .then((project) => {
                    this.origami = project.data();
                    this.origami.id = project.id;
                    this.vid2.poster = project.data().featuredImg;
                    this.vid2.sources = [
                      {
                        src: project.data().trailer,
                        type: "video/mp4",
                      },
                    ];
                    setTimeout(() => {
                      this.vid2.loaded = true;
                    }, 100);
                  });
                //fetch challenge
                db.collection("challenges")
                  .doc(week.docs[0].data().challenge.id)
                  .get()
                  .then((challenge) => {
                    this.challenge = challenge.data();
                    this.challenge.id = challenge.id;
                  });
              });
          });
      });
  },
  mounted() {},
  computed: {
    ...mapGetters({
      profile: "profile",
    }),
  },
};
</script>
<style scoped>
a.plan {
  display: block;
  padding: 40px;
  border-radius: 20px;
}
a.plan .title {
  color: #fff !important;
}

a.plan .title.is-5 {
  margin-bottom: 10px;
}
.hero {
  padding-bottom: 100px;
}

.plan .circle.selected {
  background: #fdba0d;
}
.plan .circle {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 8px solid #000;
  background: #fff;
  margin: auto;
}
.card {
  border-radius: 0 0 14px 14px;
  box-shadow: none;
  border: 2px solid;
  min-height: 550px;
}
.cardholder {
  width: 100%;
  min-height: 70vh;
  padding: 0 5px;
}

.btn {
  margin-bottom: 10px;
}

a.link {
  text-align: center;
  display: block;
  font-size: 0.8rem;
}
a.link i {
  padding-right: 5px;
}
a.link span {
  border-bottom: 2px solid;
  display: inline-block;
}
.videosection,
.video-container,
.video-player,
.vjs_video_3-dimensions,
.video-js {
  width: 100%;
  height: 200px !important;
}
.flickity-viewport {
  overflow: inherit !important;
}
.card .cardbtns {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
}
.pagination {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: right;
  padding: 20px;
  display: block;
}
.pagination span {
  background: #fff;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 0.8rem;
  font-weight: 800;
}
a.button.is-selected {
  background: #5a3e90;
  color: #fff;
}

.yourtasks {
  display: inline;
  background: #fff;
  padding: 5px 15px;
  border: none !important;
  border-radius: 10px;
}

.is-selected.is-right {
  background: green !important;
  border: green;
}

.is-selected.is-wrong {
  background: red !important;
  border: red;
}
</style>