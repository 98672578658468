<template>
  <div class="hero">
    <div class="hero-body" style="padding: 3rem 1.5rem 6rem 1.5rem">
      <h1 class="title has-text-centered">Search</h1>
      <div class="card depth" style="max-width: 700px; margin: auto">
        <div class="card-content">
          <ais-instant-search
            :search-client="searchClient"
            index-name="projects"
          >
            <!-- <ais-search-box /> -->
            <app-debounced-search-box :delay="200" />

            <ais-state-results>
              <template v-slot="{ results: { hits, query } }">
                <div v-if="!query">
                  <hr />
                  <h2 class="subtitle">Top Searches</h2>
                  <div class="tags are-medium">
                    <span
                      v-for="item in hits"
                      :key="item.objectID"
                      class="tag"
                      @click="$router.push('/project/' + item.objectID)"
                    >
                      <i class="fas fa-bolt"></i>
                      <span>
                        {{ item.name }}
                      </span>
                    </span>
                  </div>
                  <!-- <h2 v-if="hits.length > 2" class="subtitle">
                    Recommended For You
                  </h2>
                  <flickity
                    v-if="hits.length > 2"
                    ref="flickity"
                    :options="flickityOptions"
                    style="padding-bottom: 10px"
                  >
                    <img
                      v-for="item in hits"
                      :key="item.objectID"
                      style="width: 55%"
                      :src="item.featuredImg"
                    />
                  </flickity> -->
                  <h2 class="subtitle">Trending Projects</h2>
                  <div class="columns">
                    <div class="column userstories">
                      <div
                        class="storycontainer"
                        v-for="post in $store.state.trending"
                        :key="post.id"
                        @click="$router.push('social/' + post.id)"
                      >
                        <div :style="post.thumbnail" class="userphoto"></div>
                        <p>{{ post.author }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr v-if="hits.length > 0 && query" />
                <h5 v-if="hits.length > 0 && query" class="subtitle">
                  Projects
                </h5>
                <div v-if="hits.length > 0 && query" class="results products">
                  <div
                    v-for="item in hits"
                    :key="item.objectID"
                    class="columns is-mobile"
                    @click="$router.push('/project/' + item.objectID)"
                  >
                    <div class="column is-4">
                      <img :src="item.featuredImg" alt="" />
                    </div>
                    <div class="column">
                      <h3>{{ item.name }}</h3>
                    </div>
                  </div>
                </div>
              </template>
            </ais-state-results>
            <ais-index index-name="products">
              <ais-state-results>
                <template v-slot="{ results: { hits, query } }">
                  <hr v-if="hits.length > 0 && query" />
                  <h5 v-if="hits.length > 0 && query" class="subtitle">
                    Products
                  </h5>
                  <div v-if="hits.length > 0 && query" class="results products">
                    <div
                      v-for="item in hits"
                      :key="item.objectID"
                      class="columns is-mobile"
                      @click="$router.push('/product/' + item.objectID)"
                    >
                      <div class="column is-4">
                        <img :src="item.imgs[0]" alt="" />
                      </div>
                      <div class="column">
                        <h3>{{ item.name }}</h3>
                      </div>
                    </div>
                  </div>
                </template>
              </ais-state-results>
            </ais-index>
            <ais-index index-name="users">
              <ais-state-results>
                <template v-slot="{ results: { hits, query } }">
                  <hr v-if="hits.length > 0 && query" />
                  <h5 v-if="hits.length > 0 && query" class="subtitle">
                    Makers
                  </h5>
                  <div v-if="hits.length > 0 && query" class="results users">
                    <div
                      v-for="item in hits"
                      :key="item.objectID"
                      class="columns is-mobile"
                      @click="$router.push('/u/' + item.objectID)"
                    >
                      <div class="column is-3 has-text-centered">
                        <div
                          :style="
                            'background: url(' +
                            item.picture +
                            '),linear-gradient(to right, #8e2de2, #4a00e0)'
                          "
                          class="userimg"
                        ></div>
                      </div>
                      <div class="column">
                        <h3 v-if="item.fname">
                          {{ item.fname + " " + item.lname }}
                        </h3>
                        <h3 v-else>WitBlox Maker</h3>
                      </div>
                    </div>
                  </div>
                </template>
              </ais-state-results>
            </ais-index>
            <ais-index index-name="social">
              <ais-state-results>
                <template v-slot="{ results: { hits, query } }">
                  <hr v-if="hits.length > 0 && query" />
                  <h5 v-if="hits.length > 0 && query" class="subtitle">
                    Social
                  </h5>
                  <div v-if="hits.length > 0 && query" class="results">
                    <div
                      v-for="item in hits"
                      :key="item.objectID"
                      class="columns is-mobile"
                      @click="$router.push('/social/' + item.objectID)"
                    >
                      <div class="column is-3">
                        <img :src="item.thumbnail" alt="" />
                      </div>
                      <div class="column">
                        <p class="caption">
                          {{ item.caption }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </ais-state-results>
            </ais-index>
          </ais-instant-search>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { auth } from "@/main.js";
import { db } from "@/main.js";
import { firestore } from "@/main.js";
// import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite-min.css";
// import { functions } from "@/main.js";
// import { db } from "@/main.js";
// import Flickity from "vue-flickity";
//
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import AppDebouncedSearchBox from "./debouncedSearchBox.vue";

export default {
  name: "search",
  components: {
    AppDebouncedSearchBox,
    // Flickity,
  },
  data() {
    return {
      searchClient: null,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: false,
      },
    };
  },
  methods: {
    processUser(obj) {
      db.collection("batchrun")
        .doc(obj.uid)
        .set(
          {
            count: firestore.FieldValue.increment(1),
            value: firestore.FieldValue.increment(obj.total),
          },
          { merge: true }
        );
    },
  },
  beforeMount() {
    // console.log(this.typesenseInstantsearchAdapter);

    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: "witblox@123",
        nodes: [{ host: "search.witblox.com", port: "443", protocol: "https" }],
      },
      additionalSearchParameters: { numTypos: 3 },
      collectionSpecificSearchParameters: {
        projects: { query_by: "name,description" },
        orders: { query_by: "title" },
        users: { query_by: "fname,lname" },
        social: { query_by: "caption" },
        products: { query_by: "name" },
      },
    });

    const searchClient = typesenseInstantsearchAdapter.searchClient;
    this.searchClient = searchClient;

    auth.onAuthStateChanged((user) => {
      console.log(user.uid);
    });
    // db.collection("batchrun")
    //   .where("count", ">", 10)
    //   .get()
    //   .then((orders) => {
    //     orders.forEach((order) => {
    //       db.collection("batchrun")
    //         .doc("config")
    //         .update({
    //           val11: firestore.FieldValue.increment(order.data().value),
    //         });
    //     });
    //   });
  },
};
</script>
<style scoped>

.tag span {
  padding-left: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
}

.flickity-slider img {
  padding: 0 4px;
  background: #0000;
  border-radius: 10px;
}

hr {
  background-color: rgb(236 236 236);
  margin: 1rem 0;
}

@media only screen and (min-width: 600px) {
  .column.userstories {
    max-width: 450px;
    margin: 0;
  }
}
</style>
<style>
input.ais-SearchBox-input {
  font-family: "Poppins", sans-serif !important;
  font-weight: 800 !important;
  width: 100%;
  padding: 0.5rem;
}
.results .columns {
  border-radius: 10px;
  margin: 0px 0px;
  margin-bottom: 10px;
  background: #f0f0f0;
}

.results .columns .column {
  margin: auto;
}

.results.products h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  padding-right: 10px;
}

.results.products img {
  border-radius: 8px;
  border: 0.5px solid rgb(39, 39, 39);
}

.results.users img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 0.5px solid rgb(39, 39, 39);
}

.results h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.userimg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #ffff;
  display: inline-block;
  background-image: url(~@/assets/img/user.png);
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.results p {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  word-break: break-all;
}
</style>