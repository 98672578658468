<template>
    <div class="hero">
        <div class="hero-body" style="padding: 3rem 1.5rem 6rem 1.5rem">
            <h1 class="title has-text-centered">Activity</h1>
            <nav class="pagination is-centered notification-filter" role="navigation" aria-label="pagination">
                <ul class="pagination-list">
                    <li>
                        <a @click="screen = 0" class="pagination-link"
                            v-bind:class="{ 'is-current': screen === 0 }">Unread</a>
                    </li>
                    <li>
                        <a @click="screen = 1" class="pagination-link"
                            v-bind:class="{ 'is-current': screen === 1 }">This Week</a>
                    </li>
                    <li>
                        <a @click="screen = 2" class="pagination-link"
                            v-bind:class="{ 'is-current': screen === 2 }">This Month</a>
                    </li>
                </ul>
            </nav>
            <div v-if="screen === 0" class="card depth">
                <div v-if="today.length != 0" class="card-content">
                    <div v-for="notif in today" :key="notif.id" class="columns is-mobile notif"
                        v-bind:class="{ rankingnotif: notif.ranking, seen: notif.seen }" v-observe-visibility="
    (isVisible, entry) =>
        visibilityChanged(isVisible, entry, notif.id, notif.seen)
">
                        <div class="column is-narrow">
                            <div @click="$router.push('/u/' + notif.user)" :style="notif.img" class="userimg"></div>
                        </div>
                        <div v-if="notif.object.action == 'reward'" class="column">
                            <p @click="openReward(notif.object, notif.id)">
                                {{ notif.text }}
                            </p>
                            <p class="has-text-right">
                                <span class="date">{{ notif.created }}</span>
                            </p>
                        </div>
                        <div v-else class="column">
                            <p @click="$router.push(notif.action)">{{ notif.text }}</p>
                            <p class="has-text-right">
                                <span class="date">{{ notif.created }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div v-else class="card-content has-text-centered">
                    <img style="padding-top:30%;width:150px;" src="@/assets/img/file-tray-full-outline.svg" alt />
                    <h3 style="padding-bottom: 80px" class="subtitle">
                        No New Notifications
                    </h3>
                </div>
            </div>
            <div v-if="screen === 1" class="card depth">
                <div v-if="thisweek.length != 0" class="card-content">
                    <div v-for="notif in thisweek" :key="notif.id" class="columns is-mobile notif"
                        v-bind:class="{ rankingnotif: notif.ranking, seen: notif.seen }" v-observe-visibility="
    (isVisible, entry) =>
        visibilityChanged(isVisible, entry, notif.id, notif.seen)
">
                        <div class="column is-narrow">
                            <div @click="$router.push('/u/' + notif.user)" :style="notif.img" class="userimg"></div>
                        </div>
                        <div v-if="notif.object.action == 'reward'" class="column">
                            <p @click="openReward(notif.object, notif.id)">
                                {{ notif.text }}
                            </p>
                            <p class="has-text-right">
                                <span class="date">{{ notif.created }}</span>
                            </p>
                        </div>
                        <div v-else class="column">
                            <p @click="$router.push(notif.action)">{{ notif.text }}</p>
                            <p class="has-text-right">
                                <span class="date">{{ notif.created }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div v-else class="card-content has-text-centered">
                    <img style="padding-top:30%;width:150px;" src="@/assets/img/file-tray-full-outline.svg" alt />
                    <h3 style="padding-bottom: 80px" class="subtitle">
                        No New Notifications
                    </h3>
                </div>
            </div>
            <div v-if="screen === 2" class="card depth">
                <div v-if="thismonth.length != 0" class="card-content">
                    <div v-for="notif in thismonth" :key="notif.id" class="columns is-mobile notif"
                        v-bind:class="{ rankingnotif: notif.ranking, seen: notif.seen }" v-observe-visibility="
    (isVisible, entry) =>
        visibilityChanged(isVisible, entry, notif.id, notif.seen)
">
                        <div class="column is-narrow">
                            <div @click="$router.push('/u/' + notif.user)" :style="notif.img" class="userimg"></div>
                        </div>
                        <div v-if="notif.object.action == 'reward'" class="column">
                            <p @click="openReward(notif.object, notif.id)">
                                {{ notif.text }}
                            </p>
                            <p class="has-text-right">
                                <span class="date">{{ notif.created }}</span>
                            </p>
                        </div>
                        <div v-else class="column">
                            <p @click="$router.push(notif.action)">{{ notif.text }}</p>
                            <p class="has-text-right">
                                <span class="date">{{ notif.created }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div v-else class="card-content has-text-centered">
                    <img style="padding-top:30%;width:150px;" src="@/assets/img/file-tray-full-outline.svg" alt />
                    <h3 style="padding-bottom: 80px" class="subtitle">
                        No New Notifications
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { auth } from "@/main.js";
// import { functions } from "@/main.js";
import { db } from "@/main.js";

export default {
    name: "activity",
    data() {
        return {
            today: [],
            thisweek: [],
            thismonth: [],
            screen: 0,
        };
    },
    methods: {
        fetchToday(uid) {
            db.collection("users")
                .doc(uid)
                .collection("notifications")
                .where("seen", "==", false)
                .orderBy("created", "desc")
                .limit(60)
                .onSnapshot((snap) => {
                    if (!snap.empty) {
                        snap.docChanges().forEach((change) => {
                            if (change.type === "added") {
                                var date;
                                if (
                                    (new Date().getTime() - change.doc.data().created) / 36e5 <
                                    1
                                ) {
                                    date =
                                        (
                                            (new Date().getTime() - change.doc.data().created) /
                                            1000 /
                                            60
                                        ).toFixed(0) + " min ago";
                                } else if (
                                    (new Date().getTime() - change.doc.data().created) / 36e5 <
                                    23
                                ) {
                                    date =
                                        (
                                            (new Date().getTime() - change.doc.data().created) /
                                            36e5
                                        ).toFixed(0) + " hrs ago";
                                } else if (
                                    (new Date().getTime() - change.doc.data().created) / 36e5 >
                                    23 &&
                                    (new Date().getTime() - change.doc.data().created) / 36e5 > 1
                                ) {
                                    date = new Date(change.doc.data().created).toDateString();
                                }
                                this.today.push({
                                    id: change.doc.id,
                                    created: date,
                                    img:
                                        "background: url(" +
                                        change.doc.data().img +
                                        "),linear-gradient(to right, #8e2de2, #4a00e0)",
                                    link: change.doc.data().link,
                                    ranking: change.doc.data().ranking,
                                    seen: change.doc.data().seen,
                                    text: change.doc.data().text,
                                    user: change.doc.data().user,
                                    action: this.activityLink(
                                        change.doc.data().link,
                                        change.doc.data().new
                                    ),
                                    object: change.doc.data(),
                                });
                            }
                        });
                    } else this.screen = 1;
                });
        },
        fetchThisWeek(uid) {
            var d = new Date();
            var d2 = new Date();
            d2.setDate(d.getDate() - 7);
            var start = d.getTime();
            var end = d2.getTime();
            db.collection("users")
                .doc(uid)
                .collection("notifications")
                .where("created", "<", start)
                .where("created", ">", end)
                .orderBy("created", "desc")
                .limit(60)
                .onSnapshot((snap) => {
                    if (!snap.empty) {
                        snap.docChanges().forEach((change) => {
                            if (change.type === "added") {
                                var date;
                                if (
                                    (new Date().getTime() - change.doc.data().created) / 36e5 <
                                    1
                                ) {
                                    date =
                                        (
                                            (new Date().getTime() - change.doc.data().created) /
                                            1000 /
                                            60
                                        ).toFixed(0) + " min ago";
                                } else if (
                                    (new Date().getTime() - change.doc.data().created) / 36e5 <
                                    23
                                ) {
                                    date =
                                        (
                                            (new Date().getTime() - change.doc.data().created) /
                                            36e5
                                        ).toFixed(0) + " hrs ago";
                                } else if (
                                    (new Date().getTime() - change.doc.data().created) / 36e5 >
                                    23 &&
                                    (new Date().getTime() - change.doc.data().created) / 36e5 > 1
                                ) {
                                    date = new Date(change.doc.data().created).toDateString();
                                }
                                this.thisweek.push({
                                    id: change.doc.id,
                                    created: date,
                                    img:
                                        "background: url(" +
                                        change.doc.data().img +
                                        "),linear-gradient(to right, #8e2de2, #4a00e0)",
                                    link: change.doc.data().link,
                                    ranking: change.doc.data().ranking,
                                    seen: change.doc.data().seen,
                                    text: change.doc.data().text,
                                    user: change.doc.data().user,
                                    action: this.activityLink(
                                        change.doc.data().link,
                                        change.doc.data().new
                                    ),
                                    object: change.doc.data(),
                                });
                            }
                        });
                    } else this.screen = 2;
                });
        },
        fetchThisMonth(uid) {
            var d = new Date();
            d.setDate(d.getDate() - 7);
            var d2 = new Date();
            d2.setDate(d.getDate() - 28);
            var start = d.getTime();
            var end = d2.getTime();
            db.collection("users")
                .doc(uid)
                .collection("notifications")
                .where("created", "<", start)
                .where("created", ">", end)
                .orderBy("created", "desc")
                .limit(60)
                .onSnapshot((snap) => {
                    if (!snap.empty) {
                        snap.docChanges().forEach((change) => {
                            if (change.type === "added") {
                                var date;
                                if (
                                    (new Date().getTime() - change.doc.data().created) / 36e5 <
                                    1
                                ) {
                                    date =
                                        (
                                            (new Date().getTime() - change.doc.data().created) /
                                            1000 /
                                            60
                                        ).toFixed(0) + " min ago";
                                } else if (
                                    (new Date().getTime() - change.doc.data().created) / 36e5 <
                                    23
                                ) {
                                    date =
                                        (
                                            (new Date().getTime() - change.doc.data().created) /
                                            36e5
                                        ).toFixed(0) + " hrs ago";
                                } else if (
                                    (new Date().getTime() - change.doc.data().created) / 36e5 >
                                    23 &&
                                    (new Date().getTime() - change.doc.data().created) / 36e5 > 1
                                ) {
                                    date = new Date(change.doc.data().created).toDateString();
                                }
                                this.thismonth.push({
                                    id: change.doc.id,
                                    created: date,
                                    img:
                                        "background: url(" +
                                        change.doc.data().img +
                                        "),linear-gradient(to right, #8e2de2, #4a00e0)",
                                    link: change.doc.data().link,
                                    ranking: change.doc.data().ranking,
                                    seen: change.doc.data().seen,
                                    text: change.doc.data().text,
                                    user: change.doc.data().user,
                                    action: this.activityLink(
                                        change.doc.data().link,
                                        change.doc.data().new
                                    ),
                                    object: change.doc.data(),
                                });
                            }
                        });
                    }
                });
        },
        visibilityChanged: function (isVisible, entry, id, seen) {
            if (isVisible) {
                if (!seen) {
                    auth.onAuthStateChanged((user) => {
                        db.collection("users")
                            .doc(user.uid)
                            .collection("notifications")
                            .doc(id)
                            .update({ seen: true });
                    });
                }
            }
        },
        activityLink(link, newversion) {
            if (!newversion) {
                if (this.getFilename(link) == "video.html")
                    return "/social/" + this.getParams(link).pid;
                else if (this.getFilename(link) == "profile.html") return "/profile";
                else return "/profile";
            } else return link;
        },
        getFilename(url) {
            //eslint-disable-next-line
            return url.replace(/^.*[\\\/]/, "").split("?")[0];
        },
        getParams(url) {
            var params = {};
            var parser = document.createElement("a");
            parser.href = url;
            var query = parser.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                params[pair[0]] = decodeURIComponent(pair[1]);
            }
            return params;
        },
        openReward(object, id) {
            object.open = true;
            object.id = id;
            this.$store.commit("openRewardBox", object);
            this.$router.push("/social/" + object.rewardObj.postId);
        },
    },
    beforeMount() {
        auth.onAuthStateChanged((user) => {
            console.log(user.uid);
            this.fetchToday(user.uid);
            this.fetchThisWeek(user.uid);
            this.fetchThisMonth(user.uid);
        });
    },
};
</script>
<style>
.depth {
    -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
        0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
        0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.notif.rankingnotif.seen {
    background: #e8e4fd;
}

.notif.rankingnotif {
    background: #c3b4ff;
}

.notif.seen {
    background: #f4f8fbab;
}

.notif {
    background: #a5d7fd;
    border-radius: 10px;
    border-bottom: 10px solid #ffffff;
}

.notif .date {
    font-weight: 600;
    font-size: 0.8rem;
    padding: 0 8px;
    margin-right: 10px;
    background: #ffffff;
    border-radius: 10px;
    display: inline-block;
    text-align: right;
}

.notif p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.notif .userimg {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    border: 3px solid #ffff;
    display: inline-block;
    background-image: url(~@/assets/img/user.png);
    background-position: center;
    background-size: cover !important;
    background-repeat: no-repeat;
}

.notification-filter a.pagination-link.is-current {
    background: #5041e1 !important;
    color: #ffffff !important;
    border: 3px solid #5041e1;
}

.notification-filter a.pagination-link {
    background: #ffffff !important;
    color: #5041e1 !important;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    border: 3px solid;
    font-size: 0.85em;
}
</style>
<style scoped>
.card.depth {
    max-width: 700px;
    margin: 0 auto;
}
</style>