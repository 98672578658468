<template>
  <!-- carousel -->
  <div class="main">
    <div class="introholder">
      <flickity ref="flickity" :options="flickityOptions" class="carousel">
        <div class="carousel-cell">
          <img src="~@/assets/img/witblox-intro-screens/1.png" />
        </div>
        <div class="carousel-cell">
          <img src="~@/assets/img/witblox-intro-screens/2.png" />
        </div>
        <div class="carousel-cell">
          <img src="~@/assets/img/witblox-intro-screens/3.png" />
        </div>
        <div class="carousel-cell">
          <img src="~@/assets/img/witblox-intro-screens/4.gif" />
        </div>
      </flickity>
    </div>
    <div class="slider-button">
      <a
        @click="$router.push('/login?from=' + from)"
        class="button btn btn-theme"
      >
        Get Started
      </a>
    </div>
  </div>
</template>
<script>
import { SplashScreen } from "@capacitor/splash-screen";
import Flickity from "vue-flickity";
export default {
  name: "intro",
  components: {
    Flickity,
  },
  mounted() {
    SplashScreen.hide();
  },
  data() {
    return {
      from: this.$route.query.from,
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: true,
        autoPlay: true,
        pauseAutoPlayOnHover: false,
        fullscreen: true,
        imagesLoaded: true,
        wrapAround: true,
        // any options from Flickity can be used
      },
    };
  },
};
</script>
<style scoped>
div.main {
  height: 100%;
  background: #8342d7;
  width: 100%;
}
.introholder {
  height: 100%;
  background: #8342d7;
  width: 100%;
  max-width: 400px;
  margin: auto;
}
.slider-button {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 20px;
}
</style>
<style>
.introholder .flickity-page-dots {
  position: absolute !important;
}
</style>