<template>
    <!-- Rewards Section -->
    <div class="box shadow">
        <h2 class="title is-5">Create budget for your project</h2>
        <div class="card">
            <div class="card-content">
                <div class="columns">
                    <div class="column">
                        <div class="autocomplete">
                            <div class="field">
                                <label class="label">Search for items from shop:</label>
                                <div class="control has-icons-left">
                                    <input class="input" v-model="selectedItem" @input="filterItems"
                                        placeholder="Search..." />
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="menu" v-if="selectedItem.length > 2">
                                <ul class="menu-list">
                                    <div class="columns is-mobile" v-for="item in filteredItems.splice(0, 3)" :key="item.id"
                                        @click="fillItemDetails(item)">
                                        <div class="column is-2">
                                            <img v-if="item.imgs" :src="item.imgs[0]" alt="Item Image" class="item-image" />
                                        </div>
                                        <div class="column">
                                            <p>
                                                {{ item.name }}
                                            </p>
                                        </div>
                                        <div class="column is-3 has-text-right">
                                            <p v-if="item.pricing">
                                                &#8377;{{ item.pricing['in-mrp'] }}
                                            </p>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="columns is-mobile">
                    <div class="column">
                        <div class="field">
                            <label class="label">Product Name</label>
                            <div class="control">
                                <input v-model="productNameInput" type="text" class="input" list="productNames"
                                    placeholder="Enter product name" @change="onInputChange">
                                <datalist id="productNames">
                                    <option v-for="product in autocompleteProductNames" :key="product" :value="product">
                                    </option>
                                </datalist>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        <div class="field">
                            <label class="label">Price</label>
                            <div class="control">
                                <input type="number" class="input" placeholder="Enter price" v-model="price">
                            </div>
                        </div>
                    </div>

                    <div class="column is-3">
                        <div class="field">
                            <label class="label">Qty</label>
                            <div class="control">
                                <input v-model.number="quantity" type="number" class="input" placeholder="Enter quantity">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="has-text-centered">
                    <button class="button is-primary is-fullwidth" @click="addProduct">Add to your requirements</button>
                </div>
                <hr>
                <div class="table-container">
                    <h3 class="subtitle">
                        My requirements
                    </h3>
                    <table v-if="itemsFinal.length > 0" class="table items">
                        <thead>
                            <tr>
                                <th></th>
                                <th><abbr title="Image"></abbr></th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Qty</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in itemsFinal" :key="item.id">
                                <th @click="removeProduct(item)">
                                    <i class="fas fa-minus-circle"></i>
                                </th>
                                <th>
                                    <img v-if="item.image" :src="item.image" alt="" class="contributer">
                                    <img v-else src="@/assets/img/user.png" alt="" class="contributer">
                                </th>
                                <td>
                                    <p class="subtitle is-6">
                                        {{ item.name }}
                                    </p>
                                </td>
                                <td>
                                    <p class="is-6">
                                        &#8377;{{ addCommaToNumber(item.price) }}
                                    </p>
                                </td>
                                <td>
                                    <p class="is-6">
                                        {{ item.qty }}
                                    </p>
                                </td>
                                <td>
                                    <p class="subtitle is-6">
                                        &#8377;{{ addCommaToNumber(item.total) }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else class="has-text-centered">No items added yet</p>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="field">
                            <label class="label">Miscellaneous amount</label>
                            <div class="control">
                                <input v-model.number="misc" type="number" class="input" placeholder="Enter an amount">
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <label class="label">Total</label>
                            <div class="control">
                                <input :value="itemsTotal(itemsFinal) + misc" type="number" class="input"
                                    placeholder="Enter an amount" disabled>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { db } from "@/main.js";

export default {
    props: ['campaign'],
    data() {
        return {
            selectedItem: '',
            items: [
            ],
            itemsFinal: [],
            productNameInput: '',
            quantity: 1,
            autocompleteProductNames: [],
            autocompleteProductObj: [],
            price: null,
            total: null,
            image: "url(" + require("@/assets/img/user.png") + ")",
            misc: 100
        };
    },
    computed: {
        filteredItems() {
            return this.items.filter((item) =>
                item.name.toLowerCase().includes(this.selectedItem.toLowerCase())
            );
        },
    },
    methods: {
        itemsTotal(items) {
            var total = 0;
            items.forEach((item) => {
                total += item.total;
            });
            return total;
        },
        filterItems() {
            // Implement any additional filtering logic if needed
        },
        selectItem(item) {
            this.selectedItem = item.name;
            // Handle item selection, e.g., trigger an action with the selected item
        },
        addCommaToNumber(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        onInputChange() {
            console.log("item changed");
            const index = this.autocompleteProductNames.indexOf(this.productNameInput);
            this.price = Number(this.autocompleteProductObj[index].pricing["in-mrp"]);
            console.log(this.autocompleteProductObj[index], this.price);
        },
        addProduct() {
            this.selectedItem = '';
            if (!this.productNameInput || !this.price || !this.quantity) {
                alert('All fields are required');
                return;
            }
            const index = this.autocompleteProductNames.indexOf(this.productNameInput);
            const obj = this.autocompleteProductObj[index];
            var image = "";
            //generate random str
            var id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
            console.log("randomId", id);
            var isShop = false;
            if (obj) {
                id = obj.id;
                image = obj.imgs[0];
                isShop = true;
            }
            this.itemsFinal.push({
                id: id,
                name: this.productNameInput,
                price: this.price,
                qty: this.quantity,
                total: this.price * this.quantity,
                image: image,
                shop: isShop,
            });
            db.collection("crowdfunding").doc(this.campaign).update({
                items: this.itemsFinal
            });
            this.productNameInput = '';
            this.price = 0;
            this.quantity = 1;
        },
        removeProduct(item) {
            this.itemsFinal = this.itemsFinal.filter((i) => i.id != item.id);
            db.collection("crowdfunding").doc(this.campaign).update({
                items: this.itemsFinal
            });
        },
        fillItemDetails(item) {
            this.productNameInput = item.name;
            this.price = item.pricing["in-mrp"];
        }
    },
    beforeMount() {
        db.collection("crowdfunding").doc(this.campaign).get().then((doc) => {
            if (doc.exists) {
                this.itemsFinal = doc.data().items;
            }
        });
        db.collection("shop-products").get().then((querySnapshot) => {
            querySnapshot.forEach((product) => {
                if (this.autocompleteProductNames.includes(product.data().name)) return;
                this.autocompleteProductNames.push(product.data().name);
                var productObj = product.data();
                productObj.id = product.id;
                this.autocompleteProductObj.push(productObj);
                this.items.push(productObj);
            });
        });
    }
};
</script>
  
<style scoped>
/* Add your Bulma-based styles here */
.autocomplete {
    max-width: 300px;
}

.item-image {
    max-width: 24px;
    max-height: 24px;
    margin-right: 5px;
}

td p {
    padding-top: 8px;
}

.table.items {
    min-width: 425px;
}

.table.items img {
    aspect-ratio: 1/1;
    width: 40px;
}

.table tr.is-selected {
    background-color: #ffdd57;
    color: #fff;
}
</style>
  