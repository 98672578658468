<template>
  <section class="section">
    <div class="columns is-mobile">
      <div class="column is-12" style="padding-top: 45px">
        <h3 class="subtitle is-6">Stuck with your project?</h3>
        <h1 class="title">Ask AI</h1>

        <!-- <h1 class="title">
          <span class="has-text-primary">WitBlox</span> Mentors
        </h1> -->
      </div>
      <div class="column is-4"></div>
    </div>
    <div class="card mentorprofile">
      <div class="card-content">
        <div class="columns is-mobile">
          <div class="column is-3">
            <img src="@/assets/img/chadgpt-logo.svg" class="pic" />
            <div class="online">Online</div>
          </div>
          <div class="column">
            <h1 class="title is-4">ChadGPT</h1>
            <p>
              ChadGPT is an AI chatbot that can help you with your projects or
              ideas.
            </p>
          </div>
        </div>
        <a class="button is-fullwidth" href="/chadgpt">Chat with ChadGPT</a>
      </div>
    </div>
    <div class="columns is-mobile">
      <div class="column" style="padding-top: 45px">
        <h1 class="title">Ask human mentors</h1>
      </div>
      <div class="column is-4">
        <img src="@/assets/img/confused.gif" alt="" />
      </div>
    </div>
    <div class="card mentorprofile" v-for="mentor in mentors" :key="mentor.id">
      <div class="card-content">
        <div class="columns is-mobile">
          <div class="column is-3">
            <img :src="mentor.picture" class="pic" />
            <div v-if="mentor.online" class="online">Online</div>
          </div>
          <div class="column">
            <h1 class="title is-4">
              {{ mentor.name }}
              <img
                src="@/assets/img/verified.png"
                style="width: 21px; margin-left: 5px"
              />
            </h1>
            <p>
              {{ mentor.description }}
            </p>
          </div>
        </div>
        <a
          class="button is-fullwidth"
          v-html="mentor.buttonText"
          target="blank"
          @click="openinapp(mentor.buttonLink)"
        ></a>
      </div>
    </div>
  </section>
</template>
<script>
import { db, auth } from "@/main.js";
import { Browser } from "@capacitor/browser";
import { createWebengageEvent } from "../helper";
export default {
  name: "mentors",
  data() {
    return {
      mentors: [],
    };
  },
  methods: {
    async openinapp(link) {
      createWebengageEvent({
        uid: auth?.currentUser?.uid ? auth.currentUser.uid : "anonymous",
        eventName: "Mentor Profile Viewed",
        eventData: {
          uid: auth?.currentUser?.uid ? auth.currentUser.uid : "anonymous",
          email: auth?.currentUser?.email
            ? auth.currentUser.email
            : "anonymous",
          name: auth?.currentUser?.displayName
            ? auth.currentUser.displayName
            : "anonymous",
          mentor: link,
        },
      });
      await Browser.open({ url: link });
    },
  },
  beforeMount() {
    createWebengageEvent({
      uid: auth?.currentUser?.uid ? auth.currentUser.uid : "anonymous",
      eventName: "Mentors Page Viewed",
      eventData: {
        uid: auth?.currentUser?.uid ? auth.currentUser.uid : "anonymous",
        email: auth?.currentUser?.email ? auth.currentUser.email : "anonymous",
        name: auth?.currentUser?.displayName
          ? auth.currentUser.displayName
          : "anonymous",
      },
    });

    db.collection("mentors")
      .get()
      .then((mentors) => {
        mentors.forEach((mentor) => {
          var mentorObj = mentor.data();
          mentorObj.id = mentor.id;
          this.mentors.push(mentorObj);
        });
      });
  },
};
</script>
<style scoped>
.mentorprofile {
  border-radius: 20px;
}

.mentorprofile img.pic {
  border-radius: 50%;
  border: 3px solid;
}

.mentorprofile .title.is-4 {
  margin-bottom: 10px;
}

.mentorprofile .button {
  border-radius: 15px;
}

.online {
  background: #b7ffb7;
  color: #008000;
  font-size: 0.8rem;
  border-radius: 10px;
  text-align: center;
  font-weight: 700;
  padding: 5px 10px;
}
</style>