<template>
  <section class="rewards">
    <div class="columns is-mobile">
      <div class="column">
        <h1 class="title">Rewards</h1>
      </div>
      <div class="column is-4 has-text-right">
        <a class="button is-small is-rounded" @click="router('/transactions')">
          <img
            src="@/assets/img/coin.svg"
            style="width: 15px; margin-right: 5px"
          />
          <span id="coins">2678</span>
        </a>
      </div>
    </div>
    <!-- <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content">
            <div class="reward">
              <div class="columns is-mobile">
                <div class="column">
                  <h3>5 WitCoins</h3>
                  <h6>Your video received 100 views.</h6>
                </div>
                <div class="column is-5">
                  <a class="button is-fullwidth">Claim</a>
                </div>
              </div>
              <hr />
            </div>
            <div class="reward">
              <div class="columns is-mobile">
                <div class="column">
                  <h3>50 WitCoins</h3>
                  <h6>Your video received 500 views.</h6>
                </div>
                <div class="column is-5">
                  <a class="button is-fullwidth is-disabled" disabled>Claimed</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
[    <div class="columns">
      <div class="column">
        <div class="rewardscontainer">
          <div class="reward">
            <div class="card">
              <div class="card-content has-text-centered">
                <h1 class="title">Yay!</h1>
                <img src="@/assets/img/party-popper.png" alt="" />
                <h3 class="subtitle">You have won <b>5 WitCoins</b>.</h3>
              </div>
              <div class="card-footer">
                <a class="button is-fullwidth">CLAIM</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
]  </section>
</template>
<script>
// import { db } from "@/main.js";
export default {
  name: "rewards",
  data() {
    return {};
  },
  methods: {
    format(value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", "K", "M", "B", "T"];
        var suffixNum = Math.floor(("" + value).length / 3);
        var shortValue = "";
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum != 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          var dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
        newValue = shortValue + suffixes[suffixNum];
      }
      return newValue;
    },
  },
  beforeMount() {},
  mounted() {},
};
</script>
<style scoped>
section.rewards {
  /* height: 100% !important; */
  padding: 2rem 1.5rem 3rem 1.5rem;
}
.rewards {
  border-radius: 15px;
}
.rewards a.button {
  margin-left: 3px;
  margin-bottom: 3px;
  border: 2px solid;
  color: #5743e1;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.reward {
  display: inline-block;
  width: 50%;
  padding: 0 5px;
}
.reward .card {
  border-radius: 15px;
}
.reward img {
  width: 60px;
  height: 60px;
  padding: 10px;
  background: #5843e149;
  border-radius: 50%;
}
.reward h1.title {
  margin-bottom: 1rem;
}
.reward h3.subtitle {
  font-size: 0.85rem;
  margin-top: 5px;
}
.reward .card-footer {
  background: #5843e16b;
  padding: 6px 7px 4px 5px;
}
.reward .card-footer {
  border-radius: 0 0 15px 15px;
}
.reward .card-footer a.button {
  border-radius: 4px 4px 15px 15px;
  background: #5743e1;
  color: #fff;
}
</style>