<template>
  <div class="homescreen" :style="color">
    <div class="homeprofile">
      <div class="homeheader columns is-mobile">
        <div class="column is-3">
          <a @click="router('/profile')">
            <div
              v-bind:style="{ 'background-image': image }"
              v-if="$store.state.profile.picture === ''"
              class="homeprofileimg"
            ></div>
            <div
              :style="$store.state.profile.picture"
              v-else
              class="homeprofileimg"
            ></div>
          </a>
        </div>
        <div class="column is-7">
          <h6 class="home title" :style="textColor">
            Hi
            <span>
              <span
                v-if="$store.state.profile.fname === ''"
                class="preload"
                style="width: 100px"
              ></span>
              <span v-else>{{ $store.state.profile.fname }}</span>
            </span>
            <img src="../assets/img/hi.png" width="28px" />
          </h6>
        </div>
        <div class="column is-2">
          <a @click="router('/search')" class="searchIcon">
            <i class="fas fa-search"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="columns">
        <!-- video -->
        <div class="column is-4">
          <div class="grand-challenge columns is-hidden">
            <div class="column">
              <div class="card" @click="router(route)">
                <a class="gc-next">
                  <i class="fas fa-chevron-circle-right"></i>
                </a>
                <div
                  class="card-content"
                  v-bind:style="{ 'background-image': banner }"
                >
                  <div class="featured-banner" style="margin: 0px !important">
                    #{{ hashtag }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <video-player
            v-if="vid1.loaded"
            class="vjs-custom-skin"
            ref="videoPlayer"
            :options="vid1"
            :playsinline="true"
          ></video-player>
          <img class="vjs-image" :src="product.banner" alt="" />
        </div>
        <!-- /video -->
        <!-- projects -->
        <div class="column">
          <div v-if="projectstatus">
            <div
              class="preload dark"
              style="height: 32px; width: 100%; margin: 10px 0"
            ></div>
            <div class="preload dark" style="height: 120px; width: 100%"></div>
            <div
              class="preload dark"
              style="height: 32px; width: 100%; margin: 10px 0"
            ></div>
            <div class="preload dark" style="height: 120px; width: 100%"></div>
          </div>
          <div v-else class="columns">
            <div style="padding: 0 13px" class="column">
              <div
                style="padding: 0 13px"
                class="column"
                v-if="similarProjects.length > 0"
              >
                <div style="padding-bottom: 13px">
                  <span class="tag preview-headline is-medium is-info"
                    >Recommended for you</span
                  >
                </div>

                <div class="scrollingwrapper">
                  <a
                    @click="router('/project/' + project.id)"
                    class="scrolling-card"
                    :key="project.index"
                    v-for="project in similarProjects"
                  >
                    <v-lazy-image
                      :src-placeholder="require('@/assets/img/home-lazy.png')"
                      :src="project.image"
                      @error="
                        post.thumbnail = require('@/assets/img/home-lazy.png')
                      "
                    />
                  </a>
                </div>
              </div>

              <div v-for="level in levels" :key="level.index">
                <div class="column">
                  <h6 class="preview-headline subtitle is-hidden">
                    {{ level.name }}
                  </h6>
                  <span class="tag preview-headline is-medium is-info">{{
                    level.name
                  }}</span>
                </div>
                <div class="column">
                  <video-player
                    v-if="level.name == 'Level 4'"
                    class="vjs-custom-skin"
                    ref="videoPlayer"
                    :options="vid1"
                    :playsinline="true"
                  ></video-player>
                  <div v-if="level.docs < 2">
                    <div
                      class="preload dark"
                      style="height: 150px; width: 100%"
                    ></div>
                  </div>
                  <div v-else class="scrollingwrapper">
                    <a
                      @click="router('/project/' + project.id)"
                      class="scrolling-card"
                      :key="project.index"
                      v-for="project in level.docs"
                    >
                      <div v-if="project.done" class="done">
                        <i class="fas fa-check-circle"></i>
                      </div>
                      <v-lazy-image
                        v-bind:class="{ doneimg: project.done }"
                        :src-placeholder="require('@/assets/img/home-lazy.png')"
                        :src="project.image"
                        @error="
                          post.thumbnail = require('@/assets/img/home-lazy.png')
                        "
                      />
                    </a>
                  </div>
                  <img
                    v-if="level.name == 'Level 4'"
                    class="vjs-image"
                    :src="product.banner"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="columns" v-if="comingsoon">
            <div class="column">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/witblox-5ae68.appspot.com/o/category%2Fcoming-soon.jpeg?alt=media&token=1d1bad58-a610-4f8b-b62e-1b5489250f05"
                alt=""
              />
            </div>
          </div>
          <div class="columns">
            <div class="column" style="padding-top: 50px"></div>
          </div>
        </div>
        <!-- /projects -->
      </div>
    </div>
  </div>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { db } from "@/main.js";
// const { App } = Plugins;
import VLazyImage from "v-lazy-image";
import { videoPlayer } from "vue-video-player";
export default {
  name: "category",
  components: {
    VLazyImage,
    videoPlayer,
  },
  data() {
    return {
      projectstatus: true,
      image: "url(" + require("@/assets/img/user.png") + ")",
      message: "What would you like to build today?",
      challengetag: "#Challenge",
      cid: "",
      banner: "",
      coins: 0,
      levels: [],
      comingsoon: false,
      route: "",
      hashtag: "",
      similarProjects: [],
      recommendations: [],
      vid1: {
        height: "300",
        width: "100%",
        autoplay: true,
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [],
        poster: "",
        loaded: false,
      },
      product: {},
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    async fetcChallenge(id) {
      await db
        .collection("challenges")
        .doc(id)
        .get()
        .then((doc) => {
          var c = doc.data();
          this.coins = c.coins;
          this.banner = "url(" + c.featuredImg + ")";
          this.cid = doc.id;
        });
    },
    fetchProjects() {
      this.levels = [];
      this.projectstatus = true;
      this.comingsoon = false;
      this.$store.state.categories.forEach((ctgry) => {
        if (ctgry.id == this.$route.params.id) {
          this.banner = "url(" + ctgry.banner + ")";
          this.route = ctgry.route;
          this.hashtag = ctgry.hashtag;
        }
      });
      this.$store.state.categoryData.forEach((category) => {
        if (category.id == this.$route.params.id) {
          //   this.fetcChallenge(category.challenge);
          var homepage = [];
          if (category.levels) {
            category.levels.forEach((level) => {
              var prjct = [];
              var lvl = { name: level.name, docs: prjct };
              level.projects.forEach((project) => {
                this.$store.state.projectData.forEach((pdata) => {
                  if (pdata.id == project) {
                    prjct.push({
                      id: pdata.id,
                      image: pdata.featuredImg,
                      done: pdata.done,
                    });
                  }
                });
              });
              homepage.push(lvl);
            });
          } else this.comingsoon = true;
          this.levels = homepage;
          this.projectstatus = false;
          this.product = category.product;
          this.vid1.sources.push({
            type: "video/mp4",
            src: category.product.video,
          });
          this.vid1.poster = category.product.image;
          this.vid1.loaded = true;
        }
      });
    },
    async fetchRecommendations() {
      const userProjects = await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .collection("projects")
        .orderBy("created", "desc")
        .get();

      const lastProject = userProjects.docs[0];
      const lastProjectId = lastProject.data().pid;

      const categoriesConfig = await db
        .collection("config")
        .doc("categories")
        .get();

      const allProjects = categoriesConfig.data().recommended_projects;

      // Find the last project in the recommended projects array
      const lastProjectInArr = allProjects.find(
        (project) => project.id === lastProjectId
      );

      const lastProjectCategory = lastProjectInArr.category_id;

      // Find all the projects in the same category as the last project
      let similarProjects = allProjects.filter(
        (project) => project.category_id === lastProjectCategory
      );

      // remove projects that the user has already done
      const userProjectsIds = userProjects.docs.map(
        (project) => project.data().pid
      );

      similarProjects = similarProjects.filter(
        (project) => !userProjectsIds.includes(project.id)
      );

      // if there are less than 3 projects in the category, show all of them
      if (similarProjects.length <= 3) {
        this.similarProjects = similarProjects;
      } else {
        // Get 3 projects with different levels easy, medium, hard
        const easyProject = similarProjects.find(
          (project) => parseInt(project.level) === 1
        );

        const mediumProject = similarProjects.find(
          (project) => parseInt(project.level) === 2
        );

        const hardProject = similarProjects.find(
          (project) => parseInt(project.level) > 2
        );

        this.similarProjects = [easyProject, mediumProject, hardProject];
      }
    },
    getColor() {
      this.$store.commit("getColorInfo");
      this.color =
        "background: linear-gradient(360deg, #ffffff 0%, #ffffff 82%, " +
        this.$store.state.selectedColor.c1 +
        " 100%);";
      this.textColor = "color: " + this.$store.state.selectedColor.c2 + ";";
    },
  },
  beforeMount() {
    this.getColor();
    this.fetchProjects();
    this.fetchRecommendations();
  },
  watch: {
    "$store.state.projectData": function () {
      this.fetchProjects();
    },
  },
};
</script>

<style scoped>
.vjs-custom-skin {
  height: 200px !important;
  margin-bottom: 10px;
}

.vjs-custom-skin .video-js,
.vjs-image {
  border-radius: 10px;
}
</style>