<template>
    <section class="videosection">
        <a @click="$router.back()" class="closeBtns">
            <span class="icon">
                <i class="far fa-times-circle"></i>
            </span>
        </a>
        <div class="video-container" v-if="screen === 0">
            <video-player v-if="vid1.loaded" class="vjs-custom-skin" ref="videoPlayer" :options="vid1"
                :playsinline="true" @ended="onPlayerEnded($event)"></video-player>
            <div class="videoBtns">
                <a class="button is-small" id="skipBtn" @click="screen = 1">
                    <span>Build It</span>
                    <span class="icon">
                        <i class="fas fa-chevron-right"></i>
                    </span>
                </a>
            </div>
            <div v-if="popupOpen" class="cartoon2 is-hidden-desktop">
                <div class="columns">
                    <div class="column">
                        <div class="scard">
                            <h1 class="title">
                                Would you like to complete
                                <b>"{{ name }}"</b>?
                            </h1>
                            <div class="columns">
                                <div class="column has-text-centered">
                                    <img src="@/assets/img/coin.svg" alt />
                                    <h3 class="subtitle">
                                        Build &amp; Win
                                        {{ coins }} coins.
                                    </h3>
                                </div>
                            </div>
                            <button class="btn btn-theme" @click="screen = 1">Yes</button>
                            <a class="btn btn-theme" @click="router('/')">No</a>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <img src="@/assets/img/cartoon.png" class="cartoonImg" />
                    </div>
                </div>
            </div>
            <div class="cartoon2 is-hidden-touch">
                <div class="columns">
                    <div class="column">
                        <div class="scard">
                            <h1 class="title">
                                Would you like to complete
                                <b>"{{ name }}"</b>?
                            </h1>
                            <div class="columns">
                                <div class="column has-text-centered">
                                    <img src="@/assets/img/coin.svg" alt />
                                    <h3 class="subtitle">
                                        Build &amp; Win
                                        {{ coins }} coins.
                                    </h3>
                                </div>
                            </div>
                            <button class="btn btn-theme" @click="screen = 1">Yes</button>
                            <a class="btn btn-theme" @click="router('/')">No</a>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <img src="@/assets/img/cartoon.png" class="cartoonImg" />
                    </div>
                </div>
            </div>
        </div>
        <div class="video-container" v-else-if="screen === 1">
            <img :src="banner" class="featuredImg desktop-video" />
            <div class="hero desktop-video-info">
                <div class="hero-body">
                    <h1 class="title">{{ name }}</h1>
                    <!-- <a class="button is-fullwidth" @click="shareDialog">
            <span class="icon">
              <i class="fas fa-share-alt"></i>
            </span>
            <span>Share with Friends</span>
          </a> -->
                    <div class="separator">MORE INFO</div>
                    <h3 class="subtitle">To build this project, you will need:</h3>
                    <div class="kitInfo">
                        <div class="columns" v-for="(component, index) in components" :key="component.index">
                            <div class="column plist">
                                <div class="columns is-mobile">
                                    <div class="column is-3">
                                        <img :src="component.image" />
                                    </div>
                                    <div class="column">
                                        <h3 class="subtitle">
                                            #{{ index + 1 }}
                                            <br />
                                            {{ component.name }}
                                        </h3>
                                    </div>
                                    <div class="column is-2">
                                        <!-- <a @click="showInApp(component.video)">
                                            <i class="fas fa-info-circle"></i>
                                        </a> -->
                                        <!-- <a @click="showInApp(component.link)">
                    <i class="fas fa-shopping-bag"></i>
                  </a> -->
                                        <!-- <a v-if="component.pid" @click="router('/product/' + component.pid)">
                                            <i class="fas fa-shopping-bag"></i>
                                        </a> -->
                                        <a v-if="component.pid" @click="addToCart(component)">
                                            <i v-if="!component.added" class="fas fa-cart-plus"></i>
                                            <i v-else class="fas fa-check"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- alternate-components -->
                        <div v-if="altComponents.length > 0">
                            <div class="separator">OR</div>
                            <div class="columns" v-for="(component, index) in altComponents" :key="component.index">
                                <div class="column plist">
                                    <div class="columns is-mobile">
                                        <div class="column is-3">
                                            <img :src="component.image" />
                                        </div>
                                        <div class="column">
                                            <h3 class="subtitle">
                                                #{{ index + 1 }}
                                                <br />
                                                {{ component.name }}
                                            </h3>
                                        </div>
                                        <div class="column is-2">
                                            <!-- <a @click="showInApp(component.video)">
                                                <i class="fas fa-info-circle"></i>
                                            </a> -->
                                            <!-- <a v-if="component.pid" @click="router('/product/' + component.pid)">
                                                <i class="fas fa-shopping-bag"></i>
                                            </a> -->
                                            <a v-if="component.pid" @click="addToCart(component)">
                                                <i v-if="!component.added" class="fas fa-cart-plus"></i>
                                                <i v-else class="fas fa-check"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /alternate-components -->
                        <!-- share -->
                        <div>
                            <div class="columns">
                                <div class="column" style="padding: 50px 25px">
                                    <a @click="shareDialog" class="button is-fullwidth">Share It</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="trailerVidBtn">
                        <!-- <div class="columns">
                            <div class="column" style="padding-top: 0px">
                                <a style="margin-bottom: 5px" class="button btn btn-theme" @click="router(shop_link)"
                                    target="blank">Order Parts</a>
                            </div>
                        </div> -->
                        <div class="columns">
                            <div class="column" style="padding-top: 0px">
                                <a v-if="cart.length > 0" class="button btn btn-theme" @click="$router.push('/cart')"
                                    target="blank">Go to cart</a>
                                <!-- <a v-else class="button btn btn-theme" @click="
                                  if (pid) {
                                        router('/product/' + pid);
                                    } else {
                                        router('/shop/');
                                    }
                                " target="blank">Order Parts</a> -->
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column" style="padding-top: 0px">
                                <button @click="markComplete()" v-bind:class="{ 'is-loading': btnLoading }"
                                    class="button btn btn-theme">
                                    Mark Complete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a @click="screen = 0" class="screen2Btn">
                <span class="icon">
                    <i class="fas fa-chevron-circle-left"></i>
                </span>
            </a>
        </div>
        <div style="padding: 20px" v-else-if="screen === 2">
            <img src="@/assets/img/congrats.svg" class="congrats" />
            <h1 class="title has-text-centered" style="padding-bottom: 15px">
                Congratulations!
            </h1>
            <h3 class="subtitle has-text-centered">
                Upload your project's video to win
                <b style="color: #000000">{{ coins }} coins.</b>
            </h3>
            <a @click="router('/upload/challenge/' + $route.params.id + '/' + coins)" class="btn btn-theme">Upload
                Video</a>
            <a @click="screen = 1" class="screen3Btn">
                <span class="icon">
                    <i class="fas fa-chevron-circle-left"></i>
                </span>
            </a>
        </div>
    </section>
</template>
<script>
// import { Plugins } from "@capacitor/core";
import { auth } from "@/main.js";
import { functions } from "@/main.js";
import { db } from "@/main.js";
import { firestore } from "@/main.js";
// const { Browser } = Plugins;
import { Share } from "@capacitor/share";
import { Browser } from "@capacitor/browser";
import "@/assets/css/video-js.css";
import { videoPlayer } from "vue-video-player";
// import { Webengage } from "@ionic-native/webengage";
import { createWebengageEvent } from "../helper";
export default {
    name: "challenge",
    components: {
        videoPlayer,
    },
    data() {
        return {
            userId: "",
            screen: 0,
            name: "",
            orderLink: "",
            coins: "",
            banner: "",
            popupOpen: false,
            components: [],
            altComponents: [],
            btnLoading: false,
            shop_link: "",
            vid1: {
                height: "100%",
                width: "100%",
                autoplay: true,
                muted: false,
                language: "en",
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [],
                poster: "",
                loaded: false,
            },
            cart: [],
        };
    },
    beforeMount() {
        this.userId = auth.currentUser.uid;
        db.collection("config")
            .doc("categories")
            .get()
            .then((doc) => {
                this.shop_link = doc.data().challenge_shop;
            });
        db.collection("challenges")
            .doc(this.$route.params.id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    createWebengageEvent({
                        uid: this.userId,
                        eventName: "Challenge Viewed",
                        eventData: {
                            added: new Date().getTime(),
                            readableDate: new Date().toLocaleString(),
                            name: doc.data().name,
                        },
                    });
                    this.vid1.sources.push({ type: "video/mp4", src: doc.data().video });
                    this.vid1.poster =
                        "https://thumbnails.cloud/v1/jpg?token=16BcfdRyI2BfW1Rux&url=" +
                        encodeURIComponent(doc.data().video) +
                        "&size=400&mode=orig";
                    this.name = doc.data().name;
                    this.coins = doc.data().coins;
                    if (this.$store.state.profile.premium)
                        this.coins = doc.data().coins * 2;
                    this.orderLink = doc.data().orderLink;
                    this.banner = doc.data().featuredImg;
                    doc.data().components.forEach((component) => {
                        db.collection("tools")
                            .doc(component)
                            .get()
                            .then((doc) => {
                                this.components.push({
                                    name: doc.data().name,
                                    link: doc.data().orderLink,
                                    video: doc.data().video,
                                    image: doc.data().img,
                                    pid: doc.data().pid,
                                });
                            });
                    });
                    doc.data().altComponents.forEach((component) => {
                        db.collection("tools")
                            .doc(component)
                            .get()
                            .then((doc) => {
                                this.altComponents.push({
                                    name: doc.data().name,
                                    link: doc.data().orderLink,
                                    video: doc.data().video,
                                    image: doc.data().img,
                                    pid: doc.data().pid,
                                });
                            });
                    });
                    setTimeout(() => {
                        this.vid1.loaded = true;
                    }, 100);
                } else {
                    this.router("/");
                }
            });
        auth.onAuthStateChanged((user) => {
            this.useremail = user.email;
            db.collection("users")
                .doc(user.uid)
                .collection("tracking")
                .where("id", "==", this.$route.params.id)
                .get()
                .then((snap) => {
                    console.log(snap);
                    if (snap.empty) {
                        db.collection("users").doc(user.uid).collection("tracking").add({
                            id: this.$route.params.id,
                            created: new Date().getTime(),
                            type: "challenge",
                        });
                    }
                });
        });
    },
    mounted() {
        if (this.$route.params.page) this.screen = Number(this.$route.params.page);
    },
    methods: {
        router(name) {
            this.$router.push(name);
        },
        async showInApp(link) {
            await Browser.open({ url: link });
        },
        onPlayerEnded() {
            this.popupOpen = true;
        },
        markComplete() {
            this.btnLoading = true;
            var markComplete = functions.httpsCallable("markComplete");
            markComplete({ pid: this.$route.params.id, type: "challenge" }).then(
                () => {
                    createWebengageEvent({
                        uid: this.userId,
                        eventName: "Challenge Completed",
                        eventData: {
                            added: new Date().getTime(),
                            readableDate: new Date().toLocaleString(),
                            name: this.name,
                        },
                    });
                    this.btnLoading = false;
                    this.screen = 2;
                }
            );
        },
        async shareDialog() {
            await Share.share({
                title: "WitBlox Challenge",
                text:
                    "Hi, are you are good at solving problems? Take this " +
                    this.name +
                    ". Build it, upload its video on WitBlox App & win prizes!",
                url: "https://app.witblox.com/challenge/" + this.$route.params.id,
                dialogTitle: "Share with friends",
            });
        },
        addToCart(p) {
            console.log(p);
            this.cart.push(p.pid);
            this.animation = p.name;
            p.added = true;
            db.collection("shop-products").doc(p.pid).get().then((product) => {
                if (product.exists) {
                    db.collection("users")
                        .doc(auth.currentUser.uid)
                        .collection("cart")
                        .where("product", "==", p.pid).limit(1)
                        .get()
                        .then((snap) => {
                            if (snap.empty) {
                                //not added in cart
                                db.collection("users")
                                    .doc(auth.currentUser.uid)
                                    .collection("cart")
                                    .add({
                                        quantity: 1,
                                        product: p.pid,
                                        added: new Date().getTime(),
                                    })
                                    .then(() => {
                                        this.productsAdded = true;
                                    });
                            } else {
                                //already added in cart
                                db.collection("users")
                                    .doc(auth.currentUser.uid)
                                    .collection("cart")
                                    .doc(snap.docs[0].id).set({
                                        quantity: firestore.FieldValue.increment(1),
                                        product: p.pid,
                                        added: new Date().getTime(),
                                    }, { merge: true })
                                    .then(() => {
                                        this.productsAdded = true;
                                    });
                            }
                        });
                } else console.log("product doesn't exists", p.pid);
                setTimeout(() => {
                    this.animation = "";
                }, 3000);
            }).catch((err) => {
                console.log(err);
                this.animation = "";
            })
        },
    },
};
</script>
<style scoped>
.plist {
    max-width: 500px;
    margin-bottom: 20px;
}

@media only screen and (min-width: 600px) {
    .video-player.vid2 {
        height: 450px !important;
    }

    .trailerVidBtn .columns {
        max-width: 700px;
        margin: auto;
    }

    .plist {
        max-width: 500px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 600px) {
    .video-player.vid2 {
        height: 450px !important;
    }

    .trailerVidBtn .columns {
        max-width: 700px;
        margin: auto;
    }

    .plist {
        max-width: 500px;
        margin-bottom: 10px;
    }

    .cartoon2 {
        width: 27%;
    }
}

@media only screen and (min-width: 900px) {

    .video-container .desktop-video,
    .video-container .desktop-video-info {
        display: inline;
    }

    .video-container .desktop-video {
        width: 70%;
        height: 100% !important;
        float: left;
    }

    .video-container .desktop-video-info {
        width: 30%;
        height: 100% !important;
        float: right;
        overflow: scroll;
        position: relative;
    }

    .trailerVidBtn {
        width: 30%;
        right: 0;
        left: auto;
    }
}
</style>