<template>
  <section class="shop">
    <div class="hero">
      <div class="hero-body">
        <div class="columns is-mobile">
          <div class="column">
            <h1 class="title">
              <img src="https://witblox.com/assets/img/witblox.png" width="25px" />
              Shop
            </h1>
          </div>
          <div class="column is-narrow" style="margin: auto">
            <a @click="$router.push('/search')" class="searchIcon">
              <i class="fas fa-search"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="categories">
      <div class="columns">
        <div class="column ordersBtn">
          <a class="button is-small is-rounded" @click="router('/transactions')">
            <img src="@/assets/img/coin.svg" style="width: 15px; margin-right: 5px" />
            <span id="coins">{{ coins }}</span>
          </a>
          <a class="button is-small is-rounded" @click="router('/orders')">
            <i class="fas fa-shopping-bag" style="margin-right: 4px"></i>
            <span id="coins">Track Orders</span>
          </a>
        </div>
      </div>
      <!-- <hr style="margin: 0.25rem 0px 1.5rem 0px; background-color: #d7d7d7" /> -->
    </div>
    <div class="container" style="margin-top: 20px">
      <div class="columns tagsholder">
        <div class="column">
          <div class="columns is-mobile">
            <div class="column">
              <h1 class="subtitle">
                Find <u>everything</u> you need to build your project
              </h1>
            </div>
            <div class="column is-3">
              <img :src="require('@/assets/img/shop-drone.gif')" alt="" />
            </div>
          </div>
          <div class="scrollingwrapper ctgr">
            <a v-for="card in categories" :key="'i2' + card.id" class="scrolling-card"
              @click="$router.push('/products/' + card.id)">
              <div class="cardholder">
                <div class="card"  style="min-width: 9rem;">
                  <div class="card-image">
                    <figure class="image is-1by1">
                      <img :src="card.image" alt="Placeholder image" />
                    </figure>
                  </div>
                  <div class="card-content has-text-centered">
                    <p class="title is-6">{{ card.name }}</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="tags are-medium is-hidden">
            <span v-for="card in categories" :key="'i' + card.id" class="tag" @click="router('/products/' + card.id)"
              :class="{ selected: card.selected }">
              {{ card.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="columns services">
        <div class="column">
          <div class="card" style="margin: 0 20px">
            <div class="card-content">
              <h3 class="title">
                <img src="https://witblox.com/assets/img/witblox.png" alt="" />
                <a class="has-text-centered" style="background-color: #7a0bc0">Robotic Kits</a>
              </h3>
              <p>Model building kits</p>
              <div class="scrollingwrapper">
                <a v-for="product in kit" :key="product.id" class="scrolling-card"
                  @click="$router.push('/product/' + product.id)">
                  <div class="cardholder">
                    <div class="card">
                      <div class="card-image">
                        <figure class="image is-4by3">
                          <img :src="product.image" alt="Placeholder image" />
                        </figure>
                      </div>
                      <div class="card-content">
                        <p class="subtitle">{{ product.name }}</p>
                        <p class="title">
                          {{ numberWithFormat(product.mrp) }}
                          <strike class="striked">{{
                            numberWithFormat(product.original)
                          }}</strike>
                        </p>
                      </div>
                      <div class="card-footer is-hidden">
                        <a class="card-footer-item"><i class="fas fa-plus"></i></a>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="columns"> -->
        <!-- <div class="column"> -->
          <!-- mobile-carousel -->
          <!-- <flickity ref="flickity" :options="flickityOptions" class="mobile is-hidden-desktop"> -->
            <!-- banner-1 -->
            <!-- <div class="grand-challenge" style="width: 90%">
              <div class="card">
                <a class="gc-next">
                  <i class="fas fa-chevron-circle-right"></i>
                </a>
                <div class="card-content" v-bind:style="{
                  'background-color': $store.state.selectedColor.c1,
                  'background-image': $store.state.shopBanner,
                }">
                </div>
              </div>
            </div>
          </flickity>
          <p class="subtitle has-text-centered" style="padding: 1.4rem">
            For every purchase you make, we will plant a tree.
          </p> -->
          <!-- /mobile-carousel -->
        <!-- </div> -->
      <!-- </div> -->
      <div class="columns services">
        <div class="column">
          <div class="card" style="margin: 0 20px">
            <div class="card-content">
              <h3 class="title">
                <img src="https://witblox.com/assets/img/witblox.png" alt="" />
                <a class="has-text-centered" style="background-color: #7a0bc0">Bloxes</a>
              </h3>
              <p>Modular electronic circuit boards</p>
              <div class="scrollingwrapper">
                <a v-for="product in blox" :key="product.id" class="scrolling-card"
                  @click="$router.push('/product/' + product.id)">
                  <div class="cardholder">
                    <div class="card">
                      <div class="card-image">
                        <figure class="image is-4by3">
                          <img :src="product.image" alt="Placeholder image" />
                        </figure>
                      </div>
                      <div class="card-content">
                        <p class="subtitle">{{ product.name }}</p>
                        <p class="title">
                          {{ numberWithFormat(product.mrp) }}
                          <strike class="striked">{{
                            numberWithFormat(product.original)
                          }}</strike>
                        </p>
                      </div>
                      <div class="card-footer is-hidden">
                        <a class="card-footer-item"><i class="fas fa-plus"></i></a>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns services">
        <div class="column">
          <div class="card" style="margin: 0 20px">
            <div class="card-content">
              <h3 class="title">
                <img src="https://witblox.com/assets/img/witblox.png" alt="" />
                <a class="has-text-centered" style="background-color: #7a0bc0">Components</a>
              </h3>
              <p>Basic electronic components</p>
              <div class="scrollingwrapper">
                <a v-for="product in components" :key="product.id" class="scrolling-card"
                  @click="$router.push('/product/' + product.id)">
                  <div class="cardholder">
                    <div class="card">
                      <div class="card-image">
                        <figure class="image is-4by3">
                          <img :src="product.image" alt="Placeholder image" />
                        </figure>
                      </div>
                      <div class="card-content">
                        <p class="subtitle">{{ product.name }}</p>
                        <p class="title">
                          {{ numberWithFormat(product.mrp) }}
                          <strike class="striked">{{
                            numberWithFormat(product.original)
                          }}</strike>
                        </p>
                      </div>
                      <div class="card-footer is-hidden">
                        <a class="card-footer-item"><i class="fas fa-plus"></i></a>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav class="pagination notification-filter is-hidden" role="navigation" aria-label="pagination">
        <ul class="pagination-list">
          <li v-for="category in categories" :key="category.id">
            <a class="pagination-link" @click="router('/products/' + category.id)">{{ category.name }}</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="products two">
      <div class="cardholder" v-for="product in products" :key="product.id">
        <div class="card depth">
          <div class="card-image" @click="router('product/' + product.id)">
            <figure class="image is-4by3">
              <img :src="product.img" alt="Placeholder image" />
            </figure>
          </div>
          <div class="card-content" @click="router('product/' + product.id)">
            <p class="subtitle">{{ product.name }}</p>
            <p class="title">
              {{ product.mrp }}
              <strike class="striked">{{ product.original }}</strike>
            </p>
          </div>
          <div class="card-footer">
            <a class="card-footer-item" v-if="product.added">
              <i class="fas fa-check"></i>
            </a>
            <a v-else class="card-footer-item" @click="openMayNeed(product)">
              <i class="fas fa-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="cartaction" v-if="incart > 0">
      <div class="columns is-mobile" @click="router('/cart')">
        <div class="column is-3">
          <h1 class="title has-text-right">{{ incart }}</h1>
        </div>
        <div class="column">
          <h3 class="subtitle">Items in cart</h3>
        </div>
        <div class="column is-3">
          <i class="fas fa-arrow-circle-right" style="font-size: 2.2rem"></i>
        </div>
      </div>
    </div>
    <div v-if="cartbox" class="cartbox">
      <div class="content depth">
        <div class="columns is-mobile">
          <div class="column is-2">
            <img :src="selected.img" alt="" />
          </div>
          <div class="column">
            <p class="subtitle" style="margin-bottom: 0.5em; padding-bottom: 0px">
              {{ selected.name }}
            </p>
            <p class="subtitle" style="font-size: 1rem; padding-bottom: 0px">
              &#8377;{{ numberWithFormat(selected.price) }}
            </p>
            <div class="select is-rounded is-fullwidth is-small">
              <select v-model.number="qty" @change="updateQty()">
                <option v-for="option in options" :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>
          <div class="column is-2 has-text-right">
            <a style="font-size: 1.6rem; color: #000000" @click="cartbox = false">
              <i class="fas fa-times-circle"></i>
            </a>
          </div>
        </div>
        <div class="notification has-text-centered" style="padding: 1rem; margin-bottom: 1rem" v-if="selected.popupmsg">
          <img src="https://cdn-icons-png.flaticon.com/512/5290/5290058.png" style="display: inline" width="12px" />
          {{ selected.popupmsg }}
        </div>
        <hr v-if="mayNeed.length > 0" style="margin: 1rem 0" />
        <div v-if="mayNeed.length > 0">
          <div class="columns">
            <div class="column">
              <p class="title has-text-centered" style="font-size: 1.3rem; padding-bottom: 10px">
                To build this project,<br />you will also need
              </p>
            </div>
          </div>
          <div class="columns is-mobile cartproduct" v-for="item in mayNeed" :key="item.id">
            <div class="column is-4 has-text-centered">
              <img :src="item.img" />
            </div>
            <div class="column">
              <p class="title">{{ item.name }}</p>
              <p class="subtitle">&#8377;{{ numberWithFormat(item.price) }}</p>
            </div>
            <div class="column is-3">
              <div class="select is-small is-fullwidth">
                <select v-model="item.qty">
                  <option v-for="option in item.options" :key="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <a class="button is-small is-fullwidth" :class="{ 'is-loading': item.loading, active: item.added }">
                <i v-if="item.added" @click="removeItemFromCart(item)" class="fas fa-check"></i>
                <i v-else @click="addItemToCart(item.id, item.qty, item)" class="fas fa-plus"></i>
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div class="footerfixed">
          <a @click="router('/cart')" class="btn btn-theme">Total: {{ numberWithFormat(selected.price) }} &rarr;</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { auth } from "@/main.js";
// import { functions } from "@/main.js";
import { db } from "@/main.js";
// import Flickity from "vue-flickity";
import fx from "money";

import {
  createWebengageEvent,
  formatCurrency,
  getUserCountry,
} from "../helper";

export default {
  name: "shophome",
  components: {
    // Flickity,
  },
  data() {
    return {
      country: {
        code: "IN",
        currency: "INR",
      },
      incart: 0,
      coins: 0,
      categories: [],
      products: [],
      cartbox: false,
      qty: 1,
      mayNeed: [],
      options: [],
      selected: {
        name: "",
        img: "",
        price: 0,
        popupmsg: "",
        stockAvailable: true,
      },
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
      },
      trending: [],
      components: [],
      kit: [],
      blox: []
    };
  },
  methods: {
    router(name) {
      this.$router.push(name);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    numberWithFormat(number) {
      return formatCurrency(number, this.country.currency);
    },
    openMayNeed(product) {
      const pid = product.id;
      this.qty = 1;
      this.mayNeed = [];
      this.options = [];
      this.cartbox = true;
      if (product.stockAvailable)
        this.addToCart(pid, product.name, product.total);
      db.collection("shop-products")
        .doc(pid)
        .get()
        .then((doc) => {
          this.addToCart(pid, doc.data().name, doc.data().pricing["in-mrp"]);
          this.selected.name = doc.data().name;
          this.selected.img = doc.data().imgs[0];
          this.selected.price = doc.data().pricing["in-mrp"];
          this.selected.popupmsg = doc.data().popupmsg;
          if (doc.data().stock < 1) this.selected.stockAvailable = false;
          else this.selected.stockAvailable = true;
          var options = [];
          for (var q = 1; q < Number(doc.data()["purchase-limit"]) + 1; q++) {
            options.push(q);
          }
          this.options = options;
          doc.data()["may-need"].forEach((product) => {
            db.collection("shop-products")
              .doc(product.id)
              .get()
              .then((item) => {
                var options = [];
                for (
                  var i = 1;
                  i < Number(item.data()["purchase-limit"]) + 1;
                  i++
                ) {
                  options.push(i);
                }
                this.mayNeed.push({
                  id: item.id,
                  name: item.data().name,
                  price: item.data()["pricing"]["in-mrp"],
                  img: item.data().imgs[0],
                  loading: false,
                  added: false,
                  options: options,
                  qty: product.qty,
                });
              });
          });
        });
    },
    addToCart(pid, name, total) {
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .where("product", "==", pid)
          .get()
          .then((snap) => {
            if (snap.empty) {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .add({
                  quantity: 1,
                  product: pid,
                  added: new Date().getTime(),
                })
                .then((doc) => {
                  this.cartid = doc.id;
                  createWebengageEvent({
                    uid: auth.currentUser.uid,
                    eventName: "Product Added To Cart",
                    eventData: {
                      added: new Date().getTime(),
                      readableDate: new Date().toLocaleString(),
                      name: name,
                      amount: total,
                    },
                  });
                });
            } else {
              this.qty = snap.docs[0].data().quantity;
              this.cartid = snap.docs[0].id;
            }
          });
      });
    },
    updateQty() {
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .doc(this.cartid)
          .update({ quantity: this.qty });
      });
    },
    addItemToCart(id, qty, object) {
      object.loading = true;
      auth.onAuthStateChanged((user) => {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .where("product", "==", id)
          .get()
          .then((snap) => {
            if (snap.empty) {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .add({
                  added: new Date().getTime(),
                  product: id,
                  quantity: Number(qty),
                })
                .then(() => {
                  object.added = true;
                  object.loading = false;
                });
            } else {
              db.collection("users")
                .doc(user.uid)
                .collection("cart")
                .doc(snap.docs[0].id)
                .update({
                  quantity: Number(qty),
                })
                .then(() => {
                  object.added = true;
                  object.loading = false;
                });
            }
          });
      });
    },
    removeItemFromCart(item) {
      item.added = false;
      item.kitTotal = item.kitTotal - Number(item.price) * Number(item.qty);
      console.log(item);
      db.collection("users")
        .doc(auth.currentUser.uid)
        .collection("cart")
        .where("product", "==", item.id)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            db.collection("users")
              .doc(auth.currentUser.uid)
              .collection("cart")
              .doc(doc.id)
              .delete();
          });
        });
    },
  },
  beforeMount() {
    try {
      const d = fx.convert(1, {
        from: "INR",
        to: "USD",
      });
      console.log(d, "converted");
    } catch (error) {
      console.log(error);

      db.collection("config")
        .doc("currencies")
        .get()
        .then((doc) => {
          fx.base = "INR";
          fx.rates = doc.data().rates;

          const d = fx.convert(1, {
            from: "INR",
            to: "USD",
          });
          console.log(d, "converted after fetching");
        });
    }

    auth.onAuthStateChanged((user) => {
      //fetch cart count
      db.collection("users")
        .doc(user.uid)
        .collection("cart")
        .onSnapshot((snap) => {
          this.incart = snap.size;
        });
      //fetch usable coins
      db.collection("users")
        .doc(user.uid)
        .collection("coins")
        .get()
        .then((snap) => {
          var coinsCollection = [];
          for (var i = 0; i < snap.size; i++) {
            coinsCollection.push(snap.docs[i].data().value);
          }
          function arraySum(arr) {
            return arr.reduce(function (a, b) {
              return a + b;
            }, 0);
          }
          this.coins = this.numberWithFormat(arraySum(coinsCollection));
        });
    });
    //fetch kits
    db.collection("config")
      .doc("shop")
      .get()
      .then((config) => {
        config.data().kit.forEach((product) => {
          db.collection("shop-products")
            .doc(product)
            .get()
            .then((p) => {
              this.kit.push({
                id: p.id,
                name: p.data().name,
                mrp: p.data().pricing["in-mrp"],
                original: p.data().pricing["in-original"],
                image: p.data().imgs[0],
              });
            });
        });
      });
    //fetch blox
    db.collection("config")
      .doc("shop")
      .get()
      .then((config) => {
        config.data().blox.forEach((product) => {
          db.collection("shop-products")
            .doc(product)
            .get()
            .then((p) => {
              this.blox.push({
                id: p.id,
                name: p.data().name,
                mrp: p.data().pricing["in-mrp"],
                original: p.data().pricing["in-original"],
                image: p.data().imgs[0],
              });
            });
        });
      });
    //fetch components
    db.collection("config")
      .doc("shop")
      .get()
      .then((config) => {
        config.data().components.forEach((product) => {
          db.collection("shop-products")
            .doc(product)
            .get()
            .then((p) => {
              this.components.push({
                id: p.id,
                name: p.data().name,
                mrp: p.data().pricing["in-mrp"],
                original: p.data().pricing["in-original"],
                image: p.data().imgs[0],
              });
            });
        });
      });
    //fetch categories
    db.collection("shop-category")
      .orderBy("added")
      .get()
      .then((snap) => {
        snap.forEach((category) => {
          if (!category.data().ignore) {
            this.categories.push({
              id: category.id,
              name: category.data().name,
              image: category.data().image,
            });
          }
        });
      });
    //fetch featured products
    db.collection("shop-category")
      .doc("config")
      .get()
      .then((config) => {
        config.data()["featured-products"].forEach((pid) => {
          db.collection("shop-products")
            .doc(pid)
            .get()
            .then((product) => {
              this.products.push({
                id: product.id,
                name: product.data().name,
                img: product.data().imgs[0],
                original: this.numberWithFormat(
                  product.data()["pricing"]["in-original"]
                ),
                mrp: this.numberWithFormat(product.data()["pricing"]["in-mrp"]),
                added: false,
                kitTotal: 0,
              });
            });
        });
      });
  },
  async mounted() {
    const data = await getUserCountry();
    this.country = {
      code: data.code,
      currency: data.currency,
    };
    console.log(this.country);
  },
};
</script>
<style>
.cartbox {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff73;
}

.cartbox .content {
  overflow: scroll;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70%;
  padding: 30px 30px 120px 30px;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  border: 4px solid;
}

.cartproduct {
  background: #f4f8fbab;
  border-radius: 10px;
  margin-bottom: 20px !important;
}

.cartproduct img {
  border-radius: 10px 0 0 10px;
  max-height: 80px;
}

.cartproduct p.title {
  font-size: 1rem;
  padding-bottom: 5px;
}

.cartproduct p.subtitle {
  font-size: 0.9rem;
  margin-bottom: 0;
  padding-top: 6px;
}

.cartproduct .button {
  border: 3px solid;
}

.cartbox .btn {
  margin-bottom: 60px;
}

/* you may need styling */
p {
  font-family: "Poppins", sans-serif;
}

.products .cardholder .title {
  padding: 10px 0;
}
</style>
<style scoped>
.shop .hero-body {
  padding: 1rem 1.5rem;
}

.shop .card-footer {
  bottom: 0;
  position: absolute;
  width: 100%;
  background: #5743e1;
  border-radius: 0 0 20px 20px;
}

.cardholder .card-content {
  padding: 1.2rem;
}

.products .card-content {
  padding-bottom: 50px;
}

.shop .card-footer-item {
  font-weight: 600;
  font-size: 1.2rem;
}

.products.two {
  border-radius: 0;
  padding-top: 20px;
}

.featured.title {
  text-align: center;
  color: #ffffff;
  padding-top: 10px;
}

.cartaction {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 35px 35px 80px 35px;
}

.cartaction .columns {
  padding: 0 15px;
  border-radius: 15px;
  background: #5743e1;
  -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.cartaction .title,
.cartaction .subtitle,
.cartaction i {
  color: #ffffff;
}

.cartaction .subtitle {
  font-size: 1rem;
}

.cartaction .subtitle {
  height: 100%;
  line-height: 2.25rem;
}

.add-to-cart {
  border: 4px solid;
}

.cardholder .card p.title {
  font-size: 1.05rem;
  color: #414141;
  font-weight: 700;
}

.cardholder .card .subtitle {
  font-size: 0.9rem;
  margin-bottom: 20px !important;
}

.cardholder .card strike {
  font-size: 0.86rem;
  color: #464646;
  font-weight: 500;
}

.ordersBtn a.button {
  margin-left: 3px;
  margin-bottom: 3px;
  border: 2px solid;
  color: #5743e1;
}

.ordersBtn span {
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.products .image.is-4by3 {
  padding-top: 100%;
}

/* */
.products {
  padding: 12px 10px 150px 10px;
  border-radius: 30px 30px 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
  grid-gap: 10px;
}

.products .cardholder {}

.products .cardholder .card {
  border-radius: 30px;
  height: 100%;
}

.products .cardholder .card img {
  border-radius: 30px 30px 0 0;
}

.cartaction {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 35px 35px 80px 35px;
}

.cartaction .columns {
  padding: 0 15px;
  border-radius: 15px;
}

.cartaction .title,
.cartaction .subtitle,
.cartaction i {
  color: #ffffff;
}

.card-footer-item i {
  color: #ffffff;
}

.categories {
  padding: 20px 10px 0px 10px;
  /* background: #8e2de2;
  background: linear-gradient(to right, #4a00e0, #8e2de2); */
  border-radius: 30px 30px 0 0;
}

.categories .columns {
  padding: 0 10px;
}

.categories .column {
  padding: 0.3rem;
}

/* */
.categories {
  padding: 20px 10px 0px 10px;
  /* background: #8e2de2;
  background: linear-gradient(to right, #4a00e0, #8e2de2); */
  border-radius: 30px 30px 0 0;
}

.categories .columns {
  padding: 0 10px;
}

.categories .column {
  padding: 0.3rem;
}

@media only screen and (min-width: 600px) {
  .cartaction .columns {
    max-width: 400px;
    margin: auto;
  }

  .cartaction {
    padding: 35px 35px 100px 35px;
  }

  .products.two {
    grid-template-columns: repeat(4, 1fr);
  }

  .products {
    padding: 12px 10px 200px 10px;
  }
}

.grand-challenge .card {
  box-shadow: none;
  padding: 5px;
}

.scrolling-card .card-content {
  /* padding-bottom: 100px; */
}

.scrolling-card .cardholder {
  max-width: 200px;
}

.scrolling-card .card {
  min-height: 280px;
}
</style>
<style>
.tagsholder .tag {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border: 2.5px #4a4a4a solid;
}

.tagsholder {
  padding: 25px 30px;
  background: #faf7f0;
}

.tagsholder .subtitle {
  font-weight: 600;
  font-size: 1.25rem;
}

.tagsholder .subtitle u {
  font-weight: 900;
}

.footerfixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #e8e5e098;
  z-index: 10;
}

.footerfixed a.btn {
  margin-top: 20px;
}

.grand-challenge .card-content {
  background-size: cover !important;
}

.scrollingwrapper.ctgr .card {
  min-height: 185px;
  width: 100px;
  border-radius: 10px;
}

.scrollingwrapper.ctgr .card .card-image {
  padding: 10px;
  background-color: #f5f5f7;
}

.scrollingwrapper.ctgr .card .title {
  font-size: 0.9rem !important;
}
</style>